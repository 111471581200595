import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatSort, MatTableDataSource} from '@angular/material';
import {NgxLoopVehicleLiveMap, VehicleMap} from '../ngx-loop-vehicle-livemap/ngx-loop-vehicle-livemap';
import moment from 'moment';
import {PreferencesService} from '../preferences.service';
import {VehicleMapGroup} from '../ngx-loop-map-vehicle-panel/ngx-loop-map-vehicle-panel';

@Component({
  selector: 'lib-ngx-loop-vehicle-table',
  templateUrl: './ngx-loop-vehicle-table.component.html',
  styleUrls: ['./ngx-loop-vehicle-table.component.css']
})
export class NgxLoopVehicleTableComponent implements OnInit,AfterViewInit,OnChanges {

  @Input()
  group: VehicleMapGroup;

  @Input()
  dataSource: MatTableDataSource<VehicleMap> = new MatTableDataSource();

  @Input()
  liveMap: NgxLoopVehicleLiveMap;

  @Input()
  showVehicleEditionButton: boolean = false;

  @Input()
  showTrackItButton:boolean = false;

  @Output()
  onVehicleEditButtonClicked: EventEmitter<VehicleMap> = new EventEmitter<VehicleMap>();

  @Output()
  onTrackItPressed: EventEmitter<VehicleMap> = new EventEmitter<VehicleMap>();

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  displayedColumns: string[] = ['name','speed','status','options'];

  constructor(
    public preferenceService:PreferencesService
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.setupSorting();
  }

  setupSorting(){
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'name') {
        return item.vehicle.name;
      }else if (property == 'status') {
        if (!this.hasPosition(item))
          return 0;
        return item.vehicle.last_position.timestamp;
      }else if(property == 'speed'){
        if(!this.hasPosition(item))
          return -1;
        return item.vehicle.last_position['position.speed'];
      }else {
        return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  getTrackingIcon(vehicleMap:VehicleMap) : string {
    if(!this.hasPosition(vehicleMap))
      return '';

    return this.liveMap.isTrackingVehicle(vehicleMap) ? 'mdi-crosshairs-gps' : 'mdi-crosshairs'
  }

  onTrackingClicked(vehicleMap:VehicleMap) {
    this.liveMap.trackVehicle(vehicleMap);
  }

  toggleVehicleVisibility(vehicleMap:VehicleMap) {
    this.liveMap.toggleVehicleMapMarker(vehicleMap);
    this.group.setCheckState();
  }

  hasPosition(vehicleMap:VehicleMap) : boolean {
    return vehicleMap.vehicle.last_position != null;
  }

  getLastMessageSince(vehicleMap:VehicleMap) {
    if(!this.hasPosition(vehicleMap))
      return 0;
    return this.getNowTs() - vehicleMap.vehicle.last_position.timestamp;
  }

  getStatusIcon(vehicleMap:VehicleMap) {
    if(!this.hasPosition(vehicleMap))
      return 'mdi-signal-off';

    let lastReceivedInSeconds = this.getLastMessageSince(vehicleMap);

    if(lastReceivedInSeconds < 5*60){
      return 'mdi-signal-cellular-3';
    }else if (lastReceivedInSeconds < 10*60){
      return 'mdi-signal-cellular-2';
    }else if (lastReceivedInSeconds < 30*60){
      return 'mdi-signal-cellular-1';
    }else{
      return 'mdi-signal-cellular-outline';
    }

  }

  getNowTs() : number {
    return Math.round(moment.utc().valueOf() / 1000)
  }

  getCurrentSpeed(vehicleMap:VehicleMap) {
    if(!this.hasPosition(vehicleMap)){
      return ``;
    }else{
      return this.preferenceService.getFormattedSpeed(vehicleMap.vehicle.last_position['position.speed'],0);
    }
  }

  getDriverName(vehicleMap:VehicleMap) {
    return vehicleMap.driverName;
  }

  getFlashingAnimation(element:VehicleMap) : string {
    return element.newMessage ? 'flashit' : '';
  }

  getFlashingAnimationPanel(element:VehicleMap) : string {
    return element.highlighted ? 'flashit_row' : '';
  }
}
