import {NgModule} from '@angular/core';
import { NgxLoopReportComponent } from './ngx-loop-report/ngx-loop-report.component';
import {CommonModule, DatePipe, DecimalPipe} from '@angular/common';
import {
  MatButtonModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTooltipModule,
  MatTabsModule,
  MatExpansionModule,
  MatDividerModule,
  MatSnackBarModule,
  MatCheckboxModule, MatTableModule, MatSortModule, MatSlideToggleModule, MatButtonToggleModule, MatPaginatorModule, MatSliderModule, MatDialogModule, MatRadioModule, MatAutocompleteModule, MatMenuModule
} from '@angular/material';

import { ResourceSelectorComponent } from './resource-selector/resource-selector.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TranslatePipe } from './translate.pipe';
import { TranslateService } from './translate.service';
import { SafeHtmlPipe } from '../pipes/safeHtmlPipe';
import { NgxLoopDocumentComponent } from './ngx-loop-document/ngx-loop-document.component';
import { DurationPipe } from './time.pipe';
import { NgxLoopReportsListComponent } from './ngx-loop-reports-list/ngx-loop-reports-list';
import {NgxLoopVehicleLiveMap} from './ngx-loop-vehicle-livemap/ngx-loop-vehicle-livemap';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {NgxLoopMapVehiclePanel} from './ngx-loop-map-vehicle-panel/ngx-loop-map-vehicle-panel';
import { MatTableExporterModule } from 'mat-table-exporter';
import { NgxLoopTimelineComponent } from './ngx-loop-timeline/ngx-loop-timeline.component';
import {NgxLoopVehicleTableComponent} from './ngx-loop-vehicle-table/ngx-loop-vehicle-table.component';
import {NgxLoopVehiclePanelGroup} from './ngx-loop-vehicle-panel-group/ngx-loop-vehicle-panel-group';
import {NgxMediumToggleComponent} from './ngx-medium-toggle/ngx-medium-toggle.component';
import {NgxSmallToggleComponent} from './ngx-small-toggle/ngx-small-toggle.component';
import { SatDatepickerModule } from 'saturn-datepicker';
import {DynamicResourceSelectorComponent} from "./dynamic-resource-selector/dynamic-resource-selector.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {NgxTrackItPanel} from './ngx-trackit-panel/ngx-track-it-panel.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatProgressButtonsModule} from 'mat-progress-buttons';
import {SecToDateTime} from './sec-to-date-pipe';
import { AutoReportsDialogComponent } from './ngx-loop-reports-list/auto-reports-dialog/auto-reports-dialog';
import { AutoReportsCreateDialogComponent } from './ngx-loop-reports-list/auto-reports-create-dialog/auto-reports-create-dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DatePipeModule } from './depends/pipe/datepipe.module';
import { FullDatePipe, HourPipe, ShortDatePipe,DayMonth, DayHourPipe, DayPipe } from './depends/pipe/date.pipe';

export function setupTranslateFactory( service: TranslateService ): Function {
  return () => service.use('en-US');
}

@NgModule({
  declarations: [
    SafeHtmlPipe,
    TranslatePipe,
    DurationPipe,
    SecToDateTime,
    NgxLoopReportComponent,
    NgxLoopDocumentComponent,
    ResourceSelectorComponent,
    NgxLoopReportsListComponent,
    NgxLoopReportsListComponent,
    NgxLoopVehicleLiveMap,
    NgxMediumToggleComponent,
    NgxSmallToggleComponent,
    NgxLoopMapVehiclePanel,
    NgxLoopTimelineComponent,
    NgxLoopVehicleTableComponent,
    NgxLoopVehiclePanelGroup,
    DynamicResourceSelectorComponent,
    NgxTrackItPanel,
    AutoReportsDialogComponent,
    AutoReportsCreateDialogComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatTableModule,
    MatButtonModule,
    MatTooltipModule,
    MatTableExporterModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatTabsModule,
    MatExpansionModule,
    MatDividerModule,
    MatSnackBarModule,
    MatCheckboxModule,
    LeafletModule,
    MatTableModule,
    MatSortModule,
    FormsModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    InfiniteScrollModule,
    SatDatepickerModule,
    MatPaginatorModule,
    ScrollingModule,
    MatSliderModule,
    MatProgressButtonsModule,
    MatDialogModule,
    MatRadioModule,
    MatAutocompleteModule,
    DatePipeModule,
    MatMenuModule
  ],
  entryComponents:[
    ConfirmationDialogComponent,
    AutoReportsDialogComponent,
    AutoReportsCreateDialogComponent
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    SecToDateTime,
    SafeHtmlPipe,
    FullDatePipe,
    DayMonth,
    DayPipe, 
    DayHourPipe,
    ShortDatePipe,
    HourPipe,
    TranslatePipe,
    DurationPipe
  ],
  exports: [
    NgxLoopReportComponent,
    NgxLoopDocumentComponent,
    ResourceSelectorComponent,
    NgxLoopReportsListComponent,
    TranslatePipe,
    DurationPipe,
    SecToDateTime,
    NgxLoopVehicleLiveMap,
    NgxLoopMapVehiclePanel,
    NgxLoopTimelineComponent,
    NgxMediumToggleComponent,
    NgxSmallToggleComponent,
    DynamicResourceSelectorComponent,
    NgxTrackItPanel,
    AutoReportsDialogComponent,
    AutoReportsCreateDialogComponent
  ]
})


export class NgxLoopModule {
}
