import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LiveMapService} from '../depends/livemap.service';
import * as L from 'leaflet';
import {LeafletMouseEvent, MapOptions, PointExpression, TileLayer} from 'leaflet';
import {TranslateService} from '../translate.service';
import {FlespiConstants, MqttController} from '../controllers/mqtt-controller';
import {FlespiService} from '../depends/custom.service';

import 'leaflet-draw';
import 'leaflet.markercluster';
import 'leaflet.heat/dist/leaflet-heat.js';
import {MatSliderChange} from '@angular/material';

export interface ResourceMap<T> {
  resource: T,
  marker: L.Marker,
  polyline: L.Polyline
  polygon: L.Polygon,
  visible:boolean,
  highlighted: boolean,
  editable: boolean // permite edicao desse poligone
  customColor?: string
}

export interface VehicleMap {
  marker: L.Marker,
  vehicle: any,
  driverName:string,
  visible: boolean,
  newMessage: boolean,
  highlighted: boolean,
  polyline: L.Polyline
}

@Component({
  selector: 'NgxLoopVehicleLiveMap',
  templateUrl: './ngx-loop-vehicle-livemap.html',
  styleUrls: ['./ngx-loop-vehicle-livemap.css' ]
})
export class NgxLoopVehicleLiveMap implements OnInit, OnDestroy, AfterViewInit {

  vehiclesMap: VehicleMap[] = [];

  allVehiclesVisible: boolean = true;

  customPostVehiclesMapBody: any = null;

  trackingVehicle: VehicleMap = null;

  mapOptions: MapOptions = {};
  mapLayersOptions: any = {};

  mqttController : MqttController;

  @Input()
  maxRouteSize : number = 50;

  @Input()
  iconsUrl = "https://cdn.readymix.io/img/icons/";

  @Input()
  draw: boolean = false;

  @Output()
  onNewDrawing: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onEditedDrawing: EventEmitter<ResourceMap<any>> = new EventEmitter<any>();

  @Output()
  onEditedDeleted: EventEmitter<ResourceMap<any>> = new EventEmitter<any>();

  @Output()
  onMapClicked: EventEmitter<{lat:number,lng:number}> = new EventEmitter<{lat:number,lng:number}>();

  @Output()
  onMapInitialized: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onVehiclesDownloaded: EventEmitter<VehicleMap[]> = new EventEmitter<VehicleMap[]>();

  @Output()
  onVehicleDataChanged: EventEmitter<{data:VehicleMap,index:number}> = new EventEmitter<{data: VehicleMap, index: number}>();

  @Output()
  onVehicleMarkerClicked: EventEmitter<VehicleMap> = new EventEmitter<VehicleMap>();

  @Output()
  onResourceClicked: EventEmitter<ResourceMap<any>> = new EventEmitter<ResourceMap<any>>();

  @Output()
  onPolygonEditionChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public drawControl: L.Control.Draw;

  firstDownload: boolean = true;
  controlEnabled: boolean = false;

  isDrawing : boolean = false;
  deleting: boolean = false;
  ready: boolean = false;

  editingDrawResource: ResourceMap<any> = null;
  deletingResource: ResourceMap<any> = null;

  editingLayerName: string = null;

  public map : L.Map;
  private vehiclesLayer: L.FeatureGroup;
  private vehiclesClusterLayer: L.MarkerClusterGroup;

  private polylineLayer: L.FeatureGroup;

  private customLayers : {
    [key:string] : L.FeatureGroup | L.MarkerClusterGroup ;
  };

  private heatMapLayers: {
    [key:string] : any ;
  };

  private selectedPolygonLayer: L.FeatureGroup;

  constructor(
    public liveMapService: LiveMapService,
    public translate: TranslateService,
    public customService: FlespiService,
    private cd: ChangeDetectorRef,
  ){
    this.customLayers = {};
    this.heatMapLayers = {};
  }

  ngOnInit(): void {
    this.setupMap();
  }
  isReady():boolean {
    return this.ready;
  }

  cleanAndRebuild() {
    this.mqttController.disconnect();
    this.vehiclesLayer.clearLayers();
    this.vehiclesClusterLayer.clearLayers();
    this.initMQTT();
    this.resetEditing();
    this.firstDownload = true;
    this.downloadVehicles();
  }

  ngAfterViewInit(): void {
    if(this.draw)
      this.setDrawingMode();
    this.initMQTT();
  }

  getVehicleClusterLayer() : L.MarkerClusterGroup{
    return this.vehiclesClusterLayer;
  }

  enableControl() {
    if(!this.controlEnabled)
      this.map.addControl(this.drawControl);
    this.controlEnabled = true;
  }

  disableControl() {
    if(this.controlEnabled)
      this.map.removeControl(this.drawControl);
    this.controlEnabled = false;
  }

  ngOnDestroy(): void {
    this.mqttController.disconnect();
  }

  setCustomVehiclePostBody(postBody){
    this.customPostVehiclesMapBody = postBody;
  }

  isEditingResource(geofenceMap:ResourceMap<any>) : boolean {
    return this.editingDrawResource && this.editingDrawResource.resource.id == geofenceMap.resource.id
  }

  createClusterLayer(layerName:string) {
    let cluster = L.markerClusterGroup();
    cluster.addTo(this.map);
    this.customLayers[layerName] = cluster;
  }

  createHeatLayer(layerName:string,options) {
    let heat = (L as any).heatLayer([],options);
    this.heatMapLayers[layerName] = heat;
  }

  hideHeatLayer(layerName) {
    let layer = this.heatMapLayers[layerName] as TileLayer;
    if(layer){
      layer.removeFrom(this.map);
    }
  }

  showHeatLayer(layerName) {
    let layer = this.heatMapLayers[layerName] as TileLayer;
    if(layer){
      layer.addTo(this.map);
    }
  }

  getHeatLayer(layerName) {
    return this.heatMapLayers[layerName];
  }

  addMarkersToCluster(layerName:string,resources:ResourceMap<any>[] | VehicleMap[]){
    let layer = this.getLayer(layerName) as L.MarkerClusterGroup;
    resources.forEach(r => {
      if(r.marker){
        layer.addLayer(r.marker);
      }
    });
  }

  createLayer(layerName:string) {
    let layer = new L.FeatureGroup();
    layer.addTo(this.map);
    this.customLayers[layerName] = layer;
  }

  addPolygonResourceToLayer(layerName:string,resource:ResourceMap<any>){
    let layer = this.getLayer(layerName);
    if(layer){
      layer.addLayer(resource.polygon)
    }
  }

  hideLayer(layerName:string){
    let layer = this.customLayers[layerName];
    if(layer){
      layer.removeFrom(this.map);
    }
  }

  removeLayer(layerName:string) {
    this.hideLayer(layerName);
    this.customLayers[layerName] = null;
  }

  showLayer(layerName:string) {
    let layer = this.customLayers[layerName];
    if(layer){
      layer.addTo(this.map);
    }
  }

  getLayer(layerName:string) {
    return this.customLayers[layerName];
  }

  initMQTT() {
    this.mqttController = new MqttController(this.customService);
    this.mqttController.onConnected = this.onMQTTConnected;
    this.mqttController.onMessageArrived = this.onMessageArrived;

    this.mqttController.downloadFlespiTokenAndConnect();
  }

  onMQTTConnected = () => {
    console.log("Subscribing");
    this.mqttController.subscribeTo(FlespiConstants.BASE_DEVICES_TOPIC+'+');
  };

  isTrackingVehicle(vehicleMap:VehicleMap){
    if(this.trackingVehicle == null)
      return false;

    return this.trackingVehicle.vehicle.id == vehicleMap.vehicle.id;
  }

  setOpacityToLayer(layerName:string,opacity:number, fillOpacity:number){
    let layer = this.getLayer(layerName);
    if(layer){
      layer.setStyle({opacity:opacity,fillOpacity:fillOpacity});
    }
  }

  setOpacityToVehicleMarkers(opacity:number) {
    this.vehiclesMap.forEach(vehicleMap => {
      if(!this.isTrackingVehicle(vehicleMap)){
        if(vehicleMap.marker){
          vehicleMap.marker.setOpacity(opacity);
          vehicleMap.marker.setZIndexOffset(0);

        }
      }else if(vehicleMap.marker) {
        vehicleMap.marker.setOpacity(1);
        vehicleMap.marker.setZIndexOffset(1000);
      }
    });
  }

  resetTrackingVehicle() {
    this.trackingVehicle = null;
    this.polylineLayer.clearLayers();
    this.setOpacityToVehicleMarkers(1);
  }

  setDrawingMode(){

    this.selectedPolygonLayer = new L.FeatureGroup();
    this.map.addLayer(this.selectedPolygonLayer);

    let drawFullOptions: L.Control.DrawConstructorOptions = {
      draw: {
        polygon: {
          allowIntersection: false,
          drawError: {
            color: '#ff0000',
            message: this.translate.data['app.poly_edge_error']
          },
          shapeOptions: {
            color: "#dca436",
            fillColor: "#dca436",
          }
        },
        polyline: false,
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false
      },
      edit: {
        featureGroup: this.selectedPolygonLayer,
      }
    };

    this.drawControl = new L.Control.Draw(drawFullOptions);

    this.map.on('draw:drawstart',() => {
      this.isDrawing = true;
    });
    this.map.on('draw:drawstop',() => {
      this.isDrawing = false;
    });
    this.map.on('draw:deletestart',() => {
      this.deleting = true;
    });
    this.map.on('draw:deletestop',() => {
      this.deleting = false;
    });
    this.map.on('draw:editstart',() => {
      this.isDrawing = true;
      this.onPolygonEditionChanged.emit(true);
    });
    this.map.on('draw:editstop',(event) => {
      this.isDrawing = false;
      this.onPolygonEditionChanged.emit(false);
      this.resetEditing();
    });
    this.map.on('draw:created', (e) => {

      let layer = e.layer;

      if (layer.getLatLngs().length > 0) {

        let polygonArray = layer.getLatLngs()[0];
        polygonArray.push(layer.getLatLngs()[0][0]);

        this.onNewDrawing.emit(this.formatToCoordinates(polygonArray));
        this.resetEditing();
      }
      this.cd.detectChanges();
    });

    this.map.on('draw:edited', (e) => {
      console.log(e);
      let layers = e['layers'].getLayers();

      if (layers.length > 0) {

        let layer = layers[0];

        if(layer.getLatLngs().length > 0){

          let polygonArray = layer.getLatLngs()[0];
          polygonArray.push(layer.getLatLngs()[0][0]);

          this.editingDrawResource.resource.coordinates = this.formatToCoordinates(polygonArray);
          this.onEditedDrawing.emit(this.editingDrawResource);
          this.resetEditing();
        }
      }
      this.editingDrawResource = null;
      this.cd.detectChanges();
    });

    this.map.on('draw:deleted', (e) => {
      this.onEditedDeleted.emit(this.deletingResource);
    });

    this.map.on('click',(e:LeafletMouseEvent)=>{
      console.log("CLICKED MAP")
      console.log(e);
      let latLng = e.latlng;
      this.onMapClicked.emit({lat:latLng.lat,lng:latLng.lng});
    });

    this.enableControl();
  }

  formatToCoordinates(latLngs) {
    return latLngs.map((latLng) => {
      return [latLng['lng'], latLng['lat']];
    });
  }

  trackVehicle(vehicleMap:VehicleMap){

    if(this.isTrackingVehicle(vehicleMap)){
      this.resetTrackingVehicle();
    }else{
      if(vehicleMap.marker){

        this.polylineLayer.clearLayers();
        vehicleMap.polyline.addTo(this.polylineLayer);

        this.trackingVehicle = vehicleMap;
        this.setOpacityToVehicleMarkers(0.5);
        this.map.flyTo(vehicleMap.marker.getLatLng(),18,{animate:true});
      }
    }
  }

  getVehiclesVisibility() : boolean {
    return this.allVehiclesVisible;
  }

  hideVehicleMarker(vehicleMap:VehicleMap) {
    let marker = vehicleMap.marker;

    if(!marker)
      return;

    this.vehiclesLayer.removeLayer(marker);
    this.vehiclesClusterLayer.removeLayer(marker);
    this.vehiclesClusterLayer.refreshClusters();

    if(this.isTrackingVehicle(vehicleMap)){
      this.resetTrackingVehicle();
    }

    vehicleMap.visible = false;
  }

  showVehicleMarker(vehicleMap:VehicleMap){
    let marker = vehicleMap.marker;

    if(!marker)
      return;

    this.vehiclesLayer.addLayer(marker);
    this.vehiclesClusterLayer.addLayer(marker);
    this.vehiclesClusterLayer.refreshClusters();
    vehicleMap.visible = true;
  }

  toggleVehicleMapMarker(vehicleMap:VehicleMap){
    if(vehicleMap.visible){
      this.hideVehicleMarker(vehicleMap);
    }else if(!vehicleMap.visible){
      this.showVehicleMarker(vehicleMap);
    }
  }

  hideVehicleMarkers(){
    this.vehiclesLayer.removeFrom(this.map);
  }

  showVehicleMarkers() {
    this.vehiclesLayer.addTo(this.map);
  }

  showVehicleClusters() {
    this.vehiclesClusterLayer.addTo(this.map);
  }

  hideVehicleClusters() {
    this.vehiclesClusterLayer.removeFrom(this.map);
  }

  toggleAllVehiclesVisibility(clustering:boolean) {
    if(this.allVehiclesVisible){
      if(this.trackingVehicle != null)
        this.resetTrackingVehicle();
      if(!clustering)
        this.vehiclesLayer.removeFrom(this.map);
      else
        this.vehiclesClusterLayer.removeFrom(this.map);
    }else{
      if(!clustering){
        this.vehiclesLayer.addTo(this.map);
      }else
        this.vehiclesClusterLayer.addTo(this.map);
    }
    this.allVehiclesVisible = !this.allVehiclesVisible;
  }

  onMessageArrived = (message:any) => {
    let index = this.vehiclesMap.findIndex(vm => vm.vehicle.id == message['rmt.id']);

    if(index >= 0){

      let vehicleMap : VehicleMap = this.vehiclesMap[index];

      vehicleMap.vehicle.last_position = message;
      vehicleMap.newMessage = true;

      setTimeout(()=>{
        vehicleMap.newMessage = false;
      },2000);

      let marker : L.Marker = vehicleMap.marker;

      if(marker != null){
        marker.setLatLng([message['position.latitude'], message['position.longitude']]);

        vehicleMap.polyline.addLatLng(marker.getLatLng());

        if(vehicleMap.polyline.getLatLngs().length > this.maxRouteSize){
            vehicleMap.polyline.getLatLngs().pop();
        }

        this.vehiclesClusterLayer.refreshClusters();

        if(this.isTrackingVehicle(vehicleMap)){
          this.map.flyTo(marker.getLatLng())
        }
      }else{
        let marker = this.buildMarker(vehicleMap);
        marker.addTo(this.vehiclesLayer);
        vehicleMap.marker = marker;
      }

      this.onVehicleDataChanged.emit({data:vehicleMap,index:index});
    }
  };

  findVehicleByMessage(message:any){
    let index = this.vehiclesMap.findIndex(vm => vm.vehicle.id == message['rmt.id']);
    if(index > 0)
      return this.vehiclesMap[index];
    return null;
  }

  getIconsUrl() {
    return this.iconsUrl;
  }

  onMapReady = (map) => {

    this.map = map;
    this.ready = true;

    console.log("MAP READY",this.onMapInitialized);

    this.onMapInitialized.emit();

    this.polylineLayer.addTo(map);
    this.vehiclesClusterLayer.addTo(this.map);

    this.downloadVehicles();
  };

  private returnSelectedToLayer() {

    let layers = this.selectedPolygonLayer.getLayers();

    if(layers.length > 0 && this.editingLayerName != null){

      let editedResource = layers[0];
      this.selectedPolygonLayer.removeLayer(editedResource);
      let editedLayer = this.getLayer(this.editingLayerName);
      editedLayer.addLayer(layers[0]);

    }
  }

  getPolylineOptions(color:string) : any {
    return {
      color: color,
      weight: 3,
      opacity: 0.8,
      smoothFactor: 1
    }
  }

  downloadVehicles() {
    this.vehiclesMap = [];
    this.liveMapService.getVehicleWithMessages(this.customPostVehiclesMapBody).then((vehiclesWithPosition : any[]) => {
      this.buildVehiclesMarkers(vehiclesWithPosition);
    });
  }

  buildResourceMarker(iconPath:string,lat:number,lng:number,className:string,size:PointExpression,toolTipName?:string) : L.Marker {
    let markerIcon = L.icon({
      iconUrl: iconPath,
      iconSize: size,
      className: className
    });
    return L.marker([lat,lng],{riseOnHover: true, icon: markerIcon});
  }

  getVehicleMarkerIcon(iconName) : L.Icon {
    return L.icon({
      iconUrl: this.iconsUrl+iconName,
      iconSize: [25, 25],
      className: 'truck-marker'
    });
  }





  buildMarker(vehicleWithPosition) : L.Marker {

    if(vehicleWithPosition.last_position == null)
      return null;

    let markerIcon = this.getVehicleMarkerIcon(vehicleWithPosition.icon);

    let lastPosition = vehicleWithPosition.last_position;
    return L.marker([lastPosition['position.latitude'], lastPosition['position.longitude']], {riseOnHover: true, icon: markerIcon});
  }


  buildVehiclesMarkers(vehiclesWithPosition) {

    let vehiclesMap = [];
    this.vehiclesClusterLayer.clearLayers();

    vehiclesWithPosition.forEach(vp => {

      let marker = this.buildMarker(vp);

      let vehicleMap : VehicleMap = {
        vehicle: vp,
        marker: marker,
        visible: false,
        driverName:null,
        newMessage: false,
        highlighted: false,
        polyline: new L.Polyline([],this.getPolylineOptions('#3496E6'))
      };

      vehiclesMap.push(vehicleMap);

      if(this.trackingVehicle != null){
        if(this.trackingVehicle.vehicle.id == vp.id){
          this.trackingVehicle = vehicleMap;
        }
      }

      if(marker != null) {

       marker.addTo(this.vehiclesLayer);
       this.vehiclesClusterLayer.addLayer(marker);

       vehicleMap.polyline.addLatLng(marker.getLatLng());
       vehicleMap.visible = true;

        marker.on('click', () => {
          this.onVehicleMarkerClicked.emit(vehicleMap);
        });
      }
    });

    this.vehiclesMap = vehiclesMap;
    this.onVehiclesDownloaded.emit(this.vehiclesMap);

    if(this.firstDownload){
      this.map.fitBounds(this.vehiclesLayer.getBounds());
      this.firstDownload = false;
    }
  }

  unbindToolTip(mapping:ResourceMap<any> | VehicleMap ){
    if(mapping.marker){
      mapping.marker.unbindTooltip();
    }
  }

  bindToolTip(textContent:string,marker:L.Marker,className:string){
    if(marker){
      marker.bindTooltip(textContent, {
        direction: "bottom",
        permanent: true,
        opacity: 1,
        offset: [0,10],
        className: className
      });
    }
  }

  toggleResourceVisibility(layerName:string,resource:ResourceMap<any>){
    let layer = this.getLayer(layerName);

    if(layer){
      if(resource.visible){
        if(this.selectedPolygonLayer && resource.polygon){
          this.selectedPolygonLayer.removeLayer(resource.polygon);
        }
        if(resource.polygon)
          layer.removeLayer(resource.polygon);
        if(resource.marker)
          layer.removeLayer(resource.marker);
      }else{
        if(resource.polygon)
          layer.addLayer(resource.polygon);
        if(resource.marker)
          layer.addLayer(resource.marker);
      }
      resource.visible = !resource.visible;
    }
  }

  toggleAllResourceVisibility(layerName: string, visible: boolean) {
    let layer = this.getLayer(layerName);

    if (layer) {
      if (visible) {
        layer.removeFrom(this.map);
      } else {
        layer.addTo(this.map);
      }
    }
  }

  cleanLayer(layerName:string){
    let layer = this.getLayer(layerName);
    if(layer){
      layer.clearLayers();
    }
  }

  resetEditing() {
    this.returnSelectedToLayer();
    this.editingLayerName = null;
    this.deletingResource = null;
    this.editingDrawResource = null;
    this.isDrawing = false;
    this.deleting = false;
  }

  setupMap() {
    this.mapOptions = {
      layers: [
        L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
          { maxZoom: 20, attribution: '...', subdomains: ['mt0', 'mt1', 'mt2', 'mt3'] })
      ],
      center: L.latLng(0, 0),
      zoom: 2,
      preferCanvas: true
    };

    this.mapLayersOptions['baseLayers'] = {};
    this.mapLayersOptions['overlays'] = {};
    this.mapLayersOptions['baseLayers'][this.translate.data['app.street']] = this.mapOptions.layers[0];
    this.mapLayersOptions['baseLayers'][this.translate.data['app.hybrid']] = L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', { maxZoom: 20, attribution: '...', subdomains: ['mt0', 'mt1', 'mt2', 'mt3'] });
    this.mapLayersOptions['baseLayers'][this.translate.data['app.satellite']] = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', { maxZoom: 20, attribution: '...', subdomains: ['mt0', 'mt1', 'mt2', 'mt3'] });
    this.mapLayersOptions['overlays'][this.translate.data['app.traffic']] = L.tileLayer('https://{s}.google.com/vt/lyrs=h@159000000,traffic|seconds_into_week:-1&style=3&x={x}&y={y}&z={z}', { maxZoom: 20, attribution: '...', subdomains: ['mt0', 'mt1', 'mt2', 'mt3'] });

    this.vehiclesLayer = new L.FeatureGroup();
    this.polylineLayer = new L.FeatureGroup();

    this.vehiclesClusterLayer = L.markerClusterGroup({
      iconCreateFunction: function(cluster:L.MarkerCluster) {
        let iconMap = {};
        cluster.getAllChildMarkers().forEach(marker => {
          let iconUrl = (marker.getIcon() as L.Icon ).options.iconUrl;
          if(iconMap[iconUrl] == null){
            iconMap[iconUrl] = 0;
          }
          iconMap[iconUrl] += 1;
        });

        let iconUrl = Object.keys(iconMap).reduce((a, b) => iconMap[a] > iconMap[b] ? a : b);
        return new L.DivIcon({
          html: '<div class="cluster-class"><img class="truck-marker" src="'+iconUrl+'"/><p class="cluster-count">'+cluster.getChildCount()+'</p></div>',
          iconSize: new L.Point(35, 35),
          className: 'cluster-map-container'
        });
      }
    });

  }

  drawMarkers(layerName:string,objectsArray:ResourceMap<any>[]){

    let layer = this.getLayer(layerName);

    if(!layer)
      return;

    objectsArray.forEach(object => {
      let marker = object.marker;
      if(marker){
        marker.on('click',() => {
            this.onResourceClicked.emit(object);
        });
        marker.addTo(layer);
      }
    });

  }

  drawPolygons(layerName:string,objectsArray:ResourceMap<any>[], coordsField:string, color:string){
    objectsArray.forEach(object => {

      let coords = object.resource[coordsField];

      if(coords){

        let polygonColor = color;
        if(object.customColor != null)
          polygonColor = object.customColor;

        let polygon = this.drawPolygon(layerName,coords,polygonColor);

        if(polygon){

          object.polygon = polygon;

          polygon.on('click',() => {

            if(!this.isDrawing){

              this.onResourceClicked.emit(object);

              if(object.editable){
                if(this.deleting){
                  this.deletingResource = object;
                }else{
                  this.onSelectedPolygonResource(layerName,object);
                }
              }
            }
          });
        }
      }

    });
  }

  onSelectedPolygonResource(layerName:string, resource:ResourceMap<any>){

    if(!resource.editable)
      return;

    let layer = this.getLayer(layerName);

    if(layer){

      if(this.editingDrawResource){
        this.returnSelectedToLayer();
      }

      this.editingLayerName = layerName;
      this.editingDrawResource = resource;
      layer.removeLayer(resource.polygon);
      this.selectedPolygonLayer.addLayer(resource.polygon);
    }
  }

  locateResource(layerName:string,resource:ResourceMap<any>,zoom?:number){
    if(resource.polygon){
      this.map.flyToBounds(resource.polygon.getBounds());
      this.onSelectedPolygonResource(layerName,resource);
    }else if(resource.marker){
      this.map.flyTo(resource.marker.getLatLng(),zoom);
    }
  }

  drawPolygon(layerName:string, coords, color:string) {

    let latLng : L.LatLng[] = this.getLatLngsFromCoords(coords);
    let layer = this.getLayer(layerName);

    if(layer){
      if(latLng.length > 0){
        let polygon = new L.Polygon([latLng],{
          color: color,
          fillColor: color,
          weight: 2
        });

        layer.addLayer(polygon);
        return polygon;
      }
    }
    return null;
  }

  private getLatLngsFromCoords(coords) : L.LatLng[] {
    let latLngs : L.LatLng[] = [];
    if(coords && coords.length > 0){
      coords.forEach(coord =>{
        latLngs.push(L.latLng(coord[1],coord[0]));
      });
    }
    return latLngs;
  }
}
