<mat-expansion-panel #parentPanel>
  <mat-expansion-panel-header class="expansion-panel">
    <app-ngx-medium-toggle
      [checked]="allIncidentsVisible"
      [title]="'label.incidents' | translate"
      (onValueChange)="toggleAllIncidentsVisible()"
    >
    </app-ngx-medium-toggle>
  </mat-expansion-panel-header>
  <div class="vehicle-list-header">
    <div class="mx-2 my-1 aux-button px-2 py-1 d-flex flex-column" >
      <div>
        <app-ngx-small-toggle
          [checked]="heatmap"
          (onValueChange)="toggleHeatMap($event)"
          [title]="'label.heatmap' | translate"
        >
        </app-ngx-small-toggle>
      </div>
    </div>
    <div class="d-flex flex-row align-items-center mt-1">
      <i class="ml-2 mdi mdi-filter"></i>
      <p class="mb-0 ml-1" style="color: var(--foreground-default);font-weight: 500;font-size: 0.8em">{{'label.filters' | translate }}:</p>
    </div>
    <form [formGroup]="form" class="mt-2 my-1 aux-button px-2 py-1 mb-3 mx-2" style="background: var(--background-card)">
      <p class="form-label mt-1">{{'nav.period' | translate}}</p>
      <div class="d-flex flex-row align-items-center">
        <div (click)="picker.open()" style="width: 250px" class="d-inline-flex flex-row align-items-center">
          <input
            class="border-0 w-100 rounded"
            matInput
            style="width: 210px !important;font-size: 0.85em;margin-top: 2px"
            placeholder="Choose a date"
            formControlName="period"
            [satDatepicker]="picker"
          />
          <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
          <i class="mdi mdi-calendar-range-outline cursor-pointer" style="opacity: 0.6;"></i>
        </div>
        <mat-progress-spinner color="primary" *ngIf="loading" class="ml-auto" mode="indeterminate" [diameter]="13" ></mat-progress-spinner>
        <button *ngIf="!loading" (click)="onButtonClick()" class="ml-auto" mat-button color="primary" style="height: 33px;line-height: 33px;font-size: 11px" >{{'app.search' | translate}}</button>
      </div>
    </form>
  </div>
</mat-expansion-panel>
