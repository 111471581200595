<div class="w-100 h-100 position-relative">
  <div
    style="width: 100%;height: 100%"
    leaflet
    [leafletOptions]="mapOptions"
    [leafletLayersControl]="mapLayersOptions"
    (leafletMapReady)="onMapReady($event)">
  </div>
</div>



