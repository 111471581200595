<form [formGroup]="form">
  <div class="d-flex form-row mt-3 align-items-end">
    <div class="col-6 pr-3">
      <app-resource-selector
        #categorySelector
        label="{{'label.category' | translate}}"
        [control]="form.get('rule_category')"
        customInputClass="d-flex flex-row align-items-center w-100 pt-0"
        [displayField]="'description'"
        [allowCreation]="false"
        [setFirstIfOnly]="false"
        [canRemoveSelected]="false"
        [resources]="vehicleCategory"
        [position]="'bottom'"
        [maxHeight]="100"
      ></app-resource-selector>
    </div>

    <div class="col-5 mt-2 pl-3">
      <div style="color: var(--foreground-default);" loopFormField>
        <span>{{'label.category_max_speed' | translate}} *</span>
        <input type="text" formControlName="rule_speed" loopInput required />
      </div>
    </div>

    <i class="col-1 mdi mdi-close text-danger cursor-pointer" (click)="removeItem()"></i>
  </div>
</form>