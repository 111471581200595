import {BaseModel} from './base.model';
import {Company} from './company.model';

export class VehicleCategory extends BaseModel {

    constructor(
        public company?: number | Company,
        public erpkey?: string,
        public description?: string
    ) {
        super();
    }

    static fromJson(json: any): VehicleCategory {
        let vehicleCategory: VehicleCategory = Object.assign(new VehicleCategory(), json);

        return vehicleCategory;
    }

}
