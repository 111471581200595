<mat-expansion-panel #panel class="sub-expansion-panel">
  <mat-expansion-panel-header class="sub-expansion-panel-header" [ngStyle]="{opacity:liveMap.getVehiclesVisibility()?1:0.6}">
    <mat-checkbox [indeterminate]="group.indeterminateChecked" [checked]="group.allVisible" (change)="onSectionCheckboxChanged($event)" color="primary" (click)="$event.stopPropagation()"></mat-checkbox>
    <span style="margin-top: 1px">{{ group.keylangTitle | translate}}</span>
  </mat-expansion-panel-header>
  <lib-ngx-loop-vehicle-table
    [group]="group"
    [liveMap]="liveMap"
    [showTrackItButton]="showTrackIt"
    (onTrackItPressed)="onTrackItPressed.emit($event)"
    (onVehicleEditButtonClicked)="onVehicleEditButtonClicked.emit($event)"
    [showVehicleEditionButton]="showVehicleEditionButton"
    [dataSource]="group.dataSource"
  >
  </lib-ngx-loop-vehicle-table>
</mat-expansion-panel>
