import { BaseModel } from './base.model';



export class ReportModel extends BaseModel {

    constructor(
        public company?: number,
        public name?: string,
        public cod_planta?: string,
        public type?: any,
        public description?: string,
        public id_device?: string
    ) {
        super();
    }


    static fromJson(json: any): ReportModel {
        return Object.assign(new ReportModel(), json);
    }

}
