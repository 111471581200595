import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';
import {BaseModel} from '../models/base.model';
import {environment} from '../../environments/environment';
import {AbstractControl, FormGroup} from '@angular/forms';
import {UserModel} from '../models/user.model';


@Injectable({
  providedIn: 'root'
})
export class CheckFormService extends BaseService<any>{

  constructor(protected injector: Injector) {
    super(injector, 'core/check', (json:any)=>json);
  }


  async checkEmail(form: FormGroup, checkin: object, user: UserModel) {
    let inputedEmail = form.controls['email'].value;

    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    let validEmail = regexp.test(inputedEmail);

    let check = true;
    if (user && inputedEmail == user.email)
      check = false;

    if (!validEmail){
      form.controls['email'].setErrors({invalidEmail: true});
    }
    else if (inputedEmail && check){
      checkin['email'] = true;
      let isUnique = await this.isUnique('email', inputedEmail);

      if (!isUnique)
        form.controls['email'].setErrors({notUnique: true});
      else
        form.controls['email'].setErrors(null);

      checkin['email'] = false;
    }
  }


  async checkUnique(form: FormGroup, checkin: object, model: any, field: string, db_column: string = null){

    let inputedValue = form.controls[field].value;

    let check = true;
    if (model && inputedValue == model[field])
      check = false;

    if (inputedValue && check){
      checkin[field] = true;

      let whatToCheck = field;
      if (db_column)                  // usando db_column para saber qual tabela pesquisar no banco, pois o nome da chave pode ser o mesmo. Exemplo: 'erpkey'. ERPKEY de qual tabela?
        whatToCheck = db_column;
      let isUnique = await this.isUnique(whatToCheck, inputedValue);

      if (!isUnique)
        form.controls[field].setErrors({notUnique: true});
      else
        form.controls[field].setErrors(null);

      checkin[field] = false;
    }
  }


  hasError(form: FormGroup, field: string, error: string): any{
    return form.controls[field].getError(error)
  }


  async isUnique(checkField: string, value: any) : Promise<any> {

    let path = this.apiPath + '/unique';
    let headers = await this.buildHeader();

    let body = {
      'check_field': checkField,
      'value': value
    };

    return this.http.post(path, body, {headers: headers}).toPromise()
      .then((res: {data: boolean}) => {
        return res.data;
      })
      .catch((error) => {
        return true
      });
  }

}
