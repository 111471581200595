import {BaseModel} from './base.model';
import {Company} from './company.model';


export class UserModel extends BaseModel {

  constructor(
    public name?:string,
    public email?:string,
    public username?:string,
    public password?:string,
    public lang?:string,
    public firebaseUid?:string,
    public role?:{
      role:string,
      id:number,
      keylang:string
    },
    public permissions?:any[],
    public configurations?: {},
    public last_login?:any,
    public company?: any,
    public companies?: any[],
    public tz_offset?:number,
    public dealer_companies?:Company[],

    public all_plants?: boolean,
    public all_vehicles?: boolean,
    public all_centers?: boolean,
    public all_persons?: boolean,
    public all_reports?: boolean,

  ){
    super();
  }

  static fromJsonLogin(jsonData:any) : UserModel {

    let user = new UserModel();

    user.id = jsonData.id;
    user.name = jsonData.nome;
    user.email = jsonData.email;
    user.username = jsonData.user;
    user.permissions = jsonData.permissions;
    user.configurations = jsonData.configurations;
    user.lang = jsonData.lingua;
    user.role = jsonData.role;
    user.firebaseUid = jsonData.firebase_uid;
    user.all_centers = jsonData.all_centers;
    user.all_vehicles = jsonData.all_vehicles;
    user.all_plants = jsonData.all_plants;
    user.all_reports = jsonData.all_reports;
    user.all_persons = jsonData.all_persons;
    user.last_login = jsonData.last_login;


    console.log("company == >> ",jsonData.company, " --- ", user.company)


    return user;

  }

  static fromJson(jsonData:any) : UserModel {

    let user = new UserModel();

    user.id = jsonData.id;
    user.name = jsonData.nome;
    user.email = jsonData.email;
    user.username = jsonData.username;
    user.lang = jsonData.lingua;
    user.role = jsonData.role;
    user.firebaseUid = jsonData.firebase_uid;
    user.company = jsonData.company;
    user.permissions = jsonData.permissions;
    user.configurations = jsonData.configurations;
    user.companies = jsonData.companies;
    user.dealer_companies = jsonData.dealer_companies;
    user.all_centers = jsonData.all_centers;
    user.all_vehicles = jsonData.all_vehicles;
    user.all_plants = jsonData.all_plants;
    user.all_reports = jsonData.all_reports;
    user.all_persons = jsonData.all_persons;
    user.last_login = jsonData.last_login;

    return user;
  }

}
