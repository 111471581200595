import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NgxLoopVehicleLiveMap, VehicleMap} from '../ngx-loop-vehicle-livemap/ngx-loop-vehicle-livemap';
import {PreferencesService} from '../preferences.service';
import moment from 'moment';
import {
  MatButtonToggleChange,
  MatCheckboxChange,
  MatExpansionPanel,
  MatSlideToggleChange,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import {ConfigurationService} from '../depends/configuration.service';
import {NgxTrackItPanel, TrackItPointExtras} from '../ngx-trackit-panel/ngx-track-it-panel.component';

export interface TrackItPeriodChangeEvent {
  begin:string,
  end:string,
  idVehicle:number
}

export class VehicleMapGroup {

  dataSource: MatTableDataSource<VehicleMap> = new MatTableDataSource();
  indeterminateChecked: boolean = false;
  allVisible: boolean = true;
  panel: MatExpansionPanel;

  constructor(
    public vehicles:VehicleMap[],
    public keylangTitle: string,
    public idType:number
  ){
    this.dataSource.data = this.vehicles;
  }

  setPanel(panel: MatExpansionPanel) {
    this.panel = panel;
  }

  isAllVisible() : boolean {
    for(let i = 0; i < this.vehicles.length; i++){
      if(!this.vehicles[i].visible)
        return false;
    }
    return true;
  }

  setCheckState() {
    let total = 0;
    let total_checked = 0;
    let total_unchecked = 0;

    this.vehicles.forEach(v => {
      if(v.marker != null){
        total += 1;
        total_checked += v.visible || v.marker == null ? 1 : 0;
        total_unchecked += !v.visible && v.marker ? 1 : 0;
      }
    });

    if(total_checked == total){
      this.indeterminateChecked = false;
      this.allVisible = true;
    }
    else if(total_unchecked == total){
      this.indeterminateChecked = false;
      this.allVisible = false;
    }else{
      this.allVisible = false;
      this.indeterminateChecked = true;
    }

    console.log(this.indeterminateChecked);
  }
}


@Component({
  selector: 'NgxLoopMapVehiclePanel',
  templateUrl: './ngx-loop-map-vehicle-panel.html',
  styleUrls: ['./ngx-loop-map-vehicle-panel.css' ]
})
export class NgxLoopMapVehiclePanel implements OnInit, AfterViewInit {

  @ViewChild('masterPanel',{static:false})
  masterPanel: MatExpansionPanel;

  // Is not a ViewChild because of NGIF, so we have a ref method that gets its reference
  // On component view init.

  trackItPanel: NgxTrackItPanel;

  vehicles: VehicleMap[] = [];

  groupsByType: VehicleMapGroup[] = [];

  @Input()
  liveMap: NgxLoopVehicleLiveMap;

  @Input()
  showVehicleEditionButton: boolean = false;

  @Input()
  enableTrackTt: boolean = false;

  @Input()
  trackItAdditionalFields: {title:string,field:string}[] = [];

  @Output()
  onVehicleEditButtonClicked: EventEmitter<VehicleMap> = new EventEmitter<VehicleMap>();

  @Output()
  onVehiclesMapDoneBuilding: EventEmitter<VehicleMap[]> = new EventEmitter<VehicleMap[]>();

  @Output()
  onTrackItPeriodChanged: EventEmitter<TrackItPeriodChangeEvent> = new EventEmitter<TrackItPeriodChangeEvent>();

  trackItVehicle: VehicleMap = null;

  clustered: boolean = true;
  showNames: boolean = false;

  constructor(
    private configurationService:ConfigurationService
  ){
      this.showNames = this.configurationService.getConfigValue('general','live_map_show_vehicle_name');
      this.clustered = this.configurationService.getConfigValue('general','live_map_vehicle_clustered');
  }

  ngOnInit(): void {}

  setTrackitPointExtras(extras:TrackItPointExtras[]) {
    if(this.trackItPanel){
      this.trackItPanel.setPointExtras(extras);
    }
  }

  onVehicleDataChanged(vehicleId:number,name:string,iconName:string,typeId:number,typeKeylang:string) {

    let groupIndex = -1;
    let index = - 1;
    let originalResource : VehicleMap;
    let newGroupIndex = -1;

    for(let i = 0; i < this.groupsByType.length; i++){
      if(typeId == this.groupsByType[i].idType){
        newGroupIndex = i;
        console.log("New group index",newGroupIndex);
      }
      for(let j = 0; j < this.groupsByType[i].vehicles.length;j++){
        if(vehicleId == this.groupsByType[i].vehicles[j].vehicle.id){
          groupIndex = i;
          index = j;
          originalResource = this.groupsByType[i].vehicles[j];
          console.log("Group Index",groupIndex);
        }
      }
    }

    originalResource.vehicle.name = name;
    originalResource.vehicle.icon = iconName;

    let marker = originalResource.marker;

    if(marker){
      marker.setIcon(this.liveMap.getVehicleMarkerIcon(iconName));
      let tooltip = marker.getTooltip();
      if(tooltip != null){
        marker.unbindTooltip();
        this.liveMap.bindToolTip(name,marker,'vehicle-tooltip')
      }
    }

    if(newGroupIndex == -1){
      this.groupsByType.push(new VehicleMapGroup([originalResource],typeKeylang,typeId));
      this.groupsByType[groupIndex].vehicles.splice(index,1);
      this.groupsByType[groupIndex].dataSource._updateChangeSubscription();
      console.log("Creating new group");
    }
    else if(groupIndex != newGroupIndex){

      this.groupsByType[newGroupIndex].vehicles.push(originalResource);
      // this.groupsByType[newGroupIndex].dataSource.data.push(originalResource);

      this.groupsByType[groupIndex].vehicles.splice(index,1);
      this.groupsByType[groupIndex].dataSource.data.splice(index,1);

      this.groupsByType[newGroupIndex].dataSource._updateChangeSubscription();
      this.groupsByType[groupIndex].dataSource._updateChangeSubscription();

      console.log("Moving resource");
      if(this.groupsByType[groupIndex].vehicles.length == 0){
        console.log("Empty Group, removing");
        this.groupsByType.splice(groupIndex,1);
      }
    }
  }

  onTrackItPressed(vehicleMap:VehicleMap) {
    this.liveMap.resetTrackingVehicle();
    this.trackItVehicle = vehicleMap;
    console.log("PRESSED");
  }

  toggleAllVehiclesVisibility() {
    this.liveMap.toggleAllVehiclesVisibility(this.clustered);
  }

  onNameModeChanged(event:MatSlideToggleChange) {
    this.showNames = event.checked;
    this.configurationService.updateConfiguration('general',['live_map_show_vehicle_name'],[this.showNames]).then(()=>{});
    this.setVehiclesTooltip();
  }

  setVehiclesTooltip(){
      this.vehicles.forEach(vm => {
          if(this.showNames){
              this.liveMap.bindToolTip(vm.vehicle.name,vm.marker,'vehicle-tooltip');
          }else{
              this.liveMap.unbindToolTip(vm);
          }
      });
  }

  onClusterModeChanged(event:MatSlideToggleChange){
    this.clustered = event.checked;
    this.configurationService.updateConfiguration('general',['live_map_vehicle_clustered'],[this.clustered]).then(()=>{});
    if(this.clustered){
      this.liveMap.hideVehicleMarkers();
      this.liveMap.showVehicleClusters();
    }else{
      this.liveMap.hideVehicleClusters();
      this.liveMap.showVehicleMarkers();
    }
  }

  vehicleFilter(event: any) {

    let text: string = (event.target) ? event.target.value : event.data;

    this.groupsByType.forEach(group => {
      group.dataSource.data = group.vehicles.filter((vehicleMap: VehicleMap) => {
        let vehicle = vehicleMap.vehicle;
        return this.textIsContained(vehicle, text, ['name']);
      });
    });
  }

  textIsContained(resource: any, text: string, attributes: string[]): boolean{
    let contains = false;

    if (resource != null) {
      for(let attr of attributes){
        if(resource[attr] != null && String(resource[attr]).toLowerCase().includes(text.toLowerCase())){
          contains = true;
          break;
        }
      }
      return contains;
    }
  }

  organizeVehiclesWithTypes(vehicles:VehicleMap[]) {

    let mapping = {};
    this.groupsByType = [];

    vehicles.forEach(vm => {
      let typeId = vm.vehicle.type.id.toString();
      let typeKeylang = vm.vehicle.type.keylang;

      if(mapping[typeId] == null)
        mapping[typeId] = {
          data: [],
          keylang: typeKeylang,
        };
      mapping[typeId].data.push(vm);
    });

    for (let key in mapping) {
      let value = mapping[key];
      this.groupsByType.push(new VehicleMapGroup(value.data,value.keylang,Number(key)));
    }
  }

  ngAfterViewInit(): void {

    this.masterPanel.open();

    this.liveMap.onVehiclesDownloaded.subscribe(next => {
      if(next){
        this.vehicles = next;
        this.organizeVehiclesWithTypes(this.vehicles);
        this.setVehiclesTooltip();
        this.onVehiclesMapDoneBuilding.emit(this.vehicles);
        if(!this.clustered){
          this.liveMap.hideVehicleClusters();
          this.liveMap.showVehicleMarkers();
        }
      }
    });
    this.liveMap.onVehicleDataChanged.subscribe(next => {
      if(next){
        this.vehicles[next.index] = next.data;
      }
    });
    this.liveMap.onVehicleMarkerClicked.subscribe((vehicleMap : VehicleMap ) => {

      if(vehicleMap){

        this.masterPanel.open();

        let el = document.getElementById(vehicleMap.vehicle.id);

        let group : VehicleMapGroup;

        for(let i = 0; i < this.groupsByType.length; i++){
          for(let j = 0; j < this.groupsByType[i].vehicles.length;j++){
            if(vehicleMap.vehicle.id == this.groupsByType[i].vehicles[j].vehicle.id){
              group = this.groupsByType[i];
              break;
            }
          }
        }

        group.panel.open();

        setTimeout(()=>{
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        },600);

        vehicleMap.highlighted = true;

        setTimeout(()=>{
          vehicleMap.highlighted = false;
        },3000);
      }
    });
  }
}
