import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoutingModule } from './user-routing.module';
import { UserListComponent } from './user-list/user-list.component';
import { UserFormComponent } from './user-form/user-form.component';
import {
  MatDividerModule,
  MatProgressBarModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatButtonModule,
  MatListModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatTooltipModule,
  MatExpansionModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  MatTabsModule
} from '@angular/material';
import {MatProgressButtonsModule} from 'mat-progress-buttons';
import {InputsModule, WavesModule} from 'angular-bootstrap-md';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxLoopModule} from '../../../../projects/ngx-loop-components/src/lib/ngx-loop.module';
import {UserFormDialogComponent} from './user-form-dialog/user-form-dialog.component';
import {SharedModule} from '../../shared/shared.module';
import {ComponentsModule} from '../../components/components.module';
import {MatTableExporterModule} from 'mat-table-exporter';
import {DragDropModule} from '@angular/cdk/drag-drop';


@NgModule({
  declarations: [UserListComponent, UserFormComponent,UserFormDialogComponent],
  imports: [
    CommonModule,
    UserRoutingModule,
    MatDividerModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    InputsModule,
    WavesModule,
    MatButtonModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatProgressButtonsModule,
    NgxLoopModule,
    SharedModule,
    ComponentsModule,
    MatTableExporterModule,
    MatTooltipModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatTabsModule,
    DragDropModule
  ],
  entryComponents: [
    UserFormDialogComponent
  ]
})
export class UserModule { }
