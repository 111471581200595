import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { Geofence } from '../models/geofence.model';
import {SftGeofenceModel} from '../models/sft-geofence.model';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SftGeofenceService extends BaseService<SftGeofenceModel> {

  constructor(protected injector: Injector) {
    super(injector, 'safety/geofences', SftGeofenceModel.fromJson);
  }

  getGeofences(bodyParams) : Promise<SftGeofenceModel[]> {
    let customUrl = `${environment.api}safety/list-map-geofences`;
    return this.customPost(bodyParams,customUrl);
  }
}
