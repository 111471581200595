<div class="h-100 d-flex flex-column" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <mat-progress-bar mode="indeterminate" *ngIf="loading > 0"></mat-progress-bar>
    <div class="dialog_header px-3 py-2" cdkDragHandle>
      <h3 id="title"><i class="mdi mdi-file-chart mr-2"></i> Auto Report</h3>
      <i (click)="close()" class="mdi mdi-close"></i>
    </div>
    <div style="height:500px;background: var(--background-table-header);">
        <mat-tab-group class="h-100">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span style="color:var(--foreground-default);"><i class="mdi mdi-format-list-bulleted mr-2"></i> {{'label.rules'|translate}}</span>
                </ng-template>

                <div class="w-100 h-100 d-flex flex-column scroller">
                    <div class="w-100 d-flex flex-row  p-3">
                        <button mat-flat-button class="ml-auto" color="primary" (click)="createRule()">
                            {{'label.create_rule'|translate}}
                        </button>
                    </div>
                    <div class="d-flex flex-column flex-grow-1">
                        <table class="schedule">
                            <tr class="days py-0 px-3" *ngFor="let schedule of reportSchedules">
                                <td class="d-flex align-items-center flex-wrap" style="min-width: 70px;">
                                    <span class="mr-2" style="font-size:11pt;">
                                        {{(schedule.month_rule != null)?('label.daily'|translate):''}}
                                        {{(schedule.week_rule != null)?('label.weekly'|translate):''}}
                                    </span>
                                </td>
                                <td class="d-flex align-items-center flex-wrap w-100" style="min-width: 180px;">    
                                    <span style="font-size:9pt;font-weight:600;">{{'label.next_send'|translate}}: {{schedule.next_ts|dayHourFormat}}</span>
                                </td>
                                <td class="d-flex align-items-center flex-wrap w-100">    
                                    <span style="font-size:9pt;font-weight: 400;margin-left: 15px;">{{schedule.recipients.length}} {{'label.recipients'|translate}}</span>
                                </td>
                                <td class="d-flex align-items-center justify-content-center">
                                    <button class="table_options_button ml-auto" (click)="edit(schedule)" matTooltip="{{'label.edit'|translate}}">
                                        <i class="mdi mdi-square-edit-outline h6 mb-0"></i>
                                    </button>
                                    <button class="table_options_button ml-2" (click)="delete(schedule)" matTooltip="{{'label.delete'|translate}}">
                                        <i class="mdi mdi-trash-can-outline h6 mb-0 "></i>
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="w-100 d-flex flex-row  px-3 pb-3">
                        <button mat-button color="primary" (click)="sendNow()" class="ml-auto" [disabled]="loading > 0">
                            {{'label.send_report_now'|translate}}
                        </button>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span style="color:var(--foreground-default);"><i class="mdi mdi-file-multiple-outline mr-2"></i> {{'label.files'|translate}}</span>
                </ng-template>
                <div class="w-100 h-100 d-flex flex-column  scroller">
                    <div class="file px-3 py-2" *ngFor="let file of files">
                        <div class="d-flex align-items-center flex-wrap flex-grow-1">
                            <span style="font-size:9pt;">{{ file['date']|dayHourFormat }}</span>
                            <span style="font-size:9pt;font-weight: 600;" class="ml-3">{{file['name']}}</span>
                            <span style="font-size: 9pt;color: var(--primary);font-weight: 600;" class="ml-auto mr-3">{{getFileSize(file['size'])}}</span>
                        </div>
                        <button class="table_options_button ml-auto" (click)="downloadFile(file)" matTooltip="{{'label.download'|translate}}">
                            <i class="mdi mdi-download h6 mb-0"></i>
                        </button>
                        <button class="table_options_button ml-auto" (click)="deleteFile(file)" matTooltip="{{'label.remove'|translate}}">
                            <i class="mdi mdi-trash-can-outline h6 mb-0"></i>
                        </button>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="d-flex flex-row px-4 py-3 dialog_header" style="border-top: 1px solid var(--table-border);">
        <button mat-button color="primary" (click)="close()" class="ml-auto">
            {{'label.close'|translate}}
        </button>
    </div>
</div>