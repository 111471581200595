import {AfterContentChecked, AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MonthSelectorComponent} from '../../../components/month-selector/month-selector.component';
import {DashboardService, DashboardVehicleOccurenceResult} from '../../../services/dashboard.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import {BarChartComponent} from '../bar-chart/bar-chart.component';
import set = Reflect.set;
import {CompanyReactiveScreen} from '../../../components/company-reactive-screen';
import {VehicleOccurencesComponent} from '../vehicle-occurences/vehicle-occurences.component';
import {VehiclesRankingComponent} from '../vehicles-ranking/vehicles-ranking.component';
import {TitleService} from '../../../services/title.service';
import {DateRangeData, MonthSelectorV2Component} from '../../../components/month-selector-v2/month-selector.component';
import {CompanyService} from '../../../services/company.service';
import {Company} from '../../../models/company.model';
import {EventGroupService} from '../../../services/event-group.service';
import {SftEventGroup} from '../../../models/event.model';
import {EventConditionComponent} from '../../event/event-form/event-condition/event-condition.component';
import {DashboardGenericTabComponent} from './dashboard-generic-tab/dashboard-generic-tab.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent extends CompanyReactiveScreen implements OnInit,AfterViewInit,AfterContentChecked, OnDestroy {

  @ViewChildren('dashboardTabs') dashboardTabs: QueryList<DashboardGenericTabComponent>;
  @ViewChild('periodSelector',{static:false})

  periodSelector : MonthSelectorV2Component;

  loading: boolean = true;
  period: DateRangeData;

  groups : SftEventGroup[] = [];
  currentActiveTabIndex: number = 0;

  constructor(
    private dashboardService: DashboardService,
    private companyService:CompanyService,
    public titleService: TitleService,
    public eventGroupService: EventGroupService
  ) {
    super(companyService,true);
    this.companyService.setShowAllCompanies(true);
    this.initGroups();
  }

  initGroups(){
    this.groups.push(new SftEventGroup('General','#212121'));
  }

  ngOnInit() {
    this.titleService.setTitle("Home");
    this.downloadGroups();
  }

  ngOnDestroy(): void {
    this.cancelObservation();
  }

  ngAfterViewInit(): void {
    this.period = this.periodSelector.getSelectedValue();
    this.updatePeriod();
    this.refreshCurrentActiveDashboard();
  }

  downloadGroups() {
    this.eventGroupService.getResources().then(groups => {
      console.log("DOWNLOADED GROUPS");
      console.log(groups);
      groups.forEach(g => {
        this.groups.push(g);
      })
    })
  }

  onTabChanged(tabIndex:number) {
    this.currentActiveTabIndex = tabIndex;
    this.updatePeriod();
    this.refreshCurrentActiveDashboard();
  }

  resetAllCachedData() {
    let list = this.dashboardTabs.toArray();
    list.forEach(dash => {
      dash.resetDownloadedCache();
    })
  }

  onAllCompaniesSelected(companies: Company[]) {
    console.log(companies);
    this.resetAllCachedData();
    this.refreshCurrentActiveDashboard();
  }

  onCompanyChanged() {
    this.resetAllCachedData();
    this.refreshCurrentActiveDashboard();
  }

  onPeriodChanged = (period:DateRangeData) => {
    this.period = period;
    this.updatePeriod();
    this.resetAllCachedData();
    this.refreshCurrentActiveDashboard();
  };

  refreshCurrentActiveDashboard() {
    let currentDash = this.getCurrentDashboardTab();

    if(currentDash.hasCachedData == false)
      currentDash.refreshDashboard();
  }

  getCurrentDashboardTab() : DashboardGenericTabComponent {
    let list = this.dashboardTabs.toArray();
    return list[this.currentActiveTabIndex];
  }

  updatePeriod() {
    let list = this.dashboardTabs.toArray();
    list.forEach(dash => {
      dash.setPeriod(this.period);
    })
  }

  ngAfterContentChecked(): void {
  }

}
