<div class="d-flex flex-row align-items-center">
  <div class="date-input d-inline-flex flex-row align-items-center border-gray-rounded mr-3" style="width: 200px !important;" (click)="rangePicker.open()">
    <input [satDatepicker]="rangePicker" (dateChange)="onRangeSelected($event)" [(ngModel)]="selectedRange" class="border-0 w-100 rounded" placeholder="{{'label.date_range'| translate }}">
    <i class="mdi mdi-calendar-range cursor-pointer" style="color:var(--foreground-secondary);opacity: 0.6;"></i>
      <sat-datepicker  #rangePicker [rangeMode]="true"></sat-datepicker>
  </div>

  <div *ngIf="showTimePicker" class="d-flex flex-row align-items-center mr-3">
    <div class="date-input align-items-center mr-1" style="width:80px !important;">
      <input class="border-0 h-100" type="time" [(ngModel)]="selectedStartTime" />
    </div>
    <div class="date-input align-items-center" style="width:80px !important;">
      <input class="border-0 h-100" type="time" [(ngModel)]="selectedEndTime" />
    </div>
  </div>

  <mat-button-toggle-group
    #group="matButtonToggleGroup"
    [value]="selectedValue"
    appearance="legacy"
    (change)="onValueChange(group.value)">
    <mat-button-toggle *ngFor="let value of values; let i = index" [value]="values[i]" >
      {{ (labels[i] | translate).substr(0,3).toUpperCase()}}
    </mat-button-toggle>
  </mat-button-toggle-group>

  <button *ngIf="showTimePicker" (click)="onSearchClicked()" class="ml-2" mat-raised-button color="primary" style="height: 28px;line-height: 28px;font-size: 13px">
    {{'app.search' | translate}}
  </button>

</div>
