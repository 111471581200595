import {Component, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Vehicle} from '../../../models/vehicle.model';
import {VehicleCategory} from '../../../models/vehicle-category.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {VehicleTypeModel} from '../../../models/vehicleType.model';
import {ResourceSelectorComponent} from '../../../../../projects/ngx-loop-components/src/lib/resource-selector/resource-selector.component';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatSnackBarConfig} from '@angular/material';
import {TranslateService} from '../../../../../projects/ngx-loop-components/src/lib/translate.service';
import {VehicleService} from '../../../services/vehicle.service';
import {VehicleCategoryService} from '../../../services/vehicle-category.service';
import {CheckFormService} from '../../../services/check-form.service';
import {VehicleTypeService} from '../../../services/vehicleType.service';
import {SessionStorageService} from 'ngx-webstorage';
import {VehiclesDialogData} from '../../vehicles/vehicles/vehicles-form/vehicles-form.component';
import {SftGeofenceModel, SftGeofenceRule, SftGeofenceType} from '../../../models/sft-geofence.model';
import {GeofenceTypeService} from '../../../services/geofenceType.service';
import {SftGeofenceService} from '../../../services/sft-geofence.service';
import { GeofenceRulesComponent } from './geofence-rules/geofence-rules.component';

export interface GeofenceDialogData {
  geofence: SftGeofenceModel,
  coords
}

@Component({
  selector: 'app-geofence-form-dialog',
  templateUrl: './geofence-form-dialog.component.html',
  styleUrls: ['./geofence-form-dialog.component.css']
})
export class GeofenceFormDialogComponent implements OnInit {

  loading: boolean;

  editingGeofence: SftGeofenceModel;
  vehicleCategories: VehicleCategory[];
  form: FormGroup;

  checkingUniqueness = { geofenceSftErkpey: false };
  geofenceTypes: SftGeofenceType[] = [];
  rules: SftGeofenceRule[] = [new SftGeofenceRule()];

  @ViewChild('typeSelector', { static: true })
  typeSelector: ResourceSelectorComponent<SftGeofenceType>;
  
  @ViewChildren('geofenceRulesList')
  geofenceRulesList: QueryList<GeofenceRulesComponent>;

  constructor(
    public dialogRef: MatDialogRef<GeofenceFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GeofenceDialogData,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private geofenceService: SftGeofenceService,
    private vehicleCategoryService: VehicleCategoryService,
    public checkService: CheckFormService,
    private geofenceTypeService: GeofenceTypeService,
    private sessionStorage: SessionStorageService
  ) {
    this.editingGeofence = this.data.geofence;
    if (this.editingGeofence && this.editingGeofence.rules != null)
      this.rules = this.editingGeofence.rules;
  }

  ngOnInit() {
    this.initForm();
    this.downloadTypes();
    this.downloadCategories();
  }

  initForm() {
    this.form = this.formBuilder.group({
      erpkey: [null],
      name: [null, Validators.required],
      global_max_speed: [null],
      type: [null],
      direction_from: [null],
      direction_to: [null],
      rule_category: [null],
      rule_speed: [null]
    });
  }

  isEditing() : boolean {
    return this.editingGeofence != null;
  }

  downloadTypes() {
    this.loading = true;
    this.geofenceTypeService.getResources()
      .then((geoTypes: SftGeofenceType[]) => {
        this.geofenceTypes = geoTypes;
        this.typeSelector.setResources(geoTypes);
        if (this.isEditing())
          this.downloadGeofence();
        else
          this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        console.log("ERRO", error);
        this.openSnackBar(this.translate.data['msg.erro'],"error");
      });
  }

  downloadCategories() {

    this.loading = true;
    this.vehicleCategoryService.getResources()
      .then((categories: VehicleCategory[]) => {
        this.vehicleCategories = categories;
      })
      .catch(error => {
        console.log("Error Downloading Categories", error);
        this.openSnackBar(this.translate.data['msg.download_vehicle_categories_error'], 'error');
      })
      .finally(() => {
        this.loading = false;
      });
  }

  downloadGeofence() {
    this.loading = true;
    this.geofenceService.getResource(this.editingGeofence.id)
      .then((geofenceModel: SftGeofenceModel) => {
        this.editingGeofence = geofenceModel;
        if (geofenceModel.rules)
          this.rules = this.editingGeofence.rules;
        this.populateForm(geofenceModel);
      })
      .catch(error => {
        console.log('Error', error);
        this.openSnackBar(this.translate.data['msg.error_list_vehicle'], 'error');
      })
      .finally(() => {
        this.loading = false;
      })
  }

  populateForm(geofenceModel: SftGeofenceModel) {
    this.form.patchValue(geofenceModel);
    if (geofenceModel.type)
      this.typeSelector.setSelectedResource(geofenceModel.type);
  }

  public checkFieldValidator(field: string): boolean {
    return this.form.get(field).invalid && this.form.get(field).touched;
  }

  canSave(): boolean {
    return this.form.valid;
  }

  addRule(){
    this.rules.push(new SftGeofenceRule());
  }

  removeRule = (index: number) => {
    this.rules.splice(index, 1);
  };

  setFromNullIfEmpty(field){
    if(this.form.get(field).value == ""){
      console.log("NULLING",field);
      this.form.get(field).setValue(null);
    }
  }

  buildGeofence() : SftGeofenceModel {

    this.setFromNullIfEmpty('global_max_speed');
    this.setFromNullIfEmpty('direction_from');
    this.setFromNullIfEmpty('direction_to');

    let geofence: SftGeofenceModel = Object.assign(new SftGeofenceModel(), this.form.value);

    let rules = [];
    this.geofenceRulesList.toArray().forEach((ruleForm: GeofenceRulesComponent) => {
      let rule: SftGeofenceRule = new SftGeofenceRule();
      let form = ruleForm.getFormValue();
      
      if (form.id)
        rule.id = form.id 
      
      rule.max_speed = form.rule_speed;
      rule.vehicle_cat = form.rule_category;

      rules.push(rule);
    });
    geofence.rules = rules;

    return geofence;
  }

  public async save(shouldClose: boolean) {

    if (this.form.valid) {

      this.loading = true;

      let geofence = this.buildGeofence();

      if (this.isEditing()) {
        geofence.coordinates = null;
        this.geofenceService.update(geofence, this.editingGeofence.id)
          .then((geofence: SftGeofenceModel) => {
            this.openSnackBar(this.translate.data['msg.savedData'], 'success');
            this.editingGeofence = geofence;
            if (shouldClose)
              this.close();
          })
          .catch(error => {
            console.log("ERRO", error);
            this.openSnackBar(this.translate.data['msg.erro'], 'error');
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        geofence.coordinates = this.data.coords;
        this.geofenceService.create(geofence)
          .then((geofence: SftGeofenceModel) => {
            this.openSnackBar(this.translate.data['msg.savedData'], 'success');
            this.editingGeofence = geofence;
            if (shouldClose)
              this.close();
          })
          .catch(error => {
            console.log("ERRO", error);
            this.openSnackBar(this.translate.data['msg.erro'], 'error');
          })
          .finally(() => {
            this.loading = false;
          })
      }
    }
  }

  public openSnackBar(message: string, type: string) {
    let config: MatSnackBarConfig = {
      panelClass: 'snack_' + type,
      duration: 6000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    };
    this._snackBar.open(message, 'Ok', config);
  }

  close() {
    this.dialogRef.close();
  }

}
