import {BaseService} from './base.service';
import {UserModel as User} from '../models/user.model';
import {Injectable, Injector} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UserService extends BaseService<User> {

  constructor(protected injector: Injector){
    super(injector, 'core/users', User.fromJson);
  }

  getUserStoreObservable() : Observable<User> {
    return this.sessionStorage.observe('user');
  }

  getUserStore(): User {
    return this.sessionStorage.retrieve('user');
  }

  storeUser(user:User) {
    this.sessionStorage.store('user', user);
  }

  cleanUserStore(){
    this.sessionStorage.clear('user');
  }

}
