<div class="container">
  <app-page-title
      title="{{'menu.users' | translate}}"
      [hideButton]="true"
      [isLoading]="loading"
  >
  </app-page-title>
  <div class="_card mb-5 mt-4" style="background:var(--background-table-header)!important">
      <div class="px-3 pb-3 row" style="background:var(--background-table-header)">
          <div class="col-12 col-lg-4 pt-3">
              <div class="search_input_group">
                  <i class="mdi mdi-magnify h5 mb-0"></i>
                  <input class="search_input" placeholder="{{'dt.search' | translate}}:" (input)="applyFilter($event.target.value)">
              </div>
          </div>
          <div class="col-12 col-lg-8 pt-3 d-flex justify-content-end">
              <button *ngIf="permissionService.hasPermission('USER','create')" style="background:var(--background-stroked-button);color:var(--foreground-stroked-button);" mat-stroked-button color="primary" (click)="openUserDialog()">
                  {{'label.add' | translate}} {{'label.user' | translate}}
              </button>
              <app-export-button class="ml-3" [fileName]="'User List'" [exporter]="exporter"></app-export-button>
          </div>
      </div>


      <table mat-table [dataSource]="dataSource" matSort class="bg-transparent" matTableExporter #exporter="matTableExporter">

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:var(--foreground-header-table);">{{'label.name' | translate}}</th>
            <td mat-cell *matCellDef="let row" style="color:var(--foreground-default)"> {{row.name}} </td>
          </ng-container>

          <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:var(--foreground-header-table);">{{'label.userName' | translate}}</th>
            <td mat-cell *matCellDef="let row" style="color:var(--foreground-default)"> {{row.username}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:var(--foreground-header-table);">{{'label.email' | translate}}</th>
            <td mat-cell *matCellDef="let row" style="color:var(--foreground-default)"> {{row.email}} </td>
          </ng-container>

          <ng-container matColumnDef="last_login">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:var(--foreground-header-table);">{{'label.last_login' | translate}}</th>
            <td mat-cell *matCellDef="let row" style="color:var(--foreground-default)">
                <!--span *ngIf="row.last_login">{{row.last_login | fullDate}}</!--span-->
                <span *ngIf="!row.last_login">--:--</span></td>
          </ng-container>

          <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row">
                  <div class="table_options w-100 d-flex">
                      <mat-spinner *ngIf="selfLoading && row.id == selectedUser.id" [diameter]="15" class="mt-2"></mat-spinner>
                      <button *ngIf="permissionService.hasPermission('USER','edit')" class="table_options_button ml-auto" (click)="openUserDialog(row)" matTooltip="{{'label.edit'|translate}} {{'label.user'|translate}}">
                          <i class="mdi mdi-square-edit-outline h6 mb-0 "></i>
                      </button>
                      <button *ngIf="permissionService.hasPermission('USER', 'log')" (click)="openAccessLog(row)" matTooltip="{{'label.access_history'|translate}}" class="table_options_button ml-auto">
                        <i class="mdi mdi-clipboard-account-outline h6 mb-0"></i>
                      </button>
                      <button *ngIf="permissionService.hasPermission('USER','remove')" matTooltip="{{'label.remove'|translate}} {{'label.user'|translate}}" (click)="deleteUser(row)" class="table_options_button ml-auto">
                          <i class="mdi mdi-trash-can-outline h6 mb-0"></i>
                      </button>
                  </div>
              </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" style="background:var(--background-table-header)!important"></tr>
          <tr mat-row class="table_cell" *matRowDef="let row; columns: displayedColumns;" style="background:var(--background-table-body)"></tr>
      </table>

      <mat-paginator class="bg-transparent" [pageSizeOptions]="[50, 100, 150, 200]"></mat-paginator>
  </div>


</div>
