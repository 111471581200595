import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel, MatSort, MatTableDataSource } from '@angular/material';
import { NgxLoopVehicleLiveMap, ResourceMap } from 'projects/ngx-loop-components/src/lib/ngx-loop-vehicle-livemap/ngx-loop-vehicle-livemap';
import { PreferencesService } from 'projects/ngx-loop-components/src/public-api';
import { SftGeofenceModel } from 'src/app/models/sft-geofence.model';
import { TypeGeofenceGroup } from '../geofence-panel.component';

@Component({
  selector: 'app-geofence-expansion-panel',
  templateUrl: './geofence-expansion-panel.component.html',
  styleUrls: ['./geofence-expansion-panel.component.css']
})
export class GeofenceExpansionPanelComponent implements OnInit, AfterViewInit {

  @Output()
  locateGeofenceEmit: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  editGeofenceDialogEmit: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onGeofenceSharingClicked: EventEmitter<SftGeofenceModel> = new EventEmitter<SftGeofenceModel>();

  @Input()
  dataSource: MatTableDataSource<ResourceMap<SftGeofenceModel>> = new MatTableDataSource();

  @Input()
  expanded: boolean;

  @Input()
  geofencesVisible: boolean;

  @Input()
  enableSharing: boolean = false;

  @Input()
  enableEditing: boolean = true;

  @Input()
  title: string;

  @Input()
  type: TypeGeofenceGroup;

  @Input()
  layerName: string;

  @Input()
  liveMap: NgxLoopVehicleLiveMap;

  @ViewChild(MatSort, {static: true})
  sort: MatSort;

  @ViewChild('panel', {static: true})
  panel: MatExpansionPanel;

  displayedColumns: string[] = ['name', 'speed', 'type', 'options'];
  checked: boolean = true;

  constructor(
    public preferencesService: PreferencesService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.setupSorting();
    this.type.setPanel(this.panel);
  }

  setupSorting(){
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'name') {
        return item.resource.name;
      }else if (property == 'type') {
        if(item.resource.type)
          return item.resource.type.name;
        return '';
      }else if(property == 'speed'){
        return item.resource.global_max_speed;
      }else {
        return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  locateGeofence(element: ResourceMap<SftGeofenceModel>) {
    this.locateGeofenceEmit.emit(element);
  }

  openEditGeofenceDialog(geofence: SftGeofenceModel) {
    this.editGeofenceDialogEmit.emit(geofence);
  }

  toggleGeofenceVisibility(geofence:ResourceMap<SftGeofenceModel>){
    this.liveMap.toggleResourceVisibility(this.layerName, geofence);
  }

  toggleGeofenceTypeGroup(group: ResourceMap<SftGeofenceModel>[], completed: boolean) {
    this.checked = completed;
    group.forEach(element => {
      element.visible = !completed;
      this.toggleGeofenceVisibility(element);
    });
  }

  getFlashingAnimationPanel(element:ResourceMap<any>) : string {
    return element.highlighted ? 'flashit_row' : '';
  }

  someComplete(): boolean {
    if (this.dataSource.data == null) {
      return false;
    }

    let visible = this.dataSource.data.filter(resource => resource.visible);
    let all = this.dataSource.data

    if (visible.length == 0)
      this.checked = false;

    return visible.length > 0 && visible.length != all.length;
  }

}
