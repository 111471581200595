import {Component} from '@angular/core';
import {Subscription} from 'rxjs';
import {Company} from '../models/company.model';
import {AllCompaniesEvent, CompanyService} from '../services/company.service';


export class CompanyReactiveScreen implements AllCompaniesEvent {

  protected subscription : Subscription;
  private companyReactiveService: CompanyService;
  private companyOnCreate : Company = null;

  constructor(companyService:CompanyService, startObservation : boolean = true){
    this.companyReactiveService = companyService;
    this.companyOnCreate = companyService.retrieveCompany();

    if(startObservation)
      this.startObservation();

    this.companyReactiveService.setAllCompaniesListener(this);
  }

  startObservation(){
    if(!this.subscription){
      this.subscription = this.companyReactiveService.observeCompanyChanges().subscribe((company:Company) => {
        if(company && this.companyOnCreate != null){
          this.onCompanyChanged();
        }
      })
    }
  }

  cancelObservation(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

  public onCompanyChanged(){}

  public onAllCompaniesSelected(companies: Company[]) {}

}
