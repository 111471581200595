import {Driver} from './driver.model';
import {BaseModel} from './base.model';


export class DriverInterval extends BaseModel {
  constructor(
    public driver?:Driver,
    public vehicle?:number,
    public begin?:string,
    public token?:string
  ) {
    super();
  }

  static fromJson(json:any) : DriverInterval {
    return Object.assign(new DriverInterval(),json);
  }
}
