<div class="card-1 p-3"  style="display: block;border-radius: 5px; background-color: var(--background-card);">
    <h5 style="color: var(--foreground-default)">{{title}}</h5>
  <cdk-virtual-scroll-viewport *ngIf="vehiclesRanking && vehiclesRanking.length > 0" itemSize="100" [ngStyle]="{height:height}" class="justify-content-center" >
    <div style="background-color: var(--background-card)" *ngFor="let vk of vehiclesRanking; let i = index" class="d-flex flex-row align-items-center ranking-row" (click)="onClick(vk)">
      <span style="font-size: 0.75em;font-weight: 400;opacity: 0.8;white-space: nowrap;color: var(--foreground-default)">{{vk.vehicle__name}}</span>
      <mat-divider style="background-color: var(--divider)" class="w-100 mx-4"></mat-divider>
      <div class="ml-auto">
        <span style="font-weight: 500;font-size: 0.95em;opacity: 0.8;color: var(--foreground-default)">{{vk.total}}</span>
        <span class="ml-2" style="font-size: 0.65em;font-weight: 400;opacity: 0.7;color: var(--foreground-default)">{{'label.incidents' | translate}}</span>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
  <div *ngIf="vehiclesRanking && vehiclesRanking.length == 0" [ngStyle]="{height:height}" class="d-flex flex-column align-items-center justify-content-center" >
    <i class="mdi mdi-signal-off mb-2" style="font-size: 1.5em"></i>
    <p style="font-weight: 400;opacity: 0.8;font-size: 0.8em" class="align-self-center mb-0">{{ 'app.no_data_period' | translate}}</p>
  </div>
  <div class="align-items-center" *ngIf="!vehiclesRanking">
    <i class="mdi mdi-chart-gantt" style="font-size: 1.5em"></i>
  </div>
</div>
