export default interface Theme {
    name: string;
    login_logo_asset:string;
    title:string;
    alternate_favicon:string;
    app_bar_asset:string;
    properties: any;
}

export const light: Theme = {
    name: "light",
    title: "Safety Solutions",
    login_logo_asset: 'logo_light.png',
    alternate_favicon: null,
    app_bar_asset: null,
    properties: {

        "--primary":"#3C4B75",
        "--primary-medium-color":"#6da0bb",
        "--error":"#ff5c5c",

        "--background-header":"#3C4B75",

        "--login-bg-card": "#ffffff",
        "--login-foreground-default": "#222",

        "--background":"#E8EAED",
        "--background-card":"#ffffff",
        "--background-card-hovered":"#f5f5f5",
        "--background-input":"#fafafa",
        "--background-sidebar":"#ffffff",
        "--background-sidebar-hover":"#eee",

        "--background-search-input":"#e5eaf1",

        "--background-table-header":"#fafafa",
        "--background-table-body":"#ffffff",
        "--background-table-footer":"",
        "--table-border":"#cecece",
        "--background-dropdown":"#ffffff",
        "--background-dropdown-hover":"#f5f5f5",

        "--background-stroked-button":"#fff",
        "--background-flat-button":"#fff",
        "--background-danger-button":"transparent",

        "--divider-color":"#22222214",

        "--foreground-default": "#222",
        "--foreground-secondary": "#848484",
        "--foreground-border-table": "#e0e0e0",
        "--foreground-header-table": "rgba(0,0,0,.54)",
        "--foreground-danger-button":"#ff0000",
        "--foreground":null,
        "--foreground-card":null,
        "--foreground-input":null,
        "--foreground-search-input":"#000000de",
        "--foreground-secoundary-search-input":"#476282",
        "--schedule-border": "#e7e7e7",
        "--foreground-stroked-button":"#3C4B75",
        "--foreground-flat-button":"#3C4B75",

        "--placeholder-input":null,
        "--disabled-input":null,
        "--disabled-placeholder-input":null,

        "--option-bar":"#ececec",


        "--background-bar": "#949597",


        "--theme-primary-color": '#424242',
        "--theme-primary-darker": '#424242',
        "--foreground-tertiary": "#949597",
        "--foreground-light": "#41474D",
        "--foreground-sidebar": "#2c2c2c",


        "--background-header-color": "#3C4B75",
        //"--background-card": "#ffffff",
        //"--background": "#f1f1f1",
        "--background-tertiary": "#5C7D99",
        "--background-quaternary": "#ffffff",

        "--background-quaternary-hover": "#f5f5f5",
        "--background-light": "#FFFFFF",
        "--background-alternative": "#FAFAFA",
        //"--background-sidebar-hover": "#eee",

        "--background-table-row": "#ffffff",
        //"--background-table-header":"#fafafa",
        "--table-divider":"#ebebeb",

        "--dialog-border-color": "#DEE2E6",
        "--input-border-color": "#dddddd",
        "--input-background-color": "#ffffff",

        "--divider": "#C3C3C3",
        "--dashed-divider": "#CECECE",

        "--primary-text-color": "#595959",
        "--focus-text-color": "#0c0c0c",

        "--search-input-background":"#E5EAF2",
        "--search-input-foreground":"#000000de",

        "--progress-card-foregroud-primary": "#333",
        "--progress-card-foregroud-secondary": "#000000de",

        "--step-point-completed-foreground":"#4186c1",
        "--step-point-background":"#ccc",

        "--to-plant-color":"#73a76d",
        "--on-job-color":"#ffd536",
        "--to-job-color":"#6b9bff",
        "--loading-color":"#bcbcbc",


        "--primary-default": "#3C4B75",
        "--primary-dark": "#24B286",
        "--primary-light": "#B2FFE7",

        "--error-default": "#EF3E36",
        "--error-dark": "#800600",
        "--error-light": "#FFCECC",

        "--background-tertiary-shadow": "0 1px 3px 0 rgba(92, 125, 153, 0.5)",

        "--loading-placeholder": "#d4d4d4",
        "--loading-background": "#efefef",
    }
};

export const dark: Theme = {
    name: "dark",
    login_logo_asset: 'logo_dark.png',
    title: "Safety Solutions",
    alternate_favicon: null,
    app_bar_asset: null,
    properties: {

        "--primary":"#3C4B75",
        "--primary-medium-color":"#6da0bb",
        "--error":"#ff4141",

        "--background-header":"#3C4B75",

        "--login-bg-card": "#212940",
        "--login-foreground-default": "#cbd1dcde",

        "--background":"#1A2036",
        "--background-card":"#212940",
        "--background-card-hovered":"#19223c",
        "--background-input":"#192138",
        "--background-sidebar":"#212940",
        "--background-sidebar-hover":"#19223c",

        "--background-search-input":"#192138",

        "--background-table-header":"#212940",
        "--background-table-body":"#29314a",
        "--background-table-footer":"",
        "--table-border":"#181f33",
        "--background-dropdown":"#192138",
        "--background-dropdown-hover":"#111a31",

        "--background-stroked-button":"#3C4B75",
        "--background-flat-button":"#3C4B75",
        "--background-danger-button":"#212940",

        "--divider-color":"#cbd1dc14",

        "--foreground-default": "#cbd1dcde",
        "--foreground-secondary": "#cbd1dcde",
        "--foreground-border-table": "#181f33",
        "--foreground-header-table": "#cbd1dc8a",
        "--foreground-danger-button":"#ff4141",
        "--foreground":null,
        "--foreground-card":null,
        "--foreground-input":null,
        "--foreground-search-input":"#ffffffde",
        "--foreground-secoundary-search-input":"#ffffffde",
        "--foreground-stroked-button":"#fff",
        "--foreground-flat-button":"#fff",

        "--placeholder-input":null,
        "--disabled-input":null,
        "--disabled-placeholder-input":null,

        "--option-bar":"#29314a",
        "--background-bar": "#2c303a",

        "--schedule-border":"#212940",

        "--theme-primary-color": '#0A3E58',
        "--theme-primary-darker": '#073348',
        "--foreground-tertiary": "#1a2036",
        "--foreground-light": "#cbd1dcde",
        "--foreground-sidebar": "#fff",

        "--background-header-color": "#131929",
        //"--background-card": "#1d2237",
        //"--background": "#1a2036",
        "--background-tertiary": "#08090A",
        "--background-quaternary": "#131929",
        "--background-quaternary-hover":"#212940",
        "--background-light": "#41474D",
        "--background-alternative": "#212940",
        //"--background-sidebar-hover": "#1a2036",

        "--background-table-row": "#1d2237",
        //"--background-table-header":"#212940",
        "--table-divider":"#262B40",

        "--dialog-border-color": "#131929",
        "--input-border-color": "#131929",
        "--input-background-color": "#1E2644",

        "--divider": "#C3C3C3",
        "--dashed-divider": "#262B40",

        "--primary-text-color": "#cbd1dcde",
        "--focus-text-color": "#f1f1f1",

        "--search-input-background":"#1E2644",
        "--search-input-foreground":"#ffffffde",

        "--progress-card-foregroud-primary": "#cbd1dcde",
        "--progress-card-foregroud-secondary": "#A3B9CC",

        "--step-point-completed-foreground":"#4186c1",
        "--step-point-background":"#131929",


        "--to-plant-color":"#1ea069",
        "--on-job-color":"#af9e27",
        "--to-job-color":"#459bb5",
        "--loading-color":"#cbd1dcde",

        "--primary-default": "#3C4B75",
        "--primary-dark": "#24B286",
        "--primary-light": "#B2FFE7",
        "--error-default": "#ff4e46",
        "--error-dark": "#800600",
        "--error-light": "#FFCECC",

        "--background-tertiary-shadow": "0 1px 3px 0 rgba(8, 9, 10, 0.5)",

        "--loading-placeholder": "#3b4467",
        "--loading-background": "rgba(45,54,89,0.78)",
    }
};

export const kat_light: Theme = {
  name: "kat_light",
  login_logo_asset: 'logo_kat.png',
  title: "KAT33",
  alternate_favicon: 'kat_favicon.png',
  app_bar_asset: 'kat_icon.png',
  properties: {

    "--primary":"#105F89",
    "--primary-medium-color":"#6da0bb",
    "--error":"#ff5c5c",

    "--background-header":"#105F89",

    "--login-bg-card": "#ffffff",
    "--login-foreground-default": "#222",

    "--background":"#E8EAED",
    "--background-card":"#ffffff",
    "--background-card-hovered":"#f5f5f5",
    "--background-input":"#fafafa",
    "--background-sidebar":"#ffffff",
    "--background-sidebar-hover":"#eee",

    "--background-search-input":"#e5eaf1",

    "--background-table-header":"#fafafa",
    "--background-table-body":"#ffffff",
    "--background-table-footer":"",
    "--table-border":"#cecece",
    "--background-dropdown":"#ffffff",
    "--background-dropdown-hover":"#f5f5f5",

    "--background-stroked-button":"#fff",
    "--background-flat-button":"#fff",
    "--background-danger-button":"transparent",

    "--divider-color":"#22222214",

    "--foreground-default": "#222",
    "--foreground-secondary": "#848484",
    "--foreground-border-table": "#e0e0e0",
    "--foreground-header-table": "rgba(0,0,0,.54)",
    "--foreground-danger-button":"#ff0000",
    "--foreground":null,
    "--foreground-card":null,
    "--foreground-input":null,
    "--foreground-search-input":"#000000de",
    "--foreground-secoundary-search-input":"#476282",
    "--schedule-border": "#e7e7e7",
    "--foreground-stroked-button":"#105F89",
    "--foreground-flat-button":"#105F89",

    "--placeholder-input":null,
    "--disabled-input":null,
    "--disabled-placeholder-input":null,

    "--option-bar":"#ececec",


    "--background-bar": "#949597",


    "--theme-primary-color": '#424242',
    "--theme-primary-darker": '#424242',
    "--foreground-tertiary": "#949597",
    "--foreground-light": "#41474D",
    "--foreground-sidebar": "#2c2c2c",


    "--background-header-color": "#105F89",
    //"--background-card": "#ffffff",
    //"--background": "#f1f1f1",
    "--background-tertiary": "#5C7D99",
    "--background-quaternary": "#ffffff",
    "--background-quaternary-hover": "#f5f5f5",
    "--background-light": "#FFFFFF",
    "--background-alternative": "#FAFAFA",
    //"--background-sidebar-hover": "#eee",

    "--background-table-row": "#ffffff",
    //"--background-table-header":"#fafafa",
    "--table-divider":"#ebebeb",

    "--dialog-border-color": "#DEE2E6",
    "--input-border-color": "#dddddd",
    "--input-background-color": "#ffffff",

    "--divider": "#C3C3C3",
    "--dashed-divider": "#CECECE",

    "--primary-text-color": "#595959",
    "--focus-text-color": "#0c0c0c",

    "--search-input-background":"#E5EAF2",
    "--search-input-foreground":"#000000de",

    "--progress-card-foregroud-primary": "#333",
    "--progress-card-foregroud-secondary": "#000000de",

    "--step-point-completed-foreground":"#4186c1",
    "--step-point-background":"#ccc",

    "--to-plant-color":"#73a76d",
    "--on-job-color":"#ffd536",
    "--to-job-color":"#6b9bff",
    "--loading-color":"#bcbcbc",


    "--primary-default": "#105F89",
    "--primary-dark": "#24B286",
    "--primary-light": "#B2FFE7",

    "--error-default": "#EF3E36",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--background-tertiary-shadow": "0 1px 3px 0 rgba(92, 125, 153, 0.5)",

    "--loading-placeholder": "#d4d4d4",
    "--loading-background": "#efefef",
  }
};

export const kat_dark: Theme = {
  name: "kat_dark",
  login_logo_asset: 'logo_kat.png',
  title: "KAT33",
  alternate_favicon: 'kat_favicon.png',
  app_bar_asset: 'kat_icon.png',
  properties: {

    "--primary":"#10709a",
    "--primary-medium-color":"#6da0bb",
    "--error":"#ff4141",

    "--background-header":"#105F89",

    "--login-bg-card": "#ffffff",
    "--login-foreground-default": "#222",

    "--background":"#1A2436",
    "--background-card":"#212F40",
    "--background-card-hovered":"#103d66",
    "--background-input":"#062737",
    "--background-sidebar":"#21374e",
    "--background-sidebar-hover":"#1A2436",

    "--background-search-input":"#062737",

    "--background-table-header":"#212F40",
    "--background-table-body":"#21374e",
    "--background-table-footer":"",
    "--table-border":"#181f33",
    "--background-dropdown":"#212F40",
    "--background-dropdown-hover":"#1A2436",

    "--background-stroked-button":"#105F89",
    "--background-flat-button":"#105F89",
    "--background-danger-button":"#212940",

    "--divider-color":"#cbd1dc14",

    "--foreground-default": "#cbd1dcde",
    "--foreground-secondary": "#cbd1dcde",
    "--foreground-border-table": "#181f33",
    "--foreground-header-table": "#cbd1dc8a",
    "--foreground-danger-button":"#ff4141",
    "--foreground":null,
    "--foreground-card":null,
    "--foreground-input":null,
    "--foreground-search-input":"#ffffffde",
    "--foreground-secoundary-search-input":"#ffffffde",
    "--foreground-stroked-button":"#fff",
    "--foreground-flat-button":"#fff",

    "--placeholder-input":null,
    "--disabled-input":null,
    "--disabled-placeholder-input":null,

    "--option-bar":"#29314a",
    "--background-bar": "#2c303a",

    "--schedule-border":"#212940",

    "--theme-primary-color": '#0A3E58',
    "--theme-primary-darker": '#073348',
    "--foreground-tertiary": "#1a2036",
    "--foreground-light": "#cbd1dcde",
    "--foreground-sidebar": "#fff",

    "--background-header-color": "#131929",
    //"--background-card": "#1d2237",
    //"--background": "#1a2036",
    "--background-tertiary": "#08090A",
    "--background-quaternary": "#131929",
    "--background-quaternary-hover":"#212940",
    "--background-light": "#41474D",
    "--background-alternative": "#182840",
    //"--background-sidebar-hover": "#1a2036",

    "--background-table-row": "#1d2237",
    //"--background-table-header":"#212940",
    "--table-divider":"#262B40",

    "--dialog-border-color": "#131929",
    "--input-border-color": "#131929",
    "--input-background-color": "#062737",

    "--divider": "#C3C3C3",
    "--dashed-divider": "#262B40",

    "--primary-text-color": "#cbd1dcde",
    "--focus-text-color": "#f1f1f1",

    "--search-input-background":"#1E2644",
    "--search-input-foreground":"#ffffffde",

    "--progress-card-foregroud-primary": "#cbd1dcde",
    "--progress-card-foregroud-secondary": "#A3B9CC",

    "--step-point-completed-foreground":"#4186c1",
    "--step-point-background":"#131929",


    "--to-plant-color":"#1ea069",
    "--on-job-color":"#af9e27",
    "--to-job-color":"#459bb5",
    "--loading-color":"#cbd1dcde",

    "--primary-default": "#3C4B75",
    "--primary-dark": "#24B286",
    "--primary-light": "#B2FFE7",
    "--error-default": "#ff4e46",
    "--error-dark": "#800600",
    "--error-light": "#FFCECC",

    "--background-tertiary-shadow": "0 1px 3px 0 rgba(8, 9, 10, 0.5)",

    "--loading-placeholder": "#3b4467",
    "--loading-background": "rgba(45,54,89,0.78)",
  }
};
