<div style="background: var(--background-card);" class="rounded">
    <div mat-dialog-title class="mb-3">
        <i class="mr-2" [ngClass]="['mdi', data.iconClass]" style="font-size: 1.4rem;" [ngStyle]="{color: ((data.iconColor) ? data.iconColor : '#ff9400')}"></i>
        <span class="mb-0 text-bold" style="color: var(--foreground-default);">{{data.title|translate}}</span>
    </div>
    <div mat-dialog-content>
        <p class="mb-3 text-left mt-1 body-text font-small">{{data.confirmationMessage}}</p>
        <mat-checkbox class="checkbox-text" [(ngModel)]="isCheckBoxChecked" *ngIf="data.showCheckBox">{{data.checkboxText}}</mat-checkbox>
    </div>
    <div mat-dialog-actions class="mt-3 d-flex flex-row justify-content-end">
        <button *ngIf="cancelable" mat-button style="color: var(--foreground-default);" (click)="cancelAndClose()">{{'nav.btnCancelar'|translate}}</button>
        <button mat-button style="color: var(--foreground-default);" (click)="actionAndClose()">{{data.buttonText}}</button>
    </div>
</div>
