import {BaseModel} from '../models/base.model';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injector} from '@angular/core';
import {environment} from '../../environments/environment';
import { SessionStorageService} from 'ngx-webstorage';
import {TranslateService} from '../../../projects/ngx-loop-components/src/lib/translate.service';

const HTTP_FORBIDDEN = 403;
const SAFETY_BUSINESS_ID = '5';
const HTTP_UNATH = 401;

export class BaseService<T extends BaseModel> {

    http: HttpClient;
    apiPath: string;
    sessionStorage: SessionStorageService;
    translate: TranslateService;

    sessionStorageService: SessionStorageService;

    constructor(
        protected injector: Injector,
        protected specificUrl: string,
        protected jsonDataToResourceFn: (jsonData: any) => T
    ) {
        this.apiPath = environment.api + specificUrl;
        this.http = injector.get(HttpClient);
        this.translate = injector.get(TranslateService);
        this.sessionStorage = injector.get(SessionStorageService);
    }

    async buildHeader(): Promise<HttpHeaders> {

        let firebase = this.sessionStorage.retrieve('token');
        let refresh = this.sessionStorage.retrieve('rt');
        let company = this.sessionStorage.retrieve('selectedCompany');

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'firebase': firebase,
            'refresh': refresh,
            'business-app': SAFETY_BUSINESS_ID
        });

        if (company) {
            let companyID = company.id.toString();
            headers = headers.append('company', companyID);
        }

        return headers
    }

    static buildQueryParams(params: any): HttpParams {
        return Object.getOwnPropertyNames(params).reduce((p, key) => p.set(key, params[key]), new HttpParams());
    }


    async getResource(id: any): Promise<T> {

      let header = await this.buildHeader();
      return this.http.get(this.apiPath + '/' + id, {headers: header,observe:'response'}).toPromise()
      .then((response: HttpResponse<{data:T}>) => {
        // console.log('Response Get Resource', response);
        this.refreshToken(response.headers);
        return this.jsonDataToModel(response.body.data);
      })
      .catch((error) => {
        this.willLogoutUser(error);
        throw error;
      })
    }

    async customPost(body: any,customUrl=null,queryParams=null) : Promise<any> {

    let url = this.apiPath;
    if (customUrl){
      url = customUrl;
    }

      if(queryParams){
        queryParams = BaseService.buildQueryParams(queryParams);
      }else{
        queryParams = {};
      }

    let header = await this.buildHeader();
    return this.http.post(url,body,{headers:header,observe: 'response',params:queryParams}).toPromise().then(
      (response: HttpResponse<any>) => {
        this.refreshToken(response.headers);
        return response.body.data;
      }
    ).catch( reason => {
      this.willLogoutUser(reason);
      throw reason;
    });

  }

  async customPut(body: any,id,customUrl=null) : Promise<any> {

    let url = this.apiPath;
    if (customUrl){
      url = customUrl;
      if(id != null)
        url = url + id;
    }

    let header = await this.buildHeader();
    return this.http.put(url,body,{headers:header,observe: 'response'}).toPromise().then(
      (response: HttpResponse<any>) => {
        this.refreshToken(response.headers);
        return response.body.data;
      }
    ).catch( reason => {
      this.willLogoutUser(reason);
      throw reason;
    });

  }

  async customDelete(id,customUrl=null) : Promise<boolean> {

    let url = this.apiPath;
    if (customUrl){
      url = customUrl;
      if(id != null)
        url = url + id;
    }

    let header = await this.buildHeader();
    return this.http.delete(url,{headers:header,observe: 'response'}).toPromise().then(
      (response: HttpResponse<any>) => {
        this.refreshToken(response.headers);
        return response.body.data;
      }
    ).catch( reason => {
      this.willLogoutUser(reason);
      throw reason;
    });

  }

  async customGet(queryParams?: any,customUrl?:string) : Promise<any> {

    let header = await this.buildHeader();
    let url = this.apiPath;

    if (customUrl){
      url = customUrl;
    }

    if(queryParams){
      queryParams = BaseService.buildQueryParams(queryParams);
    }else{
      queryParams = {};
    }

    return this.http.get(url, {headers: header,params:queryParams,observe: 'response'}).toPromise().then(
      (response: HttpResponse<any>) => {
        this.refreshToken(response.headers);
        return response.body.data;
      }
    ).catch(reason => {
      this.willLogoutUser(reason);
      throw reason;
    });
}



async getCustomResource(queryParams?: any): Promise<any> {
    let header = await this.buildHeader();

    if (queryParams) {
        queryParams = BaseService.buildQueryParams(queryParams);
        // console.log('QUERY PARAMS', queryParams.toString());
    }

    return this.http.get(this.apiPath, {headers: header, params: queryParams,observe:'response'}).toPromise()
        .then((response: HttpResponse<{ data: any }>) => {
            this.refreshToken(response.headers);
            return response.body.data;
        })
        .catch((error) => {
            this.willLogoutUser(error);
            throw error;
        })
}


async getResources(queryParams?: any): Promise<T[]> {

    let header = await this.buildHeader();
    if (queryParams) {
        queryParams = BaseService.buildQueryParams(queryParams);
    }

    return this.http.get(this.apiPath, {headers: header, params: queryParams,observe:'response'}).toPromise()
        .then((response: HttpResponse<{ data: any[] }>) => {
            this.refreshToken(response.headers);
            if(this.jsonDataToResourceFn != null)
              return this.jsonDataToModels(response.body.data);
            return response.body.data;
        })
        .catch((error) => {
            this.willLogoutUser(error);
            throw error;
        })
}


async create(resource: T): Promise<T> {
    let header = await this.buildHeader();
    return this.http.post(this.apiPath, resource, {headers: header,observe:'response'}).toPromise()
        .then((response: HttpResponse<{ data: T }>) => {
            // console.log('Response Create ', this.apiPath, response);
            this.refreshToken(response.headers);
            return this.jsonDataToModel(response.body.data);
        })
        .catch((error) => {
            this.willLogoutUser(error);
            throw error;
        })
}


async update(resource: T, id: number, queryParams?: {}): Promise<T> {
    let header = await this.buildHeader();

    if(queryParams)
        queryParams = BaseService.buildQueryParams(queryParams);

    return this.http.put(this.apiPath + '/' + id, resource, {headers: header, params: queryParams, observe:'response'}).toPromise()
        .then((response:HttpResponse<{ data: T }>) => {
            // console.log('Response Update ', this.apiPath + id, response);
            this.refreshToken(response.headers);
            return this.jsonDataToModel(response.body.data);
        })
        .catch((error) => {
            this.willLogoutUser(error);
            throw error;
        })
}

async delete(id:any,customUrl=null,queryParams?: any): Promise<boolean> {
    let header = await this.buildHeader();

    let url = this.apiPath + '/' + id;

    if (customUrl){
      url = customUrl;
    }

    if(queryParams){
      queryParams = BaseService.buildQueryParams(queryParams);
    }else{
      queryParams = {};
    }

    return this.http.delete(url,{headers:header,observe: 'response',params:queryParams}).toPromise().then(
      ( (res:HttpResponse<{data:boolean}>) => {
        this.refreshToken(res.headers);
        return true;
      })
    ).catch( reason => {
      this.willLogoutUser(reason);
      throw reason;
    });
}

protected jsonDataToModel(jsonData: any): T {
    return this.jsonDataToResourceFn(jsonData);
}

protected jsonDataToModels(jsonData: any[]): T[] {
    let resources: T[] = [];
    jsonData.forEach((data: any) => {
        resources.push(this.jsonDataToResourceFn(data));
    });

    return resources;
}


refreshToken(responseHeaders:HttpHeaders) {
    try{

      let token = responseHeaders.get("Core-Token");
      let refresh = responseHeaders.get("Core-Refresh");
      if(token != null)
        this.sessionStorage.store('token',token);
      if(refresh != null)
        this.sessionStorage.store('rt',refresh);

    }catch(Exception){
      console.log("Token not refreshed")
    }
  }

willLogoutUser(error: HttpErrorResponse) {
    try {
        let logged = this.sessionStorage.retrieve("user");
        if (error.status === HTTP_FORBIDDEN && !!logged) {
            this.logoutUser();
            window.alert(this.translate.data['app.session_expired']);
        }
    } catch (e) {
    }
}

logoutUser() {
    this.sessionStorage.clear();
}

    async customRequest(method:string,url:string,body:any,queryParams:any) : Promise<any> {

        let parameters = {};
        let headers = await this.buildHeader();

        if(queryParams)
            parameters = BaseService.buildQueryParams(queryParams);

        if(method == 'post'){
            return this.http.post(url,body,{headers:headers,params:parameters,observe:'response'}).toPromise().then(this.onCustomRequestFinished).catch(this.onCustomRequestError)
        }else if(method == 'get'){
            return this.http.get(url,{headers:headers,params:parameters,observe:'response'}).toPromise().then(this.onCustomRequestFinished).catch(this.onCustomRequestError)
        }else if(method == 'put'){
            return this.http.put(url,body,{headers:headers,params:parameters,observe:'response'}).toPromise().then(this.onCustomRequestFinished).catch(this.onCustomRequestError)
        }else if(method == 'delete'){
            return this.http.delete(url,{headers:headers,params:parameters,observe:'response'}).toPromise().then(this.onCustomRequestFinished).catch(this.onCustomRequestError)
        }
    }
    private onCustomRequestFinished = (responseData:HttpResponse<any>) => {
        // console.log(responseData);
        this.refreshToken(responseData.headers);
        return responseData.body.data;
    };
    private onCustomRequestError = (error:any) => {
        console.log(error);
        this.willLogoutUser(error);
        throw error;
    };

    async custom(body: any,params:any={}) : Promise<any> {

        let header = await this.buildHeader();
        // console.log("Custom Request",body);
        return this.http.post(this.apiPath,body,{headers:header,observe: 'response',params:params}).toPromise().then(
            (response: HttpResponse<any>) => {
                // console.log(response);
                this.refreshToken(response.headers);
                return response.body.data;
            }
        ).catch( reason => {
            this.willLogoutUser(reason);
            throw reason;
        });

    }
}
