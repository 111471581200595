import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSlideToggleChange} from '@angular/material';
import {Incident} from '../../../models/incident.model';
import {Company} from '../../../models/company.model';
import {IncidentService} from '../../../services/incident.service';
import {
  NgxLoopVehicleLiveMap,
  ResourceMap
} from '../../../../../projects/ngx-loop-components/src/lib/ngx-loop-vehicle-livemap/ngx-loop-vehicle-livemap';
import {Router} from '@angular/router';

@Component({
  selector: 'app-incident-panel',
  templateUrl: './incident-panel.component.html',
  styleUrls: ['./incident-panel.component.css']
})
export class IncidentPanelComponent implements OnInit,AfterViewInit {

  @Input()
  liveMap : NgxLoopVehicleLiveMap

  allIncidentsVisible : boolean = false;
  heatmap: boolean = false;

  form : FormGroup;

  selectedCompanies : Company[] = null;
  loading : boolean = false;

  LAYERNAME = 'incidents_panel';
  HEATLAYERNAME = 'heat_incidents';

  incidentsMap: ResourceMap<Incident>[] = [];

  constructor(
    private router: Router,
    public formBuilder : FormBuilder,
    public incidentService: IncidentService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit() {
    this.liveMap.createClusterLayer(this.LAYERNAME);
    this.liveMap.hideLayer(this.LAYERNAME);

    this.liveMap.createHeatLayer(this.HEATLAYERNAME,{});

    this.downloadIncidents();
  }

  initForm() {

    let defaultStartDate = new Date();
    defaultStartDate.setDate(defaultStartDate.getDate()-10);

    this.form = this.formBuilder.group({
      period : [ {begin:defaultStartDate,end:new Date()},Validators.required]
    })
  }

  setSelectedCompanies(companies:Company[]){
    this.selectedCompanies = companies;
  }

  toggleAllIncidentsVisible() {
    this.allIncidentsVisible = !this.allIncidentsVisible
    if(this.allIncidentsVisible){
      if(this.heatmap){
        this.liveMap.showHeatLayer(this.HEATLAYERNAME);
      }else{
        this.liveMap.showLayer(this.LAYERNAME);
      }
    }else{
      if(this.heatmap){
        this.liveMap.hideHeatLayer(this.HEATLAYERNAME);
      }else{
        this.liveMap.hideLayer(this.LAYERNAME);
      }
    }
  }

  toggleHeatMap(event:MatSlideToggleChange) {
    this.heatmap = event.checked;
    if(!this.allIncidentsVisible){
      return;
    }
    if(this.heatmap){
      this.liveMap.hideLayer(this.LAYERNAME);
      this.liveMap.showHeatLayer(this.HEATLAYERNAME);
    }else{
      this.liveMap.hideHeatLayer(this.HEATLAYERNAME);
      this.liveMap.showLayer(this.LAYERNAME);
    }
  }

  enableIfDisabled() {
    if(!this.allIncidentsVisible){
      this.allIncidentsVisible = true;
      if(this.heatmap){
        this.liveMap.showHeatLayer(this.HEATLAYERNAME);
      }else{
        this.liveMap.showLayer(this.LAYERNAME);
      }
    }
  }

  onButtonClick(){
    this.enableIfDisabled();
    this.downloadIncidents();
  }

  downloadIncidents() {

    let body = null;

    if(this.selectedCompanies){
      body = {};
      body['companies_id'] = this.selectedCompanies.map(c => c.id);
    }

    let period = this.form.get('period').value;

    let date_start = period.begin.format('YYYY-MM-DD');
    let date_end = period.end.format('YYYY-MM-DD');

    this.loading = true;
    this.incidentService.getMapIncidents(date_start,date_end,body).then((res) => {
      this.onIncidentsDownloaded(res);
    }).finally(()=>{
      this.loading = false;
    });
  }

  onIncidentMarkerClicked = (incident:Incident) => {

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['incidents/' + incident.id],{queryParams:{fromMap:true}})
    );

    window.open(url, '_blank');
  }

  onIncidentsDownloaded(incidents:Incident[]) {

    this.incidentsMap = [];

    this.liveMap.cleanLayer(this.LAYERNAME);

    if(this.heatmap)
      this.liveMap.hideHeatLayer(this.HEATLAYERNAME);

    this.liveMap.createHeatLayer(this.HEATLAYERNAME,{minOpacity:0.4});

    if(this.heatmap)
      this.liveMap.showHeatLayer(this.HEATLAYERNAME);

    incidents.forEach(i => {

      let marker = this.liveMap.buildResourceMarker('../../../../assets/images/alert_circle.png',i.lat,i.lng,'alert-icon',[15,15])

      marker.on('click',()=>{
        this.onIncidentMarkerClicked(i);
      })

      let resource : ResourceMap<Incident> = {
        editable: false,
        highlighted: false,
        marker: marker,
        polygon: null,
        polyline: null,
        resource: i,
        visible: true,
      };
      this.liveMap.getLayer(this.LAYERNAME).addLayer(resource.marker);
      this.liveMap.getHeatLayer(this.HEATLAYERNAME).addLatLng(resource.marker.getLatLng());
      this.incidentsMap.push(resource);
    });

  }

}
