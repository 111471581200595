import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatSnackBar, MatSnackBarConfig, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { UserModel } from 'src/app/models/user.model';
import { AccessLogService } from 'src/app/services/access-log.service';
import {TranslateService} from '../../../../../projects/ngx-loop-components/src/lib/translate.service';

export interface DialogData {
  user: UserModel
}

export interface LogData {
  ts: number,
  memo: string
}

@Component({
  selector: 'app-access-log',
  templateUrl: './access-log.component.html',
  styleUrls: ['./access-log.component.css']
})
export class AccessLogComponent implements OnInit {

  loading: boolean = false;
  dataSource = new MatTableDataSource<LogData>();
  displayedColumns: string[] = ['ts','memo'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private accessLogService: AccessLogService,
    public dialogRef: MatDialogRef<AccessLogComponent>,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.downloadUserLogs();
  }

  downloadUserLogs(){
    this.loading = true;
    this.accessLogService.getLogs(this.data.user.id)
      .then((logs: LogData[]) => {
        this.dataSource.data = logs;
      })
      .catch((e) => {
        console.log("ERROR",e)
        this.openSnackBar(this.translate.data['msg.erro'],"error");
      })
      .finally(() => {
        this.loading = false;
      })
  }

  public openSnackBar(message: string, type: string) {
    let config: MatSnackBarConfig = {
      panelClass: 'snack_' + type,
      duration: 6000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    };
    this._snackBar.open(message, 'Ok', config);
  }

  close(){
    this.dialogRef.close();
  }

}
