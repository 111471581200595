<mat-expansion-panel #masterPanel >
  <mat-expansion-panel-header class="expansion-panel">
    <app-ngx-medium-toggle
      [checked]="liveMap.getVehiclesVisibility()"
      (onValueChange)="toggleAllVehiclesVisibility()"
      [title]="'menu.vehicles' | translate"
    >
    </app-ngx-medium-toggle>
  </mat-expansion-panel-header>
  <div class="vehicle-list-header">
    <div class="mx-2 mt-1 mb-2 aux-button d-flex flex-row align-items-center " >
      <div>
        <app-ngx-small-toggle
          [checked]="clustered"
          (onValueChange)="onClusterModeChanged($event)"
          [title]="'label.clusters' | translate"
        >
        </app-ngx-small-toggle>
      </div>
      <div class="ml-3">
        <app-ngx-small-toggle
          [checked]="showNames"
          (onValueChange)="onNameModeChanged($event)"
          [title]="'label.name' | translate"
        >
        </app-ngx-small-toggle>
      </div>
    </div>

    <div class="search_input_group mx-1 mb-1">
      <i class="mdi mdi-magnify h5 mb-0"></i>
      <input class="search_input w-100" placeholder="{{'dt.search' | translate}}:" (input)="vehicleFilter($event)" />
    </div>
    <app-ngx-loop-vehicle-panel-group
      *ngFor="let group of groupsByType;"
      [group]="group"
      [liveMap]="liveMap"
      [showTrackIt]="enableTrackTt"
      (onTrackItPressed)="onTrackItPressed($event)"
      [showVehicleEditionButton]="showVehicleEditionButton"
      (onVehicleEditButtonClicked)="onVehicleEditButtonClicked.emit($event)"
    ></app-ngx-loop-vehicle-panel-group>
  </div>

</mat-expansion-panel>
<div *ngIf="trackItVehicle">
  <ngx-track-it-panel (ref)="trackItPanel=$event" (onTrackItChanged)="onTrackItPeriodChanged.emit($event)"
                      [additionalFields]="trackItAdditionalFields" [liveMap]="liveMap" (onClosed)="trackItVehicle=null" [trackItVehicleSetter]="trackItVehicle"></ngx-track-it-panel>
</div>
