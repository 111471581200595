import {BaseModel} from './base.model';

export class Company extends BaseModel{
    constructor(
        public sid?: string,
        public business?: any,
        public nome?: string,
        public domain?: string,
        public timezone?: string,
        public integrado?: number,
        public firstweekday?: string,
        public googleapikey?: string,
        public unit_speed?: any,
        public unit_fuel?: any,
        public unit_vol?: any,
        public unit_dist?:any,
        public unit_temperature?:any,
        public dealers?: any[],
        public ts_disable?:string,
        public blocked?: boolean,
        public total_users?: any,
        public total_vehicles?: any,
        public cached_storage?: any,
        public production_time?: number,
        public id_dealer?: number
    ){ super(); }

    static fromJson(json:any) : Company{
        let company: Company = new Company();
        company = Object.assign(company, json);
        return company;
    }
}
