import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { VehicleCategory } from '../models/vehicle-category.model';

@Injectable({
    providedIn: 'root'
})
export class VehicleCategoryService extends BaseService<VehicleCategory> {

    constructor(protected injector: Injector) {
        super(injector, 'core/vehicle-categories', VehicleCategory.fromJson);
    }
}
