import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatExpansionPanel, MatSort, MatTableDataSource} from '@angular/material';
import {
  NgxLoopVehicleLiveMap, ResourceMap,
  VehicleMap
} from '../../../../../projects/ngx-loop-components/src/lib/ngx-loop-vehicle-livemap/ngx-loop-vehicle-livemap';
import {SftGeofenceModel} from '../../../models/sft-geofence.model';
import {SftGeofenceService} from '../../../services/sft-geofence.service';
import {Vehicle} from '../../../models/vehicle.model';
import {VehicleTypeModel} from '../../../models/vehicleType.model';
import {FilterTableController} from '../../../controllers/filter-table';
import {PreferencesService} from '../../../../../projects/ngx-loop-components/src/lib/preferences.service';
import {GeofenceFormDialogComponent} from '../geofence-form-dialog/geofence-form-dialog.component';
import {PermissionService} from '../../../services/permission.service';
import {ConfirmationDialogComponent} from '../../../components/confirmation-dialog/confirmation-dialog.component';
import {TranslateService} from '../../../../../projects/ngx-loop-components/src/lib/translate.service';
import {GeofenceImportService} from '../../../services/geofence-import.service';
import {FilesUtil} from '../../../utils/files-util';
import {CompanyService} from '../../../services/company.service';
import {
  GeofenceCompanySharingDialogComponent,
  GeofenceSharingDialogData
} from '../geofence-company-sharing-dialog/geofence-company-sharing-dialog.component';
import {VehicleAssignDialogComponent} from '../../event/vehicle-assign-dialog/vehicle-assign-dialog.component';
import {Company} from '../../../models/company.model';

export class TypeGeofenceGroup {

  dataSource: MatTableDataSource<ResourceMap<SftGeofenceModel>> = new MatTableDataSource();

  constructor(
    public geofences: ResourceMap<SftGeofenceModel>[],
    public keylang: string,
    public expand?: boolean,
    public panel?: MatExpansionPanel
  ){
    this.dataSource.data = this.geofences;
  }

  setPanel(panel: MatExpansionPanel) {
    this.panel = panel;
  }

  open() {
    this.panel.open();
  }
}

@Component({
  selector: 'app-geofence-panel',
  templateUrl: './geofence-panel.component.html',
  styleUrls: ['./geofence-panel.component.css']
})
export class GeofencePanelComponent implements OnInit, AfterViewInit {

  dataSource: MatTableDataSource<ResourceMap<SftGeofenceModel>> = new MatTableDataSource();

  displayedColumns: string[] = ['name','speed','type','options'];

  allGeofencesVisible : boolean = true;

  @Input()
  public expanded: boolean = false;

  geofences: SftGeofenceModel[] = [];
  geofenceMap: ResourceMap<SftGeofenceModel>[] = [];
  selectedGeofence: ResourceMap<SftGeofenceModel> = null;

  typesGrouped: TypeGeofenceGroup[] = [];

  @Input()
  liveMap: NgxLoopVehicleLiveMap;

  @ViewChild('parentPanel', {static: true})
  parentPanel: MatExpansionPanel;

  public LAYERNAME = "safety_geofences";
  layerVisible = true;

  loading : boolean = false;

  public enableEdition: boolean = true;

  public selectedCompanies: Company[] = null;

  constructor(
    private sftGeofenceService: SftGeofenceService,
    public preferencesService: PreferencesService,
    public permissionService: PermissionService,
    public companyService: CompanyService,
    public geofenceImportService: GeofenceImportService,
    public translate:TranslateService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {

  }

  setEnableEdition(enabled:boolean){
    this.enableEdition = enabled;
  }

  setSelectedCompanies(companies:Company[]){
    this.selectedCompanies = companies;
  }

  shareGeofences(geofences:SftGeofenceModel[]) {

    let dialogData : GeofenceSharingDialogData = {
      geofences:geofences
    }

    let ref = this.dialog.open(GeofenceCompanySharingDialogComponent,{
      panelClass: 'dialog',
      width: '600px',
      data: dialogData
    });

  }

  shareAllGeofences(){
    let geosToShares : SftGeofenceModel[] = [];
    this.geofenceMap.forEach(geoMap => {
      if(geoMap.visible){
        geosToShares.push(geoMap.resource);
      }
    });
    if(geosToShares.length > 0){
      this.shareGeofences(geosToShares);
    }
  }

  ngAfterViewInit(): void {

    console.log("Setting events",this.liveMap);

    if(this.liveMap.isReady()){
      this.liveMap.createLayer(this.LAYERNAME);
      this.downloadGeofences();
    }

    this.liveMap.onMapInitialized.subscribe(() => {
      this.liveMap.createLayer(this.LAYERNAME);
      this.downloadGeofences();
    });

    this.liveMap.onResourceClicked.subscribe(resource => {
      if(resource){
        this.onGeofencePressed(resource);
      }
    });

    this.liveMap.onPolygonEditionChanged.subscribe(isEditing => {
      if(isEditing){
        this.liveMap.setOpacityToLayer(this.LAYERNAME,0.3,0);
      }else{
        this.liveMap.setOpacityToLayer(this.LAYERNAME,1,0.15);
      }
    });

    this.liveMap.onNewDrawing.subscribe(coords => {
      console.log("CREATED");
      console.log(coords);
      this.openEditGeofenceDialog(null,coords);
    });
    this.liveMap.onEditedDeleted.subscribe(resource => {
      console.log("DELETE");
      console.log(resource);
      this.showDeleteDialog(resource)
    });
    this.liveMap.onEditedDrawing.subscribe(resource => {
      console.log("EDITED");
      console.log((resource));
      resource.resource.only_coords = true;
      this.sftGeofenceService.update(resource.resource,resource.resource.id).then(()=>{
        this.downloadGeofences();
      });
    })
  }

  handleFileInput(files: FileList,event:Event) {

    let file : File = files.item(0);
    event.target['value'] = "";

    this.loading = true;

    FilesUtil.KMLFileToJson(file,(geoJson) => {
      if(geoJson != null){
        this.geofenceImportService.sendKMLData(geoJson).then((newGeofences) => {
          this.downloadGeofences();
        }).finally(()=>{
          this.loading = false;
        });
      }else{
        this.loading = false;
      }
    });
  }

  downloadGeofences(){
    console.log("DOWNLOADING GEO");

    this.loading = true;
    let params = {};

    if(this.selectedCompanies != null){
      params['companies_id'] = this.selectedCompanies.map(c => c.id);
    }

    this.sftGeofenceService.getGeofences(params).then(geofences => {
      this.liveMap.cleanLayer(this.LAYERNAME);

      this.geofences = geofences;
      this.geofenceMap = [];

      geofences.forEach(geofence => {

        let customColor = null;

        if(geofence.type != null)
          customColor = geofence.type.color;

        let mapping : ResourceMap<SftGeofenceModel> = {
          marker: null,
          polygon: null,
          polyline: null,
          resource: geofence,
          visible: true,
          highlighted: false,
          editable: true,
          customColor: customColor
        };

        this.geofenceMap.push(mapping);
      });

      this.groupGeofenceByType();
      this.liveMap.drawPolygons(this.LAYERNAME,this.geofenceMap,'coordinates','#3C4B75');
    }).catch(e => {
      console.log(e);
    }).finally(()=>{
      this.loading = false;
    })
  }

  groupGeofenceByType() {
    this.typesGrouped = [];
    let mapping = {};

    this.geofenceMap.forEach(geo => {

      let typeId = '-1';
      let typeKeylang = 'label.geofence_none_type';

      if(geo.resource.type != null){
        typeId = geo.resource.type.id.toString();
        typeKeylang = geo.resource.type.keylang;
      }

      if(mapping[typeId] == null)
        mapping[typeId] = {
          data: [],
          keylang: typeKeylang,
        };
      mapping[typeId].data.push(geo);
    });

    for (let key in mapping) {
      let value = mapping[key];
      this.typesGrouped.push(new TypeGeofenceGroup(value.data,value.keylang,false));
    }
  }

  showDeleteDialog(resource:ResourceMap<SftGeofenceModel>) {

    let dialog = this.dialog.open(ConfirmationDialogComponent,{
      width: '300px',
      data: {
      title: this.translate.data["label.remove"],
        confirmationMessage: this.translate.data["label.sft_remove_geofence"],
        buttonText: this.translate.data['label.remove'],
        iconClass: 'mdi-alert-outline',
        action: () => {
        this.sftGeofenceService.delete(resource.resource.id).then(()=>{
          this.downloadGeofences();
        })
      },
      cancel: () => {
        console.log("CANCELIING",resource.polygon);
        this.liveMap.cleanLayer(this.LAYERNAME);
        this.liveMap.drawPolygons(this.LAYERNAME,this.geofenceMap,'coordinates','#3C4B75');
      }
    }
  });

  }

  toggleAllGeofencesVisibility(){
    this.liveMap.toggleAllResourceVisibility(this.LAYERNAME, this.layerVisible);
    this.layerVisible = !this.layerVisible;
    this.allGeofencesVisible = !this.allGeofencesVisible;
  }

  openEditGeofenceDialog(geofence:SftGeofenceModel,coords?:null){
    let ref = this.dialog.open(GeofenceFormDialogComponent,{
      autoFocus: false,
      width: '500px',
      panelClass: 'dialog',
      data: {
        geofence: geofence,
        coords: coords
      }
    });

    ref.afterClosed().toPromise().then(()=>{
      this.downloadGeofences();
    })
  }

  setStyle(color,geofenceMapping:ResourceMap<SftGeofenceModel>) {
    if(geofenceMapping.polygon){
      geofenceMapping.polygon.setStyle({
        color: color,
        fillColor: color
      })
    }
  }

  onGeofencePressed(geofenceMapping:ResourceMap<SftGeofenceModel>){

    this.toggleSelectedGeofence(geofenceMapping);
    this.parentPanel.open();

    this.typesGrouped.forEach((element: TypeGeofenceGroup) => {
      for (let i = 0; i < element.geofences.length; i++) {
        if (element.geofences[i].resource.id == geofenceMapping.resource.id) {
          element.open();
          break;

        }
      }
    });

    setTimeout(()=>{
      let el = document.getElementById(geofenceMapping.resource.id.toString());
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });

      geofenceMapping.highlighted = true;
      setTimeout(()=>{
        geofenceMapping.highlighted = false;
      },3000);
    },200);
  }

  toggleSelectedGeofence(newSelected:ResourceMap<SftGeofenceModel>){

    if(this.selectedGeofence != null && this.selectedGeofence.resource.id == newSelected.resource.id)
      return;

    if(this.selectedGeofence != null){
      let color = '#3C4B75';
      if(this.selectedGeofence.resource.type)
        color = this.selectedGeofence.resource.type.color;
      this.setStyle(color,this.selectedGeofence);
    }
    this.selectedGeofence = newSelected;
    this.setStyle('#ff7c20',this.selectedGeofence);
  }

  locateGeofence(geofenceMapping:ResourceMap<SftGeofenceModel>){
    console.log(geofenceMapping);
    this.toggleSelectedGeofence(geofenceMapping);
    this.liveMap.locateResource(this.LAYERNAME,geofenceMapping);
  }

  filter(event){
    let text: string = (event.target) ? event.target.value : event.data;

    this.typesGrouped.forEach(group => {
      group.dataSource.data = group.geofences.filter((map: ResourceMap<SftGeofenceModel> ) => {
        let type = map.resource.type;
        let hasGeofence = FilterTableController.textIsContained(map.resource, text, ['name']);
        let hasType = FilterTableController.textIsContained(type, text, ['type']);
        return hasGeofence || hasType;
      })
    });
  }

}
