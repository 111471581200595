import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '../../../../../projects/ngx-loop-components/src/lib/translate.service';

@Component({
  selector: 'app-vehicles-ranking',
  templateUrl: './vehicles-ranking.component.html',
  styleUrls: ['./vehicles-ranking.component.css']
})
export class VehiclesRankingComponent implements OnInit {

  vehiclesRanking: any[] = null;

  @Input()
  title: string;

  @Input()
  height: string;

  constructor(
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  onClick(row:any){
    console.log(row);
    this.router.navigate(['/incidents'],{queryParams:{v:row.vehicle__id}})
  }

  setData(data:any){
    this.vehiclesRanking = data;
  }


}
