import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import { AppComponent } from './app.component';
import { ThemeService } from 'ng2-charts';


import {
  MatTabsModule,
  MatSnackBarModule,
  MatSidenavModule,
  MatFormFieldModule,
  MatProgressBarModule,
  MatToolbarModule,
  MatListModule,
  MatButtonModule,
  MatDialogModule,
  MatDividerModule,
  MatInputModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSortModule,
  MatSliderModule,
  MatTableModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatMenuModule,
  MatSlideToggleModule,
  MatDialogRef, MatChipsModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatDatepickerModule, MatRadioModule, MatExpansionModule, MatBadgeModule

} from '@angular/material';
import {ButtonsModule, InputsModule, NavbarModule, WavesModule} from 'angular-bootstrap-md';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import { LoginComponent } from './pages/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastrModule} from 'ngx-toastr';
import {AngularFireAuth, AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DropdownModule} from 'angular-bootstrap-md';
import {NgxWebstorageModule} from 'ngx-webstorage';
import { ChartsModule } from 'ng2-charts';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { ComponentsModule } from './components/components.module';
import { DateAdapter as SatDateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import {MAT_MOMENT_DATE_FORMATS, MatMomentDateModule, MomentDateAdapter} from '@angular/material-moment-adapter';
import { FullDatePipe, ShortDatePipe, HourPipe, DayMonth, DayPipe } from './pipes/date.pipe';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatTableExporterModule } from 'mat-table-exporter';
import { UserFormDialogComponent } from './pages/user/user-form-dialog/user-form-dialog.component';
import { AccessLogComponent } from './pages/user/access-log/access-log.component';
import { DatePipeModule } from './pipes/datepipe.module';
import {LiveVehiclesMapComponent} from './pages/live-vehicles-map/live-vehicles-map.component';
import {DashboardModule} from './pages/dashboard/dashboard.module';
import {UserModule} from './pages/user/user.module';
import {NgxLoopModule, setupTranslateFactory} from '../../projects/ngx-loop-components/src/lib/ngx-loop.module';
import {TranslateService} from '../../projects/ngx-loop-components/src/lib/translate.service';
import {TranslatePipe} from '../../projects/ngx-loop-components/src/lib/translate.pipe';
import { GeofenceFormDialogComponent } from './pages/live-vehicles-map/geofence-form-dialog/geofence-form-dialog.component';
import { GeofencePanelComponent } from './pages/live-vehicles-map/geofence-panel/geofence-panel.component';
import { GeofenceRulesComponent } from './pages/live-vehicles-map/geofence-form-dialog/geofence-rules/geofence-rules.component';
import { GeofenceExpansionPanelComponent } from './pages/live-vehicles-map/geofence-panel/geofence-expansion-panel/geofence-expansion-panel.component';
import { GeofenceCompanySharingDialogComponent } from './pages/live-vehicles-map/geofence-company-sharing-dialog/geofence-company-sharing-dialog.component';
import { IncidentPanelComponent } from './pages/live-vehicles-map/incident-panel/incident-panel.component';
import { ConsequenceManagerGuard } from './services/auth-guard.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AccessLogComponent,
    LiveVehiclesMapComponent,
    GeofenceFormDialogComponent,
    GeofencePanelComponent,
    GeofenceRulesComponent,
    GeofenceExpansionPanelComponent,
    GeofenceCompanySharingDialogComponent,
    IncidentPanelComponent
  ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        BrowserModule,
        NavbarModule,
        RouterModule,
        InputsModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonsModule,
        DropdownModule.forRoot(),
        WavesModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        ToastrModule.forRoot(),
        NgxWebstorageModule.forRoot(),
        ChartsModule,
        LeafletModule.forRoot(),
        LeafletDrawModule.forRoot(),
        ComponentsModule,
        NgxLoopModule,
        MatTabsModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatProgressBarModule,
        MatToolbarModule,
        MatListModule,
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatInputModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatSliderModule,
        MatTableModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatNativeDateModule,
        MatDatepickerModule, MatRadioModule,
        MatTableExporterModule,
        DatePipeModule,
        UserModule,
        DashboardModule, MatExpansionModule, NgxLoopModule, NgxLoopModule, SatDatepickerModule,
    ],
  providers: [
    HourPipe,
    AngularFireAuth,
    NgxLoopModule,
    ThemeService,
    TranslateService, {
        provide: APP_INITIALIZER,
        useFactory: setupTranslateFactory,
        deps: [TranslateService],
        multi: true,
    },
    {provide: SatDateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {
        provide: MatDialogRef,
        useValue: {}
    },
    {
        provide: 'environment',
        useValue: environment
    },
    ConsequenceManagerGuard
  ],
  bootstrap: [AppComponent],
  exports: [
    TranslatePipe,
  ],
  entryComponents: [
    UserFormDialogComponent,
    AccessLogComponent,
    GeofenceFormDialogComponent,
    GeofenceCompanySharingDialogComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
