import {Injectable, Injector} from '@angular/core';
import {Vehicle} from '../models/vehicle.model';
import {environment} from '../../environments/environment';
import {EventVehicleAssignModel} from './event-vehicle.service';
import {BaseService} from './base.service';

@Injectable({
  providedIn: 'root'
})
export class GeofenceSharingServiceService extends BaseService<any> {

  constructor(public injector: Injector) {
    super(injector, '', ()=>{});
  }

  async assignCompanies(geofencesId:number[],companiesId:number[]) : Promise<any> {
    let body = {
      geofences_id: geofencesId,
      companies_id: companiesId
    }
    let path = environment.api + 'safety/geofence-company';
    return this.customPost(body,path)
  }}
