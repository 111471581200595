import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Keylang} from '../models/keylang.model';

@Injectable({
    providedIn: 'root'
})
export class KeylangService extends BaseService<Keylang> {

    constructor(public injector: Injector) {
        super(injector, 'core/keylangs', Keylang.fromJson);
    }

    async createMany(keylangList: Keylang[]) {
        let path = environment.api + 'core/keylangs-many';
        let headers = await this.buildHeader();

        return this.http.post(path, keylangList, {headers: headers, observe: 'response'}).toPromise().then(
            (res: HttpResponse<{data: Keylang[]}>) => {
                this.refreshToken(res.headers);

                return res.body.data;
            }).catch(e => {
            this.willLogoutUser(e);
            throw e;
        });
    }

    async getLanguages() {
        let path = environment.api + 'core/keylang-languages';

        return this.http.get(path, {observe: 'response'}).toPromise().then(
            (res: HttpResponse<{data: any[]}>) => {
                return res.body.data;
            }).catch(e => {
            throw e;
        });
    }

}
