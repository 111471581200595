import {BaseService} from './base.service';
import {Injectable, Injector} from '@angular/core';
import {SftGeofenceType} from '../models/sft-geofence.model';


@Injectable({
    providedIn: 'root'
})

export class GeofenceTypeService extends BaseService<SftGeofenceType> {

    constructor(protected injector: Injector) {
        super(injector, 'safety/geofence-type', SftGeofenceType.fromJson);
    }
}
