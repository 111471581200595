import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';
import {DriverInterval} from '../models/driver-interval';
import {SftEvent} from '../models/event.model';
import {Driver} from '../models/driver.model';




@Injectable({
  providedIn: 'root'
})
export class DriverIntervalService extends BaseService<DriverInterval> {

  constructor(public injector: Injector) {
    super(injector, 'core/driver-interval', DriverInterval.fromJson);
  }
}
