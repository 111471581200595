import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SAFETY_BUSINESS_ID} from '../../app.component';
import {BrokerService} from '../../services/broker.service';
import {VehicleMap} from '../../../../projects/ngx-loop-components/src/lib/ngx-loop-vehicle-livemap/ngx-loop-vehicle-livemap';
import {Howl} from 'howler';
import {ConfigurationService} from '../../services/configuration.service';
import {MatButtonToggleChange, MatSlideToggleChange} from '@angular/material';
import {PreferencesService} from '../../../../projects/ngx-loop-components/src/lib/preferences.service';
import {Router} from '@angular/router';
const BUSINESS = SAFETY_BUSINESS_ID;

export interface AlertMessage {
  timestamp:number,
  title:string,
  unread:boolean,
  details:{
    vehicle_name:string,
    event_name:string,
    id_incident:number,
    tag_name_keylang:string
  }
}

@Component({
  selector: 'app-alert-viewer',
  templateUrl: './alert-viewer.component.html',
  styleUrls: ['./alert-viewer.component.css']
})
export class AlertViewerComponent implements OnInit,OnDestroy {

  @Input()
  topicSuffix : string;

  alerts: AlertMessage[] = [];

  newMessage: boolean = false;
  isOpen: boolean = false;
  soundEnabled: boolean;
  unreadCount: number = 0;

  sound;

  constructor(
    private brokerService: BrokerService,
    private configurationService: ConfigurationService,
    private preferencesService: PreferencesService,
    private router: Router
  ) {
    this.sound= new Audio("/assets/sounds/alert.mp3");
    this.soundEnabled = configurationService.getConfigValue('general','notification_sound')
  }

  onClosed(){
    this.isOpen = false;
    this.cleanUnreadAlerts();
  }

  onSoundCheckedChange(event:MatSlideToggleChange){
    this.soundEnabled = event.checked;
    this.configurationService.updateConfiguration('general',['notification_sound'],[this.soundEnabled]).then(()=>{})
  }

  clickAnywhere(event:MouseEvent){
    event.stopPropagation();
  }

  onOpened(){
    this.isOpen = true;
  }

  isSameRoute() : boolean {
    try{
      let currentLocal = this.router.url;
      let parts = currentLocal.split('/');
      return parts[1] == 'incidents' && parts.length == 3;
    }catch (e) {
      return false;
    }
  }

  cleanUnreadAlerts(){
    this.unreadCount = 0;
    this.alerts.forEach(a => {
      a.unread = false;
    });
  }

  clean(event:MouseEvent) {
    event.stopPropagation();
    this.alerts = [];
  }

  onNewAlert(alert:AlertMessage){

    if(!this.isOpen){
      this.playSound();
    }

    this.unreadCount++;
    alert.unread = true;

    this.alerts.unshift(alert);
    this.activateAlertAnimation();
  }

  playSound(){
    if(this.soundEnabled){
      this.sound.play();
    }
  }

  activateAlertAnimation(){
    if(!this.newMessage){
      this.newMessage = true;
      setTimeout(()=>{
        this.newMessage = false;
      },3000)
    }
  }

  getAlertDatetime(alert:AlertMessage){
    let m = this.preferencesService.timestampToMoment(alert.timestamp);
    return m.format('YYYY-MM-DD HH:mm:ss')
  }

  clickDetails(event:MouseEvent,alert:AlertMessage){
    event.stopPropagation();
    this.openDetails(alert);
  }

  openDetails(alert:AlertMessage){

    if(this.isSameRoute()){
      this.router.navigate(['/']).then(() => {
        this.router.navigate([`/incidents/${alert.details.id_incident}`]);
      });
    }else{
      this.router.navigate([`/incidents/${alert.details.id_incident}`]);
    }
  }

  ngOnInit() {
    this.brokerService.startForComponent(this.topicSuffix,(canConnect) => {
      if(canConnect){
        this.brokerService.subscribe(this.topicSuffix,1,(message) => {
          this.onNewAlert(message);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.brokerService.destroyForComponent(this.topicSuffix);
  }

  getFlashingAnimation() : string {
    return this.newMessage ? 'flashit' : '';
  }

}
