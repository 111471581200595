import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
// @ts-ignore
import moment, {Moment} from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDatepicker} from '@angular/material';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {FormControl} from '@angular/forms';

export interface DateRangeData {
    start: string,
    end:string,
    timeBegin?:any,
    timeEnd?:any
}

const keylangRefs : string[] = [
  "label.january",
  "label.february",
  "label.march",
  "label.april",
  "label.may",
  "label.june",
  "label.july",
  "label.august",
  "label.september",
  "label.october",
  "label.november",
  "label.december"
];

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-month-selectorv2',
  templateUrl: './month-selector.component.html',
  styleUrls: ['./month-selector.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class MonthSelectorV2Component implements OnInit {

  @Input()
  numberOfButtons: number = 3;

  @Input()
  showTimePicker : boolean = false

  @Output()
  onDateChanged : EventEmitter<DateRangeData> = new EventEmitter<DateRangeData>();

  currentDate: Date;
  values: DateRangeData[];
  labels: string[];

  selectedValue : DateRangeData;
  selectedRange = null;

  selectedStartTime : any = null;
  selectedEndTime: any = null;

  constructor() {
    this.values = [];
  }

  ngOnInit() {
    this.currentDate = new Date();
    this.calculatePreviousMonths();
  }

  setTimeRange(startTime:string,endTime:string) {
    this.selectedStartTime = startTime;
    this.selectedEndTime = endTime;
  }

  setRange(period:DateRangeData) {
    this.selectedValue = period;

    let momentStart = moment(period.start,'YYYY-MM-DD');
    let momentEnd = moment(period.end,'YYYY-MM-DD');

    this.selectedRange = {
      begin: momentStart,
      end: momentEnd
    }
  }

  onSearchClicked() {
    this.injectTimeDataToValue(this.selectedValue);
    this.onDateChanged.emit(this.selectedValue);
  }

  onRangeSelected(event) {
      let begin = event.value.begin;
      let end = event.value.end;

      let value : DateRangeData = {
        start:begin.format('YYYY-MM-DD'),
        end:end.format('YYYY-MM-DD'),
      };

      this.injectTimeDataToValue(value);

      this.selectedValue = value;
      this.onDateChanged.emit(this.selectedValue);
  }

  getMonthRangeOfCurrentDate(momentDate) : DateRangeData {

    const startOfMonth = momentDate.clone().startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = momentDate.clone().endOf('month').format('YYYY-MM-DD');

    return {start:startOfMonth,end:endOfMonth}
  }

  calculatePreviousMonths() {

    this.values = [];
    this.labels = [];

    let momentDate = moment(this.currentDate);
    let firstValue : DateRangeData = this.getMonthRangeOfCurrentDate(momentDate);

    this.values.push(firstValue);
    this.labels.push(keylangRefs[momentDate.month()]);
    this.selectedValue = this.values[0];

    let added : number = 1;

    while(added < this.numberOfButtons){

      momentDate.subtract(1,'month');
      this.values.push(this.getMonthRangeOfCurrentDate(momentDate));
      this.labels.push(keylangRefs[momentDate.month()]);

      added++;
    }

    this.values = this.values.reverse();
    this.labels = this.labels.reverse();
  }

  public getSelectedValue() : DateRangeData {
    this.injectTimeDataToValue(this.selectedValue);
    return this.selectedValue;
  }

  public onValueChange(value:DateRangeData){

    this.injectTimeDataToValue(value);

    this.selectedValue = value;
    this.onDateChanged.emit(this.selectedValue);
    this.selectedRange = null;
  }

  injectTimeDataToValue(value:DateRangeData) {

    let start : string = this.selectedStartTime;
    let end : string = this.selectedEndTime;

    if(start == '')
      start = null;

    if(end == '')
      end = null;

    value.timeBegin = start;
    value.timeEnd = end;

  }

}
