<div class="dialog_container" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="dialog_header px-3 py-2" cdkDragHandle>
        <h3 class="d-inline-flex mb-0" style="color:var(--foreground-default);font-size: 1.0em; font-weight: 400;">
          {{title}}
        </h3>
        <i (click)="close()" style="color:var(--foreground-default)" class="mdi mdi-close"></i>
    </div>
    <form [formGroup]="form" class="scroller border-top px-3 py-4 dialog_content" style="width:400px;border-top-color:var(--table-border)!important;background-color: var(--background-table-body)!important;">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-12">
                    <p class="mb-3" *ngIf="message != null && message != ''">{{message}}</p>
                    <div loopFormField [attr.valid]="!checkFieldValidator('justification')">
                        <span>{{'label.message'|translate}} *</span>
                        <textarea loopInput formControlName="justification" style="height:100px;background-color: var(--background-input);color:var(--foreground-default);"></textarea>
                        <i class="mdi mdi-information-outline" *ngIf="checkFieldValidator('justification')" matTooltip="{{'app.required_field' | translate}}"></i>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end px-4 py-3 border-top" style="border-top-color:var(--table-border)!important;">
        <button mat-button color="primary" (click)="close()" class="ml-2">
            {{'k.cancel'|translate}}
        </button>
        <button mat-button color="primary" (click)="save()" class="ml-2" [disabled]="!canSave()">
            {{'nav.salvar'|translate}}
        </button>
    </div>
</div>
