import {Injectable, OnInit} from '@angular/core';

// @ts-ignore
import * as moment from 'moment';
import { UserModel as User } from '../models/user.model';
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class LocalTimeService implements OnInit {

    user:User;

    constructor(
        private userService: UserService
    ) { }

    ngOnInit() {
        this.userService.getUserStoreObservable()
            .subscribe((next: User) => {
                this.user = next;
            });
    }

    getLocaltime() : any {
        this.user = this.userService.getUserStore();
        let date: any;
        if(!this.user){
            date =  moment(new Date());
        }
        else{
            date = moment(new Date());
        }
        return date;
    }
}
