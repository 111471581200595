<div mat-dialog-title class="d-flex flex-row">
    <span class="mb-0 text-bold" style="color:var(--foreground-default)">{{data.title}}</span>
</div>
<div mat-dialog-content class="d-flex flex-column align-items-center">
    <i [ngClass]="['mdi', data.icon]" [ngStyle]="{'color': data.iconColor}" style="font-size: 4rem;"></i>
    <p class="mb-0 text-center" style="color:var(--foreground-default)">{{data.message}}</p>
</div>
<div mat-dialog-actions class="mt-2 d-flex flex-row justify-content-end">
    <button mat-button [matDialogClose]="null" style="color:var(--foreground-default)">
        {{'label.ok'|translate}}
    </button>
</div>
