import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {VehicleMapGroup} from '../ngx-loop-map-vehicle-panel/ngx-loop-map-vehicle-panel';
import {NgxLoopVehicleLiveMap, VehicleMap} from '../ngx-loop-vehicle-livemap/ngx-loop-vehicle-livemap';
import {MatCheckboxChange, MatExpansionPanel} from '@angular/material';

@Component({
  selector: 'app-ngx-loop-vehicle-panel-group',
  templateUrl: './ngx-loop-vehicle-panel-group.html',
  styleUrls: ['./ngx-loop-vehicle-panel-group.css']
})
export class NgxLoopVehiclePanelGroup implements OnInit,AfterViewInit {

  @ViewChild('panel',{static:false})
  panel: MatExpansionPanel;

  @Input()
  liveMap: NgxLoopVehicleLiveMap;

  @Input()
  group: VehicleMapGroup;

  @Input()
  showTrackIt: boolean = false;

  @Input()
  showVehicleEditionButton: boolean = false;

  @Output()
  onVehicleEditButtonClicked: EventEmitter<VehicleMap> = new EventEmitter<VehicleMap>();

  @Output()
  onTrackItPressed: EventEmitter<VehicleMap> = new EventEmitter<VehicleMap>();

  constructor() {
  }

  ngAfterViewInit(): void {
    this.group.setPanel(this.panel);
  }

  ngOnInit(): void {

  }

  onSectionCheckboxChanged(checkChange:MatCheckboxChange){
    this.group.vehicles.forEach(vm => {
      if(checkChange.checked){
        this.liveMap.showVehicleMarker(vm);
      }
      else{
        this.liveMap.hideVehicleMarker(vm);
      }
    });
    this.group.setCheckState();
  }

}
