<div class="background-login"></div>
<div class="bg-helper"></div>
<div class="principal-container container">
  <div class="row d-flex justify-content-center">
    <div class="col-12 col-md-8 col-lg-6 col-xl-6 p-5">
      <div class="z-depth-5 rounded overflow-hidden mt-5">
        <mat-progress-bar *ngIf="loading" mode="indeterminate" color="accent">
        </mat-progress-bar>
        <div class="py-3 px-4" style="background-color: var(--login-bg-card);">
          <div class="d-flex flex-row justify-content-center mb-1 mt-2">
          <img alt="logo" style="width: 180px;height: auto" src="../../../assets/images/{{themeService.active.login_logo_asset}}">
          </div>
          <form [formGroup]="form" >
            <mat-form-field class="w-100 mb-2">
              <mat-label>
                <i class="mdi mdi-email-outline "></i>
                Email
              </mat-label>
              <input matInput id="email" type="email" formControlName="email" placeholder="email" required >
              <mat-error *ngIf="form.get('email').invalid && (form.get('email').dirty || form.get('email').touched)">
                {{'msg.invaliddata' | translate}}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="w-100 mb-2">
              <mat-label>
                <i class="mdi mdi-key-outline "></i>
                Password
              </mat-label>
              <input matInput id="password" type="password" formControlName="password" placeholder="Password" required >
              <mat-error *ngIf="form.get('password').invalid && (form.get('password').dirty || form.get('password').touched)">
                {{'msg.invaliddata' | translate}}
              </mat-error>
            </mat-form-field>

            <button (click)="loginPress()" mat-raised-button color="primary" class="w-100 m-auto" id="save-button" style="background-color: var(--primary);font-weight: 600" >
              {{'nav.enter' | translate}}
            </button>

            <div class="d-flex justify-content-center mt-3">
              <p (click)="passwordReset()" style="cursor: pointer;font-weight: 400;font-size: 0.7em;color: var(--login-foreground-default)" class="mb-0">{{'label.pwdreset' | translate}}</p>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>
