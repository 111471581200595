import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {SftGeofenceModel} from '../../../models/sft-geofence.model';
import {SelectorComponent, SelectorConstants} from '../../../components/selector/selector.component';
import {Vehicle} from '../../../models/vehicle.model';
import {Company} from '../../../models/company.model';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatSnackBarConfig} from '@angular/material';
import {EventVehicleService} from '../../../services/event-vehicle.service';
import {TranslateService} from '../../../../../projects/ngx-loop-components/src/lib/translate.service';
import {CompanyService} from '../../../services/company.service';
import {GeofenceSharingServiceService} from '../../../services/geofence-sharing-service.service';

export interface GeofenceSharingDialogData {
  geofences: SftGeofenceModel[];
}

@Component({
  selector: 'app-geofence-company-sharing-dialog',
  templateUrl: './geofence-company-sharing-dialog.component.html',
  styleUrls: ['./geofence-company-sharing-dialog.component.css']
})
export class GeofenceCompanySharingDialogComponent implements OnInit,AfterViewInit {

  @ViewChild('selector', {static: false}) selector: SelectorComponent;

  loading: boolean;
  types: SelectorConstants;

  selectedCompanies: Company[] = [];
  availableCompanies: Company[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GeofenceSharingDialogData,
    public dialogRef: MatDialogRef<GeofenceCompanySharingDialogComponent>,
    private translate: TranslateService,
    private geofenceSharingService: GeofenceSharingServiceService,
    private companyService: CompanyService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.setAvailableCompanies();
  }

  setAvailableCompanies(){

    let currentCompany = this.companyService.retrieveCompany();
    let allCompanies = this.companyService.multipleCompanies;

    allCompanies.forEach(c => {
      if(c.id != currentCompany.id)
        this.availableCompanies.push(c);
    });

    this.selector.setList(this.availableCompanies);
  }

  shareGeofences(){
    let selectedCompaniesId = this.selector.getSelectedItemsId();
    let geofencesIds : number[] = this.data.geofences.map(val => val.id);

    if(selectedCompaniesId.length > 0){

      this.loading = true;
      this.geofenceSharingService.assignCompanies(geofencesIds,selectedCompaniesId).then(()=>{
        this.openSnackBar(this.translate.data['msg.savedData'], 'success');
        this.close();
      }).catch(()=>{
        this.openSnackBar(this.translate.data['msg.error_occurred'],"error");
      })
    }
  }

  openSnackBar(message: string, type:string) {
    let config:MatSnackBarConfig = {
      panelClass:'snack_'+type,
      duration: 6000,
      horizontalPosition:'right',
      verticalPosition:'bottom'
    }
    this._snackBar.open(message, "Ok", config);
  }

  public close(): void {
    this.dialogRef.close();
  }

}
