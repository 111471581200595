import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-justification-dialog',
  templateUrl: './justification-dialog.component.html',
  styleUrls: ['./justification-dialog.component.css']
})
export class JustificationDialogComponent implements OnInit {
  title:string = "";
  message:string = "";
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<JustificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {title:string, message:string, required:boolean},
    private formBuilder:FormBuilder
  ) {
    this.title = this.data.title;
    this.message = this.data.message;

    this.form = this.formBuilder.group({
      justification: [null, ((this.data.required == true)?Validators.required:null)],
    });
  }

  ngOnInit() {}

  checkFieldValidator(field: string): boolean {
    return this.form.get(field).invalid && this.form.get(field).touched;
  }

  canSave() {
    return this.form.valid;
  }

  save() {
    if (this.canSave()) {
      this.close(this.form.get("justification").value)
    }
  }

  close(justification:string=null) {
    this.dialogRef.close(justification);
  }

}
