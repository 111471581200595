<div class="vehicle-list-header">
  <div>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="status" matSortDirection="desc" class="bg-transparent">
      <ng-container matColumnDef="name">
        <th mat-header-cell style="margin-left: 50px" *matHeaderCellDef mat-sort-header>{{'label.name' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex flex-row align-items-center">
            <mat-checkbox color="primary" (change)="toggleVehicleVisibility(element)" [disabled]="!hasPosition(element)" class="vehicle-checkbox" [ngStyle]="{opacity:hasPosition(element)?1:0.5}" [checked]="element.visible"></mat-checkbox>
            <img class="vehicle-icon mr-2 ml-2" [src]="liveMap.getIconsUrl()+element.vehicle.icon"/>
            <span class="ml-2" style="margin-top: 2px;font-size: 9px">{{element.vehicle.name}}</span>
            <i *ngIf="getDriverName(element) != null" class="mdi mdi-steering ml-2" [matTooltip]="getDriverName(element)"></i>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="speed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'nav.speed' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex flex-row align-items-center">
            <span class="ml-2" style="margin-top: 2px;font-size: 9px">{{getCurrentSpeed(element)}}</span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header >{{'label.status' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <i  [class]="'mdi status-icon ' + getStatusIcon(element) +' '+getFlashingAnimation(element)" [matTooltip]="(getLastMessageSince(element) | duration:'day':'second' )" ></i>
        </td>
      </ng-container>
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div class="table_options w-100 d-flex">
            <i *ngIf="showTrackItButton && hasPosition(element)" class="mdi mdi-map-marker-path mr-2" (click)="onTrackItPressed.emit(element)" matTooltip="{{'label.tracking_history' | translate}}"></i>
            <i *ngIf="showVehicleEditionButton" class="mdi mdi-square-edit-outline mr-2" (click)="onVehicleEditButtonClicked.emit(element)" matTooltip="{{'label.edit' | translate }}"></i>
            <i *ngIf="hasPosition(element)" [class]="'ml-auto mdi '+getTrackingIcon(element)" (click)="onTrackingClicked(element)" matTooltip="{{'label.track_vehicle' | translate }}"></i>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" style="background:var(--background-table-header)!important;"></tr>
      <tr mat-row [id]="row.vehicle.id" [class]="'table_cell ' + getFlashingAnimationPanel(row)" *matRowDef="let row; columns: displayedColumns; let index = index" style="background:var(--background-table-body);height: 35px"></tr>
    </table>
  </div>
</div>
