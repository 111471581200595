export class FilterTableController {

    constructor() {}

    static textIsContained(resource: any, text: string, attributes: string[]): boolean{
        let contains = false;
        
        if (resource != null) {
          for(let attr of attributes){
            if(resource[attr] != null && String(resource[attr]).toLowerCase().includes(text.toLowerCase())){
              contains = true;
              break;
            }
          }
          return contains;
        }
      }
} 