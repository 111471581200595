import {Injectable} from '@angular/core';
import {SessionStorageService} from 'ngx-webstorage';


@Injectable({
  providedIn: 'root'
})
export class IntercomService {

  window : any;
  appName: string;

  currentUser: {name:string,email:string,id:number};

  constructor(
    public sessionStorageService:SessionStorageService
  ) {}

  private initObservers(userSessionName:string) {

    this.sessionStorageService.observe(userSessionName).subscribe(user => {
      if(this.currentUser == null && user){
        this.currentUser = user;
        this.start();
      }
      if(!user)
        this.shutDown();

      this.currentUser = user;
    });

    let storedUser = this.sessionStorageService.retrieve(userSessionName);

    if (storedUser){
      this.currentUser = storedUser;
      this.start();
    }
  }

  public init(appName:string,window:any,userSessionName="user") {

    this.window = window;
    this.appName = appName;


    this.initObservers(userSessionName);
  }

  private start() {
    try{
      let data = {
        app_id: "wqzepkof",
        app_name: this.appName,
        name: this.currentUser.name,
        email: this.currentUser.email,
        user_internal_id: this.currentUser.id,
        created_at: new Date().getTime()/1000,
        hide_default_launcher: true,
        custom_launcher_selector:'#custom_help_button'
      };
      this.window.Intercom('boot',data);
    }catch (e) {
      console.log("Itercom Init Failed",e);
    }
  }

  private shutDown() {
    try{
      this.window.Intercom('shutdown')
    }catch (e) {
      console.log("Intercom Shutdown Failed",e);
    }
  }
}
