import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatSlideToggleChange} from '@angular/material';

@Component({
  selector: 'app-ngx-small-toggle',
  templateUrl: './ngx-small-toggle.component.html',
  styleUrls: ['./ngx-small-toggle.component.css']
})
export class NgxSmallToggleComponent implements OnInit {

  @Output()
  onValueChange: EventEmitter<MatSlideToggleChange> = new EventEmitter<MatSlideToggleChange>();

  @Input()
  checked;

  @Input()
  title: string;

  constructor() { }

  ngOnInit() {
  }

}
