<div loopFormField #formElement class="mdi mdi-chevron-down resource-selector" [attr.valid]="isInvalid == true ? false : isValid()">
    <span *ngIf="!hideLabel" [style.color]="labelColor" >{{label}}</span>
    <input #input [disabled]="disabled" [class]="customInputClass" (focus)="_displayList='flex'" (blur)="onBlurSelector()" loopInput type="text" (input)="_filterResources($event)" placeholder="{{'app.search'|translate}}"/>
    <i class="mdi mdi-information-outline" *ngIf="hideErrorIcon && ( !isValid() || isInvalid )" matTooltip="{{'msg.invaliddata' | translate}}"></i>
    <mat-spinner [diameter]="14" color="primary" *ngIf="loading"></mat-spinner>
  <div loopSortedDrop [style.display]="_displayList"  [attr.position]="position" [style.max-height.px]="maxHeight">
        <div tabindex="0" *ngIf="allowCreation" class="action_button" (click)="emitNewItemButtonPressed()">
            <i class="mdi mdi-plus mr-2"></i><span>{{'label.new' | translate}}</span>
        </div>
        <div loopSortedDropOption class="pr-3" *ngFor="let resource of filteredResources; let i = index" [tabindex]="i + 1" (click)="emitItemSelection($event,resource)">
            <ng-template class="mr-auto wrap-content" ngFor let-resource [ngForOf]="[resource]" [ngForTemplate]="itemTemplate"></ng-template>
            <span *ngIf="!customRenderer && !translation" class="mr-auto wrap-content">{{showResourceValue(resource)|translate}}</span>
            <span *ngIf="!customRenderer && translation" class="mr-auto wrap-content">{{showResourceValue(resource) | translate}}</span>
        </div>
  </div>
</div>
<button [disabled]="!canRemove()" type="button" *ngIf="canRemove() || hideRemoveButtonWithOpacity" style="padding: 0px;height: 0px;line-height: 0px; text-align: left;" (click)="cancelSelection()" mat-button color="primary" class="mt-2">
    <span [ngStyle]="{opacity:(hideRemoveButtonWithOpacity && !canRemove())?0:1}" style="font-size: 0.8em" >{{removeSelectedMessage ? removeSelectedMessage : 'label.remove' | translate}}</span>
</button>
