import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit} from '@angular/core';
// @ts-ignore
import {TitleService} from '../../services/title.service';
import {
  NgxLoopVehicleLiveMap,
  VehicleMap
} from '../../../../projects/ngx-loop-components/src/lib/ngx-loop-vehicle-livemap/ngx-loop-vehicle-livemap';
import {GeofencePanelComponent} from './geofence-panel/geofence-panel.component';
import {
  NgxLoopMapVehiclePanel,
  TrackItPeriodChangeEvent
} from '../../../../projects/ngx-loop-components/src/lib/ngx-loop-map-vehicle-panel/ngx-loop-map-vehicle-panel';
import {IncidentService} from '../../services/incident.service';
import {TrackItPointExtras} from '../../../../projects/ngx-loop-components/src/lib/ngx-trackit-panel/ngx-track-it-panel.component';
import {EventService} from '../../services/event.service';
import {SftEvent} from '../../models/event.model';
import {PreferencesService} from '../../../../projects/ngx-loop-components/src/lib/preferences.service';
import {Incident} from '../../models/incident.model';
import moment from 'moment';
import {DriverIntervalService} from '../../services/driver-interval.service';
import {DriverInterval} from '../../models/driver-interval';
import {CompanyReactiveScreen} from '../../components/company-reactive-screen';
import {CompanyService} from '../../services/company.service';
import {Company} from '../../models/company.model';
import {IncidentPanelComponent} from './incident-panel/incident-panel.component';

@Component({
  selector: 'app-live-vehicles-map',
  templateUrl: './live-vehicles-map.component.html',
  styleUrls: ['./live-vehicles-map.component.css']
})

export class LiveVehiclesMapComponent extends CompanyReactiveScreen implements OnInit, OnDestroy,AfterViewInit {

  @ViewChild('liveMap',{static:false}) liveMap : NgxLoopVehicleLiveMap;
  @ViewChild('geofencePanel',{static:false}) geofencePanel : GeofencePanelComponent;
  @ViewChild('vehiclePanel',{static:false}) vehiclePanel : NgxLoopMapVehiclePanel;
  @ViewChild('incidentPanel',{static:false}) incidentPanel : IncidentPanelComponent;

  loading: boolean;

  vehiclesMap:VehicleMap[] = [];
  driverIntervalMapping: any = null;

  constructor(
    public titleService: TitleService,
    public incidentService: IncidentService,
    public preferencesService: PreferencesService,
    public driverIntervalService:DriverIntervalService,
    public companyService:CompanyService
  ) {
    super(companyService);
    this.companyService.setShowAllCompanies(true);
  }

  ngAfterViewInit(): void {

    if(this.companyService.isAllSelected){
      this.onAllCompaniesSelected(this.companyService.multipleCompanies);
    }

    this.downloadDriverIntervals();
  }

  onCompanyChanged() {

    this.liveMap.enableControl();

    this.incidentPanel.setSelectedCompanies(null);
    this.incidentPanel.downloadIncidents();

    this.geofencePanel.setSelectedCompanies(null);

    this.geofencePanel.setEnableEdition(true);

    this.liveMap.setCustomVehiclePostBody(null);
    this.liveMap.cleanAndRebuild();
    this.liveMap.cleanLayer(this.geofencePanel.LAYERNAME);
    this.vehiclePanel.trackItVehicle = null;

    this.geofencePanel.downloadGeofences();
  }

  onAllCompaniesSelected(companies: Company[]) {

    this.liveMap.disableControl();
    this.geofencePanel.setEnableEdition(false);

    this.geofencePanel.setSelectedCompanies(companies);
    this.incidentPanel.setSelectedCompanies(companies);

    let customBody = {
      companies_id : companies.map(c => c.id)
    };
    this.liveMap.setCustomVehiclePostBody(customBody);
    this.liveMap.cleanAndRebuild();

    this.geofencePanel.downloadGeofences();
    this.incidentPanel.downloadIncidents();

    this.vehiclePanel.trackItVehicle = null;
  }

  onVehiclesMapReceived(vehiclesMap:VehicleMap[]){
    this.vehiclesMap = vehiclesMap;
    this.downloadDriverIntervals();
  }

  downloadDriverIntervals(){
    this.driverIntervalService.getResources().then(res => {
      this.driverIntervalMapping = {};
      res.forEach(interval => {
        this.driverIntervalMapping[interval.vehicle.toString()] = interval;
      });
      this.setDriverInfo();
    });
  }

  setDriverInfo(){
    if(this.driverIntervalMapping != null && this.vehiclesMap != null){
      this.vehiclesMap.forEach(vehicleMap => {
        let driverInfo : DriverInterval = this.driverIntervalMapping[vehicleMap.vehicle.id.toString()];
        if(driverInfo != null && driverInfo.driver != null){
          vehicleMap.driverName = driverInfo.driver.nome;
        }
      });
    }
  }

  ngOnInit() {
    this.titleService.setTitle("Live Map");
  }

  ngOnDestroy() {
    this.cancelObservation();
  }

  onTrackItPeriodChanged(event:TrackItPeriodChangeEvent){
    this.downloadVehicleIncidents(event.begin,event.end,event.idVehicle);
  }

  downloadVehicleIncidents(dateStart:string,dateEnd:string,idVehicle:number) {
    this.incidentService.getPeriodVehicleIncidents(idVehicle,dateStart,dateEnd).then(incidents => {
      console.log(incidents);

      let pointExtras : TrackItPointExtras[] = [];

      incidents.forEach(incident => {
        pointExtras.push(this.convertIncidentToPointExtra(incident));
      });

      this.vehiclePanel.setTrackitPointExtras(pointExtras);
    });
  }

  convertIncidentToPointExtra(incident:Incident) : TrackItPointExtras {
    return {
      color: '#f00',
      latitude: incident.lat,
      longitude: incident.lng,
      timestamp: moment(incident.ts_start).utc().valueOf()/1000,
      title: (incident.event as SftEvent).name
    }
  }

}
