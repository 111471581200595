import {BaseModel} from './base.model';
import {Driver} from './driver.model';
import {VehicleCategory} from './vehicle-category.model';


export class Vehicle extends BaseModel {

    constructor(
        public avaiable?: boolean,
        public animate?:boolean,
        public name?:string,
        public type?:any,
        public codbt?: string,
        public company?:any,
        public category?:VehicleCategory | number,
        public model?:string,
        public last_position?:{
          latitude: number,
          longitude: number,
          speed: number,
          timestamp: number,
          direction: number
        },
        public integrated?:boolean,
        public brand?:string,
        public id_flespi?:number,
        public icon?:string,
        public plate?:string,
        public idgps?:string,
        public category_description?:string,
        public capacity?:number,
        public group_name?:number,
        public disabled?:any,
        public max_capacity?: number,
        public max_volume?: number,
        public temperature?:number,
        public last_driver?: Driver | number,
        public ticket?: any,
        public status?: string,
    ){
        super();
        if(animate == null)this.animate = true;
    }

    static fromJson(json:any): Vehicle {
        return Object.assign(new Vehicle(),json);
    }

}
