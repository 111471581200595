import {BaseService} from './base.service';
import {Inject, Injectable, Injector} from '@angular/core';
import { TranslateService } from '../translate.service';
import {Company} from "./company.model";


@Injectable({
    providedIn: 'root'
})

export class ConfigurationService extends BaseService<any> {

    configurations: object = null;
    company: Company = null;

    constructor(
        public injector: Injector,
        @Inject('environment') private env,
        private translateService:TranslateService
    ) {
        super(injector,env, 'core/configuration', () => {});
        this.subscribeChanges();
    }

    async getCompanyConfiguration(): Promise<any> {
        let header = await this.buildHeader();

        return this.http.get(this.apiPath+"-company", {headers: header}).toPromise()
        .then((response: { data: object }) => {
            return response.data;
        }).catch((error) => {
            console.log('Error getting configuration', error);
        });
    }

    subscribeChanges() {
        this.sessionStorage.observe('c').subscribe(next => {
            if(!next){
                this.configurations = next;
                this.company = next;
            }
        })
    }

    getUserConfigurations(){
        let configs = this.configurations;
        if(!configs){
            configs = this.sessionStorage.retrieve("c");
            if (!configs){
                let user = this.sessionStorage.retrieve('user');
                if (user != null) {
                    configs = user.configurations;
                }
            }
        }
        return configs
    }


    getConfigValue(businessName: string, configName: string) {
        try{
            let configurations = this.getUserConfigurations();
            if (configurations){
                let value = configurations[businessName][configName];
                return value;
            }
            return null;
        }
        catch (error) {
            console.log('Could not get value ', error);
            return null;
        }
    }

    updateUserConfigurations(newConfig: object){
        let user = this.sessionStorage.retrieve('user');
        user.configurations = newConfig;
        this.sessionStorage.store('user',user);
    }

    storeConfigurations(configurations: object) {
        this.configurations = configurations;
        this.sessionStorage.store("c", configurations);
    }

    async updateConfiguration(businessName: string, configKeys: string[], configValues: any[], companyConfig: boolean = false): Promise<any> {
        let header = await this.buildHeader();
        let config: object = {};

        if (configKeys.length != configValues.length)
            console.log('!!!! ––– ALERT ––– !!!!', 'Length of Configuration Key differ from Configuration Values');

        configKeys.forEach((key, index) => {
            let keyValue = configValues[index];
            config[key] = keyValue;
        });

        let allConfig = await this.getUserConfigurations();
        let newConfig: object = {};
        newConfig[businessName] = config;

        let body = {
            'update_company': companyConfig,
            'all_config': allConfig,
            'new_config': newConfig
        };
        this.http.put(this.apiPath, body, {headers: header}).toPromise()
            .then((response: { data: object }) => {
                this.updateUserConfigurations(response.data);
                this.storeConfigurations(response.data);
            })
            .catch((error) => {
                console.log('Error updating configuration', error);
            });
    }

    getConfigLocale() {
        let companyStore = this.getUserConfigurations();
        let locale = null;
        let format = this.translateService.lang;

        if (companyStore != null) {
            locale = companyStore['general']['locale'];
            if (locale) {
                format = locale;
            }
        }

        return format;
    }

    getCompany() {
      if (!this.company)
        this.company = this.sessionStorage.retrieve('selectedCompany');
      return this.company;
    }
}
