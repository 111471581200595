<div class="h-100 d-flex flex-column dialog-container">
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="dialog_header px-3 py-2">
    <h3 class="d-inline-flex mb-0" style="font-size: 14pt;font-weight: 400;">{{'label.access_history' | translate}}</h3>
    <i (click)="close()" class="mdi mdi-close"></i>
  </div>

  <div *ngIf="!loading" class="d-flex flex-row-reverse mb-2 mr-3">
    <app-export-button class="ml-3"
    [fileName]="'User Access List'"
    [exporter]="exporter"
    ></app-export-button>
  </div>

  <div [hidden]="loading" class="table-container scroll" style="height:266px">
    <table mat-table [dataSource]="dataSource" matSort matTableExporter #exporter="matTableExporter">

      <ng-container matColumnDef="ts">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'nav.date'|translate}}</th>
        <td mat-cell *matCellDef="let element" class="content-style" style="color:var(--foreground-default)"> {{element.ts | shortDate}} </td>
      </ng-container>

      <ng-container matColumnDef="memo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'app.log_details'|translate}}</th>
        <td mat-cell *matCellDef="let element" class="content-style" style="color:var(--foreground-default)">{{element.memo}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" style="background:var(--background-table-header)"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="background:var(--background-table-body)"></tr>
    </table>
  </div>
  <div class="d-flex px-4 py-3 btn-confirm">
    <button mat-button class="ml-auto" color="primary" [mat-dialog-close]>{{'label.ok' | translate}}</button>
  </div>
</div>