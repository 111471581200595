import { BaseModel } from './base.model';
import {SftGeofenceModel} from './sft-geofence.model';
import {Driver} from './driver.model';
import {VehicleCategory} from './vehicle-category.model';
import {PreferencesService} from '../../../projects/ngx-loop-components/src/lib/preferences.service';
import {Company} from './company.model';
import { Infringement } from './infringement.model';

export class Incident extends BaseModel {

    constructor(
        public coordinates?: any,
        public event?: any,
        public vehicle?: any,
        public ts_start?: any,
        public ts_end?: any,
        public duration?: number,
        public distance?: number,
        public s3_key?: string,
        public driver?: any | Driver,
        public id_interval?: number,
        public incident_values?: any,
        public points?: number,
        public tag?: any,
        public route?: any[],
        public geofence?: SftGeofenceModel,
        public speed_excess?: number,
        public removed_text?: string,
        public removed?: boolean,
        public lat?: number,
        public lng?: number,
        public max_allowed?:number,
        public s3_image_key?:string,
        public company?:Company,
        public checked?:boolean,
        public infringement?:Infringement,
        public last_incident_infringements?:Incident[]
    ) {
        super();
        this.checked = false;
    }

    static fromJson(json: any): Incident {
        return Object.assign(new Incident(), json);
    }

    static getSpeedLimitNumber(incident:Incident) {

      let maxSpeed = null;
      let geofence: SftGeofenceModel = incident.geofence;

      if (geofence == null && incident.max_allowed != null)
        maxSpeed = incident.max_allowed;
      else if (geofence != null) {
        try {
          maxSpeed = geofence.global_max_speed;
          geofence.rules.forEach(r => {
            if ((r.vehicle_cat as VehicleCategory).id == incident.vehicle.category.id) {
              maxSpeed = r.max_speed;
            }
          });
        } catch (e) {
          if (maxSpeed == null)
            maxSpeed = 0;
        }
      }
    }



    static getIncidentExcess(incident:Incident,preferenceService:PreferencesService){
      if(incident.speed_excess != null){
        return incident.speed_excess + " "+ preferenceService.getSpeedUnit();
      }
      return '';
    }

    static getSpeedLimit(incident:Incident,preferenceService:PreferencesService) {

      let maxSpeed = null;
      let geofence:SftGeofenceModel = incident.geofence;

      if(geofence == null && incident.max_allowed != null)
        maxSpeed = incident.max_allowed;
      else if(geofence != null){
        try{
          maxSpeed = geofence.global_max_speed;
          geofence.rules.forEach(r => {
            if(( r.vehicle_cat as VehicleCategory).id == incident.vehicle.category.id){
              maxSpeed = r.max_speed;
            }
          });
        }catch (e) {
          if(maxSpeed == null)
            maxSpeed =  0;
        }
      }

      if(maxSpeed != null){
        return maxSpeed + " "+preferenceService.getSpeedUnit();
      }

      return '';
    }
}
