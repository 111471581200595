import { BaseModel } from './base.model';


export class Keylang extends BaseModel {

    constructor(
        public category?: string,
        public keylang?: string,
        public pt?: string,
        public en?: string,
        public es?: string,
        public edited?: boolean
    ) { super(); }

    static fromJson(json: any): Keylang {
        return Object.assign(new Keylang(), json);
    }

}
