import {BaseModel} from './base.model';
export class ReportSchedule extends BaseModel{
  constructor(
    public report?: number,
    public company?: number,
    public week_rule?:string, 
    public month_rule?:string, 
    public hour?:number,
    public next_ts?:string,
    public recipients?:string[]|any[],
    public curdate?:boolean
  ){ super(); }

  static fromJson(json:any) : ReportSchedule{
    let reportSchedule: ReportSchedule = new ReportSchedule();
    reportSchedule = Object.assign(reportSchedule, json);
    return reportSchedule;
  }

}
