import { UserListComponent } from './pages/user/user-list/user-list.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {LiveVehiclesMapComponent} from './pages/live-vehicles-map/live-vehicles-map.component';
import { ConsequenceManagerGuard } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'dashboard',
    loadChildren: './pages/dashboard/dashboard.module#DashboardModule'
  },
  {
     path: 'vehicles',
     loadChildren: './pages/vehicles/vehicles.module#VehiclesModule'
  },
  {
    path: 'driver',
    loadChildren: './pages/driver/driver.module#DriverModule'
  },
  {
    path: 'users',
    component: UserListComponent
  },
  {
    path: 'companies',
    loadChildren: './pages/company/company.module#CompanyModule'
  },
  {
    path: 'incidents',
    loadChildren: './pages/incident/incident.module#IncidentModule'
  },
  {
    path: 'map',
    component: LiveVehiclesMapComponent
  },
  {
    path: 'events',
    loadChildren: './pages/event/event.module#EventModule'
  },
  {
    path: 'reports',
    loadChildren: './pages/report/report.module#ReportModule'
  },
  {
    path: 'consequence-manager',
    loadChildren: './pages/consequence-manager/consequence-manager.module#ConsequenceManagerModule',
    canActivate: [ConsequenceManagerGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
