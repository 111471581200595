import { BaseModel } from './base.model';
import { EventCondition } from './event-condition.model';
import { Geofence } from './geofence.model';
import {SftGeofenceModel} from './sft-geofence.model';

export class SftEventGroup extends BaseModel {
  constructor(
    public name?:string,
    public color?:string
  ) {
    super()
  }

  static fromJson(json:any) : SftEventGroup {
    return Object.assign(new SftEventGroup(),json);
  }

}

export class SftEvent extends BaseModel {
  constructor(
    public id_calculator?: number,
    public company?: any,
    public id_point_type?: any,
    public name?: string,
    public geofences?: SftGeofenceModel[],
    public min_duration_seconds?: number,
    public geofence_speed_rules?: boolean,
    public event_conditions?: EventCondition[],
    public point_type?: any,
    public vehicles?: any[],
    public vehicle_qtde?:number,
    public created_at?:string,
    public created_at_ts?:number,
    public refreshing?:boolean,
    public use_geofence_percentage?:boolean,
    public use_image?: boolean
  ) {
    super();
    this.refreshing = false;
  }

  static fromJson(json: any) {
    return Object.assign(new SftEvent(), json);
  }

}
