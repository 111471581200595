import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSnackBarConfig, MatSort, MatTableDataSource } from '@angular/material';
import { User } from 'firebase';
import { UserModel } from 'src/app/models/user.model';
import { PermissionService } from 'src/app/services/permission.service';
import { UserService } from 'src/app/services/user.service';
import { AccessLogComponent } from '../access-log/access-log.component';
import { UserFormDialogComponent, UserFormDialogData } from '../user-form-dialog/user-form-dialog.component';
import {TranslateService} from '../../../../../projects/ngx-loop-components/src/lib/translate.service';
import {CompanyReactiveScreen} from '../../../components/company-reactive-screen';
import {CompanyService} from '../../../services/company.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent extends CompanyReactiveScreen implements OnInit,AfterViewInit,OnDestroy {

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns:string[] = ['name', 'username', 'email', 'last_login', 'options' ];
  dataSource = new MatTableDataSource<UserModel>();

  users:UserModel[] = [];
  selectedUser:UserModel;

  loading:boolean = false;
  selfLoading:boolean = false;

  constructor(
    public userService:UserService,
    private _snackBar: MatSnackBar,
    public translate:TranslateService,
    public dialog:MatDialog,
    public companyService:CompanyService,
  public permissionService:PermissionService,
  ) {
    super(companyService);
    this.companyService.setShowAllCompanies(false);
  }

  ngOnInit() {
    this.downloadUsers();
  }

  ngOnDestroy() {
    this.cancelObservation();
  }

  onCompanyChanged() {
    this.downloadUsers();
  }

  ngAfterViewInit(){
    this.dataSource.data = this.users;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  downloadUsers(){

    this.loading = true;

    this.userService.getResources().then((users) => {
      this.users = users;
      this.dataSource.data = this.users;
      console.log('USERS',users);
    }).catch((e) => {
      console.log('ERROR ',e);
      this.openSnackBar(this.translate.data['msg.erro'],'error');
    }).finally(() => {
      this.loading = false;
    });


  }

  deleteUser(user:UserModel){

    this.selfLoading = true;
    this.selectedUser = user;

    this.userService.delete(user.id).then(() => {
      this.downloadUsers();
      this.openSnackBar(this.translate.data['msg.success'],'success');
    }).catch((e) => {
      console.log('ERROR ',e);
      this.openSnackBar(this.translate.data['msg.erro'],'error');
    }).finally(() => {
      this.selfLoading = false;
      this.selectedUser = user;
    })


  }

  openUserDialog(user=null){

    let data:UserFormDialogData = {
      user:user
    };

    let dialogRef = this.dialog.open(UserFormDialogComponent,{
      panelClass:'dialog',
      width:'600px',
      minHeight:'550px',
      data:data
    })

    dialogRef.afterClosed().subscribe((data) => {
      this.downloadUsers();
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
    }
  }

  openAccessLog(user: User) {
    let dialogRef = this.dialog.open(AccessLogComponent, {
      minWidth: '400px',
      maxHeight: '90vh',
      panelClass: 'dialog',
      data: {
        user: user
      }
    });
  }

  public openSnackBar(message: string, type:string) {
    let config: MatSnackBarConfig = {
        panelClass: 'snack_' + type,
        duration: 6000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom'
    };
    this._snackBar.open(message, "Ok", config);
  }

}
