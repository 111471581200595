<mat-toolbar style="background:var(--primary);" *ngIf="showToolbar()" [hidden]="isReportPage()" class="fixed-header shadow-steppy">

    <button type='button' mat-button *ngIf="showDrawerIcon()" (click)="toggleDrawer()">
        <i class="mdi mdi-menu" id="drawer-icon"></i>
    </button>

    <div class="d-flex flex-row align-items-center">
      <img class="ml-1" *ngIf="themeService.active.app_bar_asset != null" style="height: 42px;width: auto;filter: drop-shadow(0px 0px 3px #222);" src="../../../assets/images/{{themeService.active.app_bar_asset}}" />
      <h1>
        <a routerLink="/" class="my-title ml-2">
          {{themeService.active.title}}
        </a>
      </h1>
    </div>

    <div class="ml-4" *ngIf="companyService.multipleCompanies != null && companyService.multipleCompanies.length > 0">
        <div [matMenuTriggerFor]="companyMenu" [ngClass]="{ 'menu-disabled' : loadingCompany }"  class="shadow-steppy px-2 py-1 company-selector d-flex flex-row align-items-center">
          <span *ngIf="!companyService.isAllSelected" style="color: var(--focus-text-color)">{{ company != null ? company.nome : ( ('label.loading' | translate)+'... ') }}</span>
          <span *ngIf="companyService.isAllSelected" style="color: var(--focus-text-color);font-weight: bold">{{'label.all_companies' | translate}}</span>
          <i *ngIf="!loadingCompany" class="ml-2 mdi mdi-chevron-down" style="font-size: 13px;color:var(--focus-text-color)"></i>
          <mat-progress-spinner [diameter]="13" *ngIf="loadingCompany" color="primary" class="ml-2" mode="indeterminate" ></mat-progress-spinner>
        </div>

        <mat-menu #companyMenu="matMenu" style="min-height: 30px" class="mat-menu-panel toolbar-menu">
          <button *ngIf="companyService.showAllCompanies" (click)="onAllCompaniesSelected()" mat-menu-item class="toolbar-menu-item" style="font-size: 12px;font-weight: bold">{{'label.all_companies' | translate}}</button>
          <button (click)="onCompanySelectionChanged(companySelection)" mat-menu-item class="toolbar-menu-item" style="font-size: 12px" *ngFor="let companySelection of companyService.multipleCompanies">{{companySelection.nome}}</button>
        </mat-menu>
    </div>

  <app-alert-viewer class="ml-auto" topicSuffix="/alerts" ></app-alert-viewer>

  <div class="ml-3">
        <div [matMenuTriggerFor]="languageMenu">
            <button mat-button class="menu_button d-flex flex-row" >
                <i class="mdi mdi-earth text-white" ></i>
                <span class="mb-0 ml-2 text-white">{{ 'nav.lingua'| translate }}</span>
            </button>
            <mat-menu #languageMenu="matMenu" style="min-height: 30px" class="mat-menu-panel toolbar-menu">
                <button mat-menu-item class="toolbar-menu-item" *ngFor="let lang of languages" (click)="changeLang(lang.value)" >{{lang.label}}</button>
            </mat-menu>
        </div>
    </div>

    <button *ngIf="showHelpDesk()" mat-button id="custom_help_button" class="menu_button ml-3" >
      <i class="mdi mdi-forum-outline text-white"></i>
      <span class="mb-0 ml-2 text-white">{{'label.support_chat'| translate}}</span>
    </button>

    <div class="ml-3">
        <button *ngIf="authService.isLoggedIn()" (click)="logout()" mat-button class="menu_button d-flex flex-row">
          <i class="mdi mdi-logout text-white" ></i>
          <span class="mb-0 ml-2 text-white"> {{ 'nav.sair'| translate }}</span>
        </button>
    </div>

    <button mat-button (click)="toggleTheme()" class=" d-flex flex-row align-items-center justify-content-center">
        <i class="mdi mdi-theme-light-dark icon text-white"></i>
    </button>

    <p class="ml-3 mb-0" style="font-size: 0.5em;opacity: 0.7;color: #ffffff;margin-top: 2px;">{{VERSION}}</p>
</mat-toolbar>


<mat-drawer-container autosize [ngStyle]="{'height':(showToolbar()?'calc(100vh - var(--header-height))':'100vh')}">

    <mat-drawer #drawer mode="side" class="scroller" style="background:var(--background-sidebar);">

        <div class="menu-item" root >
            <span (click)="navigate('/dashboard')">
                <label class="mdi mdi-chart-line mb-0 mr-3"></label>
                {{'label.dashboard' | translate}}
            </span>
        </div>

        <div class="menu-item" root >
            <span (click)="navigate('/incidents')">
                <label class="mdi mdi-file-document mb-0 mr-3"></label>
                {{'label.incidents' | translate}}
            </span>
        </div>
        <div class="menu-item" root *ngIf="showConsequence()">
              <span (click)="navigate('/consequence-manager')">
                  <label class="mdi mdi-sticker-check-outline mb-0 mr-3"></label>
                {{'abbrev.consequence_manager' | translate}}
              </span>
        </div>

        <div *ngIf="company && company.id == 90" class="menu-item" root >
            <span (click)="navigate('/incidents/report')">
                <label class="mdi mdi-file-multiple-outline mb-0 mr-3"></label>
                {{'label.incidentsreport' | translate}}
            </span>
        </div>

        <div class="menu-item" root >
            <span (click)="navigate('/map')">
                <label class="mdi mdi-map mb-0 mr-3"></label>
                {{'label.livemap' | translate}}
            </span>
        </div>

        <div class="menu-item" root >
            <span (click)="navigate('events')">
                <label class="mdi mdi-tune-vertical-variant mb-0 mr-3"></label>
                {{'app.config' | translate}}
            </span>
        </div>

        <div class="menu-item" root opened="false">
            <span #registrationMenu (click)="toggleMenuItem(registrationMenu)">
                <label class="mdi mdi-folder-edit mb-0 mr-3"></label>
                {{'label.registration'|translate}}
                <i class="mdi mdi-chevron-down ml-auto"></i>
            </span>
            <div *ngIf="permissionService.hasPermission('DRIVER','list')" class="menu-item" >
                <span (click)="navigate('/driver')">
                    <label class="mdi mdi-steering mb-0 mr-3"></label>
                    {{'planning.drivers' | translate}}
                </span>
            </div>

        </div>


        <div class="menu-item" root opened="false" >

            <span #adminMenu (click)="toggleMenuItem(adminMenu)">
                <label class="mdi mdi-briefcase mb-0 mr-3"></label>
                {{'menu.admin'|translate}}
                <i class="mdi mdi-chevron-down ml-auto"></i>
            </span>

            <div *ngIf="permissionService.hasPermission('USER','list')" class="menu-item">
                <span (click)="navigate('/users')">
                    <label class="mdi mdi-account mb-0 mr-3"></label>
                    {{'menu.users' | translate}}
                </span>
            </div>
            <div *ngIf="permissionService.hasPermission('VEHICLE','list')" class="menu-item">
                <span (click)="navigate('/vehicles')">
                    <label class="mdi mdi-truck mb-0 mr-3"></label>
                    {{'planning.vehicles' | translate}}
                </span>
            </div>

        </div>




    </mat-drawer>


    <div class="drawer-content">
        <router-outlet></router-outlet>
    </div>

</mat-drawer-container>
