import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Vehicle } from '../../models/vehicle.model';
import {TranslateService} from '../../../../projects/ngx-loop-components/src/lib/translate.service';
import {from} from "rxjs";

export class SelectorConstants {
  public VEHICLE : number = 1;
  public PLANTS : number = 2;
  public COMPANIES: number = 3;
  public USERS: number = 4;
  public BUSINESS: number = 5;
  public CENTER: number = 6;
  public PERSON: number = 7;
  public REPORTS: number = 8;
  public GEOFENCES: number = 9;

  constructor(){}
}

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.css']
})
export class SelectorComponent implements OnInit {

  @Input() list : any[] = [];
  @Input() public selectedList : any[] = [];

  @Input() type: number;
  @Input() showInfoIcon: boolean = true;

  @Input() checkDisabled: boolean = false;
  @Input() additionalField: string = null;

  @Output() onChangeList: EventEmitter<void> = new EventEmitter();
  @Output() onInfoIconClicked: EventEmitter<any> = new EventEmitter<any>();

  filteredList: any[] = [];
  filteredSelectedList: any[] = [];

  originalSelection : SelectionModel<any> = new SelectionModel(true, []);
  chosenSelection : SelectionModel<any> = new SelectionModel(true, []);

  initialSelectedList: any[] = [];      // salvo aqui os items que estavam na lista pra saber quem foi acrescentado ou removido
  addedItems: any[] = [];
  removedItems: any[] = [];

  originalShiftSelection = {firstValue:null, secondValue:null};
  chosenShiftSelection = {firstValue:null, secondValue:null};

  loading : boolean = false;

  @Input()
  translateField: boolean = false;

  title: string;
  chosenText: string;

  public types : SelectorConstants;

  elementField: string = 'name';

  constructor(
    private translate: TranslateService
  ) {
    this.types = new SelectorConstants();
  }

  ngOnInit() {
    this.setType();
  }

  filterList(inputText:string,selected:boolean){
    let filtered;
    let list;

    if(selected){
      list = this.selectedList
    }else{
      list = this.list
    }

    if(inputText == ''){
      filtered = this.cloneList(list);
    }else{
      filtered = [];
      list.forEach(element => {
        let elementText : string = element[this.elementField];
        if(elementText.toUpperCase().indexOf(inputText.toUpperCase()) != -1){
          filtered.push(Object.assign({},element));
        }else{
          if(this.additionalField && element[this.additionalField] != null){
            let additionalText = element[this.additionalField];
            if(additionalText.toUpperCase().indexOf(inputText.toUpperCase()) != -1){
              filtered.push(Object.assign({},element));
            }
          }
        }
      });
    }

    if(selected){
      this.filteredSelectedList = filtered;
    }else{
      this.filteredList = filtered;
    }
  }

  public setType(): void{
    switch (this.type) {

      case this.types.VEHICLE:

        this.title = this.translate.data['app.available_vehicles'];
        this.chosenText = this.translate.data['app.choosen_vehicles'];
        break;

      case this.types.PLANTS:
        this.title = this.translate.data['app.available_plants'];
        this.chosenText = this.translate.data['app.choosen_plants'];
        break;

      case this.types.REPORTS:
          this.elementField = 'title';
          this.title = this.translate.data['label.avail_reports'];
          this.chosenText = this.translate.data['label.selected_reports'];
          break;

      case this.types.COMPANIES:
        this.elementField = 'nome';
        this.title = this.translate.data['app.available_companies'];
        this.chosenText = this.translate.data['app.choosen_companies'];
        break;

      case this.types.USERS:
        this.elementField = 'nome';
        this.title = this.translate.data['app.available_users'];
        this.chosenText = this.translate.data['app.choosen_users'];
        break;
      case this.types.BUSINESS:
        this.elementField = 'name';
        this.title = this.translate.data['app.available_business'];
        this.chosenText = this.translate.data['app.choosen_business'];
        break;
      case this.types.CENTER:
        this.elementField = 'center_description';
        this.title = this.translate.data['app.available_centers'];
        this.chosenText = this.translate.data['app.choosen_centers'];
        break;
      case this.types.PERSON:
        this.elementField = 'person_name';
        this.title = this.translate.data['app.available_persons'];
        this.chosenText = this.translate.data['app.choosen_persons'];
        break;
      case this.types.GEOFENCES:
        this.elementField = 'name';
        this.title = this.translate.data['menu.geofences'];
        this.chosenText = this.translate.data['app.choosen_geofences'];
        break;
    }
  }

  public setList(list) {
    //Filter disabled elements

    list = this.cloneList(list);

    if(this.checkDisabled && this.type == this.types.VEHICLE ){
      list.forEach((v:Vehicle)=>{
        v['disabled'] = v.group_name != null;
      });
      list.sort(function(el1, el2) {
        return (el1['disabled'] === true)? 1 : -1;
      });
    }

    this.list = list;
    this.filteredList = this.cloneList(list);
  }

  cloneList(originalList){
    let myClonedArray = [];
    originalList.forEach(val => myClonedArray.push(Object.assign({}, val)));
    return myClonedArray;
  }

  public setSelectedList(selectedList):void {

      this.initialSelectedList = selectedList;

    let selectedItemsList = [];
    let notSelectedItemsList = [];

    this.list.forEach(element => {
      if(selectedList.some(si => si.id === element.id)){
        selectedItemsList.push(element);
      }else{
        notSelectedItemsList.push(element);
      }
    });

    this.selectedList = selectedItemsList;
    this.filteredSelectedList = this.cloneList(selectedItemsList);

    this.list = notSelectedItemsList;
    this.filteredList = this.cloneList(notSelectedItemsList);
  }

  public applyFilter(filterValue: string):void{
    this.filterList(filterValue,false);
  }

  public applyFilterSelected(filterValue: string):void{
    this.filterList(filterValue,true);
  }

  public changeList(selection:SelectionModel<any>,fromSelected:boolean){

    let selectedItems = selection.selected;
    let source = [];
    let destination = [];

    this.setItemsAddedOrRemoved(selectedItems, !fromSelected);

    if(fromSelected){
      source = this.selectedList;
      destination = this.list;
    }else{
      source = this.list;
      destination = this.selectedList;
    }

    for (let i = 0; i < selectedItems.length; i++) {

      if(destination.some(item => item.id === selectedItems[i].id) == false) {
        destination.push(selectedItems[i]);
        let index = this.getIndexOf(selectedItems[i],source);
        source.splice(index,1);
      }
    }

    if(fromSelected){
      this.list = destination;
      this.selectedList = source;
    }else{
      this.list = source;
      this.selectedList = destination;
    }

    this.unselectChoosen();
    this.unselectOriginals();

    this.originalShiftSelection.firstValue = null;
    this.originalShiftSelection.secondValue = null;
    this.chosenShiftSelection.firstValue = null;
    this.chosenShiftSelection.secondValue = null;

    this.reflectChangesToFilteredLists();
    this.onChangeList.emit();

    selection.clear();
  }

  getIndexOf(element:any,baseList:any[]){
    for(let i = 0; i < baseList.length;i++){
      if(element.id === baseList[i].id){
        return i;
      }
    }
    return -1;
  }

  changeAllList(fromSelected:boolean) {

      this.setItemsAddedOrRemoved(fromSelected ? this.selectedList : this.list, !fromSelected);

    if(fromSelected){
      this.list = [...this.list,...this.cloneList(this.selectedList)];
      this.selectedList = [];
    }else{

      let filtered = [];
      let remaining = [];

      this.list.forEach((element:any)=>{
        if(element['disabled']){
          remaining.push(element);
        }else{
          filtered.push(element);
        }
      });

      this.selectedList = [...this.selectedList, ...filtered];
      this.list = remaining;
    }

    this.onChangeList.emit();
    this.reflectChangesToFilteredLists();
  }

  reflectChangesToFilteredLists(){
    this.filteredSelectedList = this.cloneList(this.selectedList);
    this.filteredList = this.cloneList(this.list);
  }

  public getSelectedList():any[] {
    return this.selectedList;
  }

  public getSelectedItemsId():any[] {
    return this.getSelectedList().map(val => val.id);
  }

  public getAddedItemsId(): number[] {
      return this.addedItems.map((item) => item.id);
  }

  public getRemovedItemsId(): number[] {
      return this.removedItems.map((item) => item.id);
  }

  public getNotSelectedList() {
    return this.list;
  }

  public getNotSelectedItemsId() {
    return this.getNotSelectedList().map(val => val.id);
  }

  public getInformations(element){
    let informations = [];
    switch(this.type){

      case this.types.VEHICLE:
          let v : Vehicle = element;
          if(v.type && v.type.keylang){
            let keylang = v.type.keylang;
            informations = [
              {"key":this.translate.data['label.type'],"value":this.translate.data[ keylang ]},
              {"key":this.translate.data['app.internal_code'],"value":v.codbt},
              {"key":this.translate.data['label.userGrupo'],"value": v.group_name != null ? v.group_name : ''}
            ];
          }
        break;
      case this.types.PLANTS:
          informations = [
            {"key":"cod","value":element['cod_planta']},
          ];
        break;
    }
    return informations;
  }

  public handleOriginalSelection(event, element, index){
    if(element['disabled'])
      return;
    if(event.type == 'click'){
      this.unselectChoosen();
      if(event.shiftKey && !event.ctrlKey){

        if(this.originalShiftSelection.firstValue != null){
          if(this.originalShiftSelection.secondValue != null){
            this.unselectOriginals();
          }
          this.originalShiftSelection.secondValue = index;
          this.selectOriginals();
        }else{
          this.originalShiftSelection.secondValue = null;
        }

      }else if(!event.shiftKey && (event.ctrlKey || event.metaKey)){

        this.originalShiftSelection.firstValue = index;
        if(this.originalSelection.isSelected(element)){
          this.originalSelection.deselect(element);
        }else{
          this.originalSelection.select(element);
        }

      }else{
        if(this.originalSelection.isSelected(element)){
          this.unselectOriginals();
          this.originalSelection.deselect(element);
        }else{
          this.unselectOriginals();
          this.originalSelection.select(element);
          this.originalShiftSelection.secondValue = null;
          this.originalShiftSelection.firstValue = index;
        }
      }
    }
  }

  public handleChoosenSelection(event, element, index){
    if(event.type == 'click'){
      this.unselectOriginals();
      if(event.shiftKey && !event.ctrlKey){

        if(this.chosenShiftSelection.firstValue != null){
          if(this.chosenShiftSelection.secondValue != null){
            this.unselectChoosen();
          }
          this.chosenShiftSelection.secondValue = index;
          this.selectChoosen();
        }else{
          this.chosenShiftSelection.secondValue = null;
        }

      }else if(!event.shiftKey && event.ctrlKey){

        this.chosenShiftSelection.firstValue = index;
        if(this.chosenSelection.isSelected(element)){
          this.chosenSelection.deselect(element);
        }else{
          this.chosenSelection.select(element);
        }

      }else{
        if(this.chosenSelection.isSelected(element)){
          this.unselectChoosen();
          this.chosenSelection.deselect(element);
        }else{
          this.unselectChoosen();
          this.chosenSelection.select(element);
          this.chosenShiftSelection.secondValue = null;
          this.chosenShiftSelection.firstValue = index;
        }
      }
    }
  }

  private selectOriginals(){
    let from = this.originalShiftSelection.firstValue;
    let to = this.originalShiftSelection.secondValue;
    if( from != null && to != null){
      if(from > to){
        let i = from;
        for(i; i >= to; i--){
          if(!this.filteredList[i]['disabled'])
            this.originalSelection.select(this.filteredList[i]);
        }
      }else{
        let i = from;
        for(i; i <= to; i++){
          if(!this.filteredList[i]['disabled'])
            this.originalSelection.select(this.filteredList[i]);
        }
      }
    }
  }

  private selectChoosen(){
    let from = this.chosenShiftSelection.firstValue;
    let to = this.chosenShiftSelection.secondValue;
    if( from != null && to != null){
      if(from > to){
        let i = from;
        for(i; i >= to; i--){
          this.chosenSelection.select(this.filteredSelectedList[i]);
        }
      }else{
        let i = from;
        for(i; i <= to; i++){
          this.chosenSelection.select(this.filteredSelectedList[i]);
        }
      }
    }
  }

  private unselectOriginals(){
    this.originalSelection = new SelectionModel(true, []);
  }

  private unselectChoosen(){
    this.chosenSelection = new SelectionModel(true, []);
  }



  setItemsAddedOrRemoved(selectedItems: any[], addingItems: boolean = true) {


      console.log('IS ADDING ', addingItems)
      console.log('SELECTED ITEMS ', selectedItems);

      if (addingItems) {

          selectedItems.forEach((item) => {

              // primeiro vejo se o item não estava na outra lista, e removo eles de lá.
              let indexRemoved = this.getIndexOf(item, this.removedItems);
              if (indexRemoved > -1)
                this.removedItems.splice(indexRemoved,1);


              // depois vejo se o item já estava na lista inicial. Só adiono os items que são novos.
              let indexInitial = this.getIndexOf(item, this.initialSelectedList);
              if (indexInitial == -1)
                  this.addedItems = this.addedItems.concat(item);
          });

      }

      else {

          selectedItems.forEach((item) => {

              let indexAdded = this.getIndexOf(item, this.addedItems);
              if (indexAdded > -1)
                  this.addedItems.splice(indexAdded, 1);


              let indexInitial = this.getIndexOf(item, this.initialSelectedList);
              if (indexInitial > -1)
                  this.removedItems = this.removedItems.concat(item);
          })
      }

  }
}
