export const constants = {
    NEW: 'new',
    EDIT: 'edit',
    VEHICLE_CAPACITY: 8,

    VOLUMETRIC_ORDER_TYPE: 1,
    READYMIX_ORDER_TYPE: 2,

    PUMP_TYPE: 2,
    READYMIX_TYPE: 3,
    VOLUMETRIC_TYPE: 4
};

export const conf = {
    GENERAL_CONFIGS: 'general',
    LANGUAGE: 'language',
    THEME: 'theme',

    PLANNING_CONFIGS: 'planning',
    TICKET_INTEGRATION: 'ticket_integration',
    REQUEST_INTEGRATION: 'request_integration',
    PROJECT_INTEGRATION: 'project_integration',

    DEFAULT_TRUCK_VOLUME: 'default_truck_volume',
    DEFAULT_GO_TIME: 'default_go_time',
    DEFAULT_LOAD_TIME: 'default_load_time',
    DEFAULT_BACK_TIME: 'default_back_time',
    DEFAULT_UNLOAD_TIME: 'default_unload_time',
    DEFAULT_TRUCK_INTERVAL: 'default_truck_interval'

};
