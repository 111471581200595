import {Component, NgZone, OnInit, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import { MatDrawer, MatSnackBar, MatSnackBarConfig} from '@angular/material';
import { DateAdapter } from 'saturn-datepicker';
import * as moment from 'moment';
import {AuthMonitor, AuthService} from "./services/auth.service";
import {UserService} from "./services/user.service";
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {CompanyService} from './services/company.service';
import {Company} from './models/company.model';
import {Location} from '@angular/common';

import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeBg from '@angular/common/locales/bg';
import localeRu from '@angular/common/locales/ru';
import localeAr from '@angular/common/locales/ar';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';

import {registerLocaleData} from '@angular/common';
import {conf} from './assets/constants';

import { ThemeService } from './services/theme.service';
import { UserModel as User } from './models/user.model';
import { LocalTimeService } from './services/local-time.service';
import { ConfigurationService } from './services/configuration.service';
import { PermissionService } from './services/permission.service';
import { ToolbarService } from './services/toolbar.service';
import { KeylangService } from './services/keylang.service';
import {TranslateService} from '../../projects/ngx-loop-components/src/lib/translate.service';
import {BrokerService} from './services/broker.service';
import {IntercomService} from '../../projects/ngx-loop-components/src/lib/intercom.service';
import {Title} from '@angular/platform-browser';
import * as permissions from 'src/app/utils/permissions.json';

const LANDING_PAGE = 'landing';
const CMS_URL = "https://cms.360kpi.io/landing";

export const SAFETY_BUSINESS_ID = 5;
export const DEFAULT_TMS_TYPE = 2;

export const LIGHT_THEME = 'light';
export const DARK_THEME = 'dark';
export const KAT_LIGHT_THEME = 'kat_light';
export const KAT_DARK_THEME = 'kat_dark';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AuthMonitor, AfterViewInit,OnDestroy {

    VERSION = "V 2.7.1";

    LOCALE_AR_AE = "ar-AE";
    LOCALE_DE_DE = "de-DE";
    LOCALE_EN_US = "en-US";
    LOCALE_ES_ES = 'es-ES';
    LOCALE_FR_FR = 'fr-FR';
    LOCALE_PT_BR = "pt-BR";
    LOCALE_BG_BG = 'bg-BG';
    LOCALE_RU_RU = 'ru-RU';

    languages: any[] = [];

    user: User = null;
    isDealer: boolean = false;
    company: Company;
    loadingCompany: boolean = false;

    title = 'CoresSafetyWeb';
    currentDate: any;
    isLoggedIn: boolean = false;
    timerId: any = -1;

    currentTheme = KAT_LIGHT_THEME;

    @ViewChild('drawer', {static: false}) drawer: MatDrawer;
    favIcon: HTMLLinkElement = document.querySelector('#appIcon');

    constructor(
        public themeService: ThemeService,
        private localTimeService: LocalTimeService,
        private router: Router,
        public authService: AuthService,
        private ngZone: NgZone,
        private userService: UserService,
        private titleService: Title,
        private sessionStorageService: SessionStorageService,
        public companyService: CompanyService,
        private translate: TranslateService,
        public _dateAdapter: DateAdapter<any>,
        private configurationService: ConfigurationService,
        public permissionService: PermissionService,
        private _snackBar: MatSnackBar,
        public toolbarService: ToolbarService,
        private keylangService: KeylangService,
        private localStorage: LocalStorageService,
        public intercomService: IntercomService,
        private location: Location,
        private brokerService: BrokerService
    ) {
        let lang = this.localStorage.retrieve('language');

        if (lang)
          this.setLang(lang);

        this.setThemeAssets();
    }

    showHelpDesk() : boolean {

      return false;

      try{

        if(this.company.id_dealer == 13 && this.companyService.multipleCompanies.length == 0){
          return false;
        }else{
          return true;
        }
      }catch (e){
        return true;
      }
    }

    setThemeAssets(){

      this.themeService.initFromUrl(window.location.host);
      this.currentTheme = this.themeService.getActiveTheme().name;

      if(this.themeService.isKatDomain()){
        this.titleService.setTitle(this.themeService.active.title);
        if(this.themeService.active.alternate_favicon != null){
          this.favIcon.href = this.themeService.active.alternate_favicon;
        }
      }
    }

    onAllCompaniesSelected(){
      this.companyService.setAllSelected(true);
    }

    async onCompanySelectionChanged(newCompany:Company) {
      if(this.company != null && ( this.company.id != newCompany.id || this.companyService.isAllSelected )){
        this.companyService.setAllSelected(false);
        await this.downloadCompany(newCompany.id);
      }
    }

    ngOnInit(): void {

        this.themeService.setTheme(this.currentTheme);

         /*
            from stackoverflow
            https://stackoverflow.com/questions/39601026/angular-2-scroll-to-top-on-route-change
        */
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
        this.registerAppLocales();
        this.authService.setAuthMonitor(this);
        this.downloadLangs();
    }

    toggleTheme(){
        if(!this.themeService.isKatDomain())
          this.currentTheme = (this.themeService.active.name == LIGHT_THEME)? DARK_THEME : LIGHT_THEME;
        else
          this.currentTheme = (this.themeService.active.name == KAT_LIGHT_THEME)? KAT_DARK_THEME : KAT_LIGHT_THEME;

        this.themeService.setTheme(this.currentTheme);
    }

    ngAfterViewInit() {
      this.intercomService.init("SAFETY",window);
      this.initSideBarItems();
    }

    downloadLangs() {
        this.keylangService.getLanguages().then(
        (langs: any[]) => {
            this.languages = langs;
        }).catch(e => {
            console.log('e', e);
        });
    }

    registerAppLocales() {
      registerLocaleData(localeEn, this.LOCALE_EN_US);
      registerLocaleData(localeEs, this.LOCALE_ES_ES);
      registerLocaleData(localePt, this.LOCALE_PT_BR);
      registerLocaleData(localeRu, this.LOCALE_RU_RU);
      registerLocaleData(localeBg, this.LOCALE_BG_BG);
      registerLocaleData(localeAr, this.LOCALE_AR_AE);
      registerLocaleData(localeDe, this.LOCALE_DE_DE);
      registerLocaleData(localeFr, this.LOCALE_FR_FR);

      this._dateAdapter.setLocale(this.LOCALE_PT_BR);
    }

    showConsequence(){
      let haspermission = this.permissionService.hasCustomPermission(permissions.SFT_CONSEQUENCE_MANAGER);
      let show = (this.isDealer==true || haspermission);
      return show;
    }

    onLoginStateChanged(isLoggedIn: boolean, redirect: boolean): void {
        this.ngZone.run(async () => {
            if (!this.isLandingPage()){
                if (isLoggedIn) {

                    this.user = this.userService.getUserStore();

                    let companyId : number = this.user.company;

                    this.isDealer = false;
                    if(this.user.dealer_companies != null && this.user.dealer_companies.length > 0){
                      this.isDealer = true;
                      this.companyService.multipleCompanies = this.user.dealer_companies;
                      companyId = this.user.dealer_companies[0].id;
                    }

                    await this.downloadCompany(companyId);

                    this.getUserLang();

                    if (redirect) {
                        try {
                            this.router.navigateByUrl('dashboard');
                        } catch (e) {
                            this.logout();
                        }
                    }
                } else {
                    this.logout();
                    this.router.navigateByUrl('login');
                }
            }
        });
    }

    isLandingPage(){
      let fullPath = this.location.path();
      let page = fullPath.substring(fullPath.lastIndexOf('/') + 1, fullPath.lastIndexOf('?'));
      return page == LANDING_PAGE;
    }

    logout(): void {
        if (this.timerId >= 0) {
            clearInterval(this.timerId);
            this.timerId = -1;
        }

        this.configurationService.clearConfigurations();
        this.companyService.multipleCompanies = [];
        this.companyService.setShowAllCompanies(false);

        this.brokerService.closeConnection();
        this.authService.logout();
        this.router.navigateByUrl('login');
    }

    showToolbar(){

      if(!this.toolbarService.visible)
        return false;

      try {
        if (!this.authService.isLoggedIn())
          this.drawer.close();

        return this.authService.isLoggedIn();
      } catch (e) {
        return false;
      }
    }

    showDrawerIcon() {
        try {
            if (!this.authService.isLoggedIn())
                this.drawer.close();

            return this.authService.isLoggedIn() && !this.authService.isFromLanding();
        } catch (e) {
            return false;
        }
    }

    isReportPage(){
        let path = this.router.url;
        return path.startsWith('/documents')
    }


    toggleDrawer() {
        if (this.drawer.opened)
            this.drawer.close();
        else
            this.drawer.open();
    }

    changeLang(lang: string): void {
        this.setLang(lang);
        this.configurationService.updateConfiguration(conf.GENERAL_CONFIGS, [conf.LANGUAGE], [lang]);
        this.localStorage.store('language', lang);

        this._dateAdapter.setLocale(lang);
        this.configurationService.updateConfiguration(conf.GENERAL_CONFIGS, ["locale"], [lang]);
        let configs = this.sessionStorageService.retrieve("c");
        configs["general"]["locale"] = lang;
        this.sessionStorageService.store("c",configs);
    }

    setLang(lang: string){
      this.translate.use(lang);
      this._dateAdapter.setLocale(lang);
    }

    getUserLang(){
        let userLang = this.configurationService.getConfigValue(conf.GENERAL_CONFIGS, conf.LANGUAGE);
        if (userLang){
          this.setLang(userLang);
          this.localStorage.store('language', userLang);
        }
    }

    private downloadCompany(companyId: number): Promise<void> {
        this.loadingCompany = true;
        return new Promise<void>((resolve, reject) => {
            this.companyService.getResource(companyId)
            .then((company: Company) => {
                this.sessionStorageService.store('selectedCompany', company);
                this.company = company;
                this.configurationService.getUserCompanyConfiguration();
                resolve();
            })
            .catch((e) => {
                this.openSnackBar(this.translate.data['msg.erro'], 'error');
                reject(e);
            }).finally(()=>{
              this.loadingCompany = false;
            });
        });
    }

    ngOnDestroy(): void {
      this.brokerService.closeConnection();
    }

    openCms(tab){
        let token = this.sessionStorageService.retrieve('token');
        let refresh = this.sessionStorageService.retrieve('rt');
        let user = this.sessionStorageService.retrieve('user');
        let company = user.company;

        let url = CMS_URL + '?t=' + token + '&r=' + refresh + '&c=' + company + '&b=' + SAFETY_BUSINESS_ID + '&tab=' + tab;
        window.open(url, "_blank");
    }

    private initSideBarItems() {
        let elements: HTMLCollectionOf<Element> = document.getElementsByClassName("menu-item");
        for (let i = 0; i < elements.length; i++) {
          elements.item(i).setAttribute("style", "transition:0.25s; height:32px; overflow:hidden");
        }
    }

    toggleMenuItem(element: HTMLElement) {
        let parent = element.parentElement;
        let contentHeight = 0;
        let childrens: HTMLCollection = parent.children;

        for (let i = 0; i < childrens.length; i++) {
          contentHeight += childrens.item(i).clientHeight;
        }

        if (parent.clientHeight > 44) {
          parent.style.height = contentHeight + "px";
          parent.style.overflow = "hidden";

          setTimeout(() => {
            parent.style.height = 44 + "px";
            parent.setAttribute("open", "false");
          }, 10);
        } else {
          parent.style.height = contentHeight + "px";
          parent.setAttribute("open", "true");

          setTimeout(() => {
            parent.style.height = "unset";
            parent.style.overflow = "unset";
          }, 250);
        }

      }

      navigate(url: string) {
        this.router.navigateByUrl(url);
      }

    public openSnackBar(message: string, type:string) {
        let config: MatSnackBarConfig = {
            panelClass: 'snack_' + type,
            duration: 6000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom'
        };
        this._snackBar.open(message, "Ok", config);
    }

}
