import {Inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from './translate.service';

@Pipe({
  name:'translate',
  pure:false
})
export class TranslatePipe implements PipeTransform{

  constructor(public translate : TranslateService){}

  transform(keys: string): string {
    try {
      let finalstr = '';
      for(let key of keys.split(' ')){
        let translated:string = (this.translate.data[key] != null)?this.translate.data[key]:key;
        finalstr += (finalstr!=''?' ':'') + translated;
      }
      return finalstr;
    } catch (e) {
      return keys;
    }
  }
}
