import { Paho } from 'src/app/libs/mqtt/mqttws31';
import {FlespiService} from '../depends/custom.service';

export const FlespiConstants  = {
  BASE_DEVICES_TOPIC: 'flespi/message/gw/devices/',
  BASE_CALCULATORS_TOPIC: 'flespi/interval/gw/calcs/',
};

const HOST = 'mqtt.flespi.io';
const PORT = 443;
const PATH = '/wss';

export class MqttController {

  private customService: FlespiService;

  private refreshToken: boolean = false;
  private controllerDestroyed: boolean = false;

  private flespiToken: string;

  public client: Paho.MQTT.Client;

  public onConnected(){};

  public onMessageArrived(message: any){

  };

  constructor(customService: FlespiService){
    this.customService = customService;
  }

  public downloadFlespiTokenAndConnect() {
    this.customService.getFlespiToken().then(
      (token: any) => {
        this.flespiToken = token.flespi_token;
        if (!this.controllerDestroyed)
          this.connectToFlespi();
      }
    ).catch(e => {
      console.log('Error Downloading Company Flespi Token', e);
    });
  }

  private connectToFlespi() {
    this.client = new Paho.MQTT.Client(HOST, PORT, PATH, this.flespiToken);
    this.client.onMessageArrived = this.onNewMessage;
    this.client.onConnectionLost = this.onConnectionLost;
    this.client.connect({ useSSL: true, userName: 'FlespiToken ' + this.flespiToken, password: '', onSuccess: this.onConnect,cleanSession:true  });
    this.refreshToken = true;
  }

  private onConnect = () => {
    if (!this.controllerDestroyed)
      this.onConnected();
    else
      this.client.disconnect();
  };

  private onConnectionLost = (r) => {
    console.log('LOST CONNECTION', r);
    if(this.refreshToken)
      this.downloadFlespiTokenAndConnect();
  };

  disconnect(){
    this.refreshToken = false;
    this.controllerDestroyed = true;
    if(this.client && this.client.isConnected())
      this.client.disconnect();
  }

  private onNewMessage = (message: Paho.MQTT.Message) => {
    let messageObj = JSON.parse(message.payloadString);
    this.onMessageArrived(messageObj);
  };


  public isConnected() : boolean {
    return this.client && this.client.isConnected();
  }


  public subscribeTo(path: string) {
    if(!path)
      return;
    this.client.subscribe(path, {});
  }

  public unsubscribeTo(path){
    if(!path)
      return;
    this.client.unsubscribe(path, {});
  }
}
