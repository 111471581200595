<div class="dialog_container">
  <mat-progress-bar mode="indeterminate" *ngIf="loading" style="height: 2px"></mat-progress-bar>
  <div class="dialog_header px-3 py-2 border-bottom d-flex flex-row align-items-center" style="color:var(--foreground-default);border-bottom-color:var(--table-border)!important;">
    <h3 class="d-inline-flex mb-0" style="font-size: 14pt;font-weight: 400;">{{'label.share_geofence'|translate}}</h3>
    <i (click)="close()" class="mdi mdi-close ml-auto"></i>
  </div>

  <div>
    <div style="overflow:auto">
      <app-selector
        #selector
        [type]="3"
      ></app-selector>
    </div>
    <div class="d-flex justify-content-end px-4 py-3 border-top" style="border-top-color:var(--table-border)!important;">
      <button mat-button color="primary" (click)="shareGeofences()"  [disabled]="loading">
        {{'nav.saveClose'|translate}}
      </button>
    </div>
  </div>
</div>

