import {Injectable, Injector} from '@angular/core';
import {Company} from '../models/company.model';
import {BaseService} from './base.service';
import {environment} from '../../environments/environment';
import {reject} from 'q';
import {Observable} from 'rxjs';
import {ConfigurationService} from './configuration.service';
import {PreferencesService} from '../../../projects/ngx-loop-components/src/lib/preferences.service';

export interface AllCompaniesEvent {
  onAllCompaniesSelected(companies:Company[]);
}

@Injectable({
    providedIn: 'root'
})

export class CompanyService extends BaseService<Company> {

    preferenceService: PreferencesService;
    configurationService: ConfigurationService;
    multipleCompanies: Company[] = [];

    showAllCompanies: boolean = false;
    isAllSelected: boolean = false;

    allCompaniesListener : AllCompaniesEvent = null;

    constructor(public injector: Injector) {
        super(injector, 'core/companies', Company.fromJson);

        this.preferenceService = injector.get(PreferencesService);
        this.configurationService = injector.get(ConfigurationService);
    }

    setAllCompaniesListener(listener:AllCompaniesEvent){
      this.allCompaniesListener = listener;
    }

    setAllSelected(allSelected:boolean) {

      this.isAllSelected = allSelected;
      if(allSelected){
        if(this.allCompaniesListener != null){
          this.allCompaniesListener.onAllCompaniesSelected(this.multipleCompanies);
        }
      }
    }

    setShowAllCompanies(show:boolean) {
      this.showAllCompanies = show;
      if(!show){
        this.isAllSelected = false;
      }
    }

    storeCompany(company: Company) {
        this.sessionStorage.store('selectedCompany', company);
    }

    retrieveCompany(): Company {
      return this.sessionStorage.retrieve('selectedCompany');
    }

    async getFlespiToken() {
      let path = environment.api + 'core/companies/token';
      let headers = await this.buildHeader();

      return this.http.get(path, {headers: headers}).toPromise().then(
        (res: any) => {
          console.log('data', res);
          return res.data;
        }).catch(e => {
        throw e;
      });
    }

    getCalculators() {
        let url  = `${environment.api}core/companies/calculators`;
        this.customGet(null, url)
            .then((companyCalcs) => {
            })
            .catch((error) => {
                reject(error)
            })

    }

    observeCompanyChanges(): Observable<Company> {
        return this.sessionStorage.observe('selectedCompany');
    }

    setConfigs() : Promise<void> {
        return this.configurationService.getUserCompanyConfiguration();
    }

    downloadAndSetCompany(companyId:number) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.getResource(companyId).then(
                async (company: Company) => {
                    this.storeCompany(company);
                    this.preferenceService.setCompany(company);
                    resolve();
                }).catch(e => {
                console.log('erro', e);
                reject(e);
            });
        });
    }
}
