import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef, ViewChild
} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {NgForOfContext} from '@angular/common';
import {BaseModel} from '../depends/base.model';
import {isNumberToken} from 'html2canvas/dist/types/css/syntax/parser';
import {TranslateService} from '../translate.service';

@Component({
  selector: 'app-resource-selector',
  templateUrl: './resource-selector.component.html',
  styleUrls: ['./resource-selector.component.css']
})
export class ResourceSelectorComponent<T extends BaseModel> {

  @ViewChild('input',{static:true}) input : ElementRef;

  @Input() control: AbstractControl;
  @Input() displayField: string = null;
  @Input() secondDisplayField: string = null;  // caso o valor do display field seja null, mostra o valor do segundo field  (serve como uma segunda opção do que seá visualizado)

  @Input() set resources(data:T[]) {
    this._resources = data;
    this.filteredResources = this._resources;
    if (this.control && this.control.validator && this.control.validator({} as AbstractControl).required)
      this.canRemoveSelected = false;
    this.setSelectedIfOnly();
  }

  @Input() label:string;
  @Input() loading:boolean = false;
  @Input() allowCreation: boolean = false;
  @Input() searchKeys: string[] = [];
  @Input() maxHeight: number = 250;

  @Input() hideRemoveButtonWithOpacity:boolean = false;

  @Input() hideLabel: boolean = false;

  @Input() labelColor : string = '';

  @Input() disabled?: boolean = false;

  @Input() customRenderer: boolean = false;

  @Input() customInputClass : string = '';

  @Input() position: string = 'bottom';

  @Input() searchFunction;

  @Input() setFirstIfOnly = true;

  @Input() canRemoveSelected: boolean = false;
  @Input() removeSelectedMessage: string;

  @Input() translation:boolean = false;

  @Input() hideErrorIcon: boolean = false;

  @Input() allowFreeText: boolean = false;                                                                            // posso usar o selector para auxiliar pra "completar" o valor do campo. Porém pode ser que a opção não exista nos resources, então o uusário escreve o que eles quiser, sem necessáriamente ter escolhido um opção

  @ContentChild('itemTemplate',{static:false}) itemTemplate: TemplateRef<NgForOfContext<T>>;

  @Output() onCreateButtonPressed: EventEmitter<void> = new EventEmitter<void>();
  @Output() onItemSelected: EventEmitter<T> = new EventEmitter<T>();
  @Output() onSelectionCancelled: EventEmitter<void> = new EventEmitter<void>();

  public filteredResources: T[] = [];
  public selectedResource: T = null;
  private _resources: T[] = [];
  private touched: boolean = false;
  public isInvalid: boolean = false;

  public _displayList: string = 'none';

  constructor(public translate:TranslateService) { }

  public setSelectedIfOnly(){
    if(this.setFirstIfOnly){
      if(this._resources && this._resources.length == 1){
        this.setSelectedResource(this._resources[0]);
        this.onItemSelected.emit(this._resources[0]);
      }
    }
  }

  public setUncreatedResource(value: string){
    let newObj = {} as T;
    newObj.id = -1;
    newObj[this.displayField] = value;

    this._resources.push(newObj);
    this.setSelectedResource(newObj as T);
  }

  public setSelectedResourceByValue(value:string){
    try{
      let item = this._resources.find((resource:T) => resource[this.displayField].toLowerCase() == value.toLowerCase());
      if (item)
        this.setSelectedResource(item as T);
      else
        this.setUncreatedResource(value);
    }catch (e) {
      console.log(e);
      this.cancelSelection();
    }
  }

  public setResources(items:T[]) {
    this._resources = items;
    this.filteredResources = this._resources;
    this.setSelectedIfOnly();
  }

  public setSelectedResource(item:T | number) {
    if(typeof item == 'number'){
      item = this._resources.find((resource:T) => resource.id == item);
    }else{
      item = item as T;
    }
    this.selectedResource = item;
    if(this.control != null)
      this.control.setValue(item.id);

    let input = this.input.nativeElement;
    let inputValue = item[this.displayField];

    if(this.translation)
      inputValue = this.translate.data[inputValue];

    input.value = inputValue;

    if (!item[this.displayField] && this.secondDisplayField)
      input.value = item[this.secondDisplayField];
  }

  public canRemove() : boolean {
    return (this.canRemoveSelected && this.selectedResource != null)
  }

  public cancelSelection() {
    this.selectedResource = null;
    if(this.control != null)
      this.control.patchValue(null);
    this.input.nativeElement.value = null;
    this.onSelectionCancelled.emit();
  }

  public getSelectedresource() : T {
    return this.selectedResource;
  }

  public _filterResources(event): void {

    this.selectedResource = null;

    let searchTerm : string = event.target.value;
    let searchCriteria : string[] = this.searchKeys;

    if(searchCriteria.length == 0){
      searchCriteria = [this.displayField];
    }

    if (this.searchFunction) {
      this.searchFunction(searchTerm);
    } else if (searchTerm != '' && searchTerm != null) {
      searchTerm = searchTerm.toUpperCase();
      this.filteredResources = this._resources.filter((resource:T) => this.filterResource(resource,searchCriteria,searchTerm));
    } else {
      this.filteredResources = this._resources;
    }
  }

  private filterResource(resource:T,searchCriteria:string[],searchTerm:string) : boolean {
    for (let i = 0; i < searchCriteria.length; i++){
      let searchable = resource[searchCriteria[i]];
      if(searchable != null){
        searchable = searchable.toString().toUpperCase();
        if(searchable.indexOf(searchTerm) != -1)
          return true;
      }
    }
    return false;
  }

  public isValid() : boolean {

    if(this.control == null)
      return true;

    let valid = !(this.control.invalid && this.touched);

    if(valid && this.control.validator && this.control.validator({} as AbstractControl).required ){
      if(this.touched && this.selectedResource == null){
        valid = false;
      }
    }
    return valid;
  }

  emitNewItemButtonPressed(){
    this.onCreateButtonPressed.emit();
  }

  public onBlurSelector() {
    this._hideList();
    this.checkNoRequiredField();
  }

  public _hideList(){
    this.touched = true;
    setTimeout(()=>this._displayList='none',200);
  }

  public checkNoRequiredField() {
    if (!this.allowFreeText && this.input.nativeElement.value.length > 0 && this.selectedResource == null)
      this.isInvalid = true;
    else
      this.isInvalid = false;
  }

  emitItemSelection(event,item:T) {
    this.onItemSelected.emit(item);
    this.setSelectedResource(item);
    this.checkNoRequiredField();
    this.filteredResources = this._resources;
  }

  showResourceValue(resource: T){
    if (resource[this.displayField])
      return resource[this.displayField];

    if (this.secondDisplayField && resource[this.secondDisplayField])
      return resource[this.secondDisplayField];

    return ''
  }

  public getInputValue() {
    return this.input.nativeElement.value;
  }

  public setInputValue(text: string) {
    return this.input.nativeElement.value = text;
  }
}
