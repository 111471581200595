import { Injectable } from "@angular/core";
import Theme, * as themes from "../utils/theme";
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

@Injectable({
  providedIn: "root"
})
export class ThemeService {

  constructor(
    private localStorage: LocalStorageService
  ){}

  public active: Theme = themes.kat_light;
  private isKat: boolean = true;

  initFromUrl(url:string) {
    this.isKat = url != 'safety.360kpi.io';

    if(this.isKat){
      this.active = themes.kat_light;
    }else{
      this.active = themes.light;
    }
  }

  isKatDomain() : boolean {
    return this.isKat;
  }

  getActiveTheme(): Theme {
    let storedTheme = this.localStorage.retrieve('theme');
    if (storedTheme){
      if (storedTheme == themes.light.name && !this.isKat)
        this.active = themes.light;
      else if(storedTheme == themes.dark.name && !this.isKat)
        this.active = themes.dark;
      else if(storedTheme == themes.kat_light.name && this.isKat)
        this.active = themes.kat_light;
      else if(storedTheme == themes.kat_dark && this.isKat)
        this.active = themes.kat_dark;
    }
    return this.active;
  }


  setTheme(themeName:string){
    let selectedTheme = (themes[themeName] != null && themeName != "Theme")? themes[themeName]: themes.dark;
    this.setActiveTheme(selectedTheme);
  }

  setActiveTheme(theme: Theme): void {
    this.active = theme;
    this.localStorage.store('theme', this.active.name);
    Object.keys(this.active.properties).forEach(property => {
      document.documentElement.style.setProperty(property, this.active.properties[property]);
    });
  }
}
