import { kml } from "@tmcw/togeojson";


export class FilesUtil {

  public static KMLFileToJson(file:File,callback) {

    let reader = new FileReader();

    reader.onload = (ev) => {

      try{
        console.log("Received file");
        let data = ev.target['result'];
        let geoJson = kml(new DOMParser().parseFromString(data, "text/xml"))
        callback(geoJson);
      }catch (e) {
        console.log(e);
        callback(null);
      }

    };
    reader.readAsText(file,'utf-8');
  }


}
