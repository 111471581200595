import {Injectable, Injector} from '@angular/core';
import {SftEvent, SftEventGroup} from '../models/event.model';
import {BaseService} from './base.service';

@Injectable({
  providedIn: 'root'
})
export class EventGroupService extends BaseService<SftEventGroup> {

  constructor(public injector: Injector) {
    super(injector, 'safety/event-groups', SftEventGroup.fromJson);
  }
}
