<div mat-dialog-content>
    <div class="d-flex flex-column align-items-center">
        <i class="mdi mdi-check-circle" style="font-size: 4rem; color: var(--primary-color);"></i>
        <p class="mb-0" style="text-align: center;">{{data.message}}</p>
    </div>
</div>
<div mat-dialog-actions class="mt-2 d-flex justify-content-center">
    <button mat-button (click)="actionAndCloseDialog()">
        <span class="font-weight-bold">OK</span>
    </button>
</div>
