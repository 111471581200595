import { Paho } from 'src/app/libs/mqtt/mqttws31';
import {CustomService} from "../services/custom.service";


export const FlespiConstants  = {
  BASE_DEVICES_TOPIC: 'flespi/message/gw/devices/',
  BASE_CALCULATORS_TOPIC: 'flespi/interval/gw/calcs/',
};

const HOST = 'mqtt.flespi.io';
const PORT = 443;
const PATH = '/wss';


export class MqttController{

  private customService: CustomService;

  private refreshToken: boolean = false;
  private controllerDestroyed: boolean = false;
  private connecting : boolean = false;
  private flespiToken: string;

  private userName: string = null;
  private brokerConnection: boolean = false;
  private cleanSession: boolean = true;

  public client: Paho.MQTT.Client;

  public onConnected(){};

  public onMessageArrived(message: any,topic:string){};

  constructor(customService: CustomService,brokerConnection?:boolean,cleanSession?:boolean){
    this.customService = customService;
    if(brokerConnection != null){
      this.brokerConnection = brokerConnection;
    }
    if(cleanSession != null){
      this.cleanSession = cleanSession;
    }
  }

  public setUserName(userName:string){
    this.userName = userName;
  }

  public downloadFlespiTokenAndConnect(): Promise<string> {

    this.connecting = true;

    if(this.brokerConnection){
      return this.customService.getBrokerFlespiToken().then(
        (data) => {
          this.flespiToken = data.token;

          if (!this.controllerDestroyed){
            this.connectToFlespi(this.cleanSession,this.userName);
          }

          return data.base_topic;
        }
      ).catch(e => {
        console.log('Error Downloading Broker Flespi Token', e);
        this.connecting = false;
        return null;
      });
    }else{
      return this.customService.getFlespiToken().then(
        (token: any) => {
          this.flespiToken = token.flespi_token;
          if (!this.controllerDestroyed)
            this.connectToFlespi(this.cleanSession,this.userName);
          return null;
        }
      ).catch(e => {
        console.log('Error Downloading Company Flespi Token', e);
        this.connecting = false;
        return null;
      });
    }
  }

  public isConnecting() : boolean {
    return this.connecting;
  }

  private connectToFlespi(cleanSession?:boolean, userName?:string) {

    let clean : boolean = cleanSession != null ? cleanSession : true;
    let user : string = userName != null ? userName : this.flespiToken;

    this.client = new Paho.MQTT.Client(HOST, PORT, PATH, user);
    this.client.onMessageArrived = this.onNewMessage;
    this.client.onConnectionLost = this.onConnectionLost;

    this.client.connect({ useSSL: true, userName: 'FlespiToken ' + this.flespiToken, password: '', onSuccess: this.onConnect,cleanSession:clean,onFailure: (error) => {
      console.log("ERROR MQTT",error);
        this.connecting = false;
      }});
    this.refreshToken = true;
  }

  private onConnect = () => {
    console.log("On Connected");
    this.connecting = false;
    if (!this.controllerDestroyed)
      this.onConnected();
    else{
      console.log("Disconnecting, controller is destroyed");
      this.client.disconnect();
    }
  };

  private onConnectionLost = (r) => {
    console.log('LOST CONNECTION', r);
    if(this.refreshToken)
      this.downloadFlespiTokenAndConnect().then(()=>{});
  };

  disconnect(){
    this.refreshToken = false;
    this.controllerDestroyed = true;
    if(this.client && this.client.isConnected())
      this.client.disconnect();
  }

  private onNewMessage = (message: Paho.MQTT.Message) => {
    let messageObj = JSON.parse(message.payloadString);
    let topic = message.destinationName;
    this.onMessageArrived(messageObj,topic);
  };


  public isConnected() : boolean {
    return this.client && this.client.isConnected();
  }

  public subscribeTo(path: string,qos?:number) {
    if(!path)
      return;

    let qosParam = qos == null ? 0 : qos;
    this.client.subscribe(path, {qos:qosParam});
  }

  public refresh(){
    this.controllerDestroyed = false;
  }

  public unsubscribeTo(path){
    if(!path)
      return;
    this.client.unsubscribe(path, {});
  }
}
