<div [matMenuTriggerFor]="panel" (menuClosed)="onClosed()" (menuOpened)="onOpened()">
  <button mat-button matBadgeSize="small" [matBadge]="unreadCount.toString()" matBadgeColor="warn" [matBadgeHidden]="unreadCount==0" [ngClass]="['menu_button d-flex flex-row shadow-sm',getFlashingAnimation()]">
    <i class="mdi mdi-bell-alert-outline text-white" ></i>
    <span class="mb-0 ml-2 text-white">{{ 'label.notifications'| translate }}</span>
  </button>
  <mat-menu #panel="matMenu" class="alert-menu" (click)="clickAnywhere($event)">
    <div class="panel" (click)="clickAnywhere($event)">
      <div class="d-flex flex-row align-items-center top-bar shadow-steppy">
        <app-ngx-small-toggle
          [checked]="soundEnabled"
          (onValueChange)="onSoundCheckedChange($event)"
          title="{{'label.sound' | translate}}"
        ></app-ngx-small-toggle>
        <i class="ml-auto mdi mdi-playlist-remove clean-icon" matTooltip="{{'label.clean_notifications' | translate }}" (click)="clean($event)"></i>
      </div>
      <div class="content-area">
        <div *ngFor="let alert of alerts" class="alert-card shadow-sm p-2 px-4 m-3 d-flex flex-column alert-texts" (click)="clickAnywhere($event)">
          <div class="d-flex flex-row align-items-center">
            <span style="font-weight: 500;color: var(--focus-text-color)">{{alert.title | translate}} {{alert.details.vehicle_name}}</span>
            <i *ngIf="alert.unread" class="ml-auto mdi mdi-bell-ring" style="color: var(--error)"></i>
          </div>
          <div class="d-flex flex-row align-items-center mt-1">
            <div class="d-flex flex-column">
              <span>{{alert.details.event_name}} ({{alert.details.tag_name_keylang | translate}})</span>
              <span style="opacity: 0.8;font-size: 7pt;font-weight: 400" class="mt-2">{{getAlertDatetime(alert) | fullDate }}</span>
            </div>
            <div class="ml-auto" *ngIf="alert.details.id_incident">
              <i class="mdi mdi-file-search-outline view-icon"  matTooltip="{{'app.details' | translate }}" (click)="clickDetails($event,alert)"></i>
            </div>
          </div>
        </div>
        <div *ngIf="alerts.length == 0" class="d-flex flex-column align-items-center p-4">
          <i  class="mdi mdi-bell-off-outline" style="color: var(--foreground-secondary)"></i>
          <span style="color: var(--foreground-secondary);font-weight: 400;font-size: 0.7em">{{'label.no_nofications' | translate }}</span>
        </div>
      </div>
    </div>
  </mat-menu>
</div>
