import {Pipe, PipeTransform} from '@angular/core';
import { formatDate } from '@angular/common';
import { TranslateService } from '../../translate.service';

export class SafeSafari {

    static formatDate(value:string|number|Date,format:string,locale:string,timezone?:string){
  
      try{
        return formatDate(value, format, locale);
      }catch (e) {
        value = value.toString().replace(' ','T');
        return formatDate(value, format, locale);
      }
    }
  }


  @Pipe({
    name: 'shortDate',
    pure: false
  })
  export class ShortDatePipe implements PipeTransform {
  
    constructor(public translate : TranslateService) {}
  
    transform(dateString: string,type='short',locale=null): string {
      if (!dateString)
        return  "";
      return SafeSafari.formatDate(dateString,type,(locale != null)?locale:this.translate.lang);
    }
  }
  
  @Pipe({
      name: 'hourFormat',
      pure: false
  })
  export class HourPipe implements PipeTransform {
  
    constructor(public translate : TranslateService) {}
  
      transform(dateString: string, locale=null): string {
          if (!dateString)
            return  "";
        return SafeSafari.formatDate(dateString,'shortTime',(locale != null)?locale:this.translate.lang);
      }
  }
  
  @Pipe({
    name: 'fullDate',
    pure: false
  })
  export class FullDatePipe implements PipeTransform {
  
    constructor(public translate : TranslateService) {}
  
    transform(dateString: string, locale=null): string {
      if (!dateString)
        return  "";
  
      return SafeSafari.formatDate(dateString,'medium',(locale != null)?locale:this.translate.lang);
    }
  }
  
  @Pipe({
      name: 'dayMonth',
      pure: false
  })
  export class DayMonth implements PipeTransform {
  
      constructor(public translate : TranslateService) {}
  
      transform(dateString: string, locale=null): string {
          if (!dateString)
              return  "";
  
          if(this.translate.lang == 'en-US')
            return SafeSafari.formatDate(dateString,'mm/dd',(locale != null)?locale:this.translate.lang);
          return SafeSafari.formatDate(dateString,'dd/mm',(locale != null)?locale:this.translate.lang);
      }
  }
  
  @Pipe({
    name: 'dayFormat',
    pure: false
  })
  export class DayPipe implements PipeTransform {
  
    constructor(public translate : TranslateService) {}
  
    transform(dateString: string, locale=null): string {
        if (!dateString)
            return  "";
  
        return SafeSafari.formatDate(dateString,'shortDate',(locale != null)?locale:this.translate.lang);
    }
  }

@Pipe({
    name: 'dayHourFormat',
    pure: false
})
export class DayHourPipe implements PipeTransform {

    constructor(private translate:TranslateService) {}

    transform(dateString: string): string {
        if (!dateString)
            return  "--:--";
        if(this.translate.lang == 'en-US')
            return formatDate(dateString,'MM/dd hh:mm aa', this.translate.lang);
        return formatDate(dateString,'dd/MM HH:mm', this.translate.lang);

    }
}

