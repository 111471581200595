import {Injectable, Injector} from '@angular/core';
import {SftGeofenceModel} from '../models/sft-geofence.model';
import {BaseService} from './base.service';

@Injectable({
  providedIn: 'root'
})
export class GeofenceImportService extends BaseService<SftGeofenceModel> {

  constructor(protected injector: Injector) {
    super(injector, 'safety/geofences/import', SftGeofenceModel.fromJson);
  }

  sendKMLData(kmlData:any) : Promise<SftGeofenceModel[]> {
    return this.customPost(kmlData);
  }

}
