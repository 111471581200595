<mat-expansion-panel #panel (expandedChange)="expanded=$event" class="sub-expansion-panel">
  <mat-expansion-panel-header class="sub-expansion-panel-header">
    <mat-checkbox [indeterminate]="someComplete()" [checked]="checked" (change)="toggleGeofenceTypeGroup(dataSource.data, $event.checked)" (click)="$event.stopPropagation()"></mat-checkbox>
    <span style="margin-top: 1px">{{title}}</span>
  </mat-expansion-panel-header>
  <div>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="status" matSortDirection="desc" class="bg-transparent">
      <ng-container matColumnDef="name">
        <th mat-header-cell style="margin-left: 50px" *matHeaderCellDef mat-sort-header>{{'label.name' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex flex-row align-items-center">
            <mat-checkbox (change)="toggleGeofenceVisibility(element)" class="vehicle-checkbox" [checked]="element.visible"></mat-checkbox>
            <span class="ml-2" style="margin-top: 2px">{{element.resource.name}}</span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="speed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'nav.speed' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.resource.global_max_speed ? element.resource.global_max_speed : '--' ) + ' ' + preferencesService.getSpeedUnit()}}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'label.type' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.resource.type ? ( element.resource.type.keylang | translate ) : '' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div class="table_options w-100 d-flex flex-row align-items-center">
            <button class="table_options_button ml-2" (click)="locateGeofence(element)" matTooltip="{{'label.location' | translate }}">
              <i class="mdi mdi-crosshairs"></i>
            </button>
            <button *ngIf="enableEditing" class="table_options_button ml-2" (click)="openEditGeofenceDialog(element.resource)" matTooltip="{{'label.edit'|translate}}">
              <i class="mdi mdi-square-edit-outline h6 mb-0"></i>
            </button>
            <button *ngIf="enableSharing && enableEditing" class="table_options_button ml-2" (click)="onGeofenceSharingClicked.emit(element.resource)" matTooltip="{{'label.share_geofence'|translate}}">
              <i class="mdi mdi-share-variant-outline h6 mb-0"></i>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" style="background:var(--background-table-header)!important;"></tr>
      <tr mat-row [id]="row.resource.id" [class]="'table_cell ' + getFlashingAnimationPanel(row)" *matRowDef="let row; columns: displayedColumns; let index = index" style="background:var(--background-table-body);height: 35px"></tr>
    </table>
  </div>
</mat-expansion-panel>
