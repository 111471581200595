import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from './translate.service';

const DAY = 86400;
const HOUR = 3600;
const MINUTES = 60;

const DAY_STRING = 'day';
const HOUR_STRING = 'hour';
const MINUTES_STRING = 'minute';
const SECOND_STRING = 'second';

@Pipe({
  name:'duration',
  pure:false,
})
export class DurationPipe implements PipeTransform{

  constructor(public translate : TranslateService){

  }
  // Todo se tudo for null fazer dias -> minutos com abrevv;
  transform(seconds:number,maxDurationMode?:string,minDurationMode?:string,abbrev?:boolean):string {

    if(!maxDurationMode)
      maxDurationMode = DAY_STRING;

    if(!minDurationMode)
      minDurationMode = MINUTES_STRING;

    if(abbrev === undefined)
      abbrev = true;

    let duration:DurationModel = TimeController.getTime(seconds,maxDurationMode);

    let durationString:string = '';

    let days:string;
    let hours:string;
    let minutes:string;
    let sec:string;

    if(abbrev){
      days = this.translate.data['abbrev.day'];
      hours = this.translate.data['abbrev.hour'];
      minutes = this.translate.data['abbrev.minutes'];
      sec = this.translate.data['abbrev.seconds'];
    }else{
      days = this.translate.data['label.days'];
      hours = this.translate.data['nav.hours'];
      minutes = this.translate.data['nav.minutes'];
      sec = this.translate.data['label.seconds'];
    }

    switch(maxDurationMode){

      case DAY_STRING:

        if(duration.days != 0){
          durationString += `${duration.days}`;
          if(!abbrev)
            durationString += ' ';
          durationString += `${days} `;
        }

        if(DAY_STRING == minDurationMode)
          break;

      case HOUR_STRING:
        if(duration.hours){
          durationString += `${duration.hours}`;
          if(!abbrev)
            durationString += ' ';
          durationString += `${hours} `;
        }
        if(HOUR_STRING==minDurationMode)
          break;

      case MINUTES_STRING:
        if(duration.minutes){
          durationString += `${duration.minutes}`;
          if(!abbrev)
            durationString += ' ';
          durationString += `${minutes} `;
        }

        if(MINUTES_STRING==minDurationMode)
          break;

      case 'second':
        if(duration.seconds){
          durationString += `${duration.seconds}`;
          if(!abbrev)
            durationString += ' ';
          durationString += `${sec}`;
        }
    }

    return durationString;
  }

}

export class DurationModel{
  days:number;
  hours:number;
  minutes:number;
  seconds:number;

}

export class TimeController {

  constructor(){};

  static getTime(seconds:number,maxDurationMode:string):DurationModel{

    let time = new DurationModel();
    let durationModule:number = seconds;

    switch(maxDurationMode){

      case DAY_STRING:
        time.days = Math.floor(seconds / DAY);
        durationModule = Math.floor(seconds % DAY);

      case HOUR_STRING:
        time.hours = Math.floor(durationModule / HOUR);
        durationModule = Math.floor(durationModule % HOUR);

      case MINUTES_STRING:
        time.minutes = Math.floor(durationModule / MINUTES);
        durationModule = Math.floor(durationModule % MINUTES);

      case SECOND_STRING:
        time.seconds = durationModule;

    }

    //console.log(time);
    return time;
  }
}
