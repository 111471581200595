
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Company } from '../../../models/company.model';
import { CompanyService } from '../../../services/company.service';
import { ToastrService } from 'ngx-toastr';
import { UserModel as  User } from 'src/app/models/user.model';
import { UserService } from '../../../services/user.service';
import {MatProgressButtonOptions} from 'mat-progress-buttons';
import {TranslateService} from '../../../../../projects/ngx-loop-components/src/lib/translate.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css']
})
export class UserFormComponent implements OnInit {

  resetEmailButtonOptions: MatProgressButtonOptions = {
    text: 'Reset Password',
    spinnerSize: 18,
    buttonColor: 'primary',
    raised: false,
    spinnerColor: 'accent',
    mode: 'indeterminate',
    active: false,
    disabled: false,
  };

  editing: boolean;
  sending: boolean;

  loadingCompanies: boolean;
  companies: Company[];

  editUser: User = new User();

  form: FormGroup;
  userId: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private companyService: CompanyService,
    private toast: ToastrService,
    private translate: TranslateService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.initForm();
    this.downloadCompanies();
    this.checkEditing();
  }

  initForm() {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      username: [null, Validators.required],
      email: [null, Validators.required],
      senha: [null, Validators.required],
      confirmSenha: [null, Validators.required],
      company: [null, Validators.required]
    });
  }

  downloadCompanies() {
    this.loadingCompanies = true;
    this.companyService.getResources().then(
      (companies: Company[]) => {
        this.companies = companies;
        if (companies.length === 1 && this.editing === false) {
          this.form.patchValue({ 'company': companies[0] });
        }
      }
    ).catch(e => {
      console.log('erro', e);
      this.toast.error(this.translate.data['msg.erro']);
    }).finally(
      () => {
        this.loadingCompanies = false;
      }
    );
  }

  checkEditing() {
    let parts = this.router.url.split('/');

    this.editing = parts[parts.length - 1] === 'edit';
    if (this.editing) {
      this.userId = parts[parts.length - 2];
      this.downloadUser();
      this.form.get('email').disable();
    }
  }

  downloadUser() {
    this.sending = true;
    this.userService.getResource(this.userId).then(
      (user: User) => {
        this.editUser = user;

        this.form.patchValue({'name': user.name});
        this.form.patchValue({'username': user.username});
        this.form.patchValue({'email': user.email});

        let company: Company = Company.fromJson(user.company);

        this.form.patchValue({ 'company': company });
      }
    ).catch(e => {
      this.toast.error(this.translate.data['msg.erro']);
    }).finally(() => {
      this.sending = false;
    });
  }

  save() {
    this.sending = true;

    this.editUser.name = this.form.get('name').value;
    this.editUser.username = this.form.get('username').value;
    this.editUser.company = this.form.get('company').value.id;

    if (this.editing === false) {

      this.editUser.email = this.form.get('email').value;
      this.editUser.password = this.form.get('senha').value;

      console.log('user', this.editUser);

      this.userService.create(this.editUser).then(
        (response: User) => {
          this.router.navigateByUrl('/users');
        }
      ).catch(e => {
        this.toast.error(this.translate.data['msg.erro']);
      }).finally(() => {
        this.sending = false;
      });

    } else {
      console.log('user',this.editUser);
      this.userService.update(this.editUser, this.editUser.id).then(
        (user: User) => {
          this.router.navigateByUrl('/users');
        }
      ).catch(e => {
        this.toast.error(this.translate.data['msg.erro']);
      }).finally(() => {
        this.sending = false;
      });
    }

  }

  delete() {
    this.sending = true;
    this.userService.delete(this.userId).then(
      response => {
        if(response) {
          this.router.navigateByUrl('/users');
        }
      }
    ).catch(e => {
      console.log('Error',e);
      this.toast.error(this.translate.data['msg.erro']);
    }).finally(() => {
      this.sending = false;
    })

  }

  checkFieldValidator(field: string): boolean{
    return this.form.get(field).invalid && this.form.get(field).touched;
  }

  compareCompany(c1: Company, c2: Company): boolean {
    return c1 && c2 && c1.id === c2.id;
  }

  isFormValid() {
    return this.form.valid && this.checkPassword();
  }

  checkPassword() {
    return this.form.get('senha').value === this.form.get('confirmSenha').value;
  }

}
