import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {SessionStorageService} from 'ngx-webstorage';
import {DateAdapter} from '@angular/material';
import {DatePipe, DecimalPipe} from '@angular/common';
import {SecToDateTime} from './sec-to-date-pipe';

const DEFAULT_LANG = "en-US";

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  data: any = {};
  public lang : string;

  constructor(
    private http: HttpClient,
    private sessionsStorage: SessionStorageService,
    private adapter: DateAdapter<Date>,
    private datePipe:DatePipe,
    private decimalPipe: DecimalPipe,
    private secToDateTime:SecToDateTime,
    @Inject('environment') public environment
  ) {}

  use(lang: string): Promise<{}> {
    this.lang = lang;
    this.adapter.setLocale(lang);
    return new Promise<{}>((resolve, reject) => {

      let path = this.environment.api + 'core/keylangs-json/' + lang;

      this.http.get(path, {observe: 'response'}).subscribe(
        (res: HttpResponse<{data: any}>) => {
          let langData = res.body.data;
          this.data = langData;
          resolve(langData);
      }, error => {
        console.log('kyelang err', error);
        let langData = {};
        this.data = langData;
        resolve(langData);
      });
    });
  }

  _downloadKeylangJson(lang: string) {
    let path = this.environment.api + 'core/keylangs-json/' + lang;

    this.http.get(path, {observe: 'response'}).toPromise().then(
      (response: HttpResponse<{data: any}>) => {
        console.log(response);
      }).catch(e => {
        console.log('e', e);
      });
  }

  setLanguage(lang: string){
    this.sessionsStorage.store('language', lang);
  }

  getLanguage(){
    let preferredLang = this.sessionsStorage.retrieve('language');
    if (!preferredLang)
      preferredLang = DEFAULT_LANG;
    return preferredLang
  }

  public translateContent(text,locale:string) : string {
    let items = text.match(/(\{\{(.*?)\}\})/g);
    if (items != null && Array.isArray(items) && items.length) {
      for (let item of items) {
        if (item.includes('|')) {
          let value = item.split('|')[0].replace('{{', '');
          let pipe = item.split('|')[1].replace('}}', '');
          let result = value;
          if (pipe == 'translate') {
            result = this.data[value];
          } else if (pipe.includes('date')) {
            if (pipe.includes('date:')) {
              let format = pipe.replace('date:', '');
              result = this.datePipe.transform(value, format, null, locale);
            }
          } else if (pipe.includes('number')) {
            if (pipe.includes('number:')) {
              let format = pipe.replace('number:', '');
              result = this.decimalPipe.transform(value, format);
            }
          } else if (pipe.includes('time')) {
            if (pipe.includes('time:')) {
              let format = pipe.replace('time:', '');
              result = this.secToDateTime.transform(value, format);
            }
          }
          text = text.replace(item, result);
        } else {
          try {
            text = text.replace(item, this.data[item.replace('{{', '').replace('}}', '')]);
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
    return text;
  }
}
