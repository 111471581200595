import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {UserModel as User} from '../models/user.model';
import {SessionStorageService} from 'ngx-webstorage';
import {LoginService} from './login.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { AuthModel } from '../models/auth.model';
import {AuthResponse} from '../models/auth-response.model';
import {PermissionService} from './permission.service';

export interface AuthMonitor {
  onLoginStateChanged(isLoggedIn:boolean,redirect:boolean) : void;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isUserInStorage : boolean = false;

  public isFirstLogin: boolean = false;
  public monitor: AuthMonitor = null;

  public authFromLanding: boolean = false;

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private httpClient: HttpClient,
    public permissionService: PermissionService,
    private sessionStorage: SessionStorageService
  ) {
    this.observeUserState();
  }

  setAuthMonitor(component:AuthMonitor) {
    this.monitor = component;
    this.monitor.onLoginStateChanged(this.isLoggedIn(),this.isFirstLogin);
  }

  isLoggedIn() : boolean {
    return this.isUserInStorage;
  }

  onLoginStateChanged(isUserInStorage:boolean){

    let oldIsloggedIn = this.isLoggedIn();

    if(isUserInStorage != this.isUserInStorage){
      this.isUserInStorage = isUserInStorage;
    }

    let newIsLoggedIn = this.isLoggedIn();

    if(newIsLoggedIn != oldIsloggedIn && this.monitor){
      this.monitor.onLoginStateChanged(this.isLoggedIn(),this.isFirstLogin);
      this.isFirstLogin = false;
    }
  }

  observeUserState() {

    this.userService.getUserStoreObservable().subscribe(res => {
      this.onLoginStateChanged(!!res);
    });

    let user = this.userService.getUserStore();
    this.onLoginStateChanged(!!user);
  }

  storeTokens(idtoken:string,refreshToken:string) {
    this.sessionStorage.store("business",5);
    this.sessionStorage.store('token',idtoken);
    this.sessionStorage.store('rt',refreshToken);
  }

  populatePermission(permissions) {
    let userPermissions = {};

    for (let business of permissions) {

      userPermissions[business['ID']] = true;

      for (let parent of business['ACCESSES']) {

        userPermissions[parent['IDACCESS']] = true;

        for (let child of parent['CHILDREN']) {

          userPermissions[child['IDACCESS']] = true;
        }
      }
    }
    this.permissionService.storePermissions(userPermissions);
  }

  loginV2(email: string, password: string): Promise<User> {

    this.isFirstLogin = true;
    let authUrl = 'auth/authenticate';

    return new Promise<User>(((resolve, reject) => {

      let loginBody = {
        email: email,
        password: password
      };

      this.httpClient.post(environment.api+authUrl,loginBody).toPromise().then((response:{success:boolean,message:string,data:AuthModel}) => {
        if(response.success){

          let token = response.data.token;
          let refresh_token = response.data.refreshToken;
          let uid = response.data.id;

          this.storeTokens(token,refresh_token);

          this.loginService.getResource(uid).then(
            (user: User) => {
              if (user != null) {
                this.userService.storeUser(user);
                this.populatePermission(user.permissions);
                resolve(user);
              } else {
                this.firebaseLogout();
                reject('Internal Error');
              }
            }
          ).catch(er => {
            console.log(er);
            this.firebaseLogout();
            reject(new Error('Erro de conexão, tente novamente'));
          });
        }else{
          reject(new Error(response.message));
        }
      }).catch(e => {
        reject(e);
      });
    }));
  }

  firebaseLogout(){
    this.sessionStorage.clear('token');
  }

  logout() {
    this.firebaseLogout();
    this.sessionStorage.clear();
  }

  public handleExternalRequest(token:string,refreshToken:string) : Promise<boolean>{
    let url = 'auth/tokenauthentication';

    return new Promise<boolean>(((resolve, reject)=> {
      let body = {
        token: token,
        refresh: refreshToken
      };

      this.httpClient.post(environment.api + url, body).toPromise()
        .then((response: {success: boolean, message: string, data: AuthResponse}) => {
          if(response.success){

            let token = response.data.token;
            let refresh_token = response.data.refreshToken;
            let uid = response.data.id;

            this.storeTokens(token,refresh_token);
            this.authenticatedUser(uid).then(
              (success: boolean) => {
                resolve(success)
              }
            ).catch(er => {
              console.log(er);
              reject(er);
            });
          }
          else
            reject(new Error(response.message))
        })
        .catch((error) => {
          reject(new Error(error))
        });
    }))
  }

  private authenticatedUser(uid:string) : Promise<boolean> {
    return new Promise<boolean>((resolve,reject) => {
      this.loginService.getResource(uid).then(async (user: User) => {
        this.sessionStorage.store("user", user);
        this.userService.storeUser(user);
        resolve(true)
      }).catch(error=>{
        reject(error);
      })
    });
  }

  setAuthFromLanding(value: boolean){
    this.authFromLanding = value;
  }

  isFromLanding(): boolean {
    return this.authFromLanding;
  }


}
