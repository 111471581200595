<div class="w-100 h-100 position-absolute">
  <mat-progress-bar mode="indeterminate" *ngIf="loading" color="accent"></mat-progress-bar>
  <div class="d-flex flex-row h-100">
    <div class="card-2 h-100" style="background-color: var(--background-card);z-index: 1;min-width: 400px;overflow-y: auto">
      <mat-accordion [multi]="true">
        <app-incident-panel
          #incidentPanel
          [liveMap]="liveMap"
        >
        </app-incident-panel>
        <app-geofence-panel
          #geofencePanel
          [liveMap]="liveMap"
        >
        </app-geofence-panel>
        <NgxLoopMapVehiclePanel
          #vehiclePanel
          [enableTrackTt]="true"
          [liveMap]="liveMap"
          (onTrackItPeriodChanged)="onTrackItPeriodChanged($event)"
          (onVehiclesMapDoneBuilding)="onVehiclesMapReceived($event)"
        >
        </NgxLoopMapVehiclePanel>
      </mat-accordion>
    </div>
    <NgxLoopVehicleLiveMap
      #liveMap
      [draw]="true"
      class="h-100 w-100"
      style="z-index: 0;"
    >
    </NgxLoopVehicleLiveMap>
  </div>

</div>


