import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
  MatDialog,
  MatPaginator,
  MatSelectChange,
  MatSliderChange,
  MatSlideToggleChange,
  MatSnackBar,
  MatSnackBarConfig,
  MatTableDataSource,
  PageEvent
} from '@angular/material';
import {NgxLoopVehicleLiveMap, VehicleMap} from '../ngx-loop-vehicle-livemap/ngx-loop-vehicle-livemap';
import moment from 'moment';
import {PreferencesService} from '../preferences.service';
import {LiveMapService} from '../depends/livemap.service';
import * as L from 'leaflet';
import {HourPipe} from '../depends/pipe/date.pipe';
import {ConfigurationService} from '../depends/configuration.service';
import {TranslateService} from '../translate.service';
import {SatDatepicker, SatDatepickerRangeValue} from 'saturn-datepicker';
import {TrackItPeriodChangeEvent} from '../ngx-loop-map-vehicle-panel/ngx-loop-map-vehicle-panel';
import {formatNumber} from '@angular/common';

const LAYER_POLYLINE = 'trackit_polyline';
const POINT_EXTRA_LAYER = 'trackit_points_extras';

export interface TrackItPointExtras {
  latitude:number,
  longitude:number,
  timestamp:number,
  color: string,
  title:string,
  relativeMessageIndex?:number
}

@Component({
  selector: 'ngx-track-it-panel',
  templateUrl: './ngx-track-it-panel.component.html',
  styleUrls: ['./ngx-track-it-panel.component.scss']
})
export class NgxTrackItPanel implements OnInit,AfterViewInit,OnChanges,OnDestroy {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('rangePicker',{static:false}) rangePicker: SatDatepicker<any>

  @Input()
  set trackItVehicleSetter(value:VehicleMap) {
    if(this.trackItVehicle != null && value != null){
      if(value.vehicle.id != this.trackItVehicle.vehicle.id){
        this.trackItVehicle = value;
        this.onVehicleChanged();
      }
    }else{
      this.trackItVehicle = value;
    }
  }

  trackItVehicle: VehicleMap;

  @Input()
  liveMap: NgxLoopVehicleLiveMap;

  @Input()
  additionalFields: {title:string,field:string}[] = [];

  @Output()
  onClosed: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onTrackItChanged: EventEmitter<TrackItPeriodChangeEvent> = new EventEmitter<TrackItPeriodChangeEvent>();

  @Output()
  ref: EventEmitter<NgxTrackItPanel> = new EventEmitter<NgxTrackItPanel>();

  selectedPeriod: {start:string,end:string};
  loading:boolean = false;

  pageSize: number = 100;
  currentPage: number = 0;

  public dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['options','timestamp','speed','latitude','longitude'];

  messages:any[] = [];

  selectedMessage:any;
  currentTableIndex: number = -1;

  pulseMarker: L.Marker = null;
  vehicleMarker: L.Marker = null;

  sekkerSize: number;
  sekkerIndex: number = 0;

  hourPipe: HourPipe;

  isPlaying:boolean = false;
  intervalId: number = -1;
  minimized: boolean = false;

  playbackSpeed = 150;

  scrollToIndex: number = null;
  selectedRange : SatDatepickerRangeValue<any>;

  pointExtras: TrackItPointExtras[] = [];
  extrasProcessed: boolean = false;

  showEvents: boolean = true;

  constructor(
    public liveMapService: LiveMapService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private configurationService: ConfigurationService,
    public preferenceService:PreferencesService,
    public translate: TranslateService
) {
    this.hourPipe = new HourPipe(translate);
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit() {
    this.additionalFields.forEach(a => {
      this.displayedColumns.push(a.field);
    });
  }

  onShowEventsChanged(event:MatSlideToggleChange){
    this.showEvents = event.checked;
    this.setEventsMakerVisibility(this.showEvents);
  }

  showEventsToggleButton() : boolean {
    return this.pointExtras != null && this.pointExtras.length > 0;
  }

  setEventsMakerVisibility(visibility:boolean) {
    this.liveMap.toggleAllResourceVisibility(POINT_EXTRA_LAYER,!visibility);
  }

  getAccDist() : string {
    try{

      if(this.selectedMessage == null)
        return null;

      return formatNumber(this.preferenceService.convertDistance(this.selectedMessage['acc_dist'],false), this.translate.lang, '1.2-2');

    }catch (e) {
      return null;
    }
  }

  initFirstDate(){
    let beginDate = moment();
    this.selectedRange = {
      begin : beginDate.startOf('day'),
      end : beginDate.endOf('day'),
    };
    this.onRangeSelected(this.selectedRange);
  }

  onPage(event:PageEvent){
    console.log(event);
    this.currentPage = event.pageIndex;

    if(this.scrollToIndex != null){
      console.log(this.scrollToIndex);
      setTimeout(()=>{
        this.scrollToMessage(this.scrollToIndex);
        this.scrollToIndex = null;
      },200)
    }
  }

  cleanPointExtras(){
    this.liveMap.getLayer(POINT_EXTRA_LAYER).clearLayers();
    this.extrasProcessed = false;
    if(!this.showEvents){
      this.showEvents = true;
      this.setEventsMakerVisibility(true);
    }
  }

  public setPointExtras(extras:TrackItPointExtras[]){
    this.cleanPointExtras();
    this.pointExtras = extras;
    if(this.canProcessExtras()){
      this.processExtras();
    }
  }

  processExtras() {
    if(!this.extrasProcessed){

      this.extrasProcessed = true;
      this.pointExtras.forEach(extra => {

        const eventIcon = {
          icon: L.divIcon({
            className: "css-icon",
            html: `<div class="event-marker" style="border-color:${extra.color}"></div>`,
            iconSize: [18, 22]
          })
        };

        let marker: L.Marker = L.marker([extra.latitude, extra.longitude], eventIcon);
        this.liveMap.bindToolTip(extra.title,marker,'event-tooltip');
        marker.addTo(this.liveMap.getLayer(POINT_EXTRA_LAYER));
      });

      let extrasIndex = 0;
      for(let i = 0; i < this.messages.length; i++){
        if(extrasIndex == this.pointExtras.length)
          break;

        let extra = this.pointExtras[extrasIndex];
        let nextMessage = null;

        if(i + 1 < this.messages.length){
          nextMessage = this.messages[i + 1];
        }

        if(nextMessage == null){
          extra.relativeMessageIndex = i;
          extrasIndex++;
        }else{

          if(extra.timestamp <= this.messages[i].timestamp){
            if(extra.timestamp >= nextMessage.timestamp){
              extra.relativeMessageIndex = i;
              extrasIndex++;
            }
          }
        }
      }
      console.log(this.pointExtras);
    }
  }

  getEventPointPositionInTimeline(event:TrackItPointExtras) : number {

    if(event.relativeMessageIndex == null || this.messages.length == 0)
      return null;

    let totalSize = this.messages.length - 1;
    let timelinePosition = this.convertMessageIndex(event.relativeMessageIndex);
    return (timelinePosition * 100) / totalSize;
  }

  reset(){
    this.cancelPlayInterval();
    this.selectedMessage = null;
    this.messages = [];
    this.dataSource.data = [];
    this.sekkerIndex = 0;
    this.sekkerSize = null;
    this.cleanMarkers();
  }

  onVehicleChanged() {
    this.reset();
    this.onTrackItChanged.emit({begin:this.selectedPeriod.start,end:this.selectedPeriod.end,idVehicle:this.trackItVehicle.vehicle.id});
    this.downloadMessages();
  }

  scrollToMessage(messageIndex:number) {

    let nextPage = this.getPageIndex(messageIndex);

    if(nextPage != this.currentPage){

      console.log("GOING TO PAGE ",nextPage);
      this.paginator.pageIndex = nextPage;
      this.paginator.page.next({
        pageIndex: nextPage,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length
      });

      this.scrollToIndex = messageIndex;

    }else{
      console.log(this.currentPage);
      let element = document.getElementById('MESSAGE_'+messageIndex.toString());
      if(element != null)
        element.scrollIntoView();
    }
  }

  getAdditionalFieldValue(field:string,element:any) {
    try{
      return element[field];
    }catch (e) {
      return '';
    }
  }

  ngAfterViewInit(): void {
    this.ref.emit(this);

    this.liveMap.createLayer(LAYER_POLYLINE);
    this.liveMap.createLayer(POINT_EXTRA_LAYER);
    this.initFirstDate();
  }

  getPageIndex(messageIndex:number) : number {
    return Math.floor((messageIndex + 1)/this.pageSize);
  }

  ngOnDestroy(): void {
    this.ref.emit(null);

    this.liveMap.removeLayer(LAYER_POLYLINE);
    this.liveMap.removeLayer(POINT_EXTRA_LAYER);

    this.cleanMarkers();
    this.cancelPlayInterval();
  }

  copyMessage(message:any,event:MouseEvent) {
    event.stopPropagation();
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value =  JSON.stringify(message);
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.openSnackBar(this.translate.data["label.copied"],"success")
  }

  onSpeedChanged(event:MatSelectChange){
    this.playbackSpeed = event.value;
    if(this.isPlaying){
      this.pauseAndStartPlayInterval();
    }
  }

  pauseAndStartPlayInterval(){
    this.cancelPlayInterval();
    this.play();
  }

  cancelPlayInterval(){
    this.isPlaying = false;
    if(this.intervalId > 0){
      clearInterval(this.intervalId);
    }
  }

  play() {
    if(!this.isPlaying){

      if(this.sekkerIndex == this.sekkerSize)
        this.sekkerIndex = 0;

      this.isPlaying = true;

      this.intervalId = window.setInterval(()=>{
       this.nextFrame();
     },this.playbackSpeed);
    }
  }

  nextFrame(){
    let nextIndex = this.sekkerIndex + 1;

    if(nextIndex > this.sekkerSize){
      this.cancelPlayInterval();
    }else{
      this.onMessageSelected(this.messages[this.convertSeekerIndex(nextIndex)],true);
    }
  }

  cleanMarkers(){
    if(this.vehicleMarker){
      this.vehicleMarker.remove();
      this.vehicleMarker = null;
    }
    if(this.pulseMarker){
      this.pulseMarker.remove();
      this.pulseMarker = null;
    }
  }

  onMessageSelected(message:any,scrollToView:boolean,zoom?:number){

    this.selectedMessage = message;
    this.sekkerIndex = this.convertMessageIndex(message.index);

    let lat = message['position.latitude'];
    let lng = message['position.longitude'];

    this.addPulseMarker(lat,lng);
    this.moveMarkers(lat,lng);

    this.liveMap.map.flyTo([lat,lng],zoom,{animate:false});

    if(scrollToView){
      this.scrollToMessage(message.index);
    }
  }

  moveMarkers(latitude,longitude) {
    this.pulseMarker.setLatLng([latitude,longitude]);
    this.vehicleMarker.setLatLng([latitude,longitude]);
  }

  canProcessExtras() : boolean {
    return this.pointExtras != null && this.pointExtras.length > 0 && this.messages != null && this.messages.length > 0;
  }

  addPulseMarker(latitude,longitude) {

    if(this.vehicleMarker)
      return;

    let markerIcon = this.liveMap.getVehicleMarkerIcon(this.trackItVehicle.vehicle.icon);

    const animatedCircleIcon = {
      icon: L.divIcon({
        className: "css-icon",
        html: `<div class="gps_ring"></div>`,
        iconSize: [18, 22]
      })
    };

    this.pulseMarker =  L.marker([latitude,longitude], animatedCircleIcon);
    this.vehicleMarker =  L.marker([latitude, longitude], {riseOnHover: true, icon: markerIcon});
    this.pulseMarker.addTo(this.liveMap.map);
    this.vehicleMarker.addTo(this.liveMap.map);
    this.vehicleMarker.setOpacity(0.8);
  }

  getMessageDateTime(message:any) {
    if(message == null)
      return '';

    let ts = message['timestamp'];
    return this.preferenceService.timestampToMoment(ts).format('YYYY-MM-DD HH:mm:ss')
  }

  convertSeekerIndex(sekkerIndex) {
    let messageIndex = 0;
    messageIndex = this.messages.length - 1 - sekkerIndex;
    return messageIndex;
  }

  convertMessageIndex(messageIndex) {
    let sekkerIndex: number;
    sekkerIndex = this.messages.length - 1 - messageIndex;
    return sekkerIndex;
  }

  onSeekerChanged(event:MatSliderChange) {
    this.sekkerIndex = event.value;
    this.seekMessage();
  }

  seekMessage(){
    let messageIndex = this.convertSeekerIndex(this.sekkerIndex);
    this.onMessageSelected(this.messages[messageIndex],true,15);
  }

  onRangeSelected(value) {
    let begin = value.begin;
    let end = value.end;
    this.selectedPeriod = {start:begin.format('YYYY-MM-DD') + ' 00:00:00',end:end.format('YYYY-MM-DD') + ' 23:59:59'};
    this.onTrackItChanged.emit({begin:this.selectedPeriod.start,end:this.selectedPeriod.end,idVehicle:this.trackItVehicle.vehicle.id});
    this.downloadMessages();
  }

  downloadMessages() {

    if(this.selectedPeriod == null)
      return;

    this.loading = true;

    this.liveMap.getLayer(LAYER_POLYLINE).clearLayers();

    this.liveMapService.getVehicleTrackItMessages(this.selectedPeriod.start,this.selectedPeriod.end,this.trackItVehicle.vehicle.id).then((response)=>{
      this.messages = response;

      for(let i = 0; i < this.messages.length; i++){
        this.messages[i].index = i;
        if(this.messages[i]['position.speed'] != null){
          this.messages[i]['position.speed'] = this.preferenceService.convertSpeed(this.messages[i]['position.speed']) + " " + this.preferenceService.getSpeedUnit();
        }
      }
      if(this.messages.length > 0)
        this.selectedMessage = this.messages[0];

      this.dataSource.data = this.messages;
      this.dataSource.paginator = this.paginator;

      this.sekkerSize = this.messages.length - 1;
      this.sekkerIndex = this.sekkerSize;


      if(this.canProcessExtras()){
        this.processExtras();
      }

      this.buildPolyline();

    }).catch(e => {
      console.log(e);
    }).finally(()=>{
      this.loading = false;
    });
  }

  formatLabel = (index) => {
    try{
      let locale = this.configurationService.getConfigLocale();
      let message = this.getMessageDateTime(this.messages[this.convertSeekerIndex(index)]);
      return this.hourPipe.transform(message, locale);
    }catch (e) {
      console.log(e);
      return '';
    }
  }

  getMessageClass(message:any) {
    if(this.selectedMessage.index == message.index){
      return 'flashit_row'
    }
    return '';
  }

  buildPolyline() {
    let polyline = new L.Polyline([],this.liveMap.getPolylineOptions('#3496E6'))
    this.messages.forEach(message => {
      polyline.addLatLng([message['position.latitude'],message['position.longitude']])
    });
    polyline.addTo(this.liveMap.getLayer(LAYER_POLYLINE));
    this.liveMap.map.fitBounds(this.liveMap.getLayer(LAYER_POLYLINE).getBounds());
  }

  public openSnackBar(message: string, type:string) {
    let config:MatSnackBarConfig = {
      panelClass:'snack_'+type,
      duration: 6000,
      horizontalPosition:'right',
      verticalPosition:'bottom'
    };
    this._snackBar.open(message, "Ok", config);
  }
}
