<div class="dialog_container h-100" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <mat-progress-bar mode="indeterminate" *ngIf="loading>0"></mat-progress-bar>
  <div class="dialog_header px-3 py-2" cdkDragHandle>
      <h3 class="d-inline-flex mb-0" style="color:var(--foreground-default);font-size: 1.0em; font-weight: 400;">
        {{(editing?'label.edit':'label.add') | translate}} {{'label.user' | translate}}
      </h3>
      <i (click)="close()" style="color:var(--foreground-default)" class="mdi mdi-close"></i>
  </div>
  <div>
    <mat-tab-group class="overflow-hidden" animationDuration="0ms">
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="h6 mr-1 mb-0 mdi mdi-clipboard-account-outline" style="color:var(--foreground-default)"></i>
          <span style="color:var(--foreground-default)">{{'label.user_data'|translate}}</span>
        </ng-template>
        <div class="mat-tab-conteiner" style="border-top-color:var(--table-border)!important;background-color: var(--background-table-body)!important;">
          <form [formGroup]=form class="px-3 py-4">
            <div class="col-12 mb-2">
              <div loopFormField [attr.valid]="!checkFieldValidator('name')">
                  <span>{{'label.name' | translate}}</span>
                  <input type="text" formControlName="name" loopInput required maxlength="50"/>
                  <i class="mdi mdi-information-outline" *ngIf="checkFieldValidator('name')" matTooltip="{{'msg.invaliddata' | translate}}"></i>
              </div>
            </div>

            <div class="col-12 mb-2">
              <div loopFormField [attr.valid]="!checkFieldValidator('email')">
                  <span>{{'label.email' | translate}}</span>
                  <input type="email" formControlName="email" loopInput required maxlength="100" (blur)="checkService.checkEmail(form, checkingUniqueness, editUser)"/>
                  <i class="mdi mdi-information-outline" *ngIf="checkFieldValidator('email') && !checkingUniqueness.email" matTooltip="{{'msg.invaliddata' | translate}}"></i>
                  <mat-spinner [diameter]="14" color="primary" *ngIf="checkingUniqueness.email"></mat-spinner>
              </div>
              <span *ngIf="checkService.hasError(form, 'email', 'notUnique')" class="unique-msg-error">{{'msg.emailNotUnique' | translate}}</span>
            </div>

            <div class="col-12 mb-2">
              <div loopFormField [attr.valid]="!checkFieldValidator('username')">
                  <span>{{'label.userName' | translate}}</span>
                  <input type="text" formControlName="username" loopInput required maxlength="50" (blur)="checkService.checkUnique(form, checkingUniqueness, editUser, 'username')"/>
                  <i class="mdi mdi-information-outline" *ngIf="checkFieldValidator('username') && !checkingUniqueness.username" matTooltip="{{'msg.invaliddata' | translate}}"></i>
                  <mat-spinner [diameter]="14" color="primary" *ngIf="checkingUniqueness.username"></mat-spinner>
              </div>
              <span *ngIf="checkService.hasError(form, 'username', 'notUnique')" class="unique-msg-error">{{'msg.usernameNotUnique' | translate}}</span>
            </div>
            <div class="d-flex flex-row mb-2" *ngIf="!editing">
              <div class="col-6">
                <div loopFormField [attr.valid]="checkPassword() && !checkFieldValidator('password')">
                    <span>{{'label.password' | translate}}</span>
                    <input type="password" formControlName="password" loopInput required maxlength="50"/>
                    <i class="mdi mdi-information-outline" *ngIf="!checkPassword() && checkFieldValidator('password')" matTooltip="{{'msg.invaliddata' | translate}}"></i>
                </div>
              </div>


              <div class="col-6">
                <div loopFormField [attr.valid]="!checkFieldValidator('confirmPassword')">
                    <span>{{'label.confirm' | translate}}</span>
                    <input type="password" formControlName="confirmPassword" loopInput required maxlength="50"/>
                    <i class="mdi mdi-information-outline" *ngIf="checkFieldValidator('confirmPassword')" matTooltip="{{'msg.invaliddata' | translate}}"></i>
                </div>
              </div>
            </div>
          </form>
        </div>
      </mat-tab>
      <mat-tab disabled="{{!editing || loading>0}}">
        <ng-template mat-tab-label>
          <div>
            <i class="h6 mr-1 mb-0 mdi mdi-auto-fix" style="color:var(--foreground-default)"></i>
            <span style="color:var(--foreground-default)">{{'label.permissions'|translate}}</span>
          </div>
        </ng-template>
        <div  style="border-top-color:var(--table-border)!important;background-color: var(--background-table-body)!important;">
          <ng-template mat-tab-label>
            <div matTooltip="{{(!editUser.id)?('msg.createUserBeforePermissions'|translate) : null}}">
              <i class="h6 mr-1 mb-0 mdi mdi-auto-fix"></i>{{'label.permissions'|translate}}
            </div>
          </ng-template>
          <div class="mat-tab-conteiner py-2" style="overflow-y: scroll;">
            <mat-accordion *ngIf="products && products.length" class="pt-2">
              <mat-expansion-panel #expansionProduct class="rounded-0" *ngFor="let product of products">
                <mat-expansion-panel-header class="expansion-header" [collapsedHeight]="'24px'" [expandedHeight]="'24px'">
                  <mat-panel-title class="d-flex align-items-center justify-content-start">
                    <div>
                    <span style="color:var(--foreground-default);">{{product.NAME}}</span>
                    </div>
                  </mat-panel-title>
                  <mat-panel-description class="m-0">
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-checkbox color="primary" style="margin-left: 30px;color:var(--foreground-default);" (change)="checkMasterAccess(product, $event)" [checked]="product.CHECKED_SUPER" *ngIf="hasMasterAccess(product) || user.role.id != 2">{{'app.super_dealer'|translate}}</mat-checkbox>
                <mat-expansion-panel #expansion *ngFor="let access of product.ACCESSES" hideToggle="true" [expanded]="access.CHECKED">
                  <mat-expansion-panel-header class="d-flex align-items-center cursor-default" (click)="toggleAccess(!access.CHECKED, access, product)" [collapsedHeight]="'24px'" [expandedHeight]="'24px'" disable="masterAccess==true">
                    <mat-panel-title style="color:var(--foreground-default);" class="pl-3 d-flex align-items-center">{{access.KEYLANG|translate}}</mat-panel-title>
                    <mat-panel-description class="m-0">
                      <mat-slide-toggle class="ml-auto" [checked]="access.CHECKED" [disabled]="product.CHECKED_SUPER" (click)="$event.stopPropagation()" (change)="toggleAccess($event.checked, access, product)"></mat-slide-toggle>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <ul style="list-style-type:none" class="p-0 mb-0 pl-5 pr-4" *ngIf="access.CHILDREN && access.CHILDREN.length">
                    <li *ngFor="let child of access.CHILDREN">
                      <div class="d-flex rounded">
                        <mat-checkbox class="d-flex justify-content-center" [checked]="child.CHECKED" [disabled]="product.CHECKED_SUPER" (change)="toggleAccess($event.checked, child, product)" color="primary"></mat-checkbox>
                        <span style="font-size: 11px;color:var(--foreground-default);" class="pl-3 d-flex align-items-center justify-content-center">{{child.KEYLANG|translate}}</span>
                      </div>
                    </li>
                  </ul>
                </mat-expansion-panel>
              </mat-expansion-panel>
            </mat-accordion>
          </div>


        </div>
      </mat-tab>
      <mat-tab disabled="{{!editing || loading>0}}">
        <ng-template mat-tab-label>
          <div>
            <i class="h6 mr-1 mb-0 mdi mdi-truck" style="color:var(--foreground-default)"></i>
            <span style="color:var(--foreground-default)">{{'menu.vehicles'|translate}}</span>
          </div>
        </ng-template>
        <div class="mat-tab-conteiner p-2">
            <mat-checkbox color="primary" *ngIf="user.all_vehicles" (change)="onVehicleCheckboxChange($event)" [(ngModel)]="allVehiclesChecked" class="px-3">
              <span  style="color:var(--foreground-default)"> {{'access.access_to_all'|translate}}</span>
            </mat-checkbox>
            <app-selector
            class="p-3"
            #vehicleSelector
            [list]='vehicles'
            [selectedList]=''
            [type]="types['VEHICLE']"
            [hidden]="allVehiclesChecked"
            >
          </app-selector>
        </div>
      </mat-tab>

      <mat-tab disabled="{{!editing || loading>0}}">
        <ng-template mat-tab-label>
          <div>
            <i class="h6 mr-1 mb-0 mdi mdi-file-chart" style="color:var(--foreground-default)"></i>
            <span style="color:var(--foreground-default)">{{'label.reports' | translate}}</span>
          </div>
        </ng-template>
        <div class="mat-tab-conteiner p-2">
            <mat-checkbox color="primary" *ngIf="user.all_reports" (change)="onReportCheckboxChange($event)" [(ngModel)]="allReportsChecked" class="px-3">
              <span  style="color:var(--foreground-default)"> {{'access.access_to_all'|translate}}</span>
            </mat-checkbox>
            <app-selector class="p-3"
            #reportSelector
            [list]='reports'
            [translateField]="true"
            [selectedList]=''
            [type]="types['REPORTS']"
            [hidden]="allReportsChecked"
            >
          </app-selector>
        </div>
      </mat-tab>


    </mat-tab-group>
  </div>
  <div class="d-flex justify-content-end px-4 py-3 border-top mt-auto" style="border-top-color:var(--table-border)!important;" cdkDragHandle>
      <button mat-button color="primary" (click)="save(true)" class="ml-2" [disabled]="loading>0">
          {{'nav.saveClose'|translate}}
        </button>
        <button mat-button color="primary" (click)="save()" class="ml-2" [disabled]="loading>0">
          {{'nav.salvar'|translate}}
        </button>
  </div>
</div>
