import {Component, Input, OnInit} from '@angular/core';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.css']
})
export class PageTitleComponent implements OnInit {

    @Input()
    title: string;

    @Input()
    buttonTitle: string;

    @Input()
    isLoading: boolean;

    @Input()
    onButtonPress: any;

    @Input()
    hideButton: boolean = true;

    constructor(
      public permissionService: PermissionService,
    ) { }

    ngOnInit() {
    }

    onClick() {
        this.onButtonPress();
    }
}

