import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'secToDateTime',
  pure: false
})
export class SecToDateTime implements PipeTransform {

  constructor() {}

  transform(seconds: number, format:string): string {
    if (seconds == null)
      return  "";
    let sign = (seconds<0?'-':'');
    seconds = Math.abs(Math.floor(seconds));
    let minutes = (seconds > 60)? Math.floor(seconds / 60) : 0;
    seconds = (minutes > 0)? seconds - (minutes * 60) : seconds;
    let hours = (minutes > 60)? Math.floor(minutes / 60) : 0;
    minutes = (hours > 0)? minutes - (hours * 60) : minutes;

    let result = new Date();
    result.setHours(hours,minutes, seconds);
    return sign + moment(result).format(format).toString();
  }
}
