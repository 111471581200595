import {BaseService} from './base.service';
import {Injectable, Injector} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Company} from '../models/company.model';
import {DashboardModel} from '../models/dashboard.model';
import {DateRangeData} from '../components/month-selector-v2/month-selector.component';
import {CompanyService} from './company.service';

const DASHBOARD_INCIDENTS_PERIOD = 1;
const DASHBOARD_VEHICLE_OCCURENCES = 2;
const DASHBOARD_VEHICLE_RANKING = 3;
const DASHBOARD_WEEKDAY_CHART = 4;
const DASHBOARD_HOUR_CHART = 5;
const DASHBOARD_GROUPED_TAGS = 6;
const DASHBOARD_GEOTYPE = 7;
const DASHBOARD_GEO_RANKING = 8;
const DASHBOARD_DRIVER_RANKING = 9;
const DASHBOARD_INCIDENTS_VEHICLE = 10;
const DASHBOARD_GROUPED_EVENTS = 11;
const DASHBOARD_GROUPED_GROUPS = 12;
const DASHBOARD_CONNECTED_VEHICLES = 13;
const DASHBOARD_GROUPED_WEEKDAY = 14;
const DASHBOARD_GROUPED_COMPANIES_RANKING = 15;
const DAHBOARD_GROUPED_KM_RANKING = 16;
const DASHBOARD_COMPANIES_RANKING_BY_GROUP = 17;

class DashboardParams {
  constructor(
    public chartType: number,
    public params: any,
    public id_event_group:number,
    public companiesId?: number[]
  ){}
}

export class DashboardVehicleOccurenceResult {
  constructor(
    public scale: number,
    public total: any,
    public value
  ){}
}

export class GroupedDashboardResult {
  constructor(
    public datasets:{label:string,color:string,data:number[],stack?:string,backgroundColor?:string}[],
    public labels:string[]
  ){}
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService<DashboardModel> {

  constructor(protected injector:Injector,public companyService:CompanyService){
    super(injector,'safety/dashboard',(data) => data);
  }

  public getOccurencesChart(period:string,eventGroupId:number) : Promise<DashboardModel[]> {
    let body : DashboardParams = new DashboardParams(DASHBOARD_INCIDENTS_PERIOD,{period:period},eventGroupId);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getWeekdayChart(period:DateRangeData,eventGroupId:number) : Promise<GroupedDashboardResult> {
    let body : DashboardParams = new DashboardParams(DASHBOARD_WEEKDAY_CHART,period,eventGroupId);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getWeekDayGroupedChart(period:DateRangeData) : Promise<GroupedDashboardResult> {
    let body : DashboardParams = new DashboardParams(DASHBOARD_GROUPED_WEEKDAY,period,null);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getGroupedTagsChart(period:DateRangeData,eventGroupId:number) : Promise<GroupedDashboardResult> {
    let body : DashboardParams = new DashboardParams(DASHBOARD_GROUPED_TAGS,period,eventGroupId);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getHourChart(period:DateRangeData,eventGroupId:number) : Promise<GroupedDashboardResult> {
    let body : DashboardParams = new DashboardParams(DASHBOARD_HOUR_CHART,period,eventGroupId);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getGeoRanking(period:DateRangeData,eventGroupId:number) : Promise<GroupedDashboardResult> {
    let body : DashboardParams = new DashboardParams(DASHBOARD_GEO_RANKING,period,eventGroupId);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getDriverRanking(period:DateRangeData,eventGroupId:number) : Promise<GroupedDashboardResult> {
    let body : DashboardParams = new DashboardParams(DASHBOARD_DRIVER_RANKING,period,eventGroupId);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getCompaniesGeneralGrouped(period:DateRangeData) : Promise<GroupedDashboardResult> {
    let body : DashboardParams = new DashboardParams(DASHBOARD_GROUPED_COMPANIES_RANKING,period,null);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getCompaniesKmRanking(period:DateRangeData) : Promise<GroupedDashboardResult> {
    let body : DashboardParams = new DashboardParams(DAHBOARD_GROUPED_KM_RANKING,period,null);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getGeoTypeChart(period:DateRangeData,eventGroupId:number) : Promise<GroupedDashboardResult> {
    let body : DashboardParams = new DashboardParams(DASHBOARD_GEOTYPE,period,eventGroupId);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getVehicleOccurences(period:string,eventGroupId:number) : Promise<DashboardVehicleOccurenceResult[]> {
    let body : DashboardParams = new DashboardParams(DASHBOARD_VEHICLE_OCCURENCES,{period:period},eventGroupId);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getVehiclesRanking(period: string,eventGroupId:number): Promise<any> {
    let body: DashboardParams = new DashboardParams(DASHBOARD_VEHICLE_RANKING, {period: period},eventGroupId);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getVehicleIncidentCount(period: DateRangeData,eventGroupId:number): Promise<any> {
    let body: DashboardParams = new DashboardParams(DASHBOARD_INCIDENTS_VEHICLE, period,eventGroupId);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getGroupedEventChart(period:DateRangeData,eventGroupId:number) : Promise<any> {
    let body: DashboardParams = new DashboardParams(DASHBOARD_GROUPED_EVENTS, period,eventGroupId);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getCompaniesRankingByGroup(period:DateRangeData,eventGroupId:number) : Promise<any> {
    let body: DashboardParams = new DashboardParams(DASHBOARD_COMPANIES_RANKING_BY_GROUP, period,eventGroupId);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getGroupedGroupsChart(period:DateRangeData) : Promise<any> {
    let body : DashboardParams = new DashboardParams(DASHBOARD_GROUPED_GROUPS,period,null);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }

  public getConnectedVehicles(period:DateRangeData) : Promise<any> {
    let body : DashboardParams = new DashboardParams(DASHBOARD_CONNECTED_VEHICLES,period,null);
    this.injectCompaniesIntoBody(body);
    return this.custom(body);
  }


  private injectCompaniesIntoBody(body:DashboardParams){
    if(this.companyService.isAllSelected){
      body.companiesId = this.companyService.multipleCompanies.map(c => c.id);
    }
  }

}
