<div class="mt-3 row mb-5">
  <div class="col-6 my-2" *ngIf="eventGroupId==null">
    <app-bar-chart
      #connectedVehiclesChart
      [showLegend]="true"
      [translateLegend]="true"
      [showRefreshButton]="true"
      (onRefreshButtonPressed)="downloadConnectedVehicles()"
      chartType="doughnut"
      [title]="'label.connection_state' | translate"
      height="400px"
    >
    </app-bar-chart>
  </div>
  <div *ngIf="willRenderGeneralGroupedCharts()" class="col-6 my-2">
    <app-bar-chart
      #groupsGroupedChart
      [showLegend]="true"
      [translateLegend]="false"
      chartType="doughnut"
      [title]="'app.events' | translate"
      height="400px"
    >
    </app-bar-chart>
  </div>
  <div *ngIf="eventGroupId!=null" class="col-6 my-2">
    <app-bar-chart
      #eventGroupedChart
      [showLegend]="false"
      chartType="horizontalBar"
      [title]="eventGroupName"
      height="400px"
    >
    </app-bar-chart>
  </div>
  <div class="col-6 my-2">
    <app-bar-chart
      #weekDayChart
      [showLegend]="true"
      [translateLegend]="!willRenderGeneralGroupedCharts()"
      chartType="bar"
      title="{{'label.incident_weekday' | translate}}"
      height="400px"
    >
    </app-bar-chart>
  </div>
  <div class="col-6 my-2" *ngIf="willRenderMultiCompanyGroupedCharts()">
    <app-bar-chart
      #companiesKmChart
      [showLegend]="false"
      [translateLegend]="false"
      chartType="horizontalBar"
      title="{{'label.companies_km_ranking' | translate}}"
      height="400px"
    >
    </app-bar-chart>
  </div>
  <div class="col-6 my-2" *ngIf="willRenderMultiCompanyGroupedCharts()">
    <app-bar-chart
      #companyMostGroupedChart
      [showLegend]="true"
      [translateLegend]="false"
      [maxRecords]="5"
      chartType="horizontalBar"
      title="{{'label.company_most_infractions' | translate}}"
      height="400px"
    >
    </app-bar-chart>
  </div>
  <div class="col-6 my-2" *ngIf="willRenderMultiCompanyGroupedCharts()">
    <app-bar-chart
      #companyLessGroupedChart
      [showLegend]="true"
      [reverse]="true"
      [maxRecords]="5"
      [translateLegend]="false"
      chartType="horizontalBar"
      title="{{'label.company_least_infractions' | translate}}"
      height="400px"
    >
    </app-bar-chart>
  </div>
  <div class="col-6 my-2" *ngIf="companyService.isAllSelected && eventGroupId != null">
    <app-bar-chart
      #companyMostChartByGroup
      [showLegend]="true"
      [translateLegend]="false"
      [maxRecords]="5"
      chartType="horizontalBar"
      title="{{'label.company_most_infractions' | translate}}"
      height="400px"
    >
    </app-bar-chart>
  </div>
  <div class="col-6 my-2" *ngIf="companyService.isAllSelected && eventGroupId != null">
    <app-bar-chart
      #companyLessChartByGroup
      [showLegend]="true"
      [reverse]="true"
      [maxRecords]="5"
      [translateLegend]="false"
      chartType="horizontalBar"
      title="{{'label.company_least_infractions' | translate}}"
      height="400px"
    >
    </app-bar-chart>
  </div>
  <div class="col-6 my-2" *ngIf="!willRenderMultiCompanyGroupedCharts()">
    <app-bar-chart
      #hourChart
      [showLegend]="true"
      chartType="radar"
      title="{{'label.incident_hour' | translate}}"
      height="400px"
    >
    </app-bar-chart>
  </div>
  <div class="col-6 my-2" *ngIf="!willRenderMultiCompanyGroupedCharts()">
    <app-bar-chart
      #driverRanking
      [showLegend]="true"
      chartType="horizontalBar"
      title="{{'label.incident_driver_ranking' | translate}}"
      height="400px"
    >
    </app-bar-chart>
  </div>
  <div class="col-6 my-2" *ngIf="!willRenderMultiCompanyGroupedCharts()">
    <app-bar-chart
      #geoRanking
      [showLegend]="true"
      chartType="horizontalBar"
      title="{{'label.incident_geo_ranking' | translate}}"
      height="400px"
    >
    </app-bar-chart>
  </div>
  <div class="col-6 my-2" *ngIf="!willRenderMultiCompanyGroupedCharts()">
    <app-bar-chart
      #geofenceTypeChart
      [showLegend]="true"
      chartType="pie"
      title="{{'label.occurence_geotype' | translate}}"
      height="400px"
    >
    </app-bar-chart>
  </div>
  <div class="col-6 my-2" *ngIf="!willRenderMultiCompanyGroupedCharts()">
    <app-bar-chart
      #groupedTagsChart
      [showLegend]="true"
      chartType="bar"
      title="{{'label.occurrences' | translate}}"
      height="400px"
    >
    </app-bar-chart>
  </div>
  <div class="col-6 my-2" *ngIf="!willRenderMultiCompanyGroupedCharts()">
    <app-vehicles-ranking
      #vehiclesTopOccurrences
      height="400px"
      title="{{'app.ranking' | translate}}"
    >
    </app-vehicles-ranking>
  </div>
</div>
