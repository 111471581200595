import {Pipe, PipeTransform} from '@angular/core';
import { formatDate } from '@angular/common';
import {TranslateService} from '../../../projects/ngx-loop-components/src/lib/translate.service';

@Pipe({
    name: 'shortDate',
    pure: false
})
export class ShortDatePipe implements PipeTransform {

    constructor(private translate:TranslateService) {}

    transform(dateString: string): string {
        if (!dateString)
            return  "--:--";
        return formatDate(dateString,'short', this.translate.lang);
    }
}


@Pipe({
    name: 'hourFormat',
    pure: false
})
export class HourPipe implements PipeTransform {

    constructor(private translate:TranslateService) {}

    transform(dateString: string): string {
        if (!dateString)
            return  "--:--";
        return formatDate(dateString,'shortTime', this.translate.lang);
    }
}

@Pipe({
    name: 'fullDate',
    pure: false
})
export class FullDatePipe implements PipeTransform {

    constructor(private translate:TranslateService) {}

    transform(dateString: string): string {
        if (!dateString)
            return  "--:--";
        return formatDate(dateString,'medium', this.translate.lang,null);
    }
}

@Pipe({
    name: 'dayMonth',
    pure: false
})
export class DayMonth implements PipeTransform {

    constructor(private translate:TranslateService) {}

    transform(dateString: string): string {
        if (!dateString)
            return  "--:--";
        if(this.translate.lang == 'en-US')
            return formatDate(dateString,'MM/dd', this.translate.lang);
        return formatDate(dateString,'dd/MM', this.translate.lang);

    }
}

@Pipe({
    name: 'dayHourFormat',
    pure: false
})
export class DayHourPipe implements PipeTransform {

    constructor(private translate:TranslateService) {}

    transform(dateString: string): string {
        if (!dateString)
            return  "--:--";
        if(this.translate.lang == 'en-US')
            return formatDate(dateString,'MM/dd hh:mm aa', this.translate.lang);
        return formatDate(dateString,'dd/MM HH:mm', this.translate.lang);

    }
}

@Pipe({
    name: 'dayFormat',
    pure: false
})
export class DayPipe implements PipeTransform {

    constructor(private translate:TranslateService) {}

    transform(dateString: string): string {
        if (!dateString)
            return  "--:--";
        return formatDate(dateString,'shortDate', this.translate.lang);
    }
}
