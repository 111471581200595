<div class="container pt-4">
    <div class="d-flex flex-row">
        <h3 class="mb-0">{{this.editing === true ? ('label.edit'|translate) : 'label.new'|translate}} {{'label.user'| translate}}</h3>
        <button mat-raised-button color="primary" [disabled]="sending || !isFormValid()" (click)="save()" class="ml-auto">
            {{'nav.salvar'|translate}}
        </button>
        <button mat-stroked-button color="accent" [disabled]="sending" (click)="delete()" *ngIf="editing" class="ml-3">
            <i class="mdi mdi-delete" style="font-size: 1.5em;"></i>
        </button>
    </div>
    <div class="card mt-4 col-sm-12 p-0 mb-4">
        <mat-progress-bar
            mode="indeterminate"
            *ngIf="sending"
        ></mat-progress-bar>
        <div class="p-4">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="nome" class="form-control" formControlName="name" />
                            <label for="nome">{{'label.name'|translate}} *</label>
                            <mat-error *ngIf="checkFieldValidator('name')">
                                {{'msg.invaliddata' | translate}}
                            </mat-error>
                        </div>
                    </div>
                  <div class="col-sm-12 col-md-6 align-content-end pb-0">
                    <mat-form-field class="pb-0 w-100 mt-3">
                      <mat-label>{{'label.company'|translate}}</mat-label>
                      <mat-select [compareWith]="compareCompany" formControlName="company">
                        <mat-option *ngFor="let company of companies" [value]="company">
                          {{company.nome}}
                        </mat-option>
                      </mat-select>
                      <mat-progress-bar *ngIf="loadingCompanies" mode="indeterminate" style="height: 2px" class="position-absolute loading-types">
                      </mat-progress-bar>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="email" class="form-control" formControlName="email"/>
                            <label for="email">{{'label.email'|translate}} *</label>
                            <mat-error *ngIf="checkFieldValidator('email')">
                                {{'msg.invaliddata' | translate}}
                            </mat-error>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="md-form md-outline">
                        <input mdbInput type="text" id="username" class="form-control" formControlName="username" />
                        <label for="username">{{'label.userName'|translate}} *</label>
                        <mat-error *ngIf="checkFieldValidator('username')">
                          {{'msg.invaliddata' | translate}}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6" *ngIf="!editing">
                        <div class="md-form md-outline">
                            <input mdbInput type="password" id="senha" class="form-control" formControlName="senha" />
                            <label for="senha">{{'label.password'|translate}} *</label>
                            <mat-error *ngIf="checkFieldValidator('senha')">
                                {{'msg.invaliddata' | translate}}
                            </mat-error>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6" *ngIf="!editing">
                        <div class="md-form md-outline">
                            <input mdbInput type="password" id="confirmSenha" class="form-control" formControlName="confirmSenha" />
                            <label for="confirmSenha">{{'label.confirm'|translate}} {{'label.password'|translate}} *</label>
                            <mat-error *ngIf="!checkPassword() || checkFieldValidator('confirmSenha')">
                                {{ checkFieldValidator('confirmSenha')? ('msg.invaliddata' | translate): 'msg.Senha1'|translate}}
                            </mat-error>
                            </div>
                        </div>
                </div>
            </form>
        </div>
    </div>
</div>
