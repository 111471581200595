import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';


@Injectable({
  providedIn: 'root'
})

export class AccessLogService extends BaseService<any>{

  constructor(protected injector: Injector) {
    super(injector, 'core/user-access-logs', (json:any)=>json);
  }

  async getLogs(userId:number) : Promise<any> {

    let path = this.apiPath + '/' + userId.toString();
    let headers = await this.buildHeader();

    return this.http.get(path, {headers: headers}).toPromise().then(
      (res: any) => {
        return res.data;
      }).catch(e => {
      throw e;
    });
  }

}
