  <div style="background-color: var(--background-table-body)!important;" class="d-flex p-3 flex-row align-items-center justify-content-center selector-container">
    <div style="min-width:250px">
      <div class="rounded-sm overflow-hidden" style="border-color: var(--background-search-input);border-style: solid;border-width: 1px;">
        <div class="d-flex flex-row justify-content-center" style="background-color: var(--background-card-hovered);color:var(--foreground-default)">
          <p class="m-0"><i class="mdi mdi-format-list-bulleted mb-1" style="font-size: 0.8em;font-weight: 500"> </i>
          <span class="ml-2" style="font-size: 0.8em;font-weight: 400">{{title}}</span></p>
        </div>
        <div class="search_group">
          <i class="mdi mdi-magnify h5 mb-0"></i>
          <input class="search_input_selector" placeholder="{{'dt.search' | translate}}:" (input)="applyFilter($event.target.value)">
        </div>
        <div class="selector-area">
          <div style="z-index: -1">
            <div class="overflow-auto" style="height:266px">
              <div
                *ngFor="let originalItem of filteredList;let i = index"
                (click)="handleOriginalSelection($event,originalItem,i)"
                [ngStyle]="{'padding-top':i==0?'4px':'0'}"
                [class]="'list_item d-flex flex-row align-items-center'+((originalSelection.isSelected(originalItem))?' active':'')">
                <div *ngIf="showInfoIcon" class="info_panel_area" (click)="onInfoIconClicked.emit(originalItem)">
                  <i *ngIf="!originalItem['disabled']" class="mdi mdi-information-outline" style="color:var(--foreground-default)"></i>
                  <i *ngIf="originalItem['disabled']" class="mdi mdi-alert-box-outline" style="color: var(--accent-color)"></i>
                  <div class="detail_painel card" position="right">
                    <ul>
                      <li *ngFor="let info of getInformations(originalItem)">
                        <span style="font-weight: 400; color:var(--foreground-default)">{{info.key}}:</span>
                        <span style="font-weight: 500; color:var(--foreground-default)" class="ml-1">{{info.value}}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <p *ngIf="!originalItem['disabled']" style="color:var(--foreground-default)" class="mb-0 disable-text-selection">{{ ( translateField ? ( originalItem[elementField] | translate )  : originalItem[elementField] ) }}</p>
                <p *ngIf="originalItem['disabled']" style="opacity: 0.8;color:var(--foreground-default)" class="mb-0 disable-text-selection">{{originalItem[elementField]}}</p>
                <p class="ml-auto" style="font-weight: 500 color:var(--foreground-default)" *ngIf="additionalField && originalItem[additionalField]">{{originalItem[additionalField]}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-center align-itens-center mt-2">
          <button [disabled]="loading || !(list.length > 0)" (click)="changeAllList(false)" matTooltip="{{'app.choose_all_info' | translate}}" mat-button color="primary">
              <span>{{'app.choose_all' | translate}}</span>
              <span class="ml-2"><i [class]="'mdi mdi-chevron-right-circle-outline '+((!loading && list.length>0)?'icon-primary':'')"></i></span>
          </button>
      </div>
    </div>
    <div class="mx-2 d-flex flex-column">
        <p class="m-0 cursor-pointer" (click) ="changeList(originalSelection,false)"><i class="mdi mdi-chevron-right-circle-outline icon-primary" style="font-size:1.3em;"></i></p>
        <p class="m-0 cursor-pointer" (click) ="changeList(chosenSelection,true)"><i class="mdi mdi-chevron-left-circle-outline icon-primary" style="font-size:1.3em;"></i></p>
        <!--<p class="m-0 cursor-pointer" (click)="activeSearch()" matTooltip="CLICK TO OPEN SEARCH FIELD"><i class="mdi mdi-magnify icon-primary" style="font-size:1.3em;"></i></p>-->
    </div>
    <div style="min-width:250px">
      <div class="rounded-sm overflow-hidden" style="border-color: var(--background-header);border-style: solid;border-width: 1px;">
        <div class="d-flex flex-row justify-content-center" style="background-color:var(--background-header);">
            <p class="m-0"><i class="mdi mdi-playlist-check mb-1" style="font-size: 0.8em;color: #fafafa;"> </i>
            <span class="ml-2 text-white" style="font-size: 0.8em;font-weight: 400;">{{chosenText}}</span></p>
        </div>
        <div class="search_group">
          <i class="mdi mdi-magnify h5 mb-0"></i>
          <input class="search_input_selector" placeholder="{{'dt.search' | translate}}:" (input)="applyFilterSelected($event.target.value)">
        </div>
        <div class="selector-area">
          <div style="z-index: -1">
            <div class="overflow-auto rounded-sm" style="height: 266px">
              <div
                *ngFor="let selectedItem of filteredSelectedList;let i = index"
                (click)="handleChoosenSelection($event,selectedItem,i)"
                [ngStyle]="{'padding-top':i==0?'4px':'0'}"
                [class]="'list_item d-flex flex-row align-items-center'+((chosenSelection.isSelected(selectedItem))?' active':'')">
                <div *ngIf="showInfoIcon" class="info_panel_area">
                  <i class="mdi mdi-information-outline" style="color:var(--foreground-default)"></i>
                  <div class="detail_painel card"  position="right">
                    <ul>
                      <li *ngFor="let info of getInformations(selectedItem)">
                        <span style="font-weight: 400;color:var(--foreground-default)">{{info.key}}:</span>
                        <span style="font-weight: 500;color:var(--foreground-default)" class="ml-1">{{info.value}}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <p class="disable-text-selection" *ngIf="!translateField" style="color:var(--foreground-default)">{{selectedItem[elementField]}}</p>
                <p class="disable-text-selection" *ngIf="translateField" style="color:var(--foreground-default)">{{selectedItem[elementField] | translate}}</p>
                <p class="ml-auto" style="font-weight: 500;color:var(--foreground-default)" *ngIf="additionalField && selectedItem[additionalField]">{{selectedItem[additionalField]}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-center align-itens-center mt-2">
        <button [disabled]="loading || !(selectedList.length>0)" (click)="changeAllList(true)" matTooltip="{{'app.remove_all_info' | translate}}" mat-button color="primary">
            <span *ngIf="loading || !(selectedList.length>0)" class="mr-2"><i class="mdi mdi-chevron-left-circle-outline"></i></span>
            <span *ngIf="(selectedList.length>0)" class="mr-2"><i class="mdi mdi-chevron-left-circle-outline icon-primary"></i></span>
            <span>{{'app.remove_all' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
