import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DriverListComponent} from '../driver/driver-list/driver-list.component';
import {DashboardComponent} from './dashboard-page/dashboard.component';


const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
