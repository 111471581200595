import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';
import {Vehicle} from '../models/vehicle.model';
import { environment } from 'src/environments/environment';
import { HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class VehicleService extends BaseService<Vehicle> {

    constructor(protected injector: Injector){
        super(injector, 'safety/vehicle', Vehicle.fromJson)
    }

    async deleteFromCMS(vehicle:Vehicle) : Promise<boolean>{
      let url = `${environment.api}core/vehicles/${vehicle.id}`;
      return this.customDelete(null,url);
    }

    async getVehicleMessages() {
      let path = environment.api + 'core/vehicles/map';
      let headers = await this.buildHeader();

      return this.http.get(path, {headers: headers}).toPromise().then(
        (res: {data:Vehicle[]}) => {
          return res.data;
        }).catch(e => {
        throw e;
      });

    }

    public async getUserVehicles(userId:number){

      let headers = await this.buildHeader();
      let url = `core/user-vehicles/${userId}`;

      return this.http.get(environment.api + url,{headers:headers,observe:'response'}).toPromise().then(
        (response:HttpResponse<{data:any[]}>) => {
          this.refreshToken(response.headers);
          let vehicles:Vehicle[] = response.body.data;
          return vehicles;
        }
      ).catch((e) => {
        this.willLogoutUser(e);
        throw e;
      });
    }

    public async saveUserVehicles(resources:any[], user_id:number):Promise<Vehicle[]>{
      let header = await this.buildHeader();
      return this.http.put(environment.api+'core/user-vehicles'+'/'+user_id,resources,{headers:header,observe: 'response'}).toPromise().then(
        (response:HttpResponse<{data:any[]}>)=>{
          if(response.body['success']){
            this.refreshToken(response.headers);
            return resources;
          }
          throw "User vehicles could not be saved!";
        }
      ).catch( reason => {
        this.willLogoutUser(reason);
        throw reason;
      });
    }


    async getPaginatedResources(params): Promise<{items: Vehicle[], total_pages: number}> {
        let path = this.apiPath + '/paginated';
        return this.customGet(params, path).then((response: {items: Vehicle[], total_pages: number}) => {
            return response;
        });
    }
}
