import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import {DashboardComponent} from './dashboard-page/dashboard.component';
import {ComponentsModule} from '../../components/components.module';
import {MatButtonModule, MatDividerModule, MatProgressBarModule, MatTabsModule} from '@angular/material';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import {ChartsModule} from 'ng2-charts';
import { VehicleOccurencesComponent } from './vehicle-occurences/vehicle-occurences.component';
import { VehiclesRankingComponent } from './vehicles-ranking/vehicles-ranking.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NgxLoopModule} from '../../../../projects/ngx-loop-components/src/lib/ngx-loop.module';
import {AppModule} from '../../app.module';
import { RadarChartComponent } from './radar-chart/radar-chart.component';
import { DashboardGenericTabComponent } from './dashboard-page/dashboard-generic-tab/dashboard-generic-tab.component';


@NgModule({
  declarations: [
    DashboardComponent,
    BarChartComponent,
    VehicleOccurencesComponent,
    VehiclesRankingComponent,
    RadarChartComponent,
    DashboardGenericTabComponent
  ],
    imports: [
        CommonModule,
        DashboardRoutingModule,
        ComponentsModule,
        MatDividerModule,
        MatProgressBarModule,
        ChartsModule,
        ScrollingModule,
        NgxLoopModule,
        MatTabsModule,
        MatButtonModule,
    ],
  providers: [
  ]
})
export class DashboardModule { }
