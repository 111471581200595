<mat-expansion-panel #parentPanel>
  <mat-expansion-panel-header class="expansion-panel">
    <mat-slide-toggle class="mr-2" [disableRipple]="true" [checked]="allGeofencesVisible" (change)="toggleAllGeofencesVisibility()" (click)="$event.stopPropagation()"></mat-slide-toggle>
    <span style="margin-top: 1px">{{'app.geofences' | translate}}</span>
  </mat-expansion-panel-header>
  <div class="vehicle-list-header" [ngStyle]="{opacity:allGeofencesVisible?1:0.5}" >
    <div class="search_input_group mx-1 mb-1">
      <i class="mdi mdi-magnify h5 mb-0"></i>
      <input class="search_input w-100" placeholder="{{'dt.search' | translate}}:" (input)="filter($event)" />
    </div>
    <div *ngIf="loading" class="import-button d-flex flex-row align-items-center justify-content-center mx-2 my-1">
      <mat-progress-spinner diameter="13" mode="indeterminate" color="accent"></mat-progress-spinner>
    </div>
    <input type="file" style="display: none" class="file-input" accept=".kml" #fileUpload (change)="handleFileInput($event.target.files,$event)" #fileUpload >
    <div *ngIf="enableEdition" class="d-flex flex-row align-items-center">
      <button *ngIf="!loading" class="import-button mx-2 my-1" mat-stroked-button color="primary" (click)="fileUpload.click()" >
        {{'label.import' | translate}} KML
      </button>
      <button *ngIf="!loading && companyService.multipleCompanies.length > 1" class="import-button mx-2 my-1" mat-stroked-button color="primary" (click)="shareAllGeofences()" >
        {{'label.share_geofence' | translate}}
      </button>
    </div>

    <app-geofence-expansion-panel
      *ngFor="let type of typesGrouped"
      (locateGeofenceEmit)="locateGeofence($event)"
      (editGeofenceDialogEmit)="openEditGeofenceDialog($event)"
      (onGeofenceSharingClicked)="shareGeofences([$event])"
      [dataSource]="type.dataSource"
      [expanded]="expanded"
      [enableEditing]="enableEdition"
      [enableSharing]="companyService.multipleCompanies.length > 1"
      [liveMap]="liveMap"
      [title]="type.keylang | translate"
      [type]="type"
      [layerName]="LAYERNAME"
    >
    </app-geofence-expansion-panel>

  </div>
</mat-expansion-panel>
