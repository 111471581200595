import { Inject, Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { ReportSchedule } from './report-schedule.model';

@Injectable({
  providedIn: 'root'
})
export class AutoReportService extends BaseService<any> {

  constructor(protected injector: Injector,@Inject('environment') private env ){
      super(injector,env, 'reports/daily', (data)=>data);
  }

  downloadAutoReportFile(file_name:string,report_id:number):Promise<any>{
    let params:any = {
      report_id:report_id
    }
    return this.customGet(params,`${this.environment.apiAutoReports}reports/report_file/${file_name}`);
  }

  removeAutoReportFile(file_name:string,report_id:number):Promise<any>{
    let params:any = {
      report_id:report_id
    }
    return this.delete(file_name,`${this.environment.apiAutoReports}reports/report_file/${file_name}`,params);
  }

  getReportFiles(report_id:number):Promise<any[]>{
    let params = {
      report_id:report_id
    }
    return this.customGet(params,`${this.environment.apiAutoReports}reports/report_files`);
  }

  sendScheduleReport(report_id:number):Promise<any>{
    let params = {
      report_id:report_id
    }
    return this.customGet(params,`${this.environment.apiAutoReports}reports/report_schedule_send`);
  }

  getReportSchedules(report_id:number):Promise<ReportSchedule[]>{
    let params = {
      report_id:report_id
    }
    return this.customGet(params,`${this.environment.apiAutoReports}reports/report_schedule` );
  }

  deleteReportSchedule(report_id:number):Promise<any>{
    return this.delete(report_id,`${this.environment.apiAutoReports}reports/report_schedule/`+report_id);
  }

  createReportSchedule(data:{report_schedule:ReportSchedule,recipients:string[]}):Promise<ReportSchedule>{
    return this.customPost(data,`${this.environment.apiAutoReports}reports/report_schedule` );
  }

  editReportSchedule(id:number, data:{report_schedule:ReportSchedule,recipients:string[]}):Promise<ReportSchedule>{
    return this.customPut(data,id,`${this.environment.apiAutoReports}reports/report_schedule/` );
  }


}