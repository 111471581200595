import {BaseService} from './base.service';
import {Inject, Injectable, Injector} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Observable} from 'rxjs';
import { UserModel as User } from './user.model';

@Injectable({
  providedIn: 'root'
})

export class UserService extends BaseService<User> {
  constructor(public injector: Injector,@Inject('environment') private env ) {
    super(injector,env,'core/users', User.fromJson);
    this.environment = env;
  }

  getUserStoreObservable() : Observable<User> {
    return this.sessionStorage.observe('user');
  }

  getUserStore(): User {
    return this.sessionStorage.retrieve('user');
  }

  storeUser(user:User) {
    this.sessionStorage.store('user', user);
  }

  cleanUserStore(){
    this.sessionStorage.clear('user');
  }

}
