import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BarChartComponent} from '../../bar-chart/bar-chart.component';
import {VehicleOccurencesComponent} from '../../vehicle-occurences/vehicle-occurences.component';
import {VehiclesRankingComponent} from '../../vehicles-ranking/vehicles-ranking.component';
import {DateRangeData} from '../../../../components/month-selector-v2/month-selector.component';
import {DashboardService} from '../../../../services/dashboard.service';
import {CompanyService} from '../../../../services/company.service';
import {TitleService} from '../../../../services/title.service';
import {EventGroupService} from '../../../../services/event-group.service';

@Component({
  selector: 'app-dashboard-generic-tab',
  templateUrl: './dashboard-generic-tab.component.html',
  styleUrls: ['./dashboard-generic-tab.component.css']
})
export class DashboardGenericTabComponent implements OnInit {

  @ViewChild('companiesKmChart',{static:false})
  companiesKmChart: BarChartComponent;

  @ViewChild('connectedVehiclesChart',{static:false})
  connectedVehiclesChart: BarChartComponent;

  @ViewChild('eventGroupedChart',{static:false})
  eventGroupedChart: BarChartComponent;

  @ViewChild('groupsGroupedChart',{static:false})
  groupsGroupedChart: BarChartComponent;

  @ViewChild('occurrencesChart',{static:false})
  occurrencesChart : BarChartComponent;

  @ViewChild('weekDayChart',{static:false})
  weekDayChart : BarChartComponent;

  @ViewChild('hourChart',{static:false})
  hourDayChart : BarChartComponent;

  @ViewChild('geofenceTypeChart',{static:false})
  geofenceTypeChart : BarChartComponent;

  @ViewChild('groupedTagsChart',{static:false})
  groupedTagsChart: BarChartComponent;

  @ViewChild('geoRanking',{static:false})
  geoRanking: BarChartComponent;

  @ViewChild('driverRanking',{static:false})
  driverRanking: BarChartComponent;

  @ViewChild('companyMostGroupedChart',{static:false})
  companyMostGroupedChart: BarChartComponent;

  @ViewChild('companyLessGroupedChart',{static:false})
  companyLessGroupedChart: BarChartComponent;

  @ViewChild('companyMostChartByGroup',{static:false})
  companyMostChartByGroup: BarChartComponent;

  @ViewChild('companyLessChartByGroup',{static:false})
  companyLessChartByGroup: BarChartComponent;

  @ViewChild('vehicleOccurrences',{static:false})
  vehicleOccurrences: VehicleOccurencesComponent;

  @ViewChild('vehiclesTopOccurrences',{static:false})
  vehiclesTopOccurrences: VehiclesRankingComponent;

  @Input()
  eventGroupId: number = null;

  @Input()
  eventGroupName: string = "";

  @Input()
  companyHasGroups: boolean = false;

  loading: boolean = true;

  period: DateRangeData;

  hasCachedData : boolean = false;

  constructor(
    private dashboardService: DashboardService,
    public companyService:CompanyService,
    public titleService: TitleService,
    public eventGroupService: EventGroupService
  ) {
  }

  ngOnInit() {
  }

  setPeriod(period:DateRangeData) {
    this.period = period;
  }

  willRenderGeneralGroupedCharts() : boolean {
    return this.companyHasGroups && this.eventGroupId == null;
  }

  willRenderMultiCompanyGroupedCharts() : boolean {
    return this.companyService.isAllSelected && this.willRenderGeneralGroupedCharts();
  }

  resetDownloadedCache() {
    this.hasCachedData = false;
  }

  public downloadVehiclesRanking() {
    this.dashboardService.getVehicleIncidentCount(this.period,this.eventGroupId).then(
      (vehiclesRanking: any) => {
        this.vehiclesTopOccurrences.setData(vehiclesRanking);
      }).catch(e => {
      console.log('er', e);
    }).finally(() => {});
  }

  public downloadConnectedVehicles() {
    this.dashboardService.getConnectedVehicles(this.period).then(result => {
      this.connectedVehiclesChart.setStackedData(result);
    });
  }

  public downloadCompaniesGeneralGroupedRanking() {
    this.dashboardService.getCompaniesGeneralGrouped(this.period).then(data => {
      this.companyMostGroupedChart.setStackedData(data);
      this.companyLessGroupedChart.setStackedData(data);
    }).catch(e => {
      console.log(e);
    });
  }

  public downloadCompaniesRankingByGroup() {
    this.dashboardService.getCompaniesRankingByGroup(this.period,this.eventGroupId).then(data => {
      this.companyMostChartByGroup.setStackedData(data);
      this.companyLessChartByGroup.setStackedData(data);
    }).catch(e => {
      console.log(e);
    });
  }

  public downloadCompaniesGeneralKmRanking() {
    this.dashboardService.getCompaniesKmRanking(this.period).then(data => {
      this.companiesKmChart.setStackedData(data);
    }).catch(e => {
      console.log(e);
    })
  }

  public downloadGeneralGroupChart() {
    this.dashboardService.getGroupedGroupsChart(this.period).then(data => {
      this.groupsGroupedChart.setStackedData(data);
    })
  }

  public downloadGeneralWeekdayChart(){
    this.dashboardService.getWeekDayGroupedChart(this.period).then(data => {
      this.weekDayChart.setStackedData(data);
    }).catch(e => {
      console.log(e);
    });
  }

  public downloadEventGroupedChart() {
    this.dashboardService.getGroupedEventChart(this.period,this.eventGroupId).then(data => {
      this.eventGroupedChart.setStackedData(data);
    });
  }

  public downloadIncidentWeekdayChart() {
    this.dashboardService.getWeekdayChart(this.period,this.eventGroupId).then(data => {
      this.weekDayChart.setStackedData(data);
    }).catch(e => {
      console.log(e);
    });
  }

  public downloadSingleGroupCharts() {
    this.dashboardService.getGeoTypeChart(this.period,this.eventGroupId).then(data => {
      this.geofenceTypeChart.setStackedData(data);
    }).catch(e => {
      console.log(e);
    });

    this.dashboardService.getGeoRanking(this.period,this.eventGroupId).then(data => {
      this.geoRanking.setStackedData(data);
    }).catch(e => {
      console.log(e);
    }).finally(()=>{
      this.loading = false;
    });

    this.dashboardService.getDriverRanking(this.period,this.eventGroupId).then(data => {
      this.driverRanking.setStackedData(data);
    }).catch(e => {
      console.log(e);
    }).finally(()=>{
      this.loading = false;
    });


    this.dashboardService.getGroupedTagsChart(this.period,this.eventGroupId).then(data => {
      this.groupedTagsChart.setStackedData(data);
    }).catch(e => {
      console.log(e);
    });

    this.dashboardService.getHourChart(this.period,this.eventGroupId).then(data => {
      this.hourDayChart.setStackedData(data);
    });
  }

  refreshDashboard() {

    this.loading = true;
    this.hasCachedData = true;

    if(this.eventGroupId == null){
      this.downloadConnectedVehicles();
    }

    if(this.companyService.isAllSelected && this.eventGroupId != null){
      this.downloadCompaniesRankingByGroup();
    }

    if(this.willRenderMultiCompanyGroupedCharts()){
      this.downloadCompaniesGeneralGroupedRanking();
      this.downloadGeneralWeekdayChart();
      this.downloadGeneralGroupChart();
      this.downloadCompaniesGeneralKmRanking();
    }else if(this.willRenderGeneralGroupedCharts()){
      this.downloadGeneralGroupChart();
      this.downloadGeneralWeekdayChart();
      this.downloadVehiclesRanking();
      this.downloadSingleGroupCharts();
    }else{
      if(this.eventGroupId != null){
        this.downloadEventGroupedChart();
      }
      this.downloadIncidentWeekdayChart();
      this.downloadVehiclesRanking();
      this.downloadSingleGroupCharts();
    }

  }

}
