import {Component, Inject, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { SnackBarController } from '../../controllers/snackbar-controller';
import { AutoReportService } from '../../depends/auto-reports.service';
import { ReportSchedule } from '../../depends/report-schedule.model';
import { TranslateService } from '../../translate.service';
import { AutoReportsCreateDialogComponent } from '../auto-reports-create-dialog/auto-reports-create-dialog';

@Component({
    selector: 'AutoReportsDialog',
    templateUrl: './auto-reports-dialog.html',
    styleUrls: ['./auto-reports-dialog.css' ]
})

export class AutoReportsDialogComponent implements OnInit {
    snackbarController:SnackBarController;
    loading: number = 0;
    files: any[] = [];
    weekDays = ["abbrev.sunday","abbrev.monday","abbrev.tuesday","abbrev.wednesday","abbrev.thursday","abbrev.friday","abbrev.saturday"];
    reportSchedules:ReportSchedule[] = [];

    constructor(
        private dialog:MatDialog,
        private translate: TranslateService,
        public dialogRef: MatDialogRef<AutoReportsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data:any,
        private autoReportService:AutoReportService,
        private snackbar:MatSnackBar
    ) {
        this.snackbarController = new SnackBarController(this.snackbar);
    }

    ngOnInit() {
        if(this.data != null && this.data.id != null){
            this.downloadReportSchedules();
            this.downloadReportFiles();
        }else{
            this.close();
        }
    }

    public createRule(){
        if(this.data != null && this.data.id != null){
            let dialogRef = this.dialog.open(AutoReportsCreateDialogComponent,{
                maxWidth:'500px',
                width: '500px',
                panelClass:'dialog',
                data:{
                    report_id:this.data.id
                }
            });

            dialogRef.afterClosed().toPromise().then(result => {
                if(result != null){
                    this.downloadReportSchedules();
                }
            });
        }
    }

    public downloadReportSchedules(){
        this.setLoading(true);
        this.autoReportService.getReportSchedules(this.data.id).then((result:ReportSchedule[]) => {
            this.reportSchedules = result.map(report => Object.assign(new ReportSchedule(), report));
        }).catch((error) => {
            this.snackbarController.openSnackBar(this.translate.data['msg.error_occurred'],"error");
        }).finally(() => {
            this.setLoading(false);
        });
    }

    public downloadReportFiles(){
        this.setLoading(true);
        this.autoReportService.getReportFiles(this.data.id).then((result:any[]) => {
            this.files = result;
        }).catch((error) => {
            this.snackbarController.openSnackBar(this.translate.data['msg.error_occurred'],"error");
        }).finally(() => {
            this.setLoading(false);
        });
    }

    public sendNow(){
        this.setLoading(true);
        this.autoReportService.sendScheduleReport(this.data.id).then((result) => {
            this.downloadReportFiles();
            this.snackbarController.openSnackBar(this.translate.data['app.sent_success'],"success");
        }).catch((error) => {
            this.snackbarController.openSnackBar(this.translate.data['msg.error_occurred'],"error");
        }).finally(() => {
            this.setLoading(false);
        });
    }

    public getMonthDays(month_rule){
        let days = [];
        for(let i = 0; i < month_rule.length; i++){
            if(month_rule[i] == "1"){
                days.push((i+1).toString());
            }
        }
        return days;
    }

    public getWeekDays(week_rule){
        let days = [];
        for(let i = 0; i < week_rule.length; i++){
            if(week_rule[i] == "1"){
                days.push(this.weekDays[i]);
            }
        }
        return days;
    }

    public edit(schedule){
        if(schedule != null && schedule.id != null){
            let dialogRef = this.dialog.open(AutoReportsCreateDialogComponent,{
                maxWidth:'500px',
                width: '500px',
                panelClass:'dialog',
                data:{
                    report_id:this.data.id,
                    schedule:schedule
                }
            });

            dialogRef.afterClosed().toPromise().then(result => {
                if(result != null){
                    this.downloadReportSchedules();
                }
            });
        }
    }

    public delete(schedule){
        if(schedule != null && schedule.id != null){
            this.dialog.open(ConfirmationDialogComponent, {
                width: '300px',
                data: {
                  title: this.translate.data["label.remove"],
                  confirmationMessage: this.translate.data["msg.remove_config"],
                  buttonText: this.translate.data['label.remove'],
                  iconClass: 'mdi-alert-outline',
                  action: () => {
                    this.setLoading(true);
                    this.autoReportService.deleteReportSchedule(schedule.id).then((result) => {
                        this.downloadReportSchedules();
                    }).catch((error) => {
                        this.snackbarController.openSnackBar(this.translate.data['msg.error_occurred'],"error");
                    }).finally(() => {
                        this.setLoading(false);
                    });
                  },
                  cancel: () => {}
                }
            });
        }
    }

    public downloadFile(file){
        this.setLoading(true);
        this.autoReportService.downloadAutoReportFile(file.name, this.data.id).then((link) => {
            if(link != null && link.trim() != ""){
                this.download(link,file.name);
            }
        }).catch((error) => {
            this.snackbarController.openSnackBar(this.translate.data['msg.error_occurred'],"error");
        }).finally(() => {
            this.setLoading(false);
        });
    }

    public deleteFile(file){
        if(file != null && file.name != null){
            this.dialog.open(ConfirmationDialogComponent, {
                width: '300px',
                data: {
                  title: this.translate.data["label.remove"],
                  confirmationMessage: this.translate.data["msg.remove_file"],
                  buttonText: this.translate.data['label.remove'],
                  iconClass: 'mdi-alert-outline',
                  action: () => {
                    this.setLoading(true);
                    this.autoReportService.removeAutoReportFile(file.name,this.data.id).then(() => {
                        this.downloadReportFiles();
                    }).catch((error) => {
                        this.snackbarController.openSnackBar(this.translate.data['msg.error_occurred'],"error");
                    }).finally(() => {
                        this.setLoading(false);
                    });
                  },
                  cancel: () => {}
                }
            });
        }
    }

    download(link, file_name) {
        if(link !=null && link.trim() != "" && file_name != null && file_name.trim() != ""){
            var a = document.createElement("a");
            a.href = link;
            a.setAttribute("download", file_name);
            a.click();
        }
    }

    getFileSize(size){
        let sufixo = ' bytes';
        if(size>=1024){
            size = size / 1024;
            sufixo = ' Kb';
        }
        if(size>=1024){
            size = size / 1024;
            sufixo = ' Mb';
        }
        return size.toFixed(2) + sufixo;
    }


    public isLoading(): boolean {
        return this.loading > 0;
    }

    public setLoading(value: boolean) {
        if (value)
            this.loading += 1;
        else if (this.loading > 0)
            this.loading -= 1;
    }

    close(): void {
        this.dialogRef.close();
    }

}
