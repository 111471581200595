import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';
import {environment} from '../../environments/environment';

export class PlantProductionModel {
  constructor(
    public deliveries_by_driver?: number,
    public deliveries?: number,
    public drivers?: number,
    public volume_by_driver?: number,
    public volume?: number,
  ){}

  static fromJson(data:any){
    return Object.assign(new PlantProductionModel(), data)
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomService extends BaseService<any> {

  // DATE_FORMAT YYYY-MM-DD HH:mm:ss

  constructor(public injector:Injector) {
    super(injector,null,null);
  }

  getPlantProduction(plantId,startDateString,endDateString) : Promise<PlantProductionModel> {
    let url = `${environment.api}readymix/plant/production/${plantId}`;
    return this.customGet({date_start:startDateString,date_end:endDateString}, url)
  }

  getFlespiToken() : Promise<{flespi_token:string}> {
    let url  = `${environment.api}core/companies/token`;
      return this.customGet(null, url)
  }

  getBrokerFlespiToken() : Promise<{token:string,base_topic:string}> {
    let url = `${environment.api}core/broker-token`;
    return this.customGet(null, url)
  }

}
