import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ResourceSelectorComponent } from 'projects/ngx-loop-components/src/lib/resource-selector/resource-selector.component';
import { SftGeofenceRule, SftGeofenceType } from 'src/app/models/sft-geofence.model';
import { VehicleCategory } from 'src/app/models/vehicle-category.model';

export class GeofenceRule {
  public id?: number;
  public rule_category?: number | VehicleCategory;
  public rule_speed?: number
}

@Component({
  selector: 'app-geofence-rules',
  templateUrl: './geofence-rules.component.html',
  styleUrls: ['./geofence-rules.component.css']
})
export class GeofenceRulesComponent implements OnInit, AfterViewInit {

  @ViewChild('categorySelector', { static: true })
  categorySelector: ResourceSelectorComponent<VehicleCategory>;

  @Input()
  loading: boolean;

  @Input()
  rule: SftGeofenceRule;

  @Input()
  vehicleCategory: VehicleCategory[];

  @Input()
  removeRule: any;

  @Input()
  index: number;

  public form: FormGroup;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit() {
    this.populateForm();
  }

  populateForm() {
    if (this.rule && !(this.isEmpty(this.rule))) {
      this.form.patchValue({rule_speed: (this.rule.max_speed)});
      this.categorySelector.setSelectedResource(this.rule.vehicle_cat);
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      rule_category: [null, Validators.required],
      rule_speed: [null, Validators.required]
    });
  }

  isEmpty(obj: object){
    return Object.keys(obj).length === 0
  }

  removeItem(){
    this.removeRule(this.index)
  }

  public getFormValue(): GeofenceRule {
    let rule: GeofenceRule = Object.assign(new GeofenceRule(), this.form.value);
    
    if (this.rule.id)
      rule.id = this.rule.id;
      
    return rule;
  }

}
