import {Inject, Injectable, Injector} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {SessionStorageService} from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class FlespiService  {

  constructor(
    private http: HttpClient,
    private sessionStorage: SessionStorageService,
  ){}

  async buildHeader(): Promise<HttpHeaders> {

    let firebase = this.sessionStorage.retrieve('token');
    let refresh = this.sessionStorage.retrieve('rt');
    let company = this.sessionStorage.retrieve('selectedCompany');

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'firebase': firebase,
      'refresh': refresh,
      'business-app': '5'
    });

    if (company) {
      let companyID = company.id.toString();
      headers = headers.append('company', companyID);
    }

    return headers
  }

  async getFlespiToken() : Promise<{flespi_token:string}> {
    let url  = `https://api.360kpi.io/core/companies/token`;
    let header = await this.buildHeader();
    return this.http.get(url, {headers: header,params:{},observe: 'response'}).toPromise().then(
      (response: HttpResponse<any>) => {
        return response.body.data;
      }
    ).catch(reason => {
      throw reason;
    });
  }

}
