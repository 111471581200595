import { Injectable, Injector } from '@angular/core';
import { Incident } from '../models/incident.model';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IncidentService extends BaseService<Incident>{

  constructor(public injector: Injector) {
    super(injector, 'safety/incidents', Incident.fromJson);
  }

  deativateIncident(incident: Incident, incidentId: number) : Promise<any> {
    let url = `${environment.api}safety/incidents/deactivate/`;
    return this.customPut(incident, incidentId, url)
  }

  getPeriodVehicleIncidents(id_vehicle:number,date_start:string,date_end:string) : Promise<Incident[]> {
    let url = `${environment.api}safety/incidents/vehicle/${id_vehicle}`;
    let params = {date_start:date_start,date_end:date_end};
    return this.customGet(params,url);
  }

  getMapIncidents(date_start:string,date_end:string,postBody:any) : Promise<Incident[]> {
    let url = `${environment.api}safety/incidents/map`;
    let params = {date_start:date_start,date_end:date_end};
    return this.customPost(postBody,url,params);
  }

  getIncidentPublicImage(incident:Incident) : Promise<{signed_url:string}> {
    let url = `${environment.api}safety/incidents/${incident.id}/picture`;
    return this.customGet({},url);
  }
}
