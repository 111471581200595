import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

export interface DialogData {
    message: string;
}

@Component({
    selector: 'app-informative-dialog',
    templateUrl: './informative-dialog.component.html',
    styleUrls: ['./informative-dialog.component.css']
})
export class InformativeDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<InformativeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
    }

    ngOnInit() {
    }

    actionAndCloseDialog() {
        this.dialogRef.close();
    }

}
