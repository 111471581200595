<div class="position-absolute w-100 trackit-panel">
  <mat-progress-bar color="primary" mode="indeterminate" style="height: 2px" *ngIf="loading"></mat-progress-bar>
  <div class="top-bar d-flex flex-row align-items-center">

    <span class="mr-3" style="color: var(--foreground-default)!important;font-size: 8pt;font-weight: 500;white-space: pre;">{{trackItVehicle.vehicle.name}}</span>

    <div class="date-input d-inline-flex flex-row align-items-center border-gray-rounded mr-3" (click)="rangePicker.open()">
      <input [satDatepicker]="rangePicker" [(ngModel)]="selectedRange" (dateChange)="onRangeSelected($event.value)" class="border-0 w-100 rounded" placeholder="{{'label.date_range'| translate }}">
      <i class="mdi mdi-calendar-range cursor-pointer" style="color:var(--foreground-secondary);opacity: 0.6;"></i>
      <sat-datepicker #rangePicker [rangeMode]="true"></sat-datepicker>
    </div>
    <div *ngIf="showEventsToggleButton()" class="event-toggle-container">
      <app-ngx-small-toggle
        [title]="'label.show_events' | translate"
        (onValueChange)="onShowEventsChanged($event)"
        [checked]="showEvents"
      >
      </app-ngx-small-toggle>
    </div>
    <div class="w-100 mx-3 d-flex flex-row align-items-center">
      <span class="mr-1" *ngIf="getAccDist()!=null" style="color: var(--foreground-default)!important;font-size: 11px;font-weight: 500;white-space:nowrap;">{{getAccDist()}} <span style="font-size: 8px;font-weight: 400">{{preferenceService.getDistUnit()}}</span></span>
      <button [disabled]="messages.length == 0" mat-button color="primary"  *ngIf="!isPlaying" (click)="play()" >
        <i class="mdi mdi-play" style="color: var(--foreground-default)!important;font-size: 14pt"></i>
      </button>
      <button mat-button color="primary" *ngIf="isPlaying" (click)="cancelPlayInterval()" >
        <i class="mdi mdi-pause" style="color: var(--foreground-default)!important;font-size: 14pt"></i>
      </button>
      <mat-select class="ml-3" [(value)]="playbackSpeed" style="width: 50px" (selectionChange)="onSpeedChanged($event)">
        <mat-option [value]="300">x1</mat-option>
        <mat-option [value]="150">x2</mat-option>
        <mat-option [value]="75">x4</mat-option>
        <mat-option [value]="37">x8</mat-option>
      </mat-select>
      <div class="slide-area w-100">
        <div *ngFor="let extra of pointExtras" [hidden]="getEventPointPositionInTimeline(extra) == null || showEvents == false" [style.left]="getEventPointPositionInTimeline(extra)+'%'" class="event-timeline"></div>
        <mat-slider
          thumbLabel
          [displayWith]="formatLabel"
          color="accent"
          [disabled]="messages.length<=0"
          (change)="onSeekerChanged($event)"
          class="w-100"
          min="0"
          [value]="sekkerIndex"
          [max]="sekkerSize"></mat-slider>
      </div>

    </div>
    <i class="ml-auto mdi mdi-unfold-less-horizontal close-icon" (click)="minimized=!minimized"></i>
    <i class="ml-3 mdi mdi-close close-icon" (click)="onClosed.emit()"></i>
  </div>
  <div [hidden]="minimized">
    <div id="message-range-table" class="vehicle-list-header">
      <table mat-table class="bg_transparent w-100" [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div class="table_options w-100 d-flex">
              <i style="color: var(--foreground-default)!important;" class="mdi mdi-content-copy mr-2 cursor-pointer" (click)="copyMessage(element,$event)" matTooltip="{{'label.copy' | translate}}"></i>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="timestamp">
          <th mat-header-cell style="margin-left: 50px" *matHeaderCellDef>{{'nav.date' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{getMessageDateTime(element) | fullDate}}</td>
        </ng-container>
        <ng-container matColumnDef="speed">
          <th mat-header-cell *matHeaderCellDef>{{'nav.speed' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex flex-row align-items-center">
              <span class="ml-2" style="margin-top: 2px">{{element['position.speed']}}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="latitude">
          <th mat-header-cell *matHeaderCellDef  >{{'label.lat' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            {{element['position.latitude']}}
          </td>
        </ng-container>
        <ng-container matColumnDef="longitude">
          <th mat-header-cell *matHeaderCellDef >{{'label.lng' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            {{element['position.longitude']}}
          </td>
        </ng-container>

        <ng-container *ngFor="let additionalField of additionalFields" [matColumnDef]="additionalField.field">
          <th mat-header-cell *matHeaderCellDef >{{(additionalField.title) | translate}}</th>
          <td mat-cell *matCellDef="let element">
            {{getAdditionalFieldValue(additionalField.field,element)}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:false" style="background:transparent!important;"></tr>
        <tr [id]="'MESSAGE_'+row.index" mat-row (click)="onMessageSelected(row,false)" [class]="'table_cell ' + getMessageClass(row)" *matRowDef="let row; columns: displayedColumns; let index = index" style="height: 20px;font-size: 10pt;color:var(--foreground-default)"></tr>
      </table>
      <mat-paginator style="background-color: transparent !important;" (page)="onPage($event)" [pageSizeOptions]="[pageSize]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
