import { ReportModel } from 'src/app/models/report.model';
import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportServiceUser extends BaseService<ReportModel>{

  constructor(public injector:Injector) {
    super(injector, 'core/reports', ReportModel.fromJson)
  }

  public async getReportByUserId(user_id:number):Promise<ReportModel[]>{
    let headers = await this.buildHeader();


    return this.http.get(this.apiPath+'/'+user_id,{headers:headers,observe:'response'}).toPromise().then(
      (response:HttpResponse<{data:any[]}>) => {
        this.refreshToken(response.headers);
        let reports = response.body.data.map((report:any) => {
          return this.jsonDataToModel(report);
        });
        return reports;
      }
    ).catch( reason => {
      this.willLogoutUser(reason);
      throw reason;
    });
  }

  public async saveUserReport(resources:any[], user_id:number):Promise<ReportModel[]>{
    let header = await this.buildHeader();
    return this.http.put(this.apiPath+'/'+user_id,resources,{headers:header,observe: 'response'}).toPromise().then(
      (response:HttpResponse<{data:any[]}>)=>{
        if(response.body['success']){
          this.refreshToken(response.headers);
          return resources;
        }
        throw "User reports could not be saved!";
      }
    ).catch( reason => {
      this.willLogoutUser(reason);
      throw reason;
    });
  }

}
