import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';


export class PermissionCategory {
  constructor(
    public list?: number,
    public edit?: number,
    public remove?: number,
    public create?: number,
    public log?: number,
    public plant?: number
  ) {}

  public get(method: string): number {
    if (method === 'list') {
      return this.list;
    } else if(method === 'edit'){
      return this.edit;
    } else if(method === 'remove'){
      return this.remove;
    } else if(method === 'create'){
      return this.create;
    } else if(method === 'log'){
      return this.log;
    } else if (method === 'plant') {
      return this.plant;
    }

    return -1;
  }
}


@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private permissionMap: any;
  private permission: any = null;

  constructor(private sessionStorage: SessionStorageService) {
    this.init();
  }

  init() {
    this.permissionMap = {
      "VEHICLE": new PermissionCategory(233 , 235, 236, 234),
      "VEHICLE_GROUP": new PermissionCategory(237 , 239, 240, 238),
      "VEHICLE_CATEGORY": new PermissionCategory(241 , 243, 244, 242),
      "GEOFENCE": new PermissionCategory(4, 5, 6, 25, null, 87),
      "ALLOCATION": new PermissionCategory(115, 116, 117, 118),                 // colocar permissions
      "PLANNING": new PermissionCategory(119, 120, 121, 122),                   // colocar permissões plan
      "CUSTOMER": new PermissionCategory(123, 124, 125, 126),
      "PRODUCT": new PermissionCategory(127, 128, 129, 130),
      "SELLER": new PermissionCategory(131, 132, 133, 134),
      "PROJECT": new PermissionCategory(135, 136, 137, 138),
      "DRIVER": new PermissionCategory(229, 231, 232, 230),
      "ORDER": new PermissionCategory(143, 144, 145, 146),
      "VEHICLE_CAPACITY": new PermissionCategory(null, 147, null, null),
      "USAGE": new PermissionCategory(148, 149, null, null),
      "OPERATION": new PermissionCategory(150, null, null, null),
      "STATUS": new PermissionCategory(161, 162, null, null),
      "TAX": new PermissionCategory(163, 164, null, null),
      "CHANGE_ORDER_STATUS": new PermissionCategory(null,187,null,null),
      "DASHBOARD": new PermissionCategory(197),
      "DASH_REVENUE": new PermissionCategory(198),
      "LIVE_TRIPS" : new PermissionCategory(205, 206),
      "PLAN_VEHICLE": new PermissionCategory(211, 212, 213, 214),
      "USER" : new PermissionCategory(245,247,248,246,249),
      "REPORTS": new PermissionCategory(220),
      "DELIVERY_REPORT": new PermissionCategory(221),
      "CUSTOMER_REPORT": new PermissionCategory(222),
    };
  }

  storePermissions(permissions: any) {
    this.permission = permissions;
    this.sessionStorage.store('p', permissions);
  }

  getPermissions() {
    return this.sessionStorage.retrieve('p');
  }

  cleanPermissions() {
    this.sessionStorage.clear('p');
  }

  hasPermission(category: string, method: string): boolean {

    if (this.permission == null) {
      this.permission = this.sessionStorage.retrieve('p');
    }

    try {
      let categoryObj: PermissionCategory = this.permissionMap[category];
      return !!this.permission[categoryObj.get(method)];
    } catch (e) {
      return false;
    }
  }

  hasCustomPermission(permissionID: number){
    if (this.permission == null)
        this.permission = this.sessionStorage.retrieve('p');
    try {
        return !!this.permission[permissionID];
    }
    catch (e) {
        return false;
    }
  }


}
