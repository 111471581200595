import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InformativeDialogComponent} from './informative-dialog/informative-dialog.component';
import {
  MatButtonModule,
  MatDialogModule,
  MatProgressBarModule,
  MatDividerModule,
  MatTooltipModule,
  MatRippleModule,
  MatFormFieldModule,
  MatButtonToggleModule,
  MatInputModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatChipsModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatTabsModule,
  MatPaginatorModule, MatTableModule, MatSortModule, MatDatepickerModule, MatBadgeModule
} from '@angular/material';
import { IconDialogComponent } from './icon-dialog/icon-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { InputsModule } from 'angular-bootstrap-md';
import { SearchSelectComponent } from './search-select/search-select.component';
import { SelectorComponent } from './selector/selector.component';
import { MonthSelectorComponent } from './month-selector/month-selector.component';
import {ExportButtonComponent} from './export-button/export-button.component';
import { WeekDayPickerComponent } from './week-day-picker/week-day-picker.component';
import {MapComponent} from './map/map.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {MonthSelectorV2Component} from './month-selector-v2/month-selector.component';
import {ChartsModule} from 'ng2-charts';
import {DatePipeModule} from '../pipes/datepipe.module';
import { ScrollingModule} from '@angular/cdk/scrolling';
import { IconSelectorComponent } from './icon-selector/icon-selector.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { WeekPickerComponent } from './week-picker/week-picker.component';
import {SuccessDialogComponent} from './success-dialog/success-dialog.component';
import {NgxLoopModule} from '../../../projects/ngx-loop-components/src/lib/ngx-loop.module';
import { AlertViewerComponent } from './alert-viewer/alert-viewer.component';
import {SatDatepickerModule} from 'saturn-datepicker';
import { JustificationDialogComponent } from './justification-dialog/justification-dialog.component';

@NgModule({
    declarations: [
        InformativeDialogComponent,
        IconDialogComponent,
        ConfirmationDialogComponent,
        SearchSelectComponent,
        SelectorComponent,
        MonthSelectorComponent,
        ExportButtonComponent,
        WeekDayPickerComponent,
        MonthSelectorV2Component,
        MapComponent,
        SuccessDialogComponent,
        IconSelectorComponent,
        WeekPickerComponent,
        AlertViewerComponent,
        JustificationDialogComponent,
    ],
    imports: [
        InputsModule,
        CommonModule,
        MatInputModule,
        MatDialogModule,
        MatButtonModule,
        MatRippleModule,
        MatTooltipModule,
        MatDividerModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        InputsModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatDatepickerModule,
        LeafletModule,
        MatProgressSpinnerModule,
        FormsModule,
        MatChipsModule,
        MatExpansionModule,
        MatCheckboxModule,
        ChartsModule,
        DatePipeModule,
        ScrollingModule,
        MatTabsModule,
        MatPaginatorModule,
        MatTableModule,
        NgxLoopModule,
        MatSortModule,
        DragDropModule,
        MatBadgeModule,
        SatDatepickerModule
    ],
  exports: [
    InformativeDialogComponent,
    SearchSelectComponent,
    SelectorComponent,
    MonthSelectorV2Component,
    MonthSelectorComponent,
    ExportButtonComponent,
    WeekDayPickerComponent,
    IconSelectorComponent,
    MapComponent,
    WeekPickerComponent,
    AlertViewerComponent,
    JustificationDialogComponent,
  ],
    entryComponents: [
        InformativeDialogComponent,
        IconDialogComponent,
        ConfirmationDialogComponent,
        SuccessDialogComponent,
        JustificationDialogComponent
    ]
})
export class ComponentsModule {
}
