import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
    confirmationMessage: string;
    title: string,
    buttonText: string;
    iconClass: string;
    iconColor?: string;
    checkboxText?:string;
    showCheckBox?:boolean,
    cancelable?:boolean,
    action: (isCheckBoxChecked:boolean) => {};
    cancel: () => {};
}


@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

    isCheckBoxChecked:boolean = false;
    cancelable:boolean = true;

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
        this.cancelable = (data.cancelable != null)? data.cancelable : true;
    }

    ngOnInit() {
    }

    cancelAndClose(){
      this.data.cancel();
      this.dialogRef.close(true);
    }

    actionAndClose() {
        this.data.action(this.isCheckBoxChecked);
        this.dialogRef.close(true);
    }

}
