import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-export-button',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss']
})
export class ExportButtonComponent implements OnInit {

  @Input() fileName:string = "Export Excel";
  @Input() exporter = null;
  @Input() type = 'xls';

  @Input() additionalExporters : {title:string,icon:string,exporter:any,type:string,filename:string}[] = [];

  @Input() keylangTitle = 'app.export';

  @Input() small : boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
