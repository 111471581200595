import {Component, Inject, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import moment from 'moment';
import { SessionStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AutoReportService } from '../../depends/auto-reports.service';
import { ReportSchedule } from '../../depends/report-schedule.model';
import { UserModel } from '../../depends/user.model';
import { UserService } from '../../depends/user.service';
import { TranslateService } from '../../translate.service';
import { SnackBarController } from '../../controllers/snackbar-controller';

@Component({
    selector: 'AutoReportsDialog',
    templateUrl: './auto-reports-create-dialog.html',
    styleUrls: ['./auto-reports-create-dialog.css' ]
})

export class AutoReportsCreateDialogComponent implements OnInit {
    snackbarController: SnackBarController;
    editing:boolean = false;

    shippingTypes = {
        "WEEKDAYS":"label.weekly",
        "MONTHDAYS":"label.daily"
    }

    weekDays = ["abbrev.sunday","abbrev.monday","abbrev.tuesday","abbrev.wednesday","abbrev.thursday","abbrev.friday","abbrev.saturday"];
    monthDays = new Array(31);
    selectedWeekDays = new Array(7).fill(0);
    selectedMonthDays = new Array(31).fill(0);
    users:UserModel[] = [];
    selectedRecipients:string[] = [];
    curdate:boolean = true;
    loading: number = 0;
    shippingType:string='MONTHDAYS';
    selectedTime:string=moment().format('HH');
    hours:string[]=[];

    recipientOptions:UserModel[] = [];
    private recipientSubject: Subject<string> = new Subject();

    constructor(
        public dialogRef: MatDialogRef<AutoReportsCreateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data:{report_id:number, schedule:ReportSchedule},
        private userService:UserService,
        private autoReportService:AutoReportService,
        private sessionStorage:SessionStorageService,
        private translate: TranslateService,
        private dialog:MatDialog,
        private snackbar:MatSnackBar
    ) {
        this.snackbarController = new SnackBarController(this.snackbar);
        if(data.schedule != null && data.schedule != null && data.schedule.id != null){
            this.editing = true;
            if(data.schedule.curdate != null)
                this.curdate = data.schedule.curdate;
            if(data.schedule.month_rule != null){
                this.selectedMonthDays = data.schedule.month_rule.split("").map(value => parseInt(value));
                this.shippingType = "MONTHDAYS";
            }
            if(data.schedule.week_rule != null){
                this.selectedWeekDays = data.schedule.week_rule.split("").map(value => parseInt(value));
                this.shippingType = "WEEKDAYS";
            }
            this.selectedRecipients = (data.schedule.recipients as any[]).map(recipient => recipient.email);
            let hour:any = data.schedule.hour;
            hour = hour < 10?'0'+hour:hour.toString();
            this.selectedTime = hour;
        }

        for(let i = 0; i <= 23; i++){
            this.hours.push((i).toString());
        }
        
    }

    ngOnInit() {
        this.downloadUsers();
    }

    ngAfterViewInit(){
        this.recipientSubject.pipe(
          debounceTime(800)
        ).subscribe(searchText => {
            if(searchText != null && searchText.trim() != "")
                this.recipientOptions = this.users.filter(user => user.email.toLowerCase().includes(searchText.toLowerCase()));
        });
    }

    onRecipientSearch(event){
        this.recipientSubject.next(event.target.value);
    }

    onRecipientInputBlur(recipientInput, selectedRecipientsIndex){
        if(selectedRecipientsIndex != null && (recipientInput.value == null || recipientInput.value.trim() == ""))
            this.selectedRecipients.splice(selectedRecipientsIndex,1);
        else
            this.selectedRecipients[selectedRecipientsIndex] = recipientInput.value;
    }

    private downloadUsers(){
        this.setLoading(true);
        this.userService.getResources().then((users) => {
            if(users != null)
                this.users = users;
        }).finally(() => {
            this.setLoading(false);
        });
    }

    public onRecipientSelect(event, recipientInput, selectedRecipientsIndex=null){
        recipientInput.value = event.option.value;
        this.recipientOptions = [];
        if(selectedRecipientsIndex != null)
            this.selectedRecipients[selectedRecipientsIndex] = event.option.value;
    }

    public addRecipient(recipientInput:HTMLInputElement){
        if(recipientInput.value != null && recipientInput.value.trim() != "" && !this.selectedRecipients.some(recipient => recipient == recipientInput.value)){
            this.selectedRecipients.push(recipientInput.value);
            recipientInput.value = null;
        }
    }

    public removeRecipient(recipient){
        this.selectedRecipients.splice(this.selectedRecipients.indexOf(recipient),1);
    }

    public validate(value){
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return value.trim() == "" || re.test(String(value).toLowerCase());
    }

    public confirm(){
        if(this.data != null && this.data.report_id != null){
            let recipients:string[] = this.selectedRecipients;
            let weekRule:string = (this.shippingType == 'WEEKDAYS')?this.selectedWeekDays.map(d => d.toString()).join(""):null;
            let monthRule:string = (this.shippingType == 'MONTHDAYS')?this.selectedMonthDays.map(d => d.toString()).join(""):null;;
            let hour:number = parseInt(this.selectedTime);
            let company = this.sessionStorage.retrieve("selectedCompany");

            let reportSchedule:ReportSchedule = new ReportSchedule(this.data.report_id,company.id,weekRule, monthRule, hour, null,null,this.curdate);
            let error_message = "";
            if(!this.selectedWeekDays.includes(1) && !this.selectedMonthDays.includes(1)){
                error_message = this.translate.data["msg.select_shipping_days"];
            }
            if(recipients.some(recipient => !this.validate(recipient))){
                error_message = this.translate.data["msg.enter_valid_recipients"];
            }
            if(error_message != ""){
                this.dialog.open(ConfirmationDialogComponent, {
                    width: '300px',
                    data: {
                      title: this.translate.data["label.attention"],
                      confirmationMessage: error_message,
                      buttonText: this.translate.data['label.ok'],
                      iconClass: 'mdi-alert-outline',
                      cancelable:false,
                      action: () => {}
                    }
                });
            }else{
                this.setLoading(true);
                if(!this.editing){
                    this.autoReportService.createReportSchedule({report_schedule:reportSchedule, recipients:recipients}).then((result) => {
                        this.dialogRef.close(result);
                    }).catch((error) => {
                        this.snackbarController.openSnackBar(this.translate.data['msg.error_occurredr_occurred'],"error");
                        console.log(error);
                    }).finally(() => {
                        this.setLoading(false);
                    });
                }else{
                    reportSchedule.id=this.data.schedule.id;
                    this.autoReportService.editReportSchedule(this.data.schedule.id,{report_schedule:reportSchedule, recipients:recipients}).then((result) => {
                        this.dialogRef.close(result);
                    }).catch((error) => {
                        this.snackbarController.openSnackBar(this.translate.data['msg.error_occurredr_occurred'],"error");
                        console.log(error);
                    }).finally(() => {
                        this.setLoading(false);
                    });
                }
            }
        }
    }

    public calcNextTs(){
        let config = this.sessionStorage.retrieve("selectedCompany");
        let timezone = config.timezone;
        
        let nextTs = moment();
        let today = moment();
        let current_time = today.hour();
        let first = null;
        let next = null;
        let rule = [];
        let selectedTime:number = parseInt(this.selectedTime);
        switch(this.shippingType){
            case 'MONTHDAYS':
                rule = this.selectedMonthDays;
                for(let i=0;i<rule.length;i++){
                    if(rule[i]==1){
                        if(first==null) first = (i+1);
                        if((i+1) >= today.date()){
                            if((i+1) > today.date()) current_time = 0;
                            if(selectedTime > current_time){
                                next = (i+1);
                                break;
                            }
                        }
                    }
                }
                if(next==null) next = first;
                nextTs.set({date:next,hour:selectedTime,minute:0,second:0})
                if(next < today.date() || (next == today.date() && selectedTime < current_time)) nextTs.add(1,"month");
            break;
            case 'WEEKDAYS':
                rule = this.selectedWeekDays;
                for(let i=0;i<rule.length;i++){
                    if(rule[i]==1){
                        if(first==null) first = i;
                        if(i >= today.day()){
                            if(i > today.day()) current_time = 0;
                            if(selectedTime > current_time){
                                next = i;
                                break;
                            }
                        }
                    }
                }
                if(next==null) next = first;
                nextTs.set({day:next,hour:selectedTime,minute:0,second:0})
                if(next < today.day() || (next == today.day() && selectedTime < current_time))
                    nextTs.add(7,"days");
            break;
        }
        return (nextTs != null)?nextTs.tz(timezone).format("YYYY-MM-DD HH:mm:ss"):null;
    }

    public selectDay(event, ar){
        for(let i = 0; i < ar.length;i++){
            ar[i] = (event.value.includes(i)?1:0);
        }
    }

    public changeShippingType(event){
        this.shippingType = this.shippingTypes[event.value];
    }

    public isLoading(): boolean {
        return this.loading > 0;
    }

    public setLoading(value: boolean) {
        if (value)
            this.loading += 1;
        else if (this.loading > 0)
            this.loading -= 1;
    }

    close(): void {
        this.dialogRef.close();
    }

    public cancel(){
        this.dialogRef.close();
    }

}
