import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets, ChartPointOptions, PointStyle } from 'chart.js';
import {BaseChartDirective, Color, Label} from 'ng2-charts';
import {DashboardModel} from '../../../models/dashboard.model';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {GroupedDashboardResult} from '../../../services/dashboard.service';
import {TranslateService} from '../../../../../projects/ngx-loop-components/src/lib/translate.service';
import { ThemeService } from 'src/app/services/theme.service';
import {KAT_LIGHT_THEME, LIGHT_THEME} from 'src/app/app.component';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent implements OnInit,AfterViewInit {

  @ViewChild(BaseChartDirective,{static:false}) chart: BaseChartDirective;

  public darkRadarChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    showLines:false,
    tooltips: {
      enabled: true,
      callbacks:{
        label: function(tooltipItem, data) {
          return data.labels[tooltipItem.index].toString();
        }
      }
    },
    elements: {
      point: {
        radius:2
      },
      line:{
        borderWidth: 2
      }
    },
    scale:{
      pointLabels:{
        fontSize:13,
        fontColor: 'var(--foreground-default)',
        fontStyle: 'bold'
      },
      ticks: {
        showLabelBackdrop: false,
        fontColor: 'var(--primary-text)'
      }
    },
    scales: {
      xAxes: [{
        display:false,
        ticks: {
          display: false,
          fontColor: 'var(--foreground-color)'
        },
        gridLines : {
          display: false
        }
      }],
      yAxes: [{
        display: false,
        ticks: {
          fontColor: 'var(--foreground-color)',
        }
      }],

    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 10,
        bottom: 10
      }
    },
    legend: {
      align: 'start',
      labels: {
        fontColor: 'white'
      }
    },
    plugins: {
      datalabels: {
        display: false,
      }
    }
  };
  public radarChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    showLines:false,
    tooltips: {
      enabled: true,
      callbacks:{
        label: function(tooltipItem, data) {
          return data.labels[tooltipItem.index].toString();
        }
      }
    },
    elements: {
      point: {
        radius:2
      },
      line:{
        borderWidth: 2
      }
    },
    scale:{
      pointLabels:{
        fontSize:13,
        fontStyle: 'bold'
      },
      ticks: {
        showLabelBackdrop: false,
        fontColor: 'var(--primary-text)'
      }
    },
    scales: {
      xAxes: [{
        display:false,
        ticks: {
          display: false
        },
        gridLines : {
          display: false
        }
      }],
      yAxes: [{
        display: false
      }],

    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 10,
        bottom: 10
      }
    },
    legend: {
      align: 'start'
    },
    plugins: {
      datalabels: {
        display: false,
      }
    }
  };

  public darkPieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    showLines:false,
    elements:{
      arc: {
        borderWidth: 0
      }
    },
    tooltips: {
      enabled: true,
      callbacks:{
        label: (tooltipItem, data) => {
          if(this.translateLegend)
            return this.translate.data[data.labels[tooltipItem.index].toString()] + " " + data.datasets[0].data[tooltipItem.index].toString();
          return data.labels[tooltipItem.index].toString() + " " + data.datasets[0].data[tooltipItem.index].toString();
        }
      }
    },

    legend: {
      align: 'start',
      labels: {
        fontColor: 'white',
      },
    },

    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 16,
        bottom: 16
      }
    },

    plugins: {
      datalabels: {
        display: (context) => context.dataset.data[context.dataIndex] > 0,
        anchor: 'end',
        offset: 0,
        align: 'end',
        color: 'var(--focus-text-color)',
        font: {
          weight: 'bold',
          size: 13
        },
      }
    }

  };
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    showLines:false,
    tooltips: {
      enabled: true,
      callbacks:{
        label: (tooltipItem, data) => {
          if(this.translateLegend)
            return this.translate.data[data.labels[tooltipItem.index].toString()] + " " + data.datasets[0].data[tooltipItem.index].toString();
          return data.labels[tooltipItem.index].toString() + " " + data.datasets[0].data[tooltipItem.index].toString();
        }
      }
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 16,
        bottom: 16
      }
    },
    legend: {
      align: 'start'
    },
    plugins: {
      datalabels: {
        display: (context) => context.dataset.data[context.dataIndex] > 0,
        anchor: 'end',
        offset: 0,
        align: 'end',
        color: 'var(--focus-text-color)',
        font: {
          weight: 'bold',
          size: 13
        },
      }
    }
  };

  public darkBarChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,

    showLines:false,
    tooltips: {
      enabled: false
    },
    scales: {
      xAxes: [{
        ticks: {
          fontColor: 'white',
          callback: (value,index,values) => {
            return this.translate.data[value];
          }
        },
        gridLines : {
          display: false
        }
      }],
      yAxes: [{
        ticks: {
          fontColor: 'white'
        }
      }]
    },

    layout: {
      padding: {
        left: 15,
        right: 15,
        top: 10,
        bottom: 0
      }
    },
    legend: {
      align: 'start',
      labels: {
        fontColor: 'white'
      }
    },
    plugins: {
      legend: {
        labels: {
          // This more specific font property overrides the global property
          color: 'white',
          font: {
            size: 14,
            color: 'white'
          }
        },
      },
      datalabels: {
        display: (context) => context.dataset.data[context.dataIndex] > 0,
        anchor: 'end',
        offset: 0,
        align: 'end',
        color: 'white',
        font: {
          weight: 'bold',
          size: 10
        },
      }
    }
  };
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,

    showLines:false,
    tooltips: {
      enabled: false
    },
    scales: {
      xAxes: [{
        ticks: {
          fontColor: 'var(--foreground-default)',
          callback: (value,index,values) => {
            return this.translate.data[value];
          }
        },
        gridLines : {
          display: false
        }
      }],
      yAxes: [{
        ticks: {
          fontColor: 'var(--foreground-default)',
        }
      }]
    },
    layout: {
      padding: {
        left: 15,
        right: 15,
        top: 10,
        bottom: 0
      }
    },
    legend: {
      align: 'start'
    },
    plugins: {
      datalabels: {
        display: (context) => context.dataset.data[context.dataIndex] > 0,
        anchor: 'end',
        offset: 0,
        align: 'end',
        color: 'var(--foreground-default)',
        font: {
          weight: 'bold',
          size: 10
        },
      }
    }
  };

  public barChartLabels: Label[] = [];
  public barChartPlugins = [
    {
      afterLayout:  (chart) => {
        chart.legend.legendItems.forEach(
          (label) => {
            if(label.text != null) {
              if (this.translateLegend)
                label.text = this.translate.data[label.text];
            }
            else
              label.text = "";
          }
        )
      }
    },
    pluginDataLabels,
    {
      beforeInit: function(chart, options) {
        chart.legend.afterFit = function() {
          this.height += 20; // must use `function` and not => because of `this`
        };
      }
    }];

  public barChartData: ChartDataSets[] = [{
    label: '',
    data: [],
  }];

  @Input()
  showLegend : boolean = false;

  @Input()
  maxRecords : number = null;

  @Input()
  reverse: boolean = false;

  @Input()
  showRefreshButton : boolean = false;

  public dataset: any = [];

  @Input()
  title: string = "";

  @Input()
  translateLegend: boolean = true;

  @Input()
  height: number;

  @Input()
  chartType: ChartType = 'bar';

  @Output()
  onRefreshButtonPressed: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public translate: TranslateService,
    public themeService: ThemeService,
  ) { }

  ngOnInit() {

  }

  getChartOptions(){
    if(this.chartType == 'pie' || this.chartType == 'doughnut') {
      if (this.themeService.active.name == LIGHT_THEME || this.themeService.active.name == KAT_LIGHT_THEME)
        return this.pieChartOptions;
      else
        return this.darkPieChartOptions;
    } else if(this.chartType == 'radar') {
      if (this.themeService.active.name == LIGHT_THEME || this.themeService.active.name == KAT_LIGHT_THEME)
        return this.radarChartOptions;
      else
        return this.darkRadarChartOptions;
    } else {
      if (this.themeService.active.name == LIGHT_THEME || this.themeService.active.name == KAT_LIGHT_THEME)
        return this.barChartOptions;
      else
        return this.darkBarChartOptions;
    }
  }

  setData(dataset:any[]){
    this.dataset = dataset;
    this.draw();
  }

  setStackedData(datasets:GroupedDashboardResult) {

    if(this.reverse){

      let reversedLabels = [];
      let reversedDatasets = [];

      let i = datasets.labels.length - 1;

      while(i >= 0){
        reversedLabels.push(datasets.labels[i]);
        i--;
      }

      datasets.datasets.forEach(set => {
        let dataset : {label:string,color:string,data:number[],stack?:string,backgroundColor?:string} = {
          label: set.label,
          color: set.color,
          backgroundColor: set.backgroundColor,
          stack: set.stack,
          data: []
        }

        let j = set.data.length - 1;

        while(j >= 0) {
          dataset.data.push(set.data[j]);
          j--;
        }

        reversedDatasets.push(dataset);
      });
      console.log(reversedDatasets);
      this.barChartLabels = reversedLabels;
      this.barChartData = reversedDatasets;

      // this.barChartLabels = datasets.labels.reverse();
      // this.barChartData = datasets.datasets.reverse();
    }else{
      this.barChartLabels = datasets.labels;
      this.barChartData = datasets.datasets;
    }

    if(this.maxRecords != null){
      let labels = [];
      let timesAdded = 0;

      this.barChartLabels.forEach(l => {
        if(timesAdded < this.maxRecords){
          labels.push(l);
        }
        timesAdded++;
      });

      this.barChartLabels = labels;
    }

    this.chart.update();
  }

  draw() : void {

    let isFirstSet : boolean = true;

    let dataLabels = [];
    this.barChartData = [];

    this.dataset.forEach((set:{data:DashboardModel[],legend:string}) => {

      let chartDataSet = {
        label : set.legend,
        data: []
      };

      set.data.forEach((d:DashboardModel) => {
        if(isFirstSet){
          dataLabels.push(d.label);
        }
        chartDataSet.data.push(d.value);
      });
      isFirstSet = false;

      this.barChartData.push(chartDataSet);
    });
    this.barChartLabels = dataLabels;
    this.chart.update();
  }

  ngAfterViewInit(): void {
  }
}
