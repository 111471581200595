import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import {Company} from './depends/company.model';
// @ts-ignore
var moment: any = require('moment-timezone');

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  company: Company;

  BASE_DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
  BASE_DATE_FORMAT = "DD/MM/YYYY";

  DEFAULT_TIME_FORMAT = "HH:mm";
  DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
  DEFAULT_DATE_TIME_FORMAT = `${this.DEFAULT_DATE_FORMAT } ${this.DEFAULT_TIME_FORMAT}`;

  US_TIME_FORMAT = "hh:mm a";
  US_DATE_FORMAT = "M/DD/YYYY";
  US_DATE_TIME_FORMAT = `${this.US_DATE_FORMAT } ${this.US_TIME_FORMAT}`;

  DEFAULT_SPEED = "Km/h";
  DEFAULT_TEMPERATURE = "ºC";
  US_SPEED = "mph";

  constructor(
    private sessionStorage: SessionStorageService
  ) {}

  checkAndSetCompany(){
    if(this.company == null){
      this.company = this.sessionStorage.retrieve('selectedCompany');
    }
  }

  setCompany(company: Company) {
    this.company = company;
  }

  getSpeedUnit() {
    this.checkAndSetCompany();
    if (this.company && this.company.unit_speed){
      return this.company.unit_speed.abbrev;
    }
    return this.DEFAULT_SPEED;
  }

  getTemperatureUnit() {
    this.checkAndSetCompany();
    if (this.company && this.company.unit_temperature){
      return this.company.unit_temperature.abbrev;
    }
    return this.DEFAULT_TEMPERATURE;
  }

  convertSpeed(kph:number) : number {
    this.checkAndSetCompany();
    if(this.company && this.company.unit_speed){
      return Math.round(kph*this.company.unit_speed.convert);
    }
    return kph;
  }

  public getFormattedSpeed(kph,digits:number){
    return `${this.convertSpeed(kph).toFixed(digits)} ${this.getSpeedUnit()}`;
  }

  convertDistance(km:number,round?:boolean) : number {
    this.checkAndSetCompany();

    if(round == null)
      round = true;

    if(this.company && this.company.unit_dist){
      if(round)
        return Math.round(km*this.company.unit_dist.convert);
      else
        return km*this.company.unit_dist.convert;
    }
    return km;
  }

  getVolumeUnit() {
    this.checkAndSetCompany();
    if(this.company && this.company.unit_vol){
      return this.company.unit_vol.abbrev;
    }
    return "m³";
  }

  getFuelUnit() {
    this.checkAndSetCompany();
    if (this.company && this.company.unit_fuel) {
      return this.company.unit_fuel.abbrev;
    }

    return 'L';
  }

  getDistUnit() {
    this.checkAndSetCompany();
    if (this.company && this.company.unit_dist) {
      return this.company.unit_dist.abbrev;
    }

    return 'KM';
  }

  getDrumSpeedUnit() {
    return 'rpm';
  }

  timestampToMoment(ts:number) {
    this.checkAndSetCompany();
    return moment(new Date(ts * 1000)).tz(this.company.timezone);
  }

}
