import {BaseService} from './base.service';
import {Injectable, Injector} from '@angular/core';
import {UserService} from './user.service';
import {SessionStorageService} from 'ngx-webstorage';


@Injectable({
    providedIn: 'root'
})

export class ConfigurationService extends BaseService<any> {

    configurations: object = null;

    constructor(
        protected injector: Injector,
        protected userService: UserService,
    ) {
        super(injector, 'core/configuration', () => {
        });
    }


    // trás as configurações do usuário junto com a da empresa, e salva no local storage do usuário (que será a utilizada para se basear nas configurações finais)
    // obs: a configuração do usuário já é pega quando ele se loga
    // obs: a configuração da empresa é pega quando fazemos o get na empresa
    async getUserCompanyConfiguration(): Promise<any> {
        let header = await this.buildHeader();

        this.http.get(this.apiPath, {headers: header}).toPromise()
            .then((response: { data: object }) => {
                this.updateUserConfigurations(response.data);
                this.storeConfigurations(response.data);
            })
            .catch((error) => {
                console.log('Error getting configuration', error);
            });
    }

    async updateConfiguration(businessName: string, configKeys: string[], configValues: any[], companyConfig: boolean = false): Promise<any> {
        let header = await this.buildHeader();
        let config: object = {};

        if (configKeys.length != configValues.length)
            console.log('!!!! ––– ALERT ––– !!!!', 'Length of Configuration Key differ from Configuration Values');

        configKeys.forEach((key, index) => {
            let keyValue = configValues[index];
            config[key] = keyValue;
        });

        let allConfig = await this.getUserConfigurations();
        let newConfig: object = {};
        newConfig[businessName] = config;

        let body = {
            'update_company': companyConfig,
            'all_config': allConfig,
            'new_config': newConfig
        };
        this.http.put(this.apiPath, body, {headers: header}).toPromise()
            .then((response: { data: object }) => {
                this.updateUserConfigurations(response.data);
                this.storeConfigurations(response.data);
            })
            .catch((error) => {
                console.log('Error updating configuration', error);
            });
    }


    updateUserConfigurations(newConfig: object){
        let user = this.userService.getUserStore();
        user.configurations = newConfig;
        this.userService.storeUser(user);
    }

    storeConfigurations(configurations: object) {
        this.configurations = configurations;
        this.sessionStorage.store("c", configurations);
    }

    async getCompanyConfiguration(): Promise<any> {
        let header = await this.buildHeader();

        return this.http.get(this.apiPath+"-company", {headers: header}).toPromise()
        .then((response: { data: object }) => {
            return response.data;
        }).catch((error) => {
            console.log('Error getting configuration', error);
        });
    }

    clearConfigurations(){
        this.configurations = null;
        this.sessionStorage.clear("c");
    }

    getUserConfigurations(){
        let configs = this.configurations;
        if(!configs){
            configs = this.sessionStorage.retrieve("c");
            if (!configs){
                let user = this.userService.getUserStore();
                configs = user.configurations;
            }
        }
        return configs
    }


    getConfigValue(businessName: string, configName: string) {
        try{
            let configurations = this.getUserConfigurations();
            let value = configurations[businessName][configName];
            return value;
        }
        catch (error) {
            console.log('Could not get value ', error);
            return null;
        }
    }
}
