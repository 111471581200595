<div class="card-1 p-3" style="display: block;border-radius: 5px; background-color: var(--background-card)">
  <div class="d-flex flex-row align-items-center mb-1">
    <h5 style="color: var(--foreground-default)" class="mb-0">{{title}}</h5>
    <button mat-stroked-button color="primary" style="height: 25px;line-height: 25px" class="ml-auto" *ngIf="showRefreshButton" (click)="onRefreshButtonPressed.emit()"><i class="mr-2 mdi mdi-refresh" style="color:var(--primary-color);" ></i>{{'app.refresh' | translate}}</button>
  </div>
  <div [ngStyle]="{height:height}">
    <canvas style="background-color: var(--background-card)"
            baseChart
            [hidden]="barChartData == null"
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="getChartOptions()"
            [legend]="showLegend"
            [plugins]="barChartPlugins"
            [chartType]="chartType">
    </canvas>
  </div>
  <div class="align-items-center" *ngIf="barChartData == null">
    <i class="mdi mdi-database" style="font-size: 1.5em"></i>
  </div>
</div>
