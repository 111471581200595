import {BaseService} from './base.service';
import {UserModel} from '../models/user.model';
import {Injectable, Injector} from '@angular/core';


@Injectable({
    providedIn: 'root'
})

export class LoginService extends BaseService<UserModel> {

    constructor(protected injector: Injector){
        super(injector, 'core/users-login', UserModel.fromJson);
    }

}
