import { Injectable, NgZone } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserModel } from '../models/user.model';
import { PermissionService } from './permission.service';
import { UserService } from './user.service';
import * as permissions from 'src/app/utils/permissions.json';
import { AuthMonitor } from './auth.service';

@Injectable()
export class ConsequenceManagerGuard implements CanActivate, AuthMonitor {

    isLogged:boolean = false;

  constructor(
    public userService: UserService, 
    private permissionService:PermissionService,
    public router: Router,
    private ngZone: NgZone
  ) {}

  onLoginStateChanged(isLoggedIn: boolean): void {
    this.ngZone.run(async () => {
        this.isLogged = isLoggedIn;
    });
  }
  
  canActivate(): boolean {

    let user:UserModel = this.userService.getUserStore();
    return (this.isLogged && (user.dealer_companies != null && user.dealer_companies.length > 0) || this.permissionService.hasCustomPermission(permissions.SFT_CONSEQUENCE_MANAGER))
  }
}