import { MatSnackBar, MatSnackBarConfig } from "@angular/material";

type messageType = 'error' | 'warning' | 'success'

export class SnackBarController {

    private _snackBar: MatSnackBar;

    constructor(snackBar: MatSnackBar) {
        this._snackBar = snackBar;
    }

    public openSnackBar(message: string, type: messageType) {
        let config: MatSnackBarConfig = {
            panelClass: 'snack_' + type,
            duration: 6000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
        };
        this._snackBar.open(message, 'Ok', config);
    }
}
