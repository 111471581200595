<div class="pt-4">
    <div class="mb-3 d-flex flex-row align-items-center">
        <h3 class="mb-0 title">{{title}}</h3>
        <button 
            *ngIf="!hideButton"
            (click)="onClick()"
            mat-raised-button
            color="primary"
            class="ml-auto"
        >
            <i class="mdi mdi-cog mr-2" style="color:#fff"></i>
            {{buttonTitle}}
        </button>
    </div>
    
    <mat-progress-bar *ngIf="isLoading" style="height: 2px;"  mode="indeterminate"></mat-progress-bar>
    <mat-divider style="border-top-color: var(--foreground-default);opacity: 0.1;"></mat-divider>
</div>
