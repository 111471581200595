export const environment = {
  production: true,
  api: 'https://api.360kpi.io/',
  firebase: {
    apiKey: "AIzaSyDlRtscQQEHTKR-1CaWJ3579GAhpHlN1ho",
    authDomain: "cat33-69131.firebaseapp.com",
    projectId: "cat33-69131",
    storageBucket: "",
    messagingSenderId: "633425751451",
    appId: "1:633425751451:web:0307d3ffaf4f7c577f853e"
  }
};
