<div class="container-fluid px-5 py-4">
  <div class="d-flex flex-row align-items-center">
    <h3 style="color: var(--foreground-default);" class="mb-0">{{'label.dashboard' | translate }}</h3>
    <app-month-selectorv2
      #periodSelector
      [numberOfButtons]="3"
      (onDateChanged)="onPeriodChanged($event)"
      class="ml-auto"
    ></app-month-selectorv2>
  </div>
  <mat-tab-group class="my-mat-tabs-group mt-3" (selectedIndexChange)="onTabChanged($event)">
    <mat-tab class="my-mat-tabs" *ngFor="let group of groups" [label]="group.name">
      <div class="px-2">
        <app-dashboard-generic-tab [companyHasGroups]="groups.length > 0" [eventGroupName]="group.name" [eventGroupId]="group.id" #dashboardTabs ></app-dashboard-generic-tab>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
