import { Injectable } from '@angular/core';
import {SessionStorageService} from 'ngx-webstorage';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  public toolbar: boolean = true;

  constructor(
    private sessionStorage : SessionStorageService
  ) { }

  public getShowToolbar() : boolean {
    return this.toolbar;
  }

  public getTitle(){
    let title = this.sessionStorage.retrieve('t');
    return title || "Login";
  }

  public setTitle(title:string){
    this.sessionStorage.store('t',title);
  }

  public observe() : Observable<string> {
    return this.sessionStorage.observe('t');
  }
}
