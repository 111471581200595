import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PageTitleComponent} from './components/page-title/page-title.component';
import { MatButtonModule, MatDatepickerModule, MatDividerModule, MatProgressBarModule, MatSelectModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {InputsModule} from 'angular-bootstrap-md';
import { StepLabelComponent } from './components/step-label/step-label.component';


@NgModule({
  declarations: [PageTitleComponent, StepLabelComponent],
    imports: [
        CommonModule,
        MatProgressBarModule,
        MatDividerModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatSelectModule,
        InputsModule,
        MatDatepickerModule
    ],
    exports: [
        PageTitleComponent,
        StepLabelComponent,
        MatDatepickerModule
    ],
})
export class SharedModule {}
