import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AngularFireAuth} from '@angular/fire/auth';
import {UserService} from '../../services/user.service';
import UserCredential = firebase.auth.UserCredential;
import {UserModel as User} from '../../models/user.model';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {LoginService} from '../../services/login.service';
import {AuthService} from '../../services/auth.service';
import {TitleService} from '../../services/title.service';
import {TranslateService} from '../../../../projects/ngx-loop-components/src/lib/translate.service';
import { ThemeService } from 'src/app/services/theme.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  loading: boolean = false;
  invalidEmail: boolean = false;
  authSubecription: Subscription;

  constructor(private toastr: ToastrService,
              private formBuilder: FormBuilder,
              private fireAuth: AngularFireAuth,
              private translate: TranslateService,
              private router: Router,
              private sessionStorageService: SessionStorageService,
              private loginService: LoginService,
              public titleService: TitleService,
              public themeService: ThemeService,

  private authService: AuthService) {

  }

  ngOnInit() {
    this.titleService.setTitle("Login");
    if(this.authService.isLoggedIn()){
      this.router.navigateByUrl('/dashboard');
    }

    this.initForm();
  }

  initForm(){
    this.form = this.formBuilder.group({
      email : [null,[Validators.required]],
      password: [null,Validators.minLength(6)]
    });
  }

  passwordReset() : void {
    if(this.form.get('email').valid){
      this.fireAuth.auth.sendPasswordResetEmail(this.form.get('email').value).then(
        ()=>{
          this.toastr.success(this.translate.data['msg.pwdresetsent']);
          this.invalidEmail = false;
        }
      )
    }else{
      this.invalidEmail = true;
    }
  }

  loginPress():void{
    if(this.form.valid){

      this.loading = true;

      let email:string = this.form.get('email').value;
      let password: string = this.form.get('password').value;

      /**
      this.authService.loginV2(email,password).then((user:User) => {}).catch(e => {
        this.toastr.error(this.translate.data['msg.erro']);
        this.loading = false;
      }).finally(()=>{});
       */

      this.authService.loginV2(email, password)
      .then(() => {})
      .catch(e => {
          console.log('login press error', e);
          if (e.status == 401) {
              this.authService.logout();
              //this.showAccessBlockedDialog();
          } else {
              //this.openSnackBar(this.translate.data['msg.erro'], 'error');
          }
      })
      .finally(() => {
          this.loading = false;
      });



    }
  }

  showAccessBlockedDialog() {
    /**
    this.dialog.open(IconDialogComponent, {
        width: '300px',
        data: {
            title: this.translate.data['access.blocked_title'],
            icon: 'mdi-account-cancel',
            iconColor: 'var(--warn-color)',
            message: this.translate.data['msg.no_business_access']
          }
    });
     */
  }

  public openSnackBar(message: string, type:string) {
    /**
    let config: MatSnackBarConfig = {
        panelClass: 'snack_' + type,
        duration: 6000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom'
    };
    this._snackBar.open(message, "Ok", config);
    */
  }

  checkFieldValidator(field:string):boolean{
    return this.form.get(field).invalid && this.form.get(field).touched;
  }


}
