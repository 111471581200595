import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { async } from '@angular/core/testing';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MatSnackBarConfig, MAT_DIALOG_DATA } from '@angular/material';
import { SessionStorageService } from 'ngx-webstorage';
import { SelectorComponent, SelectorConstants } from 'src/app/components/selector/selector.component';
import { Company } from 'src/app/models/company.model';
import { ReportModel } from "src/app/models/report.model";
import { UserModel } from 'src/app/models/user.model';
import { Vehicle } from 'src/app/models/vehicle.model';
import { CheckFormService } from 'src/app/services/check-form.service';
import { PermissionService } from 'src/app/services/permission.service';
import { ReportServiceUser } from 'src/app/services/report.service.user';
import { UserService } from 'src/app/services/user.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import {TranslateService} from '../../../../../projects/ngx-loop-components/src/lib/translate.service';
import * as permissions from 'src/app/utils/permissions.json';

export class UserFormDialogData{
  user:UserModel
}

@Component({
  selector: 'app-user-form-dialog',
  templateUrl: './user-form-dialog.component.html',
  styleUrls: ['./user-form-dialog.component.css']
})
export class UserFormDialogComponent implements OnInit, AfterViewInit {

  @ViewChild('vehicleSelector',{static:true}) vehicleSelector:SelectorComponent;
  @ViewChild('reportSelector',{static:true}) reportSelector:SelectorComponent;

  form:FormGroup;

  checkingUniqueness = {
    email: false,
    username: false
  };

  user:UserModel;
  editUser:UserModel;
  editing:boolean = false;
  selectedCompany:Company;

  allPlantsChecked:boolean = false;

  vehicles:Vehicle[] = [];
  selectedVehicles:Vehicle[];
  allVehiclesChecked:boolean = false;

  reports:ReportModel[] = [];
  selectedReports:ReportModel[];
  allReportsChecked:boolean = false;

  products: any[] = [];

  loading:number = 0;

  types: SelectorConstants = new SelectorConstants();

  constructor(
    public dialogRef: MatDialogRef<UserFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: UserFormDialogData,
    public formBuilder:FormBuilder,
    public checkService:CheckFormService,
    public userService:UserService,
    public sessionStorage:SessionStorageService,
    private _snackBar: MatSnackBar,
    public translate:TranslateService,
    public vehicleService:VehicleService,
    public permissionService:PermissionService,
    public reportService:ReportServiceUser,
  ) {

    this.user = this.userService.getUserStore();
    this.selectedCompany = this.sessionStorage.retrieve('selectedCompany');
    console.log('CURRENT USER ',this.user);

    if(this.data.user){
      this.editUser = this.data.user;
      this.editing = true;
      this.allVehiclesChecked = this.editUser.all_vehicles;
      this.allReportsChecked = this.editUser.all_reports;
    }
  }

  ngOnInit() {
    this.initForm();

    if(this.editing)
      this.downloadUser();
  }

  ngAfterViewInit(){
    this.downloadVehicles();
    this.downloadReports();
  }

  initForm(){

    if(this.editing){
      this.form = this.formBuilder.group({
        name:[null,Validators.required],
        email:[null,Validators.required],
        username:[null,Validators.required],
      });
    }else{
      this.form = this.formBuilder.group({
        name:[null,Validators.required],
        email:[null,Validators.required],
        username:[null,Validators.required],
        password:[null,Validators.required],
        confirmPassword:[null,Validators.required]
      });
      this.buildConfigs();
    }

  }

  private downloadUser(): void {
    this.setLoading(true);
    this.userService.getResource(this.editUser.id).then(
      (user: UserModel) => {

        console.log("USER",user);

        this.editUser = user;
        this.form.get('email').disable();
        this.form.get('username').disable();
        this.form.patchValue({'name': user.name});
        this.form.patchValue({'username': user.username});
        this.form.patchValue({'email': user.email});

        this.allVehiclesChecked = user.all_vehicles;
        this.allReportsChecked = user.all_reports;

        this.buildConfigs();
      }
    ).catch(e => {
      console.log('ERROR ', e);
      this.openSnackBar(this.translate.data['msg.erro'], 'error');
    }).finally(() => {
      this.setLoading(false);
    });
  }


  private downloadReports(){
    this.setLoading(true);

    this.reportService.getResources().then((reports) => {
      this.reports = reports;
      this.reportSelector.setList(reports);
      if(this.editing)
        this.downloadSelectedReports();
    }).catch((e) => {
      console.log('ERROR',e);
      this.openSnackBar(this.translate.data['msg.erro'],'erro');
    }).finally(() => {
      this.setLoading(false);
    });
  }

  private downloadSelectedReports(): void {
    this.setLoading(true);
    this.reportService.getReportByUserId(this.editUser.id).then((reports) => {
      this.selectedReports = reports;
    }).finally(() => {
      this.reportSelector.setSelectedList(this.selectedReports);
      this.setLoading(false);
    });
  }

  private downloadVehicles(){

    this.setLoading(true);

    this.vehicleService.getUserVehicles(this.user.id).then((vehicles) => {
      this.vehicles = vehicles;
      this.vehicleSelector.setList(vehicles);
      if(this.editing)
        this.downloadSelectedVehicles();
    }).catch((e) => {
      this.openSnackBar(this.translate.data['msg.erro'],'error');
      console.log('ERROR ',e);
    }).finally(() => {
      this.setLoading(false);
     })
  }

  private downloadSelectedVehicles(): void {
    this.setLoading(true);

    this.vehicleService.getUserVehicles(this.editUser.id).then((vehicles: any[]) => {
      this.selectedVehicles = vehicles;
    }).finally(() => {
      this.vehicleSelector.setSelectedList(this.selectedVehicles);
      this.setLoading(false);
    });
  }

  filterPermissionByBusiness(emptyPermissions) {

    let filteredPermissions = [];

    console.log("emptyPermissions == ",emptyPermissions)

    for (let product of emptyPermissions) {

      if ( product['ID'] == permissions.SAFETY_BUSINESS_ID  ) {
        let idsToRemove = [permissions.DELAERS_GROUP_ACCESS_ID, permissions.SUPER_DEALER_ACCESS_ID];
        this.removeCmsAdminAccesses(product['ACCESSES'], idsToRemove);
        filteredPermissions.push(product);
      }

    }
    console.log(filteredPermissions)
    return filteredPermissions;
  }

  removeCmsAdminAccesses(cmsAccesses: any[], idsToRemove: number[]) {
    for (let id of idsToRemove) {
      for (let i = 0; i < cmsAccesses.length; i++) {
        if (cmsAccesses[i]['IDACCESS'] == id) {
          cmsAccesses.splice(i, 1);
          break;
        }
      }
    }
  }

  private buildConfigs(): void {

    let empty_permissions = JSON.parse(JSON.stringify(this.user.permissions));
    console.log("EMPT",empty_permissions)
    empty_permissions = this.filterPermissionByBusiness(empty_permissions);
    console.log("EMPT",empty_permissions)
    let editUser_permissions = this.editUser.permissions


    for (let product of empty_permissions) {
      product['CHECKED'] = false;
      product['CHECKED_SUPER'] = false;

      for (let access of product.ACCESSES) {
        access['CHECKED'] = false;
        for (let children of access['CHILDREN']) {
          children['CHECKED'] = false;
        }
      }
    }

    if ((this.editing && editUser_permissions.length)) {

      let access_ids: number[] = [];
      let productChecked: number[] = [];
      let productSuperUser: number[] = [];

      for (let product of editUser_permissions) {

        productChecked.push(product.ID);
        if (product.SUPER_USER == 1)
          productSuperUser.push(product.ID);

        for (let access of product.ACCESSES) {
          access_ids.push(access.IDACCESS);
          for (let child of access.CHILDREN) {
            access_ids.push(child.IDACCESS);
          }
        }
      }
      for (let product of empty_permissions) {

        product['CHECKED'] = productChecked.includes(product.ID);
        product['CHECKED_SUPER'] = productSuperUser.includes(product.ID);

        for (let access of product.ACCESSES) {
          access['CHECKED'] = access_ids.includes(access.IDACCESS);
          for (let children of access['CHILDREN']) {
            children['CHECKED'] = access_ids.includes(children.IDACCESS);
          }
        }
      }
    }
    this.products = empty_permissions;
    console.log('PRODUCTS',this.products);
  }

  generatePermissionsObject(): any[] {
    let permissions: any[] = [];

    let i = 0;
    if (this.products.length) {
      let clonedProducts = JSON.parse(JSON.stringify(this.products));
      for (let product of clonedProducts) {

        if (product['CHECKED']) {
          let accesses: number[] = [];
          let super_user: boolean = true;

          if (product['CHECKED_SUPER'] && this.user.permissions[i].SUPER_USER == 1) {
            product['SUPER_USER'] = 1;
            product['ACCESSES'] = [];
          }
          else {
            for (let access of product.ACCESSES) {
              if (access['CHECKED'] == true) {
                accesses.push(access['IDACCESS']);
                for (let children of access['CHILDREN']) {
                  if (children['CHECKED'])
                    accesses.push(children['IDACCESS']);
                }
              }
            }
            product['SUPER_USER'] = 0;
            product['ACCESSES'] = accesses;
          }
          permissions.push(product);
        }
        i++;
      }
    }
    return permissions;
  }



  async save(close=false){

    this.setLoading(true);

    try{
      await this.saveUserTab();
      await this.assignVehiclesSaveTab();
      await this.assignReportsSaveTab();
      this.openSnackBar(this.translate.data['msg.success'],'success');
    }
    catch(e){
      console.log('ERROR',e);
      this.openSnackBar(this.translate.data['msg.erro'],'error');
    }

    this.setLoading(false);
    if(close)
      this.dialogRef.close();
  }

  saveUserTab():Promise<any>{

    let user = new UserModel();

    user.name = this.form.get('name').value;
    user.username = this.form.get('username').value;
    user.permissions = this.generatePermissionsObject();

    console.log("PERMISSIONS",user.permissions)

    return new Promise<any>(async(resolve,rejects) => {

      if(this.editing){


        this.userService.update(user,this.editUser.id).then((user) => {
          this.editUser = user;
          this.editing = true;
          resolve();
        }).catch((e) => {
          rejects(e);
        })
      }
      else{
        user.email = this.form.get('email').value;
        user.password = this.form.get('password').value;
        user.all_centers = this.user.all_centers;
        user.all_persons = this.user.all_persons;
        user.all_vehicles = this.user.all_vehicles;
        user.all_reports = this.user.all_reports;
        user.company = this.selectedCompany.id;

        this.userService.create(user).then((user) => {
          this.editUser = user;
          this.editing = true;
          this.downloadUser();
          resolve();
        }).catch((e) => {
          rejects(e);
        });
      }


    });
  }

  public assignReportsSaveTab(): Promise<any> {
    let resources: any;

    let reports: any;
    if (this.allReportsChecked) {
      reports = [];
    } else {
      reports = this.reportSelector.getSelectedList().map((resource) => {
        resource['default'] = 0;
        return resource;
      });
    }

    resources = {
      all_reports: this.allReportsChecked,
      reports: reports
    };

    return new Promise<any>((resolve, reject) => {
      this.reportService.saveUserReport(resources, this.editUser.id).then(() => {
        resolve();
      }).catch((e) => {
        reject(e);
      });
    });
  }

  public assignVehiclesSaveTab(): Promise<any> {
    let resources: any;

    let vehicles: any;
    if (this.allVehiclesChecked) {
      vehicles = [];
    } else {
      vehicles = this.vehicleSelector.getSelectedList().map((resource) => {
        return resource.id;
      });
    }

    resources = {
      all_vehicles: this.allVehiclesChecked,
      vehicles: vehicles
    };

    return new Promise<any>((resolve, reject) => {
      this.vehicleService.saveUserVehicles(resources, this.editUser.id).then(() => {
        resolve();
      }).catch((e) => {
        reject(e);
      });
    });
  }

  public checkMasterAccess(product, event): void{
    if(event.checked){
      product.CHECKED = event.checked;
    }
    product.CHECKED_SUPER = event.checked;
  }

  public toggleProduct(expansionProduct, product: any, event = null): void {
    if (event) {
      product.CHECKED = event.checked;
      expansionProduct.expanded = event.checked;
    }
  }

  public toggleAccess(checked, access: any, product:any): void {
    if (access) {
      access.CHECKED = checked;
      if(checked){
        product.CHECKED = true;
      }
    }
  }

  hasMasterAccess(product){
    if(product.SUPER_USER)
      return true;

    return false;
  }


  public onReportCheckboxChange(event): void {
    if (!event.checked) {
      this.reportSelector.setSelectedList([]);
      this.reportSelector.setList(this.reports);
    }
  }

  public onVehicleCheckboxChange(event): void {
    if (!event.checked) {
      this.vehicleSelector.setSelectedList([]);
      this.vehicleSelector.setList(this.vehicles);
      console.log('VEHICLES',this.vehicles);
    }
  }


  setLoading(state:boolean){

    if(state)
      this.loading++;
    else
      this.loading--;

  }

  checkPassword(): boolean {
    return this.form.get('password').value === this.form.get('confirmPassword').value;
  }

  checkFieldValidator(field: string): boolean {
    return this.form.get(field).invalid && this.form.get(field).touched;
  }

  public openSnackBar(message: string, type:string) {
    let config: MatSnackBarConfig = {
        panelClass: 'snack_' + type,
        duration: 6000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom'
    };
    this._snackBar.open(message, "Ok", config);
}

  close(){
    this.dialogRef.close();
  }

}
