import {Injectable, OnDestroy} from '@angular/core';
import {UserModel} from '../models/user.model';
import {Company} from '../models/company.model';
import {CustomService} from './custom.service';
import {UserService} from './user.service';
import {CompanyService} from './company.service';
import {MqttController} from '../controllers/mqtt-controller';

@Injectable({
  providedIn: 'root'
})
export class BrokerService implements OnDestroy {

  MQTTController : MqttController;
  user: UserModel = null;
  company: Company = null;

  baseTopic: string;

  messageFunctionMap = {};
  connectionFunctionMap = {};

  constructor(
    private customService: CustomService,
    private userService: UserService,
    private companyService: CompanyService
  ) {
    this.MQTTController = new MqttController(this.customService,true,false);
    this.init();
  }

  startForComponent(topic:string,func) {
    this.connectionFunctionMap[topic] = func;
    if(this.MQTTController.isConnected()){
      this.connectionFunctionMap[topic](true);
    }
  }

  destroyForComponent(topic:string) {
    this.connectionFunctionMap[topic] = null;
    this.unsubscribe(this.baseTopic+topic);
  }

  init(){
    this.observeUserStorage();
    this.observeCompanyStorage();
    this.checkAndInitConnection();
  }

  onMessage = (message:any,topic:string) => {

    let topicParts = topic.split('/');
    let topicSuffix = '/'+topicParts[topicParts.length - 1];

    let func = this.messageFunctionMap[topicSuffix];

    if(func)
      func(message);
  };

  async initConnection() {
    if(this.user != null){

      console.log("Connecting broker flespi");
      this.MQTTController.refresh();
      this.MQTTController.onConnected = this.onConnected;
      this.MQTTController.onMessageArrived = this.onMessage;

      this.baseTopic = await this.MQTTController.downloadFlespiTokenAndConnect();
    }
  }

  subscribe(topic:string,qos:number,func){
    if(this.MQTTController.isConnected()){
      this.MQTTController.subscribeTo(this.baseTopic+topic,qos);
      console.log("SUBSCRIBE",this.baseTopic+topic);
      this.messageFunctionMap[topic] = func;
    }else{
      console.log("UNABLE TO SUBSCRIBE, MQTT IS NOT CONNECTED");
    }
  }

  private unsubscribe(topic:string) {
    if(this.MQTTController.isConnected()){
      this.MQTTController.unsubscribeTo(topic);
      this.messageFunctionMap[topic] = null;
    }
  }

  onConnected = () => {
    console.log("Connected");
    this.alertObservers(true);
  };

  alertObservers(param){
    for (let componentId in this.connectionFunctionMap) {
      let func = this.connectionFunctionMap[componentId];
      if(func != null){
        func(param);
      }
    }
  }

  checkAndInitConnection(){
    if(this.company != null && this.user != null && !this.MQTTController.isConnected() && !this.MQTTController.isConnecting()){
      this.initConnection().then(()=>{});
    }
  }

  private setUserName(){
    if(this.user != null)
      this.MQTTController.setUserName(`BROKER USER ${this.user.id.toString()}`);
  }

  observeUserStorage() {
    this.user = this.userService.getUserStore();
    this.setUserName();

    this.userService.getUserStoreObservable().subscribe(user => {

      if(this.user == null && user != null){
        this.user = user;
        this.setUserName();
        this.checkAndInitConnection();

      }else if(user == null && this.user != null){
        this.user = null;
      }
    });
  }

  observeCompanyStorage(){
    this.company = this.companyService.retrieveCompany();
    this.companyService.observeCompanyChanges().subscribe(company => {
      if(this.company == null && company != null) {
        this.company = company;
        this.checkAndInitConnection();
      }
      else if(company != null && this.company != null && company.id != this.company.id ){
        this.closeConnection();
        this.checkAndInitConnection();
      }
      else if(company == null && this.company != null){
        this.company = null;
      }
    });
  }

  closeConnection() {
    console.log("Disconnecting MQTT Broker");
    if(this.MQTTController != null && this.MQTTController.isConnected()){
      for (let topic in this.connectionFunctionMap) {
        this.unsubscribe(topic);
      }
      this.MQTTController.disconnect();
    }
  }

  ngOnDestroy() {
    this.closeConnection();
  }
}
