<div class="dialog_container">
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="dialog_header px-3 py-2">
    <h3 class="d-inline-flex mb-0" style="color:var(--foreground-default);font-size: 1.0em; font-weight: 400;">
      {{ 'app.geofence' | translate }}
    </h3>
    <i (click)="close()" style="color:var(--foreground-default)" class="mdi mdi-close"></i>
  </div>

  <form [formGroup]="form" style="border-top-color:var(--table-border)!important;background-color: var(--background-table-body)!important;" class="bg-white scroller border-top p-2 dialog_content">
    <div class="container-fluid">
      <div class="row mb-2">

        <div [hidden]="!isEditing()" class="col-12">
          <div class="input-text" loopFormField>
            <span>{{'label.code' | translate}}</span>
            <input type="text" [readOnly]="isEditing()" formControlName="erpkey" loopInput />
          </div>
        </div>

        <div class="col-12 mt-3">
          <div class="input-text" loopFormField [attr.valid]="!checkFieldValidator('name')">
            <span>{{'label.name' | translate}} *</span>
            <input type="text" formControlName="name" loopInput required />
            <i class="mdi mdi-information-outline" *ngIf="checkFieldValidator('name')" matTooltip="{{'msg.invaliddata' | translate}}"></i>
          </div>
        </div>

        <div class="col-6 mt-3">
          <app-resource-selector
              #typeSelector
              label="{{'label.type' | translate}}"
              [labelColor]="'var(--foreground-default)'"
              [control]="form.get('type')"
              customInputClass="d-flex flex-row align-items-center w-100 pt-0"
              [displayField]="'keylang'"
              [allowCreation]="false"
              [setFirstIfOnly]="false"
              [translation]="true"
              [customRenderer]="true"
              [canRemoveSelected]="true"
              [resources]="geofenceTypes"
              [position]="'bottom'"
              [maxHeight]="150"
          >
            <ng-template let-resource #itemTemplate >
              <div class="d-flex flex-row align-items-center w-100">
                <span>{{resource.keylang | translate}}</span>
                <span class="ml-auto" style="height: 8px;width: 15px;border-radius: 2px;border: 1px solid #0000000a" [style.backgroundColor]="resource.color"></span>
              </div>
            </ng-template>
          </app-resource-selector>
        </div>

        <div class="col-6 mt-3">
          <div class="input-text" loopFormField>
            <span>{{'label.geofence_max_speed' | translate}}</span>
            <input type="text" formControlName="global_max_speed" loopInput />
          </div>
        </div>

        <div class="col-6 mt-3">
          <div class="input-text" loopFormField>
            <span>{{'label.direction_from' | translate}}</span>
            <input type="text" formControlName="direction_from" loopInput />
          </div>
        </div>

        <div class="col-6 mt-3">
          <div class="input-text" loopFormField>
            <span>{{'label.direction_to' | translate}}</span>
            <input type="text" formControlName="direction_to" loopInput />
          </div>
        </div>

        <div class="col-12">
          <app-geofence-rules
            #geofenceRulesList
            *ngFor="let rule of rules; let index = index"
            [rule]="rule"
            [vehicleCategory]="vehicleCategories"
            [index]="index"
            [removeRule]="removeRule"
          ></app-geofence-rules>
          <button style="padding: 2px;height: 25px;line-height: 26px" (click)="addRule()" mat-button color="primary" class="mt-1">
            <span style="font-size: 0.8em; color:var(--foreground-default);" >{{'label.add' | translate}} {{'label.rule' | translate}}</span>
          </button>
        </div>
      </div>

    </div>
  </form>

  <div class="d-flex justify-content-end px-4 py-3 border-top" style="border-top-color:var(--table-border)!important;">
    <button mat-button color="primary" (click)="save(true)" class="ml-2" [disabled]="!canSave() || loading">
      {{'nav.saveClose'|translate}}
    </button>
    <button mat-button color="primary" (click)="save(false)" class="ml-2" [disabled]="!canSave() || loading">
      {{'nav.salvar'|translate}}
    </button>
  </div>
</div>
