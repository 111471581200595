<div>
    <button [ngClass]="small?'ml-auto small':'ml-auto'"  style="background:var(--background-stroked-button);color:var(--foreground-stroked-button);"  mat-stroked-button color="primary" [matMenuTriggerFor]="exportMenu">{{ keylangTitle |translate}}</button>
    <mat-menu #exportMenu="matMenu" xPosition="before" class="mat-menu-panel export-menu">
        <button mat-menu-item (click)="exporter.exportTable(type,{fileName:fileName})">
            <i class="mdi mdi-file-excel" style="color: #22a664;font-size: 1.2em;"></i>
            <span style="font-size: 0.9em;color:var(--foreground-default)"> .xls</span>
        </button>
      <button *ngFor="let addExporter of additionalExporters" mat-menu-item (click)="addExporter.exporter.exportTable(addExporter.type,{fileName:addExporter.filename})">
        <i class="mdi" [ngClass]="addExporter.icon" style="color: #22a664;font-size: 1.2em;"></i>
        <span style="font-size: 0.9em;color:var(--foreground-default)"> {{addExporter.title | translate}}</span>
      </button>
    </mat-menu>
</div>
