import {BaseService} from './base.service';
import {Inject, Injectable, Injector} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LiveMapService extends BaseService<any> {

  environment;

  constructor(public injector: Injector,@Inject('environment') private env ) {
    super(injector,env,'core/vehicles/map', (value) => {
      return value
    });
    this.environment = env;
  }

  async getVehicleTrackItMessages(startDateTime:string,endDateTime:string,vehicleId:number) {
    let url = `${this.environment.api}core/vehicle/trackit/${vehicleId}`
    let params = {
      date_time_start: startDateTime,
      date_time_end: endDateTime
    }
    return this.customGet(params,url)
  }

  async getVehicleWithMessages(customBody:any) {
    let headers = await this.buildHeader();
    return this.http.post(this.apiPath,customBody,{headers: headers}).toPromise().then(
      (res: {data:any[]}) => {
        return res.data;
      }).catch(e => {
      throw e;
    });
  }
}
