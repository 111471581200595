<div class="h-100 d-flex flex-column" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <mat-progress-bar mode="indeterminate" *ngIf="loading > 0"></mat-progress-bar>
    <div class="dialog_header px-3 py-2" cdkDragHandle>
      <h3 id="title"><i class="mdi mdi-file-chart mr-2"></i> {{'label.rule'|translate}}</h3>
      <i (click)="close()" class="mdi mdi-close"></i>
    </div>
    <div style="height:500px;background: var(--background-table-header);">
        <mat-tab-group class="h-100">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span style="color:var(--foreground-default);"><i class="mdi mdi-format-list-bulleted mr-2"></i> {{'label.shipping_rule'|translate}}</span>
                </ng-template>
                <div class="w-100 h-100 d-flex flex-column  scroller">
                    <div class="w-100 d-flex flex-row  px-3 pt-3">
                        <div class="d-flex flex-column">
                            <mat-radio-group class="d-flex align-items-center mb-2" [(ngModel)]="shippingType">
                                <mat-radio-button class="mr-3" *ngFor="let item of shippingTypes|keyvalue" [value]="item.key" style="color:var(--foreground-default);">{{item.value|translate}}</mat-radio-button>
                            </mat-radio-group>
                            <div class="d-flex flex-row align-items-center">
                                <mat-checkbox color="primary" [(ngModel)]="curdate"></mat-checkbox>
                                <span class="ml-2">Incluir dia atual</span>
                            </div>
                        </div>
                        
                        <div [matMenuTriggerFor]="hourMenu" class="d-flex flex-row ml-auto align-items-center" style="color: var(--foreground-default)">
                            <span class="form-label mr-2">{{'app.time' | translate}}</span>
                            <button mat-button class="menu_button d-flex flex-row" >
                                <span class="mb-0 mr-2">{{selectedTime}}:00</span>
                                <i class="mdi mdi-clock" ></i>
                            </button>
                            <mat-menu #hourMenu="matMenu">
                                <button mat-menu-item style="color: var(--foreground-default)" *ngFor="let hour of hours" (click)="selectedTime = hour" >{{(hour<10)?'0':''}}{{hour}}:00</button>
                            </mat-menu>
                            <!--input
                                type="time"
                                [(ngModel)]="selectedTime"
                                (change)="onHourChange($event)"
                                class="border-0 w-100 rounded"
                                matInput
                                step="3600000"
                            /-->
                        </div>
                    </div>
                    <div [ngClass]="(shippingType=='MONTHDAYS')?['d-flex']:['d-none']" class="flex-column p-3">
                        <mat-button-toggle-group style="width:calc(500px - 29px);" class="d-flex flex-row flex-wrap calendar"  (change)="selectDay($event,selectedMonthDays)" multiple>
                            <mat-button-toggle class="calendar" style="width:calc(((500px - 32px) / 7) - 8px);height:calc(((500px - 32px) / 7) - 8px);color:var(--foreground-default);" *ngFor="let day of monthDays;let i = index;"  [checked]="selectedMonthDays[i]==1"  [value]="i">{{i+1}}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div [ngClass]="(shippingType=='WEEKDAYS')?['d-flex']:['d-none']" class="flex-column p-3 weekdays">
                        <mat-button-toggle-group style="width:calc(500px - 30px);" class="d-flex flex-row flex-wrap" (change)="selectDay($event,selectedWeekDays)" multiple>
                            <mat-button-toggle style="width:calc((500px - 34px)/7);line-height: 34px!important;color:var(--foreground-default);" *ngFor="let day of weekDays;let i = index;"  [checked]="selectedWeekDays[i]==1" [value]="i">{{day|translate}}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span style="color:var(--foreground-default);"><i class="mdi mdi-account-group mr-2"></i> {{'label.recipients'|translate}}</span>
                </ng-template>
                <div class="w-100 d-flex flex-column p-3 h-100  scroller">
                    <div class="d-flex flex-row pb-3">

                        <div loopFormField class="w-100 pt-2" style="display: flex; flex-direction: column; font-size: 9pt; font-weight: 400;">
                            <input #recipientInput type="email" loopInput (input)="onRecipientSearch($event)" [matAutocomplete]="recipient" />
                            <label *ngIf="!validate(recipientInput.value)" style="color:var(--error-default);margin-bottom: 0px;">Email Inválido!</label>
                            <mat-autocomplete #recipient="matAutocomplete" (optionSelected)="onRecipientSelect($event, recipientInput)">
                                <mat-option *ngFor="let option of recipientOptions" [value]="option.email">
                                    {{option.email}}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                        <button mat-button class="ml-auto" color="primary" (click)="addRecipient(recipientInput)">
                            {{'label.add'|translate}}
                        </button>
                    </div>
                    <div  class="w-100 d-flex flex-column flex-grow-1">
                        <div class="d-flex flex-row pb-3" *ngFor="let recip of selectedRecipients;let i = index;">
                            <div loopFormField class="w-100 pt-2" style="display: flex; flex-direction: column; font-size: 9pt; font-weight: 400;">
                                <input #recipientInput type="email" loopInput  (blur)="onRecipientInputBlur(recipientInput, i)" (input)="onRecipientSearch($event)" [value]="recip"  [matAutocomplete]="recipient" />
                                <label *ngIf="!validate(recipientInput.value)" style="color:var(--error-default);margin-bottom: 0px;">Email Inválido!</label>
                                <mat-autocomplete #recipient="matAutocomplete" (optionSelected)="onRecipientSelect($event, recipientInput, i)">
                                    <mat-option *ngFor="let option of recipientOptions" [value]="option.email">
                                        {{option.email}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <button mat-button class="ml-auto" color="primary" (click)="removeRecipient(recip)">
                                <i class="mdi mdi-trash-can-outline"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="d-flex flex-row px-4 py-3 dialog_header" style="border-top: 1px solid var(--table-border);">
        <button mat-button color="primary" (click)="cancel()">
            {{'k.cancel'|translate}}
        </button>
        <button mat-button class="ml-auto" color="primary" (click)="confirm()">
            {{'label.confirm'|translate}}
        </button>
    </div>
</div>
