import {BaseModel} from './base.model';
import {VehicleCategory} from './vehicle-category.model';


export class SftGeofenceType extends BaseModel {
  constructor(
    public name?:string,
    public keylang?:string,
    public color?:string
  ){
    super();
  }
  static fromJson(json) : SftGeofenceType {
    return Object.assign(new SftGeofenceType(),json);
  }
}

export class SftGeofenceRule extends BaseModel {
  constructor(
    public vehicle_cat?: number | VehicleCategory,
    public max_speed?: number
  ){
    super()
  }

  static fromJson(json) : SftGeofenceRule {
    return Object.assign(new SftGeofenceRule(),json);
  }
}

export class SftGeofenceModel extends BaseModel {
  constructor(
    public erpkey?: string,
    public name?: string,
    public global_max_speed?:number,
    public direction_from?:number,
    public direction_to?:number,
    public coordinates?: any[],
    public rules?: SftGeofenceRule[],
    public visible?: boolean,
    public type?: SftGeofenceType,
  ){
    super();
    this.visible = true;
  }

  static fromJson(json) : SftGeofenceModel {
    return Object.assign(new SftGeofenceModel(),json);
  }
}
